import { Box, styled } from '@mui/material';

export const Wrapper = styled(Box)(() => {
  return {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    width: 'fit-content',
  };
});
