import { Box, Button, styled } from '@mui/material';

export const TitleBlockCSS = styled(Box)(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    alignItems: 'center',
    marginBottom: '26px',
  };
});

export const ButtonGroupCSS = styled(Box)(() => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',
  };
});
export const ButtonCSS = styled(Button)(() => {
  return {
    fontSize: '16px',
    borderRadius: '12px',
  };
});
