import React from 'react';
import { DatePickerCSS, Wrapper } from './styles';
import { useField, useFormikContext } from 'formik';
import { Typography } from '@mui/material';

import dayjs from 'dayjs';

interface IDatePickerFormikProps {
  name: string;
  minDate?: string;
  label?: string;
}

export const DatePickerFormik = ({
  name,
  label,
  minDate,
}: IDatePickerFormikProps) => {
  const [field] = useField(name);
  const { setFieldValue } = useFormikContext();

  const handleChange = (value: Date) => {
    const formatValueISO = dayjs(value).toISOString();
    setFieldValue(name, formatValueISO);
  };
  const today = minDate && new Date(minDate);
  const tomorrow =
    minDate && today && new Date(today.getTime() + 24 * 60 * 60 * 1000);

  return (
    <Wrapper>
      {label ? <Typography variant={'sm350'}>{label}</Typography> : null}
      <DatePickerCSS
        {...(!minDate && { disablePast: true })}
        {...(minDate && {
          minDate: dayjs(minDate),
        })}
        {...(field.value && {
          value: dayjs(field.value),
        })}
        minDate={dayjs(tomorrow)}
        slotProps={{
          textField: {
            size: 'large',
            variant: 'outlined',
            placeholder: 'Выберите дату',
          },
        }}
        onChange={handleChange}
        format='DD MMMM'
      />
    </Wrapper>
  );
};
