export const NeutralIcon = () => {
  return (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='10.5' cy='10' r='10' fill='#EECA0B' />
      <circle cx='6.5' cy='7' r='1' fill='black' />
      <circle cx='14.5' cy='7' r='1' fill='black' />
      <path
        d='M6.5 13C6.5 13 7.5 13 10.5 13C13.5 13 14.5 13 14.5 13'
        stroke='black'
        stroke-linecap='round'
      />
    </svg>
  );
};
