import React from 'react';
import { Box } from '@mui/material';
import { Wrapper } from './styles';
import { AvatarDropMenu, NavigationSidebar, Logo } from '..';
import { useAuthContext } from '../../context';

export const Sidebar = () => {
  const { user } = useAuthContext();

  return (
    <Wrapper>
      <Box mb={'28px'} ml={'18px'}>
        <Logo position='flex-start' />
      </Box>
      <NavigationSidebar />
      <AvatarDropMenu
        name={user?.firstName as string}
        jobAtWork={user?.position as string}
        avatarUrl={user?.photo?.url as string}
      />
    </Wrapper>
  );
};
