import { gql } from '@apollo/client';

export const OFFERS_BY_BRAND = gql`
  query OffersByBrand($options: GetOffersAsManagerInput!) {
    offersByBrand(options: $options) {
      rows {
        workDays {
          id
          day
          from
          to
          isActive
        }
        scheduleType
        id
        name
        budgetPerPerson
        restaurants {
          address
        }
      }
      count
    }
  }
`;
