import { Box, Button, styled } from '@mui/material';
import { palette } from '../../theme/foundations/index';

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '26px',
}));

export const TittleBlockCSS = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '7px',
}));
export const ButtonGroupCSS = styled(Box)(() => ({
  display: 'flex',
  gap: '16px',
}));

export const ButtonSaveChangesCSS = styled(Button)(() => ({
  width: 'fit-content',
  background: palette.green400,
  '&:hover': {
    background: palette.green400,
  },
}));
