import { LogoImg, LogoWrapper } from './styles';
import { ILogoProps } from './types';
import { useTranslatesContext } from '../../../context/TranslatesContext';

export const Logo = ({
  position = 'center',
  mb = '0px',
  width = 80,
}: ILogoProps) => {
  const { t } = useTranslatesContext();
  return (
    <LogoWrapper justifyContent={position} marginBottom={mb}>
      <LogoImg src={t('web_login_logo')} width={Number(width)} />
    </LogoWrapper>
  );
};
