import { Box, BoxProps, styled, TextField } from '@mui/material';
import { palette } from '../../theme/foundations/index';
import { AliasBoolTypes } from '../../types/baseTypes';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '../../constants/constants';

interface IWrapperProps extends BoxProps {
  istarget?: AliasBoolTypes;
  onClick?: () => void;
}

export const Wrapper = styled(Box)<IWrapperProps>(
  ({ theme, istarget, onClick }) => {
    const location = useLocation();

    return {
      display: 'flex',
      boxShadow: '0px 2px 16px rgba(33, 52, 103, 0.1)',
      borderRadius: '16px',
      background: palette.white,
      padding: '20px',
      ...(location.pathname === ROUTES.adminOffer && { cursor: 'pointer' }),
      ...(istarget === 'true' && {
        outline: `1px solid ${palette.primary.main}`,
      }),
      ...(onClick && theme.mixins.hoverItem),
    };
  },
);

export const ImageBlockCSS = styled(Box)({
  width: '89px',
  height: '89px',
  marginRight: '22px',
  img: {
    borderRadius: '8px',
    width: '89px',
    height: '89px',
  },
});

export const ContentCSS = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginRight: '46px',
});

export const PriceBlockCSS = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '16px',
  marginLeft: 'auto',
  position: 'relative',
});

export const PortionTF = styled(TextField)({
  '.MuiInputBase-root': {
    paddingRight: '15px',
    minHeight: '40px !important',
    width: '110px',
    input: {
      WebkitTextFillColor: 'black',
    },
  },
});

export const PriceTF = styled(TextField)({
  '.MuiInputBase-root': {
    paddingRight: '11px',
    minHeight: '40px !important',
    width: '110px',
    input: {
      WebkitTextFillColor: 'black',
    },
  },
});
export const PricePerPortionCSS = styled(Box)({
  color: 'red',
  position: 'absolute',
  right: 0,
  bottom: '5px',
});
