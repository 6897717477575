import * as yup from 'yup';
import { MESSAGES } from '../messages';

export const editBudgetPerGuestsSchema = yup.object().shape({
  baseValueBudget: yup
    .number()
    .positive(MESSAGES.INVALID_VALUE)
    .required(MESSAGES.REQUIRE_MESSAGE),
  minValueBudget: yup
    .number()
    .positive(MESSAGES.INVALID_VALUE)
    .required(MESSAGES.REQUIRE_MESSAGE),
  maxValueBudget: yup
    .number()
    .positive(MESSAGES.INVALID_VALUE)
    .required(MESSAGES.REQUIRE_MESSAGE),
});
