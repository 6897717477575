import React from 'react';

export const BackIcon = () => {
  return (
    <svg
      width='7'
      height='13'
      viewBox='0 0 7 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6 1.5L1 6.5L6 11.5'
        stroke='#3D3835'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
