import { Logo } from '../UI/Logo/Logo';
import { Wrapper } from './styles';

type LayoutType = {
  children: React.ReactNode;
  height?: string | number;
};

export const AuthFormWrapper = ({ children, height = 'auto' }: LayoutType) => {
  return (
    <Wrapper height={height}>
      <Logo mb={'38px'} width={140} />
      {children}
    </Wrapper>
  );
};
