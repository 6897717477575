import { Box, BoxProps, Button, styled } from '@mui/material';
import { palette } from '../../theme/foundations/index';

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  alignSelf: 'flex-start',
  flexWrap: 'wrap',
}));

interface TabBtnProps extends BoxProps {
  target?: 'true' | 'false';
}

export const ButtonCSS = styled(Button)<TabBtnProps>(({ target }) => ({
  background: target === 'true' ? palette.white300 : '',
  whiteSpace: 'nowrap',
  width: 'fit-content',
}));
