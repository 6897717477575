import { FieldArray, useField, useFormikContext } from 'formik';
import {
  AddCategoryButtonCSS,
  CategoriesListWrapperCSS,
  CategoryTitleCSS,
  CategoryTitleWrapperCSS,
  CategoryWrapper,
  RemoveCategoryButtonCSS,
} from './styles';
import { Box, IconButton, Typography } from '@mui/material';
import { palette } from '../../../theme/foundations';
import { TextFieldFormik } from '../../UI/TextFieldFormik/TextFieldFormik';
import { CloseModalIcon, CompleteIcon, TextIcon } from '../../Icons';
import { IFormikProps } from './types';
import { useTranslatesContext } from '../../../context/TranslatesContext';

type Props = {
  name: string;
};

export const CategoriesList = ({ name }: Props) => {
  const { t } = useTranslatesContext();
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const list = field.value as IFormikProps['categories'];
  return (
    <FieldArray name='categories'>
      {({ push, remove }) => {
        return (
          <CategoriesListWrapperCSS>
            {list.map((_, index: number) => {
              const title = list[index]?.title;
              const count = list[index]?.count;
              const isEdit = list[index]?.isEdit;

              const hasError = meta.error?.[index] && meta.touched;
              const errorInfo = meta.error?.[index] as
                | { title: string }
                | undefined;

              return (
                <CategoryWrapper key={index}>
                  <Box>
                    {!isEdit ? (
                      <>
                        {hasError ? (
                          <Box mb='8px' display={'flex'} alignItems='center'>
                            <Typography ml={'auto'} variant='error'>
                              {String(errorInfo?.title)}
                            </Typography>
                          </Box>
                        ) : null}
                        <CategoryTitleWrapperCSS
                          iserror={hasError ? 'true' : ''}
                        >
                          <CategoryTitleCSS>{title}</CategoryTitleCSS>
                          {count ? (
                            <Typography
                              color={palette.gray600}
                              sx={{ marginLeft: '5px' }}
                            >
                              {count}
                            </Typography>
                          ) : null}

                          <Typography
                            onClick={() => {
                              const newCategoriesValue = [...list];
                              list[index].isEdit = true;
                              setFieldValue(name, newCategoriesValue);
                            }}
                            variant='sm600'
                            component={'span'}
                            color={palette.gray600}
                          >
                            {t('web_categories_modal_edit_btn')}
                          </Typography>
                        </CategoryTitleWrapperCSS>
                      </>
                    ) : (
                      <TextFieldFormik
                        size='large'
                        name={`${name}.${index}.title`}
                        sx={{ width: '350px' }}
                        IconButton={
                          <>
                            {count ? (
                              <Box sx={{ marginRight: '5px' }}>
                                <TextIcon text={String(count)} />
                              </Box>
                            ) : null}

                            {title ? (
                              <IconButton
                                sx={{ marginRight: '5px' }}
                                onClick={() => {
                                  const newCategoriesValue = [...list];
                                  list[index].isEdit = false;
                                  setFieldValue(name, newCategoriesValue);
                                }}
                              >
                                <CompleteIcon />
                              </IconButton>
                            ) : null}
                          </>
                        }
                      />
                    )}
                  </Box>
                  {!count ? (
                    <RemoveCategoryButtonCSS
                      onClick={() => remove(index)}
                      size='small'
                    >
                      <CloseModalIcon size='12px' />
                    </RemoveCategoryButtonCSS>
                  ) : null}
                </CategoryWrapper>
              );
            })}
            <AddCategoryButtonCSS
              variant='text'
              onClick={() => push({ title: '', count: 0, isEdit: true })}
            >
              {t('web_categories_modal_add_item_btn')}
            </AddCategoryButtonCSS>
          </CategoriesListWrapperCSS>
        );
      }}
    </FieldArray>
  );
};
