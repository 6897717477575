import { Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  AuthFormWrapper,
  AuthLayout,
  Button,
  CheckboxFormik,
  TextFieldFormik,
} from '../../components';
import { LS, ROUTES } from '../../constants/constants';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { loginSchema } from '../../validation';
import { Wrapper, RememberWrapper } from './styles';
import {
  Manager,
  useManagerSignInMutation,
} from '../../graphql/generated/graphql';
import { useAlertContext, useAuthContext } from '../../context/index';
import { IFormikProps } from './types';

export const Login = () => {
  const navigate = useNavigate();
  const { signIn: onAuth } = useAuthContext();
  const { t } = useTranslatesContext();
  const { onOpenAlert } = useAlertContext();

  const handleRegistrationNavigation = () => {
    navigate(ROUTES.registration);
  };
  const handlePasswordResetNavigation = () => {
    navigate(ROUTES.resetPassword);
  };

  const [signIn] = useManagerSignInMutation();

  const handleSubmit = (values: IFormikProps) => {
    signIn({
      variables: {
        managerSignInInput: {
          email: values.email,
          password: values.password,
        },
      },
      onCompleted: (data) => {
        if (values.saved) {
          localStorage.setItem(
            LS.TOKEN_BYDE_ADMIN,
            data.managerSignIn.accessToken,
          );
        } else {
          sessionStorage.setItem(
            LS.TOKEN_BYDE_ADMIN,
            data.managerSignIn.accessToken,
          );
        }
        onAuth(data.managerSignIn.manager as Manager);
      },
      onError: (error) => {
        onOpenAlert({ title: error.message, error: true });
      },
    });
  };

  return (
    <AuthLayout backgroundimage={t('web_login_bg')}>
      <AuthFormWrapper height={583}>
        <Wrapper>
          <Formik<IFormikProps>
            initialValues={{
              email: '',
              password: '',
              saved: false,
            }}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
          >
            {({ dirty, isValid, setFieldValue, values }) => {
              return (
                <Form>
                  <TextFieldFormik
                    name='email'
                    placeholder={t('web_login_email_placeholder')}
                    type='text'
                    label={t('web_login_email_label')}
                  />
                  <TextFieldFormik
                    name='password'
                    placeholder={t('web_login_password_placeholder')}
                    type='password'
                    label={t('web_login_password_label')}
                    mt='10px'
                  />
                  <RememberWrapper>
                    <CheckboxFormik name='saved' mr='10px' />
                    <Typography
                      variant='body1'
                      sx={{ cursor: 'pointer' }}
                      onClick={() => setFieldValue('saved', !values.saved)}
                    >
                      {t('web_login_remember_me')}
                    </Typography>
                  </RememberWrapper>

                  <Button
                    mt='30px'
                    type='submit'
                    disabled={!(isValid && dirty)}
                  >
                    {t('web_login_auth_btn')}
                  </Button>
                  <Button
                    mt='15px'
                    variant='outlined'
                    onClick={handleRegistrationNavigation}
                  >
                    {t('web_login_register_btn')}
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </Wrapper>
        <Button
          mt='auto'
          variant='text'
          onClick={handlePasswordResetNavigation}
          sx={{ fontSize: '14px' }}
        >
          {t('web_login_forgot_pass_btn')}
        </Button>
      </AuthFormWrapper>
    </AuthLayout>
  );
};
