import { useNavigate } from 'react-router-dom';
import { BackIcon } from '../../Icons';
import { Wrapper } from './styles';
import { IBackButtonProps } from './types';

export const BackButton = ({ sx }: IBackButtonProps) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };
  return (
    <Wrapper onClick={handleBack} sx={sx}>
      <BackIcon />
    </Wrapper>
  );
};
