import React, { useCallback, useEffect, useState } from 'react';
import {
  AlertOfferToClient,
  BackButton,
  MainLayout,
  OrderMenuList,
  PageTitleBlock,
  PaginationRow,
  RequestHandler,
  SearchTextField,
} from '../../components';
import { Box, Typography, Button } from '@mui/material';
import { FilterTabs } from '../../components/FilterTabs/FilterTabs';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { IOfferDish } from './types';
import {
  Dish,
  useAddOrUpdateOfferDishesMutation,
  useDishCategoriesQuery,
  useDishesLazyQuery,
  useOfferQuery,
} from '../../graphql/generated/graphql';
import { COUNTER_TYPES, ROUTES } from '../../constants/constants';
import { useAccesses, usePagination } from '../../hooks';
import { client } from '../../graphql/client';
import { getDishesTransformData } from '../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { useAlertContext } from '../../context';

export const AdminOfferEditDishesPage = () => {
  const navigate = useNavigate();
  const { onOpenAlert } = useAlertContext();
  const { t } = useTranslatesContext();
  const { id } = useParams();
  const { isOfferEditAccess } = useAccesses();

  const [searchValue, setSearchValue] = useState('');
  const [offerDishes, setOfferDishes] = useState<Array<IOfferDish>>([]);
  const [activeCategories, setActiveCategories] = useState<Array<number>>([]);

  const [mutate] = useAddOrUpdateOfferDishesMutation({
    onCompleted: () => {
      onOpenAlert({ title: t('web_alert_update_offer_dishes') });
      navigate(-1);
    },
  });

  const {
    data: offerData,
    loading: offerLoading,
    error: offerError,
  } = useOfferQuery({
    variables: { offerId: Number(id) },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  const [
    query,
    {
      data: dishesData,
      loading: dishesLoading,
      error: dishesError,
      fetchMore,
      refetch,
    },
  ] = useDishesLazyQuery({
    fetchPolicy: 'network-only',
  });

  const brandId = offerData?.offer.brand.id;

  const {
    data: dishCategories,
    loading: dishCategoriesLoading,
    error: dishCategoriesError,
  } = useDishCategoriesQuery({
    variables: { brandId: Number(brandId) },
    skip: !brandId,
  });

  const handleSetAllItems = () => {
    const result = [] as Array<IOfferDish>;
    (dishesData?.dishes.rows || []).forEach((dish) => {
      if (!offerDishes.map((item) => item.id).includes(dish.id)) {
        result.push({
          count: 1,
          id: dish.id,
          price: dish.price,
          title: dish.title,
          img: dish.image.url,
        });
      }
    });

    setOfferDishes((prev) => [...prev, ...result]);
  };

  const handleChangeItem = (
    eventRating: COUNTER_TYPES,
    id: number,
    price: number,
    title: string,
    img: string,
  ) => {
    const index = offerDishes.findIndex((dish) => dish.id === id);

    if (index === -1) {
      if (eventRating === COUNTER_TYPES.PLUS) {
        setOfferDishes((prev) => [
          ...prev,
          { count: 1, id, price, title, img },
        ]);
      }
    } else {
      if (eventRating === COUNTER_TYPES.PLUS) {
        setOfferDishes((prev) => {
          const newValue = [...prev];
          newValue[index].count++;
          return newValue;
        });
      } else {
        setOfferDishes((prev) => {
          const newValue = [...prev];
          if (newValue[index].count > 1) {
            newValue[index].count--;
            return newValue;
          } else {
            newValue.splice(index, 1);
            return newValue;
          }
        });
      }
    }
  };

  const {
    paginationOptions,
    handleLoadMore: paginationHandleLoadMore,
    handleChangePage,
    setPaginationOptions,
  } = usePagination();

  const getDishesQuery = (limit?: number, offset?: number) => {
    limit = limit ?? paginationOptions.itemsCount;
    offset = offset ?? paginationOptions.offset;

    client.cache.evict({ fieldName: 'dishes' });

    query({
      variables: {
        options: {
          limit,
          offset,
          brandId: brandId, //TODO
          dishCategoryIds: activeCategories,
          search: searchValue,
          restaurantsIds: (offerData?.offer.restaurants || []).map((i) => i.id),
        },
      },
    });
  };

  const handleChangeSearchValue = (e: React.ChangeEvent<any>) => {
    setSearchValue(e.target.value);
  };

  const handleUpdateOfferDishes = () => {
    const formatedDishesValues = offerDishes.map((offerDish) => {
      return {
        dishId: offerDish.id,
        quantity: offerDish.count,
      };
    });
    mutate({
      variables: {
        input: {
          offerDishes: formatedDishesValues,
          offerId: Number(id),
        },
      },
    });
  };

  useEffect(() => {
    if (offerData) {
      setOfferDishes(() => {
        return (offerData.offer.offerDishes || []).map((offerDish) => {
          return {
            id: Number(offerDish.dishId),
            count: offerDish.quantity,
            price: offerDish.dish.price,
            title: offerDish.dish.title,
            img: offerDish.dish.image.url,
          };
        });
      });
    }
  }, [offerData]);

  useEffect(() => {
    brandId && offerData && getDishesQuery();
  }, [paginationOptions.page, brandId, offerData]);

  useEffect(() => {
    brandId && offerData && getDishesQuery(paginationOptions.itemsCount, 0);
  }, [searchValue, brandId, activeCategories, offerData]);

  const handleLoadMore = () => {
    paginationHandleLoadMore(fetchMore);
  };

  const data = dishesData?.dishes.rows;
  const allItemsCount = dishesData?.dishes.count || 0;

  const currentItemsCount =
    paginationOptions.itemsCount * paginationOptions.page >= allItemsCount
      ? allItemsCount
      : paginationOptions.itemsCount * paginationOptions.page;

  const pagesCount = Math.ceil(allItemsCount / paginationOptions.itemsCount);

  const formatedData = getDishesTransformData(data as Array<Dish>);

  const dishCategoriesOptions = !dishCategories
    ? []
    : dishCategories.dishCategories?.map((item) => ({
        label: item.title,
        id: String(item.id),
      }));

  const page =
    paginationOptions.page > pagesCount ? pagesCount : paginationOptions.page;

  const onClickPagination = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    handleChangePage(value);
  };

  const handleAddDish = () => {
    navigate(`${ROUTES.addDish}/?brandId=${brandId}`);
  };

  const handleTabs = (id: string) => {
    setActiveCategories((prev) => {
      if (prev.includes(Number(id))) {
        return prev.filter((item) => item !== Number(id));
      } else return [...prev, Number(id)];
    });
    setPaginationOptions((prev) => {
      return {
        limit: prev.limit,
        offset: 0,
        itemsCount: prev.itemsCount,
        page: 1,
      };
    });
  };

  const getTotaOfferPrice = useCallback(() => {
    return offerDishes.reduce((acc, item) => {
      return acc + item.count * Number(item.price);
    }, 0);
  }, [offerDishes]);

  const totalOfferPrice = getTotaOfferPrice();

  return (
    <MainLayout>
      <Box mb={'10px'}>
        <BackButton />
      </Box>
      <Typography mb={'4px'}>{t('web_offer_dishes_title')}</Typography>
      {offerData?.offer.name ? (
        <PageTitleBlock title={offerData?.offer.name} />
      ) : null}

      <SearchTextField
        value={searchValue}
        onChange={handleChangeSearchValue}
        sx={{ mb: '45px' }}
      />
      <Box mb={'40px'}>
        <FilterTabs
          options={dishCategoriesOptions}
          activeTabs={activeCategories}
          onClick={handleTabs}
        />
      </Box>
      {data && data.length === 0 && allItemsCount === 0 ? (
        <Button
          size={'large'}
          sx={{ width: 'fit-content' }}
          onClick={handleAddDish}
        >
          {t('web_restaurant_menu_add_dish_btn')}
        </Button>
      ) : (
        <RequestHandler
          loading={dishesLoading || offerLoading || dishCategoriesLoading}
          error={dishesError || offerError || dishCategoriesError}
        >
          <>
            {formatedData.map((list, index) => {
              return (
                <OrderMenuList
                  key={list.data[0].id}
                  label={list.label}
                  data={list.data}
                  refetch={refetch}
                  handleChangeItem={handleChangeItem}
                  offerDishes={offerDishes}
                  isHasAllBtn={index === 0}
                  handleSetAllItems={handleSetAllItems}
                />
              );
            })}

            {pagesCount > 1 ? (
              <PaginationRow
                from={currentItemsCount as number}
                fromText={t('web_pagination_out_of')}
                to={allItemsCount as number}
                onClickButton={handleLoadMore}
                onClickPagination={onClickPagination}
                buttonName={t('web_pagination_show_more')}
                pagesCount={pagesCount}
                page={page}
              />
            ) : null}
          </>
        </RequestHandler>
      )}

      {totalOfferPrice ? (
        <AlertOfferToClient
          title={offerData?.offer.name}
          subTitle={`${offerData?.offer.budgetPerPerson}$ / ${t(
            'web_eit_offer_dishes_per_person',
          )}`}
          offerDishes={offerDishes}
          countDishes={String(offerDishes.length)}
          offerInitPrice={Number(offerData?.offer.budgetPerPerson)}
          totalOfferPrice={totalOfferPrice}
          onSubmit={handleUpdateOfferDishes}
          isDisabledButton={!isOfferEditAccess}
        />
      ) : null}
    </MainLayout>
  );
};
