import React from 'react';

export const BrandIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.31055 1C4.88737 1 5.34458 1.48668 5.3086 2.06238L5.06627 5.93969C5.03036 6.51427 5.48668 7 6.06238 7C6.58941 7 7.02561 6.59021 7.05849 6.0642L7.3164 1.93762C7.34934 1.41059 7.78639 1 8.31445 1H10C10.5523 1 11 1.44772 11 2V6C11 6.55228 11.4477 7 12 7C12.5523 7 13 6.55228 13 6V2C13 1.44772 13.4477 1 14 1H15.6855C16.2136 1 16.6507 1.41059 16.6836 1.93762L16.9415 6.0642C16.9744 6.59021 17.4106 7 17.9376 7C18.5133 7 18.9696 6.51427 18.9337 5.93969L18.6914 2.06238C18.6554 1.48668 19.1126 1 19.6895 1H20.3307C21.2972 1 22.1255 1.69125 22.2984 2.64223L23.4148 8.78224C23.703 10.3677 22.566 11.8339 21 11.9869V21.5C21 22.3284 20.3284 23 19.5 23C18.6716 23 18 22.3284 18 21.5V12H13V21C13 22.1046 12.1046 23 11 23H5C3.89543 23 3 22.1046 3 21V11.9869C1.43385 11.8341 0.296565 10.3678 0.584853 8.78224L1.70122 2.64223C1.87412 1.69125 2.70239 1 3.66896 1H4.31055Z'
        fill='#B5B1AE'
      />
    </svg>
  );
};
