import { Box, styled } from '@mui/material';

export const Wrapper = styled(Box)(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
  };
});
export const LabelCSS = styled(Box)(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '8px',
  };
});
export const MainCSS = styled(Box)(() => {
  return {
    display: 'flex',
    alignItems: 'center',
  };
});
