import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ILogoImgProps } from './types';

export const LogoWrapper = styled(Box)(() => ({
  display: 'flex',
}));

export const LogoImg = styled('img')(({ width = 80 }: ILogoImgProps) => ({
  width,
}));
