import * as yup from 'yup';
import { MESSAGES } from '../messages';

export const editGuestsSchema = yup.object().shape({
  baseValueGuest: yup
    .number()
    .positive(MESSAGES.INVALID_VALUE)
    .required(MESSAGES.REQUIRE_MESSAGE),
  minValueGuest: yup
    .number()
    .positive(MESSAGES.INVALID_VALUE)
    .required(MESSAGES.REQUIRE_MESSAGE),
  maxValueGuest: yup
    .number()
    .positive(MESSAGES.INVALID_VALUE)
    .required(MESSAGES.REQUIRE_MESSAGE),
});
