import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import image from './../../../assets/images/logo-bg.jpg';

import { IWrapperProps } from './types';

export const Wrapper = styled(Box)(
  ({ backgroundimage = image }: IWrapperProps) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${backgroundimage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    minHeight: '100vh',
  }),
);
