import { Paper, Table, TableBody, TableContainer } from '@mui/material';
import { TableListType } from './types';
import { CircularIndeterminate } from '../CircularIndeterminate/CircularIndeterminate';
import { TableListHeader } from './components/TableListHeader/TableListHeader';
import { TableListRow } from './components/TableListRow';

export const CustomTable = ({
  isLoading,
  headCells,
  rows,
  tableContainerSx,
  sortInfo,
  handleRow,
}: TableListType) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        height: 'calc(100vh - 335px)',
        ...tableContainerSx,
      }}
    >
      {isLoading ? (
        <CircularIndeterminate />
      ) : (
        <Table stickyHeader aria-label='simple table'>
          <TableListHeader headCells={headCells} sortInfo={sortInfo} />

          <TableBody>
            {rows?.map((row, index) => {
              return (
                <TableListRow
                  key={index}
                  row={row}
                  headCells={headCells}
                  handleRow={handleRow}
                />
              );
            })}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};
