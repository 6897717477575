import {
  Schedule_Enum,
  WorkDay,
  Workday_Enum,
} from '../../graphql/generated/graphql';
import { IScheduleOptions } from '../../pages/AddOrUpdateRestaurant/types';
import {
  transformWorkTimeString,
  transformWorkTimeToNumber,
} from './transformWorkTime';

export const transformCreateWordDays = (
  type: Schedule_Enum,
  data: Array<IScheduleOptions>,
) => {
  if (type === Schedule_Enum.Static) {
    return data.map((shedule) => {
      const item = {
        day: shedule.day,
        from: transformWorkTimeToNumber(data[0].from),
        to: transformWorkTimeToNumber(data[0].to),
        isActive: shedule.isActive,
      } as WorkDay;

      if (shedule.id) {
        item.id = shedule.id;
      }
      return item;
    });
  } else {
    return data.map((shedule) => {
      const item = {
        day: shedule.day,
        from: transformWorkTimeToNumber(shedule.from),
        to: transformWorkTimeToNumber(shedule.to),
        isActive: shedule.isActive,
      } as WorkDay;
      if (shedule.id) {
        item.id = shedule.id;
      }
      return item;
    });
  }
};

export const getLabelForDay = (day: Workday_Enum) => {
  switch (day) {
    case Workday_Enum.Monday:
      return 'web_add_res_schedule_day_1';
    case Workday_Enum.Tuesday:
      return 'web_add_res_schedule_day_2';
    case Workday_Enum.Wednesday:
      return 'web_add_res_schedule_day_3';
    case Workday_Enum.Thursday:
      return 'web_add_res_schedule_day_4';
    case Workday_Enum.Friday:
      return 'web_add_res_schedule_day_5';
    case Workday_Enum.Saturday:
      return 'web_add_res_schedule_day_6';
    case Workday_Enum.Sunday:
      return 'web_add_res_schedule_day_7';
    default:
      return 'web_add_res_schedule_day_1';
  }
};

export const transformToFormikFormat = (data: Array<WorkDay>) => {
  return data.map((day) => {
    return {
      from: transformWorkTimeString(day.from),
      to: transformWorkTimeString(day.to),
      isActive: day.isActive,
      label: getLabelForDay(day.day),
      day: day.day,
      id: day.id,
    };
  });
};
