export const palette = {
  primary: {
    main: '#FD720D',
  },
  secondary: {
    main: '#B5B1AE',
  },
  error: { main: '#FF3B30' },

  black: '#000',

  black400: 'rgba(0, 0, 0, 0.85)',
  white: '#FFF',
  white100: '#F9F9F9',
  white200: '#EFEEEE',
  white300: '#D7CFCA',
  white400: '#E3E0DE',
  brown500: '#3D3835',
  gray400: '#F5F3F1',
  gray500: '#B5B1AE',
  gray550: '#9A9AB0',
  gray600: '#92929D',
  gray650: '#969BA0',
  gray700: 'rgba(154, 154, 176, 0.55)',
  gray750: '#B3B1B8',
  gray800: 'rgba(33, 52, 103, 0.1)',
  gray900: 'rgba(61, 56, 53, 0.24)',
  gray1000: 'rgba(218, 218, 218, 0.2)',
  dark500: '#11142D',
  green300: '#6FCF97',
  green400: '#00B074',
  orange500: '#FF9F0A',
  input: {
    text: '#3D3835',
    placeholder: '#B5B1AE',
    border: '#E3E0DE',
    outline: '#96A0B5',
    label: '#11142D',
    error: '#FF3B30',
  },
  button: {
    textBg: '#F5F3F1',
    backBtnBg: '#E3E0DE',
  },
};

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    black: string;
    white: string;
    brown500: string;
    gray500: string;
    dark500: string;
    gray900: string;
    white200: string;
    white300: string;
    orange500: string;
    gray1000: string;
    green400: string;
    gray650: string;
    gray750: string;
    black400: string;
    gray400: string;
    gray550: string;
    input: Record<string, string>;
    button: Record<string, string>;
  }

  interface PaletteOptions {
    black: string;
    white: string;
    brown500: string;
    gray500: string;
    dark500: string;
    gray900: string;
    white200: string;
    white300: string;
    orange500: string;
    gray1000: string;
    green400: string;
    gray650: string;
    gray750: string;
    black400: string;
    gray400: string;
    gray550: string;
    input: Record<string, string>;
    button: Record<string, string>;
  }
}
