import React, { useEffect, useState } from 'react';
import {
  BackButton,
  DropzoneFormik,
  MainLayout,
  PageTitleBlock,
  RequestHandler,
  TextFieldFormik,
} from '../../components';
import { Box, Button } from '@mui/material';
import { Form, Formik } from 'formik';
import { addBrandsSchema } from '../../validation';
import { IFormikProps } from './types';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useBrandLazyQuery,
  useCreateBrandMutation,
  useUpdateBrandMutation,
} from '../../graphql/generated/graphql';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { useAlertContext } from '../../context';

export const AddOrUpdateBrand = () => {
  const { id } = useParams();
  const { t } = useTranslatesContext();
  const navigate = useNavigate();

  const { onOpenAlert } = useAlertContext();

  const [mutation] = useCreateBrandMutation({
    onCompleted: () => {
      onOpenAlert({ title: t('web_alert_add_brand') });
      navigate(-1);
    },
  });

  const [updateMutation] = useUpdateBrandMutation({
    onCompleted: () => {
      onOpenAlert({ title: t('web_alert_update_brand') });
      navigate(-1);
    },
  });

  const [getBrand, { loading, error }] = useBrandLazyQuery({
    onCompleted: ({ brand }) => {
      setInitialValues({
        title: brand.title,
        description: brand.description,
        image: brand.image.url,
      });
    },
    fetchPolicy: 'network-only',
  });
  const handleSubmit = (
    values: IFormikProps,
    { resetForm }: { resetForm: () => void },
  ) => {
    if (!id) {
      mutation({ variables: { createBrandInput: values } });
      resetForm();
    } else {
      updateMutation({
        variables: {
          input: {
            id: Number(id),
            title: values.title,
            description: values.description,
            image: typeof values.image === 'string' ? null : values.image,
          },
        },
      });
    }
  };

  const [initialValues, setInitialValues] = useState<IFormikProps>({
    title: '',
    description: '',
    image: null,
  });

  useEffect(() => {
    if (id) {
      getBrand({ variables: { brandId: Number(id) } });
    }
  }, [id]);

  return (
    <MainLayout>
      <Box mb={'10px'}>
        <BackButton />
      </Box>
      <PageTitleBlock
        title={
          !id ? t('web_add_brand_page_title') : t('web_edit_brand_page_title')
        }
        subTitle={''}
      />
      <RequestHandler loading={loading} error={error} height='50vh'>
        <Formik<IFormikProps>
          initialValues={initialValues}
          validationSchema={addBrandsSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <TextFieldFormik
              name={'title'}
              mb={'24px'}
              label={t('web_add_brand_title_label')}
              placeholder={t('web_add_brand_title_placeholder')}
            />
            <TextFieldFormik
              name={'description'}
              multiline
              label={t('web_add_brand_desc_label')}
              placeholder={t('web_add_brand_desc_placeholder')}
              minRows={3}
              mb={'24px'}
            />
            <DropzoneFormik
              name={'image'}
              label={t('web_add_brand_logo_label')}
              subLabel={t('web_add_brand_logo_sub_label')}
              mb={'24px'}
            />
            <Box>
              <Button sx={{ maxWidth: '275px' }} size={'large'} type={'submit'}>
                {!id
                  ? t('web_add_brand_submit_btn')
                  : t('web_edit_brand_submit_btn')}
              </Button>
            </Box>
          </Form>
        </Formik>
      </RequestHandler>
    </MainLayout>
  );
};
