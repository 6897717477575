import React from 'react';

export const AnaliticsIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.5 22H3C2.45 22 2 21.5 2 20.8889V9.77778C2 9.16667 2.45 8.66667 3 8.66667H6.5C7.05 8.66667 7.5 9.16667 7.5 9.77778V20.8889C7.5 21.5 7.05 22 6.5 22ZM13.75 2H10.25C9.7 2 9.25 2.5 9.25 3.11111V20.8889C9.25 21.5 9.7 22 10.25 22H13.75C14.3 22 14.75 21.5 14.75 20.8889V3.11111C14.75 2.5 14.3 2 13.75 2ZM21 10.8889H17.5C16.95 10.8889 16.5 11.3889 16.5 12V20.8889C16.5 21.5 16.95 22 17.5 22H21C21.55 22 22 21.5 22 20.8889V12C22 11.3889 21.55 10.8889 21 10.8889Z'
        fill='#B5B1AE'
      />
    </svg>
  );
};
