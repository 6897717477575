import React from 'react';

export const CloseChipIcon = () => {
  return (
    <svg
      width='8'
      height='8'
      viewBox='0 0 8 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.535534 0.464466C0.652744 0.347256 0.811715 0.281408 0.977476 0.281408C1.14324 0.281408 1.30221 0.347256 1.41942 0.464466L4.07107 3.11612L6.72272 0.464466C6.83993 0.347256 6.9989 0.281408 7.16466 0.281408C7.33042 0.281408 7.48939 0.347256 7.6066 0.464466C7.72381 0.581677 7.78966 0.740648 7.78966 0.906408C7.78966 1.07217 7.72381 1.23114 7.6066 1.34835L4.95495 4L7.6066 6.65165C7.72381 6.76886 7.78966 6.92783 7.78966 7.09359C7.78966 7.25935 7.72381 7.41832 7.6066 7.53553C7.48939 7.65274 7.33042 7.71859 7.16466 7.71859C6.9989 7.71859 6.83993 7.65274 6.72272 7.53553L4.07107 4.88388L1.41942 7.53553C1.30221 7.65274 1.14324 7.71859 0.977476 7.71859C0.811715 7.71859 0.652744 7.65274 0.535534 7.53553C0.418323 7.41832 0.352476 7.25935 0.352476 7.09359C0.352475 6.92783 0.418324 6.76886 0.535534 6.65165L3.18718 4L0.535534 1.34835C0.418324 1.23114 0.352475 1.07217 0.352476 0.906408C0.352476 0.740647 0.418323 0.581677 0.535534 0.464466Z'
        fill='#11142D'
      />
    </svg>
  );
};
