export const transformWorkTimeToNumber = (time: string) => {
  switch (time) {
    case '00:00':
      return 0;
    case '01:00':
      return 100;
    case '02:00':
      return 200;
    case '03:00':
      return 300;
    case '04:00':
      return 400;
    case '05:00':
      return 500;
    case '06:00':
      return 600;
    case '07:00':
      return 700;
    case '08:00':
      return 800;
    case '09:00':
      return 900;
    case '10:00':
      return 1000;
    case '11:00':
      return 1100;
    case '12:00':
      return 1200;
    case '13:00':
      return 1300;
    case '14:00':
      return 1400;
    case '15:00':
      return 1500;
    case '16:00':
      return 1600;
    case '17:00':
      return 1700;
    case '18:00':
      return 1800;
    case '19:00':
      return 1900;
    case '20:00':
      return 2000;
    case '21:00':
      return 2100;
    case '22:00':
      return 2200;
    case '23:00':
      return 2300;
    default:
      return 2400;
  }
};

export const transformWorkTimeString = (time: number) => {
  switch (time) {
    case 0:
      return '00:00';
    case 100:
      return '01:00';
    case 200:
      return '02:00';
    case 300:
      return '03:00';
    case 400:
      return '04:00';
    case 500:
      return '05:00';
    case 600:
      return '06:00';
    case 700:
      return '07:00';
    case 800:
      return '08:00';
    case 900:
      return '09:00';
    case 1000:
      return '10:00';
    case 1100:
      return '11:00';
    case 1200:
      return '12:00';
    case 1300:
      return '13:00';
    case 1400:
      return '14:00';
    case 1500:
      return '15:00';
    case 1600:
      return '16:00';
    case 1700:
      return '17:00';
    case 1800:
      return '18:00';
    case 1900:
      return '19:00';
    case 2000:
      return '20:00';
    case 2100:
      return '21:00';
    case 2200:
      return '22:00';
    case 2300:
      return '23:00';
    default:
      return '24:00';
  }
};
