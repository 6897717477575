import React, { ChangeEvent, useState } from 'react';
import {
  MainLayout,
  OffersSearchedNowList,
  PageTitleBlock,
  SearchTextField,
} from '../../components';
import { useDebounce } from '../../hooks';
import { useTranslatesContext } from '../../context/TranslatesContext';

export const OffersSearchedNowPage = () => {
  const [searchText, setSearchText] = useState('');
  const { t } = useTranslatesContext();

  const handleSearchText = (e: ChangeEvent<any>) => {
    setSearchText(e.target.value);
  };

  const searchTextDebounce = useDebounce(searchText);

  return (
    <MainLayout>
      <PageTitleBlock title={t('web_offer_title_search_now')} />
      <SearchTextField
        value={searchText}
        onChange={handleSearchText}
        sx={{ mb: '45px' }}
      />
      <OffersSearchedNowList searchText={searchTextDebounce} />
    </MainLayout>
  );
};
