import React from 'react';

export const PlusIcon = () => {
  return (
    <svg
      width='14'
      height='15'
      viewBox='0 0 14 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask id='path-1-inside-1_3431_54818' fill='white'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.00033 1.90002C8.00033 1.34774 7.55261 0.900024 7.00033 0.900024C6.44804 0.900024 6.00033 1.34774 6.00033 1.90002V6.50002H1C0.447715 6.50002 0 6.94774 0 7.50002C0 8.05231 0.447715 8.50002 1 8.50002H6.00033V13.1C6.00033 13.6523 6.44804 14.1 7.00033 14.1C7.55261 14.1 8.00033 13.6523 8.00033 13.1V8.50002H13C13.5523 8.50002 14 8.05231 14 7.50002C14 6.94774 13.5523 6.50002 13 6.50002H8.00033V1.90002Z'
        />
      </mask>
      <path
        d='M6.00033 6.50002V8.50002C7.10489 8.50002 8.00033 7.60459 8.00033 6.50002H6.00033ZM6.00033 8.50002H8.00033C8.00033 7.39545 7.10489 6.50002 6.00033 6.50002V8.50002ZM8.00033 8.50002V6.50002C6.89576 6.50002 6.00033 7.39545 6.00033 8.50002H8.00033ZM8.00033 6.50002H6.00033C6.00033 7.60459 6.89576 8.50002 8.00033 8.50002V6.50002ZM7.00033 2.90002C6.44804 2.90002 6.00033 2.45231 6.00033 1.90002H10.0003C10.0003 0.243171 8.65718 -1.09998 7.00033 -1.09998V2.90002ZM8.00033 1.90002C8.00033 2.45231 7.55261 2.90002 7.00033 2.90002V-1.09998C5.34347 -1.09998 4.00033 0.24317 4.00033 1.90002H8.00033ZM8.00033 6.50002V1.90002H4.00033V6.50002H8.00033ZM1 8.50002H6.00033V4.50002H1V8.50002ZM2 7.50002C2 8.05231 1.55228 8.50002 1 8.50002V4.50002C-0.656854 4.50002 -2 5.84317 -2 7.50002H2ZM1 6.50002C1.55228 6.50002 2 6.94774 2 7.50002H-2C-2 9.15688 -0.656854 10.5 1 10.5V6.50002ZM6.00033 6.50002H1V10.5H6.00033V6.50002ZM8.00033 13.1V8.50002H4.00033V13.1H8.00033ZM7.00033 12.1C7.55261 12.1 8.00033 12.5477 8.00033 13.1H4.00033C4.00033 14.7569 5.34347 16.1 7.00033 16.1V12.1ZM6.00033 13.1C6.00033 12.5477 6.44804 12.1 7.00033 12.1V16.1C8.65718 16.1 10.0003 14.7569 10.0003 13.1H6.00033ZM6.00033 8.50002V13.1H10.0003V8.50002H6.00033ZM13 6.50002H8.00033V10.5H13V6.50002ZM12 7.50002C12 6.94774 12.4477 6.50002 13 6.50002V10.5C14.6569 10.5 16 9.15688 16 7.50002H12ZM13 8.50002C12.4477 8.50002 12 8.05231 12 7.50002H16C16 5.84317 14.6569 4.50002 13 4.50002V8.50002ZM8.00033 8.50002H13V4.50002H8.00033V8.50002ZM6.00033 1.90002V6.50002H10.0003V1.90002H6.00033Z'
        fill='#11142D'
        mask='url(#path-1-inside-1_3431_54818)'
      />
    </svg>
  );
};
