import React from 'react';

export const PromoIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_3585_11244)'>
        <path
          d='M4.75342 23.0556C5.15186 23.3461 5.6333 23.2548 6.22266 22.8232L12 18.5898L17.7773 22.8232C18.3667 23.2548 18.8564 23.3461 19.2466 23.0556C19.6284 22.7651 19.7197 22.2836 19.4873 21.5864L17.2212 14.7963L23.04 10.6127C23.6294 10.1894 23.8618 9.74945 23.7041 9.28461C23.5547 8.83636 23.123 8.59564 22.376 8.60394L15.2373 8.64545L13.0542 1.83051C12.8301 1.12494 12.4814 0.776306 12 0.776306C11.5186 0.776306 11.1699 1.12494 10.9458 1.83051L8.7627 8.64545L1.62402 8.60394C0.885254 8.59564 0.445312 8.83636 0.295898 9.28461C0.138183 9.74945 0.370605 10.1894 0.959961 10.6127L6.77881 14.7963L4.521 21.5864C4.28027 22.2836 4.37158 22.7651 4.75342 23.0556Z'
          fill='#B5B1AE'
        />
      </g>
      <defs>
        <clipPath id='clip0_3585_11244'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
