import React, { useCallback, useEffect, useState } from 'react';
import { Wrapper } from './styles';
import { OffersItem } from '../OffersItem/OffersItem';
import { PaginationRow } from '../PaginationRow/PaginationRow';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { AlertDishCreateMenu } from '../AlertDishCreateMenu/AlertDishCreateMenu';
import {
  Order_Offer_By_Enum,
  Sort_Direction_Enum,
  useActiveOffersLazyQuery,
  useCountFavoritesQuery,
  useOffersToFavoritesMutation,
} from '../../graphql/generated/graphql';
import { RequestHandler } from '../UI/RequestHandler/RequestHandler';
import { usePagination } from '../../hooks/index';
import { client } from '../../graphql/client';
import { useAlertContext } from '../../context';

import { COUNT_FAVORITES } from '../../graphql/queries/countFavorites.gql';

interface IOffersToFavorite {
  toFavoritesIds: Array<number>;
  removeFromFavoritesIds: Array<number>;
}

export const OffersSearchedNowList = ({
  searchText,
}: {
  searchText: string;
}) => {
  const { t } = useTranslatesContext();
  const { onOpenAlert } = useAlertContext();

  const [offersToFavorite, setOffersToFavorite] = useState<IOffersToFavorite>({
    toFavoritesIds: [],
    removeFromFavoritesIds: [],
  });

  const [targetOfferQuantity, setTargetOfferQuantity] = useState(0);

  const {
    paginationOptions,
    handleLoadMore: paginationHandleLoadMore,
    handleChangePage,
  } = usePagination();

  const [
    getOffers,
    { data: offersData, loading, error, fetchMore, networkStatus },
  ] = useActiveOffersLazyQuery({
    notifyOnNetworkStatusChange: true,
  });

  const [offersToFavoriteMutation] = useOffersToFavoritesMutation({
    onCompleted: () => {
      onOpenAlert({ title: t('web_alert_update_favorite_offers') });
      setOffersToFavorite({
        toFavoritesIds: [],
        removeFromFavoritesIds: [],
      });
    },
    refetchQueries: [COUNT_FAVORITES],
  });

  const {
    data: countFavoritesData,
    loading: countFavoritesLoading,
    error: countFavoritesError,
  } = useCountFavoritesQuery({
    fetchPolicy: 'network-only',
  });

  const hadnleGetOffers = (limit?: number, offset?: number) => {
    limit = limit ?? paginationOptions.itemsCount;
    offset = offset ?? paginationOptions.offset;

    client.cache.evict({ fieldName: 'activeOffers' });

    getOffers({
      variables: {
        options: {
          limit,
          offset,
          search: searchText,
          sort: {
            by: Order_Offer_By_Enum.CreatedAt,
            direction: Sort_Direction_Enum.Asc,
          },
        },
      },
    });
  };

  useEffect(() => {
    if (countFavoritesData) {
      setTargetOfferQuantity(countFavoritesData.countFavorites);
    }
  }, [countFavoritesData]);

  useEffect(() => {
    hadnleGetOffers();
  }, [searchText, paginationOptions.page]);

  const handleLoadMore = () => {
    const additionalInformation = {
      sort: {
        by: Order_Offer_By_Enum.CreatedAt,
        direction: Sort_Direction_Enum.Asc,
      },
    };
    paginationHandleLoadMore(fetchMore, additionalInformation);
  };

  const onClickPagination = (e: React.ChangeEvent<unknown>, value: number) => {
    handleChangePage(value);
  };

  const handleChangeOffersToFavorite = useCallback(
    (id: number, chekedValue: boolean) => {
      setOffersToFavorite((prev) => {
        const newValue = { ...prev };
        if (!chekedValue) {
          if (prev.toFavoritesIds.includes(id)) {
            return {
              ...newValue,
              toFavoritesIds: newValue.toFavoritesIds.filter((i) => i !== id),
            };
          } else {
            return {
              ...newValue,
              removeFromFavoritesIds: [...newValue.removeFromFavoritesIds, id],
            };
          }
        } else {
          if (prev.removeFromFavoritesIds.includes(id)) {
            return {
              ...newValue,
              removeFromFavoritesIds: newValue.removeFromFavoritesIds.filter(
                (i) => i !== id,
              ),
            };
          } else {
            return {
              ...newValue,
              toFavoritesIds: [...newValue.toFavoritesIds, id],
            };
          }
        }
      });
    },
    [offersToFavorite],
  );

  const handleSaveActionAlert = () => {
    offersToFavoriteMutation({
      variables: {
        updates: offersToFavorite,
      },
    });
  };

  const handleSetTargetOfferQuantity = (action: any) => {
    if (action === 'up') {
      setTargetOfferQuantity((prevState) => prevState + 1);
    } else {
      if (targetOfferQuantity === 0) return;
      setTargetOfferQuantity((prevState) => prevState - 1);
    }
  };

  const allItemsCount = offersData?.activeOffers.count || 0;

  const pagesCount = Math.ceil(allItemsCount / paginationOptions.itemsCount);
  const currentItemsCount =
    paginationOptions.itemsCount * paginationOptions.page >= allItemsCount
      ? allItemsCount
      : paginationOptions.itemsCount * paginationOptions.page;

  const page =
    paginationOptions.page > pagesCount ? pagesCount : paginationOptions.page;

  return (
    <>
      <RequestHandler
        loading={(loading && networkStatus !== 3) || countFavoritesLoading}
        error={error || countFavoritesError}
        height={'50vh'}
      >
        <Wrapper>
          {offersData?.activeOffers.rows.map((item) => {
            const restaurantImagesArrays = item.restaurants?.map(
              (restaurant) => {
                return {
                  id: Number(restaurant?.image?.id),
                  url: String(restaurant?.image?.url),
                };
              },
            );

            const isFavorite =
              item.isFavorite ||
              offersToFavorite.toFavoritesIds.includes(item.id);

            return (
              <OffersItem
                handleChangeOffersToFavorite={handleChangeOffersToFavorite}
                key={item.id}
                id={item.id}
                isFavorite={isFavorite}
                url={item.image?.url}
                rating={3}
                name={item.name}
                title={item.name}
                subTitle={item.description}
                price={String(item.budgetPerPerson)}
                avatarUrl={restaurantImagesArrays}
                setTargetOfferQuantity={handleSetTargetOfferQuantity}
              />
            );
          })}
        </Wrapper>
        <AlertDishCreateMenu
          title={`${t('web_offer_alert_selected')}: ${targetOfferQuantity}`}
          onClick={handleSaveActionAlert}
          btnText={t('web_offer_alert_action_save')}
        />
      </RequestHandler>
      {pagesCount > 1 ? (
        <PaginationRow
          from={currentItemsCount as number}
          fromText={t('web_pagination_out_of')}
          to={allItemsCount as number}
          onClickButton={handleLoadMore}
          onClickPagination={onClickPagination}
          buttonName={t('web_pagination_show_more')}
          pagesCount={pagesCount}
          page={page}
        />
      ) : null}
    </>
  );
};
