import React from 'react';

export const UsersIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 0C5.3726 0 0 5.37257 0 12C0 18.6274 5.3726 23.9999 12 23.9999C18.6274 23.9999 24 18.6274 24 12C24 5.37257 18.6274 0 12 0ZM8.00052 9.48915C8.00622 7.22497 9.79489 5.3913 12.0005 5.3913C14.2061 5.3913 15.9948 7.22496 16.0005 9.48913C15.9948 11.7533 14.2061 13.587 12.0005 13.587C9.79489 13.587 8.00622 11.7533 8.00052 9.48915ZM8.00052 9.48915L8.0005 9.5V9.47829L8.00052 9.48915ZM16.0005 9.48913L16.0005 9.47829V9.5L16.0005 9.48913ZM19.2479 18.8901C19.4981 18.627 19.5674 18.2382 19.4037 17.9142C18.5155 16.157 16.7278 14.9566 14.6672 14.9566H9.33384C7.27305 14.9566 5.48525 16.1571 4.59719 17.9145C4.43347 18.2385 4.5028 18.6273 4.75297 18.8903C6.57475 20.8059 9.14808 22 12.0003 22C14.8527 22 17.4261 20.8058 19.2479 18.8901Z'
        fill='#B5B1AE'
      />
    </svg>
  );
};
