import React from 'react';
import { ApplicationItem } from '../ApplicationItem/ApplicationItem';
import { Wrapper } from './styles';
import { PaginationRow } from '../PaginationRow/PaginationRow';
import { useTranslatesContext } from '../../context/TranslatesContext';

import { RegistrationApplication } from '../../graphql/generated/graphql';

interface IApplicationListProps {
  data?: Array<RegistrationApplication> | undefined;
  handleLoadMore: () => void;
  handleChangePage: (page: number) => void;
  pagesCount: number;
  page: number;
  allItemsCount?: number;
  currentItemsCount?: number;
}

export const ApplicationList = ({
  data,
  handleLoadMore,
  handleChangePage,
  pagesCount,
  page,
  allItemsCount,
  currentItemsCount,
}: IApplicationListProps) => {
  const { t } = useTranslatesContext();

  const onClickPagination = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    handleChangePage(value);
  };

  return (
    <>
      <Wrapper>
        {data?.map((item) => (
          <ApplicationItem
            key={item.id}
            id={item.id}
            name={item.name}
            title={item?.restaurantName}
            phoneNumber={item?.phone}
          />
        ))}
      </Wrapper>
      {pagesCount > 1 ? (
        <PaginationRow
          from={currentItemsCount as number}
          fromText={t('web_pagination_out_of')}
          to={allItemsCount as number}
          onClickButton={handleLoadMore}
          onClickPagination={onClickPagination}
          buttonName={t('web_pagination_show_more')}
          pagesCount={pagesCount}
          page={page}
        />
      ) : null}
    </>
  );
};
