import { Roles } from '../graphql/generated/graphql';

export const getUserRoleKey = (role: Roles) => {
  switch (role) {
    case Roles.RestaurantEdit:
      return 'web_users_role_edit_rest';
    case Roles.RestaurantRemove:
      return 'web_users_role_delete_rest';
    case Roles.DishEdit:
      return 'web_users_role_edit_dish';
    case Roles.DishRemove:
      return 'web_users_role_delete_dish';
    case Roles.ManagerEdit:
      return 'web_users_role_edit_user';
    case Roles.ManagerRemove:
      return 'web_users_role_delete_user';
    case Roles.OrderConfirm:
      return 'web_users_role_confirm_order';
    case Roles.OrderReject:
      return 'web_users_role_reject_order';
    case Roles.OfferEdit:
      return 'web_users_role_edit_offer';
    case Roles.Administrator:
      return 'web_users_role_super_admin';
    default:
      return '';
  }
};
