import { Box, Button as ButtonMUI } from '@mui/material';
import { IButtonProps } from './types';

export const Button = ({
  variant = 'contained',
  size = 'medium',
  disabled = false,
  mb = '0px',
  mr = '0px',
  mt = '0px',
  width = '100%',
  height = '44px',
  children,
  type = 'button',
  onClick = () => {},
  sx,
}: IButtonProps) => {
  return (
    <Box width={width} mb={mb} mr={mr} mt={mt}>
      <ButtonMUI
        variant={variant}
        size={size}
        disabled={disabled}
        fullWidth={true}
        type={type}
        sx={{ height, ...sx }}
        onClick={onClick}
      >
        {children}
      </ButtonMUI>
    </Box>
  );
};
