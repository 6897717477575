import React from 'react';

export const Placeholder = () => {
  return (
    <svg
      width='55'
      height='50'
      viewBox='0 0 55 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.5475 38H35.4863C43.2092 37.3572 42.552 29.3225 41.0731 27.0728L36.9652 20.4844C34.9934 18.3953 33.2516 17.9133 29.5709 23.0555C25.8902 28.1977 23.6556 27.0728 21.1908 24.0196C18.8903 21.9306 14.9467 22.4127 13.3035 28.5191C11.7901 34.1433 16.2612 38 19.5475 38Z'
        fill='#9A9AB0'
        fillOpacity='0.55'
      />
      <circle cx='18' cy='14' r='2' fill='#9A9AB0' fillOpacity='0.55' />
      <rect
        x='31'
        y='26'
        width='22.1423'
        height='22.1423'
        rx='11.0711'
        fill='#F9F9F9'
      />
      <path
        d='M47.8959 37.9525C48.3826 37.9525 48.7772 37.5579 48.7772 37.0712C48.7772 36.5844 48.3827 36.1899 47.8959 36.1899L42.9524 36.1899L42.9525 31.2464C42.9525 30.7597 42.5579 30.3651 42.0712 30.3651C41.5845 30.3651 41.1899 30.7597 41.1899 31.2464L41.1899 36.19L36.2463 36.19C35.7596 36.19 35.365 36.5846 35.365 37.0713C35.365 37.558 35.7596 37.9526 36.2463 37.9526L41.1898 37.9525L41.1898 42.896C41.1898 43.3828 41.5844 43.7773 42.0711 43.7773C42.5578 43.7773 42.9524 43.3827 42.9524 42.896L42.9524 37.9525L47.8959 37.9525Z'
        fill='#C5C5D1'
      />
      <rect
        x='0.5'
        y='0.5'
        width='54'
        height='49'
        rx='7.5'
        stroke='#9A9AB0'
        fillOpacity='0.55'
      />
    </svg>
  );
};
