import { Box, Typography } from '@mui/material';

interface IProps {
  title: string;
}

export const KitchenLabel = ({ title }: IProps) => {
  return (
    <Box
      sx={{
        padding: '2.5px 12px',
        borderRadius: 30,
        backgroundColor: '#B5B1AE26',
        display: 'inline-block',
      }}
    >
      <Typography
        variant='sm550'
        lineHeight={'18px'}
        color={'#3D3835'}
        sx={{ display: 'inline-block' }}
      >
        {title}
      </Typography>
    </Box>
  );
};
