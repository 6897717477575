import React from 'react';
import { Box, Divider, TextField, Typography } from '@mui/material';
import {
  MenuInfoBlockCSS,
  MenuItemWrapperCSS,
  MenuRowCSS,
} from '../AlertOfferToClient/styles';
import { palette } from '../../theme/foundations/index';
import { IMenuToAlertProps } from './types';
import { FlexColumn, FlexRow } from './styles';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { IOfferDish } from '../../pages/OrderPage/types';

export const MenuToAlert = ({
  options,
  price,
  excessAmount,
  isAlertTitle,
  // exceedingTheAmount,
  coastMenu,
  // discountForTheCustomer,
  amountOfTheDiscount,
  handleDiscount,
  isHasDiscountField,
  isOrderType,
}: IMenuToAlertProps) => {
  const { t } = useTranslatesContext();

  const discountValue = Number(coastMenu) - Number(price);

  return (
    <MenuItemWrapperCSS sx={{ bottom: isAlertTitle ? '120px' : '66px' }}>
      {options.length ? (
        <>
          <MenuInfoBlockCSS>
            {options.map((el: IOfferDish) => {
              const totalItemPrice = Number(el.price) * Number(el.count);
              return (
                <MenuRowCSS key={el.id}>
                  <Typography>{`${el.title} - ${el.count} шт.`}</Typography>
                  <Typography
                    sx={{ marginLeft: 'auto' }}
                  >{`$${totalItemPrice}`}</Typography>
                </MenuRowCSS>
              );
            })}
          </MenuInfoBlockCSS>
          <Box mt={'11px'} mb={'10px'}>
            <Divider />
          </Box>
        </>
      ) : (
        <Typography mb={'10px'}>Список блюд пуст</Typography>
      )}

      {isHasDiscountField ? (
        <Box mb={'11px'}>
          <Typography mb={'8px'}>{t('web_alert_amount_discount')} $</Typography>
          <TextField
            value={amountOfTheDiscount}
            type='number'
            onChange={handleDiscount}
            sx={{
              input: {
                WebkitTextFillColor: '#000 !important',
                width: 200,
              },
            }}
            inputProps={{ max: coastMenu, min: 0 }}
          />
        </Box>
      ) : null}

      <FlexRow>
        <FlexColumn>
          <Typography variant={'md700'}>
            {isOrderType ? t('web_alert_total') : t('web_promo_title')}: $
            {price}
          </Typography>

          {excessAmount ? (
            <Typography
              mt={'10px'}
              variant={'sm600'}
              color={isAlertTitle ? palette.error.main : palette.gray550}
            >
              {`${t(
                isAlertTitle
                  ? 'web_alert_exceeding_amount'
                  : 'web_alert_remainder_amount',
              )} $${excessAmount}`}
            </Typography>
          ) : null}
        </FlexColumn>
        {!isOrderType ? (
          <FlexColumn alignItems={'end'}>
            {coastMenu && (
              <Typography color={'gray600'}>
                {t('web_alert_coast_for_menu')}: ${coastMenu}
              </Typography>
            )}

            {discountValue > 0 ? (
              <Typography color={'gray600'}>
                {t('web_alert_discount_for_person')}: ${discountValue}
              </Typography>
            ) : null}
          </FlexColumn>
        ) : null}
      </FlexRow>
    </MenuItemWrapperCSS>
  );
};
