import React from 'react';
import { IconProps } from '../../types/baseTypes';

export const MenuIcon = ({ size = '24' }: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3.59967 0C2.80438 0 2.15967 0.64471 2.15967 1.44V7.68C2.15967 9.34196 2.83234 10.4284 3.79258 11.1395C4.60319 11.7398 5.59804 12.0566 6.47967 12.2731V22.56C6.47967 23.3553 7.12438 24 7.91967 24C8.71496 24 9.35966 23.3553 9.35966 22.56V12.2731C10.2413 12.0566 11.2361 11.7398 12.0468 11.1395C13.007 10.4284 13.6797 9.34196 13.6797 7.68V1.44C13.6797 0.644709 13.035 0 12.2397 0C11.4444 0 10.7997 0.644709 10.7997 1.44V7.68C10.7997 8.47614 10.4618 8.87263 10.085 9.09662C9.85382 9.23409 9.59435 9.31289 9.35966 9.35559V1.44C9.35966 0.64471 8.71495 0 7.91967 0C7.12438 0 6.47967 0.64471 6.47967 1.44V9.35808C6.23863 9.31601 5.97521 9.23699 5.74268 9.09754C5.3727 8.87569 5.03967 8.48046 5.03967 7.68V1.44C5.03967 0.64471 4.39496 0 3.59967 0Z'
        fill='#B5B1AE'
      />
      <path
        d='M21.8403 2.03956C21.8403 -0.191538 18.6761 -0.628036 18.0721 1.51973L15.0372 12.3102C14.6066 13.8412 15.7572 15.36 17.3476 15.36H18.9604V22.56C18.9604 23.3553 19.6051 24 20.4004 24C21.1956 24 21.8403 23.3553 21.8403 22.56V2.03956Z'
        fill='#B5B1AE'
      />
    </svg>
  );
};
