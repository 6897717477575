import { Box, styled } from '@mui/material';
import { AliasBoolTypes } from '../../types/baseTypes';
import { palette } from '../../theme/foundations/index';

export const Wrapper = styled(Box)(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '16px',
    boxShadow: '0px 2px 16px rgba(33, 52, 103, 0.1)',
    padding: '20px',
    background: palette.white,
  };
});

export const HeadRowCSS = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '8px',
});

export const MainCSS = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  marginBottom: '16px',
});
export const MainTypographyCSS = styled(Box)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const ImageRowCSS = styled(Box)(() => ({
  display: 'flex',
  gap: '8px',
}));

interface IButtonCroupCSSProps {
  disabledjustyfycontent?: AliasBoolTypes;
}

export const ButtonCroupCSS = styled(Box)<IButtonCroupCSSProps>(
  ({ disabledjustyfycontent }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent:
      disabledjustyfycontent === 'true' ? 'unset' : 'space-between',
    gap: '8px',
    marginTop: 'auto',
  }),
);
