import { useAuthContext } from '../context';
import { Roles } from '../graphql/generated/graphql';

export const useAccesses = () => {
  const { user } = useAuthContext();

  const roles = user?.roles || [];

  const isAddRestaurantAccess = roles.includes(Roles.RestaurantEdit);
  const isRemoveRestaurantAccess = roles.includes(Roles.RestaurantRemove);
  const isDishEditAccess = roles.includes(Roles.DishEdit);
  const isDishRemoveAccess = roles.includes(Roles.DishRemove);
  const isManagerEditAccess = roles.includes(Roles.ManagerEdit);
  const isManagerRemoveAccess = roles.includes(Roles.ManagerRemove);
  const isOfferEditAccess = roles.includes(Roles.OfferEdit);
  const isOrderConfirmAccess = roles.includes(Roles.OrderConfirm);
  const isOrderRejectAccess = roles.includes(Roles.OrderReject);
  const isSuperAdmin = roles.includes(Roles.Administrator);

  return {
    isAddRestaurantAccess,
    isRemoveRestaurantAccess,
    isDishEditAccess,
    isDishRemoveAccess,
    isManagerEditAccess,
    isManagerRemoveAccess,
    isOfferEditAccess,
    isOrderConfirmAccess,
    isOrderRejectAccess,
    isSuperAdmin,
  };
};
