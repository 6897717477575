import React, { useCallback, useContext } from 'react';
import { RequestHandler } from '../components';

import {
  TranslatesQuery,
  useTranslatesQuery,
} from '../graphql/generated/graphql';

type TranslatesContextType = {
  t: (key: string) => string;
};

export const TranslatesContext = React.createContext<TranslatesContextType>({
  t: () => '',
});

export function useTranslatesContext() {
  const translatesContext = useContext(TranslatesContext);
  if (!translatesContext) {
    throw new Error(
      'useTranslatesContext must be used within a TranslatesProvider',
    );
  }
  return translatesContext;
}

type TranslatesContextProviderType = {
  children: React.ReactNode;
};

type TranslatesInfoType = {
  [key: string]: string;
};

export function TranslatesContextProvider({
  children,
}: TranslatesContextProviderType) {
  const { data, loading, error } = useTranslatesQuery({
    variables: {
      localeCode: 'en',
    },
  });

  const getTranslateInfo = useCallback(
    (data: TranslatesQuery | undefined): TranslatesInfoType => {
      const result: TranslatesInfoType = {};
      data?.translates?.forEach((item) => {
        const value = item.strings.length
          ? item.strings[0].string
          : item.texts.length
          ? item.texts[0].text
          : item.images[0].image.url;
        result[item.key] = value;
      });

      return result;
    },
    [data],
  );

  const translates = getTranslateInfo(data);

  const getTranslateValue = useCallback(
    (key: string) => {
      return translates[key] ?? key;
    },
    [translates],
  );

  return (
    <TranslatesContext.Provider
      value={{
        t: getTranslateValue,
      }}
    >
      <RequestHandler loading={loading} error={error}>
        {children}
      </RequestHandler>
    </TranslatesContext.Provider>
  );
}
