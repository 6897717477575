import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  MainLayout,
  PageTitleBlock,
  RequestHandler,
  RestaurantList,
} from '../../components';
import { ROUTES } from '../../constants/constants';
import { useTranslatesContext } from '../../context/TranslatesContext';
import {
  Restaurant,
  useRestaurantsQuery,
} from '../../graphql/generated/graphql';
import { useAuthContext } from '../../context';
import { useAccesses } from '../../hooks/index';
import { client } from '../../graphql/client';

export const RestaurantsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslatesContext();
  const { user } = useAuthContext();
  const { isAddRestaurantAccess } = useAccesses();

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const brandIdParams = searchParams.get('brandId');

  const { data, loading, error } = useRestaurantsQuery({
    variables: {
      getRestaurantsInput: {
        brandId: Number(user?.brand?.id || brandIdParams),
        limit: 1000,
        offset: 0,
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    client.cache.evict({ fieldName: 'restaurants' });
  }, []);

  const handleAddRestourant = () => {
    navigate(ROUTES.addRestaurant);
  };

  return (
    <MainLayout>
      <RequestHandler loading={loading} error={error} height='50vh'>
        <>
          <PageTitleBlock
            title={t('web_restaraunts_title')}
            subTitle={t('web_restaraunts_sub_title')}
            onClick={handleAddRestourant}
            isDisabledButton={!isAddRestaurantAccess}
            buttonText={
              !data?.restaurants.rows.length ? '' : t('web_restaraunts_add_btn')
            }
          />
          <RestaurantList
            data={data?.restaurants.rows as Restaurant[]}
            isHasAddButton={true}
            handleAddRestourant={handleAddRestourant}
          />
        </>
      </RequestHandler>
    </MainLayout>
  );
};
