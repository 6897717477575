import React, { useEffect, useState } from 'react';
import {
  BackButton,
  MainLayout,
  DishList,
  RestaurantCardInfoForm,
  PaginationRow,
  AlertDishCreateMenu,
  SearchTextField,
  RequestHandler,
} from '../../components';

import { Box, Typography, Button } from '@mui/material';
import { palette } from '../../theme/foundations';
import { FilterTabs } from '../../components/FilterTabs/FilterTabs';
import { FilterTitleBlockCSS } from './styles';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { client } from '../../graphql/client';
import { useAccesses, usePagination } from '../../hooks';
import {
  Dish,
  Restaurant as RestaurantType,
  useDishCategoriesQuery,
  useDishesInRestaurantMutation,
  useDishesLazyQuery,
  useRestaurantQuery,
} from '../../graphql/generated/graphql';
import { useAlertContext } from '../../context';
import { compareArrays, getDishesTransformData } from '../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../constants/constants';

export const Restaurant = () => {
  const [searchValue, setSearchValue] = useState('');
  const [activeCategories, setActiveCategories] = useState<Array<number>>([]);
  const [activeDishesIds, setActiveDishesIds] = useState<Array<number>>([]);
  const { isDishEditAccess } = useAccesses();

  const navigate = useNavigate();
  const { t } = useTranslatesContext();
  const { id } = useParams();
  const { onOpenAlert } = useAlertContext();

  const [
    query,
    {
      data: dishesData,
      loading: dishesLoading,
      error: dishesError,
      fetchMore,
      refetch,
    },
  ] = useDishesLazyQuery({
    fetchPolicy: 'network-only',
  });

  const {
    data: restaurantData,
    refetch: restaurantRefetch,
    loading,
    error,
  } = useRestaurantQuery({
    variables: { id: Number(id) },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  const brandId = restaurantData?.restaurant.brandId;

  const {
    data: dishCategories,
    loading: dishCategoriesLoading,
    error: dishCategoriesError,
  } = useDishCategoriesQuery({
    variables: { brandId: Number(brandId) },
    skip: !brandId,
  });

  const {
    paginationOptions,
    handleLoadMore: paginationHandleLoadMore,
    handleChangePage,
    setPaginationOptions,
  } = usePagination();

  const [setDishesMutation] = useDishesInRestaurantMutation({
    onCompleted: () => {
      restaurantRefetch();
      onOpenAlert({ title: t('web_alert_update_menu') });
    },
  });

  const handleLoadMore = () => {
    paginationHandleLoadMore(fetchMore, { brandId });
  };

  const getDishesQuery = (limit?: number, offset?: number) => {
    limit = limit ?? paginationOptions.itemsCount;
    offset = offset ?? paginationOptions.offset;

    client.cache.evict({ fieldName: 'dishes' });

    query({
      variables: {
        options: {
          limit,
          offset,
          brandId: restaurantData?.restaurant.brandId,
          dishCategoryIds: activeCategories,
          search: searchValue,
        },
      },
    });
  };

  const initDishesArray = restaurantData?.restaurant?.dishes || [];

  useEffect(() => {
    brandId && getDishesQuery();
  }, [paginationOptions.page, brandId]);

  useEffect(() => {
    brandId && getDishesQuery(paginationOptions.itemsCount, 0);
  }, [activeCategories, searchValue, brandId]);

  useEffect(() => {
    if (restaurantData?.restaurant) {
      setActiveDishesIds(initDishesArray.map((i) => i.id));
    }
  }, [restaurantData]);

  const data = dishesData?.dishes.rows;
  const allItemsCount = dishesData?.dishes.count || 0;

  const currentItemsCount =
    paginationOptions.itemsCount * paginationOptions.page >= allItemsCount
      ? allItemsCount
      : paginationOptions.itemsCount * paginationOptions.page;

  const pagesCount = Math.ceil(allItemsCount / paginationOptions.itemsCount);

  const formatedData = getDishesTransformData(data as Array<Dish>);

  const dishCategoriesOptions = !dishCategories
    ? []
    : dishCategories.dishCategories?.map((item) => ({
        label: item.title,
        id: String(item.id),
      }));

  const handleAddDish = () => {
    navigate(`${ROUTES.addDish}/?brandId=${brandId}`);
  };
  const handleChangeSearchValue = (e: React.ChangeEvent<any>) => {
    setSearchValue(e.target.value);
  };

  const handleTabs = (id: string) => {
    setActiveCategories((prev) => {
      if (prev.includes(Number(id))) {
        return prev.filter((item) => item !== Number(id));
      } else return [...prev, Number(id)];
    });
    setPaginationOptions((prev) => {
      return {
        limit: prev.limit,
        offset: 0,
        itemsCount: prev.itemsCount,
        page: 1,
      };
    });
  };

  const handleCreateMenuClick = () => {
    const deleteItems: Array<number> = [];

    const initDishesArrayIds = initDishesArray.map((i) => i.id);

    initDishesArrayIds.forEach((item) => {
      if (!activeDishesIds.includes(item as number)) {
        deleteItems.push(item);
      }
    });

    const addItems: Array<number> = activeDishesIds.filter((item) => {
      if (!initDishesArrayIds.includes(item as number)) return true;
      return false;
    });

    setDishesMutation({
      variables: {
        dishesInRestaurantInput: {
          restaurantId: Number(id),
          addDishIds: addItems,
          removeDishIds: deleteItems,
        },
      },
    });
  };

  const page =
    paginationOptions.page > pagesCount ? pagesCount : paginationOptions.page;

  const onClickPagination = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    handleChangePage(value);
  };

  const isDishesChanged = !compareArrays(
    activeDishesIds,
    initDishesArray.map((i) => i.id),
  );

  return (
    <MainLayout>
      <BackButton sx={{ marginBottom: '20px' }} />
      <Box mb={'41px'}>
        <RequestHandler loading={loading} error={error} height='293px'>
          <RestaurantCardInfoForm
            data={restaurantData?.restaurant as RestaurantType}
          />
        </RequestHandler>
      </Box>
      <FilterTitleBlockCSS>
        <Box>
          <Typography variant={'big700'}>
            {t('web_restaurant_menu_title')}
          </Typography>
        </Box>
        <Box>
          <Typography variant={'body1'} color={palette.gray650}>
            {t('web_restaurant_menu_sub_title')}
          </Typography>
        </Box>
      </FilterTitleBlockCSS>
      <SearchTextField
        sx={{ mb: '32px' }}
        value={searchValue}
        onChange={handleChangeSearchValue}
      />
      <Box mb={'28px'} display={'flex'}>
        <FilterTabs
          options={dishCategoriesOptions}
          activeTabs={activeCategories}
          onClick={handleTabs}
        />
      </Box>
      {data && data.length === 0 && allItemsCount === 0 ? (
        <Button
          size={'large'}
          sx={{ width: 'fit-content' }}
          onClick={handleAddDish}
        >
          {t('web_restaurant_menu_add_dish_btn')}
        </Button>
      ) : (
        <RequestHandler
          loading={loading || dishCategoriesLoading || dishesLoading}
          error={error || dishCategoriesError || dishesError}
        >
          <>
            {formatedData.map((list, index) => {
              return (
                <DishList
                  key={list.data[0].id}
                  label={list.label}
                  data={list.data}
                  setActiveDishesIds={setActiveDishesIds}
                  activeDishesIds={activeDishesIds}
                  isHasAllBtn={index === 0}
                  initData={data as Array<Dish>}
                  refetch={refetch}
                />
              );
            })}

            {pagesCount > 1 ? (
              <PaginationRow
                from={currentItemsCount as number}
                fromText={t('web_pagination_out_of')}
                to={allItemsCount as number}
                onClickButton={handleLoadMore}
                onClickPagination={onClickPagination}
                buttonName={t('web_pagination_show_more')}
                pagesCount={pagesCount}
                page={page}
              />
            ) : null}

            {isDishesChanged && (
              <AlertDishCreateMenu
                title={`${t('web_restaurant_menu_submit_title')}: ${
                  activeDishesIds.length
                }`}
                onClick={handleCreateMenuClick}
                disabled={!isDishEditAccess}
              />
            )}
          </>
        </RequestHandler>
      )}
    </MainLayout>
  );
};
