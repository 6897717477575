import {
  DescItemCSS,
  DescriptionsCSS,
  RatingBlockCSS,
  ReviewDescriptionsBlockCSS,
  ReviewDescriptionsRowCSS,
  ReviewWrapperCSS,
  UserBlockCSS,
  UserItemCSS,
  UserNameCSS,
  Wrapper,
} from './styles';
import { PointStatus } from '../../PointStatus/PointStatus';
import { Avatar, Box, Divider, Typography } from '@mui/material';
import { CustomRating } from '../../CustomRating/CustomRating';
import { useModalContext } from '../../../context/index';
import { EventOfferDish } from '../../../graphql/generated/graphql';
import { useTranslatesContext } from '../../../context/TranslatesContext';
import dayjs from 'dayjs';
import { REVIEW_OPTIONS, REVIEW_TYPES } from '../../../constants/constants';
import { getReviewLabel, getReviewSmile } from '../../../utils';

// TODO translations

export const CompletedSuccessOrderModal = () => {
  // const data = ORDER_MODAL_COMPLETED_SUCCESS_MOC;
  const { infoModal } = useModalContext();
  const { t } = useTranslatesContext();

  const { numberOrder, title, eventOfferDishes, total, discount, review } =
    infoModal.content;

  return (
    <Wrapper>
      <Box mb={'15px'}>
        <PointStatus
          status={'success'}
          title={`${t(
            'web_completed_rejected_modal_order_title',
          )} #${numberOrder}`}
          isLargeText
        />
      </Box>
      <Box mb={'10px'}>
        <Typography variant={'body3'}>{title}</Typography>
      </Box>
      <DescriptionsCSS>
        {eventOfferDishes.map((item: EventOfferDish) => {
          return (
            <DescItemCSS key={item.dishId}>
              <Typography letterSpacing={'0.3px'}>
                {item.dish.title} - {item.quantity}шт.
              </Typography>{' '}
              <Typography letterSpacing={'0.3px'}>
                ${item.dish.price * item.quantity}
              </Typography>
            </DescItemCSS>
          );
        })}
      </DescriptionsCSS>
      <Box mt={'8.5px'} mb={'10px'}>
        <Divider />
      </Box>
      {discount ? (
        <Box>
          <Typography variant={'body5'}>
            {t('web_completed_rejected_modal_discount')}: ${discount}
          </Typography>
        </Box>
      ) : null}

      <Box>
        <Typography variant={'md750'}>
          {t('web_completed_rejected_modal_total')}: ${total}{' '}
        </Typography>
      </Box>
      {review ? (
        <ReviewWrapperCSS>
          <UserBlockCSS>
            <UserItemCSS>
              <Avatar
                sx={{ width: 61, height: 61 }}
                alt={'user logo'}
                src={review.user.photo?.url || ''}
              />
              <UserNameCSS>
                <Typography variant={'md800'}>{review.user.name}</Typography>
                <Typography variant={'body4'}>
                  {dayjs(+review.createdAt).format('DD.MM.YYYY')}
                </Typography>
              </UserNameCSS>
            </UserItemCSS>
            <RatingBlockCSS>
              <Typography>{review.avgRating}</Typography>
              <CustomRating rating={review.avgRating} />
            </RatingBlockCSS>
          </UserBlockCSS>
          <Box mt={'20px'} mb={'20px'}>
            <Divider />
          </Box>
          <ReviewDescriptionsBlockCSS>
            {REVIEW_OPTIONS.map((reviewItem: REVIEW_TYPES) => {
              const Icon = getReviewSmile(review[reviewItem]);
              const label = getReviewLabel(reviewItem);
              return (
                <ReviewDescriptionsRowCSS key={reviewItem}>
                  <Icon />
                  <Typography variant={'md550'}>{t(label)}</Typography>
                </ReviewDescriptionsRowCSS>
              );
            })}
            <Box>
              <Typography variant={'sm550'}>{review?.comment}</Typography>
            </Box>
          </ReviewDescriptionsBlockCSS>
        </ReviewWrapperCSS>
      ) : null}
    </Wrapper>
  );
};
