import React from 'react';
import { Sidebar } from '../..';
import { ContentCSS, Wrapper } from './styles';

type LayoutType = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: LayoutType) => {
  return (
    <Wrapper>
      <Sidebar />
      <ContentCSS>{children}</ContentCSS>
    </Wrapper>
  );
};
