import * as yup from 'yup';
import { MESSAGES } from '../messages';

export const restaurantSchema = yup.object().shape({
  logo: yup.mixed().required(MESSAGES.REQUIRE_MESSAGE),
  switchWorking: yup.boolean(),
  dataIn: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  dataOut: yup.string().when('dataIn', {
    is: (val: any) => {
      return !!val;
    },
    then: yup.string().test('', 'ERR', (field: any, schema: any) => {
      const getDate = (str: string) => {
        return str.split('T')[0];
      };

      const inDate = getDate(schema.from[0].value.dataIn);
      const outDate = getDate(schema.from[0].value.dataOut);

      return inDate < outDate;
    }),
  }),
});
