import { DEFAULT_DROPZONE_OPTIONS } from '../constants/constants';
import { useAlertContext } from '../context/index';
import { useTranslatesContext } from '../context/TranslatesContext';

export const useDropzoneValidator = () => {
  const { t } = useTranslatesContext();
  const { onOpenAlert } = useAlertContext();

  function nameLengthValidator(file: File) {
    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
      onOpenAlert({ error: true, title: t('web_dropzone_error_format_file') });
    }
    if (file.size > DEFAULT_DROPZONE_OPTIONS.maxSize) {
      onOpenAlert({ error: true, title: t('web_dropzone_error_long_file') });
    }
    return null;
  }

  return { nameLengthValidator };
};
