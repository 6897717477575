import React from 'react';
import { ButtonCSS, Wrapper } from './styles';
import { TabsCustomProps } from './types';

export const FilterTabs = ({
  options,
  onClick = (id) => {},
  activeTabs = [],
}: TabsCustomProps) => {
  return (
    <Wrapper>
      {options.map((option) => {
        const isActive = activeTabs.includes(Number(option.id));
        return (
          <ButtonCSS
            key={option.id}
            variant={'tab'}
            target={isActive ? 'true' : 'false'}
            onClick={() => onClick(option.id)}
          >
            {option.label}
          </ButtonCSS>
        );
      })}
    </Wrapper>
  );
};
