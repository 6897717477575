import React, { useEffect } from 'react';
import {
  BackButton,
  DropzoneFormik,
  MainLayout,
  PageTitleBlock,
  TextFieldFormik,
} from '../../components';
import { Box, Button } from '@mui/material';
import { Form, Formik } from 'formik';
import { AutocompleteFormik } from '../../components/UI/AutocompleteFormik/AutocompleteFormik';
import { ButtonBlockCSS, FormWrapper } from './styles';
import { IFormikProps } from './types';
import { useAccesses, useCustomRouter } from '../../hooks';
import { addUserSchema } from '../../validation';
import {
  Roles,
  useCreateManagerMutation,
  useManagerMeQuery,
  useManagerQuery,
  useUpdateManagerMutation,
} from '../../graphql/generated/graphql';
import { useAlertContext } from '../../context';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { MANAGERS } from '../../graphql/queries/managers.gql';
import { useTranslatesContext } from '../../context/TranslatesContext';

export const AddOrUpdateUser = () => {
  const { goBack } = useCustomRouter();
  const { onOpenAlert } = useAlertContext();
  const navigate = useNavigate();
  const { id: managerId } = useParams();
  const { t } = useTranslatesContext();
  const { isManagerEditAccess, isSuperAdmin } = useAccesses();

  const [searchParams] = useSearchParams();
  const brandIdParams = searchParams.get('brandId');

  const { data } = useManagerMeQuery(); // for get brand id

  const { data: managerData } = useManagerQuery({
    variables: {
      managerId: Number(managerId),
    },
    skip: !Number(managerId),
  });

  const optionsLaw = [
    { id: Roles.RestaurantEdit, label: t('web_users_role_edit_rest') },
    { id: Roles.RestaurantRemove, label: t('web_users_role_delete_rest') },
    { id: Roles.DishEdit, label: t('web_users_role_edit_dish') },
    { id: Roles.DishRemove, label: t('web_users_role_delete_dish') },
    { id: Roles.ManagerEdit, label: t('web_users_role_edit_user') },
    { id: Roles.ManagerRemove, label: t('web_users_role_delete_user') },
    { id: Roles.OrderConfirm, label: t('web_users_role_confirm_order') },
    { id: Roles.OrderReject, label: t('web_users_role_reject_order') },
    { id: Roles.OfferEdit, label: t('web_users_role_edit_offer') },
  ];

  const brandId =
    data?.managerMe.brand?.id ||
    brandIdParams ||
    managerData?.manager.brand?.id;

  const [createManager] = useCreateManagerMutation({
    onCompleted: () => {
      onOpenAlert({ title: t('web_users_page_user_added') });
      navigate(-1);
    },
    onError: () => {
      onOpenAlert({ error: true, title: t('web_users_at_add_error_title') });
    },
    refetchQueries: [MANAGERS],
  });

  const [updateManager] = useUpdateManagerMutation({
    onCompleted: () => {
      onOpenAlert({ title: t('web_users_page_user_updated') });
      navigate(-1);
    },
    onError: () => {
      onOpenAlert({ error: true, title: t('web_users_at_add_error_title') });
    },
  });

  const handleSubmit = (values: IFormikProps) => {
    const roles: Roles[] = values.userPermission.map((el) => (el as any).id);

    const formValues = {
      brandId: Number(brandId),
      email: values.email,
      password: values.password as string,
      photo:
        typeof values.photo === 'string'
          ? null
          : {
              upload: values.photo,
            },
      firstName: values.name,
      lastName: values.surname,
      position: values.position,
      roles: roles,
    };

    if (!managerId) {
      createManager({
        variables: {
          createManagerInput: {
            ...formValues,
          },
        },
      });
    } else {
      updateManager({
        variables: {
          updateManagerInput: {
            ...formValues,
            id: Number(managerId),
          },
        },
      });
    }
  };

  useEffect(() => {
    if (!isManagerEditAccess) {
      navigate(-1);
    }
  }, []);

  const isDisabled =
    !brandId ||
    !isManagerEditAccess ||
    (!isSuperAdmin &&
      data?.managerMe.brand?.id !== managerData?.manager.brand?.id);

  return (
    <MainLayout>
      <Box mb={'15px'}>
        <BackButton />
      </Box>
      <PageTitleBlock
        title={
          !managerId
            ? t('web_users_page_user_adding')
            : t('web_edit_user_page_title')
        }
        subTitle={''}
      />
      <Formik<IFormikProps>
        initialValues={{
          name: '',
          surname: '',
          email: '',
          password: '',
          position: '',
          userPermission: [],
          photo: null,
        }}
        onSubmit={handleSubmit}
        validationSchema={addUserSchema(!managerId)}
      >
        {({ resetForm }) => {
          useEffect(() => {
            if (managerData?.manager) {
              const { firstName, lastName, email, position, roles, photo } =
                managerData.manager;
              resetForm({
                values: {
                  name: firstName as string,
                  surname: lastName as string,
                  email: email as string,
                  photo: photo?.url as string,
                  position: position as string,
                  userPermission: optionsLaw.filter((el) =>
                    roles.includes(el.id as Roles),
                  ),
                },
              });
            }
          }, [managerId, managerData]);

          return (
            <Form>
              <FormWrapper>
                <TextFieldFormik
                  name={'name'}
                  label={t('web_users_page_label_name')}
                  size={'large'}
                  mb={'24px'}
                />
                <TextFieldFormik
                  name={'surname'}
                  label={t('web_users_page_label_lastname')}
                  size={'large'}
                  mb={'24px'}
                />
                <TextFieldFormik
                  name={'email'}
                  label={t('web_users_page_label_email')}
                  size={'large'}
                  mb={'24px'}
                />
                {!managerId ? (
                  <TextFieldFormik
                    name={'password'}
                    label={t('web_users_page_label_password')}
                    size={'large'}
                    mb={'24px'}
                    type={'password'}
                  />
                ) : null}
                <TextFieldFormik
                  name={'position'}
                  label={t('web_users_page_label_job_title')}
                  size={'large'}
                  mb={'24px'}
                />
                <AutocompleteFormik
                  name={'userPermission'}
                  options={optionsLaw}
                  placeholder={t('web_users_page_label_placeholder')}
                  sx={{ marginBottom: '20px' }}
                  label={t('web_users_page_label_role')}
                />
                <DropzoneFormik
                  name={'photo'}
                  label={t('web_users_page_label_photo')}
                  subLabel={'До 5 Мб'}
                  mb={'24px'}
                />

                <ButtonBlockCSS>
                  <Button
                    type={'submit'}
                    size={'large'}
                    sx={{ width: 'fit-content' }}
                    disabled={isDisabled}
                  >
                    {!managerId
                      ? t('web_action_add')
                      : t('web_edit_user_submit_btn')}
                  </Button>
                  <Button
                    type={'reset'}
                    variant={'outlined'}
                    size={'large'}
                    sx={{ width: 'fit-content' }}
                    onClick={goBack}
                  >
                    {t('web_action_cancel')}
                  </Button>
                </ButtonBlockCSS>
              </FormWrapper>
            </Form>
          );
        }}
      </Formik>
    </MainLayout>
  );
};
