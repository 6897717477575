import { gql } from '@apollo/client';

export const DISH_CATEGORIES = gql`
  query dishCategories($brandId: Int!) {
    dishCategories(brandId: $brandId) {
      id
      title
      countDishes
      brandId
    }
  }
`;
