import { Box, Dialog, styled } from '@mui/material';

export const Wrapper = styled(Dialog)(() => ({
  borderRadius: '50%',
  '.MuiPaper-root': {
    borderRadius: '16px',
    boxShadow: '0px 2px 16px rgba(33, 52, 103, 0.1)',
  },
}));

export const FormWrapperCSS = styled(Box)(() => ({
  display: 'flex',
  padding: '20px',
}));
