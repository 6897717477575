import { Box, styled } from '@mui/material';
import { palette } from '../../theme/foundations/index';

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  boxShadow: `0px 2px 16px ${palette.gray800}`,
  background: '#fff',
  borderRadius: '16px',
  padding: '16px 16px 16px 16px',
  cursor: 'pointer',
  ...theme.mixins.hoverItem,
}));

export const ImageBlockCSS = styled(Box)(() => ({
  display: 'flex',
  border: `0.5px solid ${palette.gray900}`,
  borderRadius: '8px',
  height: '64px',
  width: '64px',
  marginRight: '16px',
  svg: {
    height: '62px',
    width: '62px',
  },
  image: {
    height: '62px',
    width: '62px',
  },
}));

export const TitleBlockCSS = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  marginRight: '112px',
}));

export const ContentCSS = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  width: '100%',
}));

export const AmountBlockCSS = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  borderRight: `0.5px solid ${palette.gray900}`,
  borderLeft: `0.5px solid ${palette.gray900}`,
  height: '100%',
  padding: '0 16px',
  gap: '4px',
  whiteSpace: 'nowrap',
  marginRight: '8px',
  marginLeft: 'auto',
}));

export const LogoCSS = styled('img')(() => ({
  width: 64,
  height: 64,
  borderRadius: 8,
}));
