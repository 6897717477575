import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { palette } from '../../theme/foundations';
import { MAX_WIDTH } from '../../constants/constants';

export const FormWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: MAX_WIDTH,
});
export const NutritionalFeaturesWrapperCSS = styled(Box)(() => ({
  marginTop: 22,
  display: 'flex',
  alignItems: 'flex-end',
}));
export const AddCategoryWrapperCSS = styled(Box)(() => ({
  cursor: 'pointer',
  padding: '15px 16px 6px',
  borderTop: '1px solid #E3E0DE',
  marginTop: '8px',
}));

export const ButtonGroupCSS = styled(Box)(() => ({
  display: 'flex',
  aliItems: 'center',
  gap: '12px',
}));

export const CategoriesButtonCSS = styled(Button)(() => ({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '20px',
  height: '20px',
  backgroundColor: palette.white100,
  justifyContent: 'flex-start',
  padding: 0,
  marginTop: '12px',
  color: palette.primary.main,
  width: 'fit-content',
  '&:hover': {
    backgroundColor: palette.white100,
  },
}));
