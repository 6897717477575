import { Box, styled } from '@mui/material';

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '380px',
}));

export const ContentCSS = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
}));

export const ImageTextRowCSS = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '16px',
}));

export const ImageTextRightBlockCSS = styled(Box)(() => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
}));
