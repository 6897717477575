import * as yup from 'yup';
import { MESSAGES } from '../messages';

export const editContactPhoneSchema = yup.object().shape({
  phone: yup
    .string()
    .min(10, MESSAGES.MIN_LENGTH('10'))
    .matches(/^[\d ()+-]+$/, MESSAGES.INCORRECT_FORMAT)
    .required(MESSAGES.REQUIRE_MESSAGE),
});
