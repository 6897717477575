import { Link } from '@mui/material';
import { Layout } from '../../components';

export const Home = () => {
  return (
    <Layout>
      Home page
      <Link href='/devPage'>Link</Link>
    </Layout>
  );
};
