import React from 'react';
import { useField, useFormikContext } from 'formik';
import { SwitcherCustom } from '../SwitcherCustom/SwitcherCustom';
import { ISwitcherFormikProps } from '../SelectFormik/types';

export const SwitcherFormik = ({
  name,
  label,
  sx,
  disabled,
}: ISwitcherFormikProps) => {
  const [field] = useField(name);
  const { handleChange } = useFormikContext();
  return (
    <SwitcherCustom
      value={field.value}
      onChange={handleChange}
      name={name}
      label={label}
      sx={sx}
      disabled={disabled}
    />
  );
};
