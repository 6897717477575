import { gql } from '@apollo/client';

export const EVENT_THEMES = gql`
  query EventThemes {
    eventThemes {
      id
      title
      isFavorite
      image {
        id
        width
        height
        mime
        size
        url
      }
    }
  }
`;
