import React, { useEffect } from 'react';
import {
  MainLayout,
  PageTitleBlock,
  ScrollToTopButton,
  TableList,
} from '../../components';
import {
  MODAL_NAME,
  TRANSLATION_TABLE_OPTIONS,
} from '../../constants/constants';
import { useAlertContext, useModalContext } from '../../context/index';

import { FormProvider, useForm } from 'react-hook-form';
import {
  Translate,
  useBulkUpdateTranslateMutation,
  useRemoveTranslateMutation,
  useTranslatesQuery,
} from '../../graphql/generated/graphql';
import { TRANSLATES } from '../../graphql/queries/translates.gql';
import { transformTranslateInit } from '../../utils/index';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { translationsPageSchema } from '../../validation/index';

export const TranslationsPage = () => {
  const { t } = useTranslatesContext();
  const { onOpenModal, onCloseModal } = useModalContext();
  const { onOpenAlert } = useAlertContext();
  const handleOpenModal = () => {
    onOpenModal(MODAL_NAME.ADD_ELEMENT);
  };

  const methods = useForm({
    defaultValues: {
      data: [] as any,
    },
    mode: 'onChange',
    resolver: yupResolver<any>(translationsPageSchema),
  });
  const {
    getValues,
    reset,
    formState: { errors },
  } = methods;

  const { data, loading } = useTranslatesQuery({
    variables: {
      localeCode: 'en',
    },
  });
  const [removeTranslate] = useRemoveTranslateMutation();

  useEffect(() => {
    if (data) {
      reset({
        data: transformTranslateInit(data?.translates as Translate[] | []),
      });
    }
  }, [data]);

  const cancelFunc = () => {
    onCloseModal();
  };

  const goRemove = (key: string) => {
    removeTranslate({
      variables: {
        key: key,
      },
      onCompleted: () => {
        onCloseModal();
        onOpenAlert({ title: t('web_alert_delete_translate') });
      },
      onError: (error) => {
        onOpenAlert({ title: error.message, error: true });
      },
      refetchQueries: [TRANSLATES],
    });
  };

  const handleRemove = (key: string) => {
    onOpenModal(MODAL_NAME.ARE_YOU_SURE, {
      title: t('web_are_you_sure'),
      leftCB: cancelFunc,
      rightCB: () => goRemove(key),
    });
  };

  const targetAllData = (prevArray: any[], currentArray: any[]) => {
    const result = [] as any[];
    prevArray.forEach((inObject, index) => {
      if (
        inObject.fieldDataEN?.url &&
        currentArray[index].fieldDataEN?.url &&
        inObject.fieldDataEN?.url === currentArray[index].fieldDataEN?.url
      ) {
        return;
      }

      if (inObject.fieldDataEN === currentArray[index].fieldDataEN) {
        return;
      }
      result.push(currentArray[index]);
    });
    return result;
  };

  const [bulkUpdateTranslate] = useBulkUpdateTranslateMutation();

  const clickSaveChanges = () => {
    const changesData = targetAllData(
      transformTranslateInit(data?.translates as Translate[] | []),
      getValues().data,
    );

    const outputData = changesData.map((item) => {
      return {
        isDelete: item.type === 'image' && item.fieldDataEN === null,
        key: item.key,
        strings: [
          ...(item.type === 'string'
            ? [{ string: item.fieldDataEN, localeId: 1 }]
            : []),
        ],
        texts: [
          ...(item.type === 'text'
            ? [{ text: item.fieldDataEN, localeId: 1 }]
            : []),
        ],
        images: [
          ...(item.type === 'image' && item.fieldDataEN !== null
            ? [{ image: item.fieldDataEN, localeId: 1 }]
            : []),
        ],
      };
    });

    bulkUpdateTranslate({
      variables: {
        translatesData: {
          list: outputData,
        },
      },
      onCompleted: () => {
        onOpenAlert({ title: t('web_alert_update_translate') });
        onCloseModal();
      },
      onError: (error) => {
        onOpenAlert({ title: error.message, error: true });
      },
      refetchQueries: [TRANSLATES],
    });
  };

  const handleOnClickSaveChanges = () => {
    if (Object.values(errors).length) {
      return;
    }
    onOpenModal(MODAL_NAME.ARE_YOU_SURE, {
      leftCB: cancelFunc,
      rightCB: clickSaveChanges,
      rightBtnTitle: t('web_action_save'),
    });
  };

  return (
    <MainLayout>
      <PageTitleBlock
        title={t('web_translations_title')}
        subTitle={''}
        buttonText={t('web_translations_add_btn')}
        buttonTextSaveChanges={t('web_translations_save_btn')}
        onClick={handleOpenModal}
        onClickSaveChanges={handleOnClickSaveChanges}
      />
      <FormProvider {...methods}>
        <form>
          <TableList
            options={TRANSLATION_TABLE_OPTIONS}
            data={getValues().data}
            onClick={handleRemove}
            loading={loading}
          />
        </form>
      </FormProvider>
      <ScrollToTopButton />
    </MainLayout>
  );
};
