import { Box, InputAdornment, TextField } from '@mui/material';
import { IProps } from './types';
import { LensIcon } from '../Icons/LensIcon';
import { useTranslatesContext } from '../../context/TranslatesContext';

export const SearchTextField = ({
  sx,
  value,
  onChange,
  placeholder,
}: IProps) => {
  const { t } = useTranslatesContext();
  return (
    <Box sx={sx}>
      <TextField
        fullWidth
        value={value}
        size={'large'}
        placeholder={placeholder || t('web_general_placeholder_search')}
        onChange={onChange}
        InputProps={{
          startAdornment: (
            <Box sx={{ marginRight: '12px' }}>
              <InputAdornment position='start'>
                <LensIcon />
              </InputAdornment>
            </Box>
          ),
        }}
      />
    </Box>
  );
};
