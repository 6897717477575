import { ChangeEvent, useState } from 'react';
import {
  MainLayout,
  PageTitleBlock,
  RequestHandler,
  SearchTextField,
} from '../../components';
import { useDebounce } from '../../hooks';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { useCitiesQuery } from '../../graphql/generated/graphql';
import { Box, Stack } from '@mui/material';
import { SelectFormik } from '../../components/UI/SelectFormik/SelectFormik';
import { Formik } from 'formik';
import { RestaurantsWithoutBrandList } from '../../components/RestaurantsWithoutBrandList/RestaurantsWithoutBrandList';
import dayjs from 'dayjs';

export const RestaurantsWithoutBrandPage = () => {
  const [searchText, setSearchText] = useState('');
  const { t } = useTranslatesContext();

  const [periodOptions] = useState([
    { id: 'all-time', label: t('web_all_time') },
    { id: dayjs().subtract(1, 'day').toISOString(), label: t('web_one_day') },
    { id: dayjs().subtract(1, 'week').toISOString(), label: t('web_one_week') },
    {
      id: dayjs().subtract(1, 'month').toISOString(),
      label: t('web_one_mounth'),
    },
  ]);

  const { data: citiesData, loading, error } = useCitiesQuery();

  const handleSearchText = (e: ChangeEvent<any>) => {
    setSearchText(e.target.value);
  };

  const searchTextDebounce = useDebounce(searchText);

  const cityOptions = !citiesData
    ? []
    : citiesData.cities.map((item) => ({
        label: item.title,
        id: String(item.id),
      }));

  const initialValues = {
    city: 'all-cities',
    period: 'all-time',
  };

  return (
    <MainLayout>
      <PageTitleBlock title={t('web_restaraunts_withount_brand_title')} />
      <RequestHandler loading={loading} error={error} height='50vh'>
        <Formik initialValues={initialValues} onSubmit={() => {}}>
          {() => {
            return (
              <>
                <Stack direction={'row'} mb='16px'>
                  <SearchTextField
                    value={searchText}
                    onChange={handleSearchText}
                    sx={{ flex: 1 }}
                  />
                  <Box sx={{ width: '247px', marginLeft: '24px' }}>
                    <SelectFormik
                      name={'city'}
                      options={[
                        { label: t('web_all_cities'), id: 'all-cities' },
                        ...cityOptions,
                      ]}
                      placeholder={t('web_add_res_city_placeholder')}
                    />
                  </Box>
                </Stack>
                <SelectFormik
                  name={'period'}
                  options={periodOptions}
                  placeholder={t('web_restaraunts_withount_period_placeholder')}
                  sx={{ width: '300px', mb: '24px' }}
                />
                <RestaurantsWithoutBrandList searchText={searchTextDebounce} />
              </>
            );
          }}
        </Formik>
      </RequestHandler>
    </MainLayout>
  );
};
