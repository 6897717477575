import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CONTENT_WIDTH } from '../../constants/constants';

export const SchedulesSingleWrapperCSS = styled(Box)(() => ({
  marginTop: 22,
  display: 'flex',
  alignItems: 'flex-end',
}));
export const SchedulesElementCSS = styled(Box)(() => ({
  marginTop: 20,
  display: 'flex',
  alignItems: 'center',
  minHeight: 50,
}));
export const CoastWrapperCSS = styled(Box)(() => ({
  marginTop: 22,
  display: 'flex',
  alignItems: 'center',
}));
export const FormWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: CONTENT_WIDTH,
});

export const ButtonGroupCSS = styled(Box)({
  display: 'flex',
  gap: '15px',
  marginTop: '25px',
});

export const ButtonGroupWhenEditCSS = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  marginTop: '25px',
});
