import React from 'react';
import { TextFieldFormik } from '../../UI/TextFieldFormik/TextFieldFormik';
import { ITextBlockProps } from './types';

export const TextForm = ({ enName, enLabel }: ITextBlockProps) => {
  return (
    <>
      <TextFieldFormik name={enName} label={enLabel} size={'large'} />
    </>
  );
};
