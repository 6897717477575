import { gql } from '@apollo/client';

export const RESTAURANT = gql`
  query restaurant($id: Int!) {
    restaurant(id: $id) {
      id
      brandId
      name
      scheduleType
      priceLevel
      description
      averageCost
      address
      latitude
      longitude
      availableSeatsNumber
      isClosed
      closedFrom
      closedTo
      createdAt
      updatedAt
      image {
        id
        width
        height
        mime
        size
        url
      }
      city {
        id
        title
      }
      cuisines {
        id
        title
      }
      workDays {
        id
        day
        from
        to
        isActive
      }
      dishes {
        id
      }
      secondImage {
        id
        width
        height
        mime
        size
        url
      }
    }
  }
`;
