import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled(Box)(() => ({
  padding: '0 20px',
}));
export const RememberWrapper = styled(Box)(() => ({
  marginTop: '14px',
  display: 'flex',
  alignItems: 'center',
}));
