import { Box, BoxProps, styled } from '@mui/material';
import { palette } from '../../theme/foundations/index';
import { AliasBoolTypes } from '../../types/baseTypes';

interface IWrapperProps extends BoxProps {
  istarget?: AliasBoolTypes;
}

export const Wrapper = styled(Box)<IWrapperProps>(({ istarget }) => ({
  display: 'flex',
  background: palette.white,
  padding: '20px',
  borderRadius: '16px',
  boxShadow: '0px 5px 16px rgba(33, 52, 103, 0.1)',
  ...(istarget === 'true' && {
    outline: `1px solid ${palette.primary.main}`,
  }),
}));

export const ImageBlockCSS = styled(Box)(() => ({
  width: '89px',
  height: '89px',
  borderRadius: '8px',
  overflow: 'hidden',
  marginRight: '22px',
  img: {
    width: '89px',
    height: '89px',
    borderRadius: '8px',
  },
}));

export const ContentBlockCSS = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
  justifyContent: 'space-between',
}));

export const FlexRowPersonCSS = styled(Box)(() => ({
  display: 'flex',
  gap: '10px',
}));

export const NumericBlockCSS = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  // height: '100%',
  alignItems: 'center',
  gap: '25px',
}));

export const PerPortionBlockCSS = styled(Box)(() => ({
  position: 'absolute',
  bottom: 0,
  right: 0,
}));

export const PriceInputWrapperCSS = styled(Box)(() => {
  return {
    '.MuiInputBase-root': {
      minHeight: '40px !important',
      width: '100px',
      paddingInlineEnd: '14px',
    },
  };
});
