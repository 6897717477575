import {
  Box,
  InputAdornment,
  TextField,
  Typography,
  IconButton,
} from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { useState } from 'react';
import { EyeIcon, EyeOffIcon } from '../../Icons';
import { ITextFieldFormikProps } from './types';

export const TextFieldFormik = ({
  name = '',
  label = '',
  placeholder = '',
  size = 'medium',
  fullWidth = true,
  maxLength = Infinity,
  type = 'text',
  disabled = false,
  errorMessage = '',
  mb = '0px',
  mr = '0px',
  mt = '0px',
  width = 'auto',
  multiline = false,
  minRows,
  readOnly = false,
  sx,
  Icon,
  IconButton: IconButtonProps,
  inputRef,
  autoFocus = false,
}: ITextFieldFormikProps) => {
  const [viewPassword, setViewPassword] = useState(false);

  const [field, meta] = useField(name);
  const { handleChange } = useFormikContext();

  const hasError = Boolean(meta.error && meta.touched);
  const hasErrorMessage = hasError
    ? meta.error
    : errorMessage
    ? errorMessage
    : '';

  return (
    <Box mb={mb} mr={mr} mt={mt} width={width} sx={sx}>
      {label || errorMessage || hasErrorMessage ? (
        <Box mb='8px' display={'flex'} alignItems='center'>
          {label ? (
            <label htmlFor={name}>
              <Typography variant='body1'>{label}</Typography>
            </label>
          ) : null}
          {hasErrorMessage ? (
            <Typography ml={'auto'} variant='error'>
              {hasErrorMessage}
            </Typography>
          ) : null}
        </Box>
      ) : null}

      <TextField
        id={name}
        size={size}
        name={name}
        /* eslint-disable-next-line jsx-a11y/no-autofocus */
        autoFocus={autoFocus}
        value={field.value}
        onChange={handleChange}
        error={hasError || Boolean(errorMessage)}
        placeholder={placeholder}
        fullWidth={fullWidth}
        disabled={disabled}
        type={viewPassword ? 'text' : type}
        inputProps={{ maxLength: maxLength }}
        multiline={multiline}
        minRows={minRows}
        sx={{
          '.MuiInputBase-root': {
            paddingInlineEnd: Icon ? '14px' : '4px',
          },
        }}
        inputRef={inputRef}
        InputProps={{
          readOnly: readOnly,
          endAdornment: (
            <InputAdornment position='start'>
              {type === 'password' ? (
                <IconButton
                  onClick={() => {
                    setViewPassword(!viewPassword);
                  }}
                >
                  {!viewPassword ? <EyeIcon /> : <EyeOffIcon />}
                </IconButton>
              ) : Icon ? (
                <Icon />
              ) : IconButtonProps ? (
                IconButtonProps
              ) : null}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};
