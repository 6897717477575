import { Form, Formik } from 'formik';
import { Box } from '@mui/material';
import { OptionsCaseLayout, TextFieldFormik } from '../../components';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { ButtonSaveChangesCSS } from './styles';
import { ISettingsCrateEventFormProps, ISettingsGuestsFormik } from './types';
import { useAlertContext } from '../../context';
import { useBulkUpdateSettingMutation } from '../../graphql/generated/graphql';
import { SETTINGS } from '../../graphql/queries/settings.gql';
import { editGuestsSchema } from '../../validation/schema/editGuests.schema';
import { getOption } from '../../utils';
import { GENERAL_SETTINGS } from '../../constants/constants';

export const SettingsCrateEventForm = ({
  guestOption,
}: ISettingsCrateEventFormProps) => {
  const { t } = useTranslatesContext();
  const { onOpenAlert } = useAlertContext();

  const [updateSetting] = useBulkUpdateSettingMutation({
    onCompleted: () => onOpenAlert({ title: t('web_setting_guests_update') }),
    onError: () =>
      onOpenAlert({
        title: t('web_setting_occurred_error_update'),
        error: true,
      }),
    refetchQueries: [SETTINGS],
  });

  const baseValueGuest = getOption(
    GENERAL_SETTINGS.basicEventAmountPersons,
    guestOption,
  );
  const minValueGuest = getOption(
    GENERAL_SETTINGS.minEventAmountPersons,
    guestOption,
  );
  const maxValueGuest = getOption(
    GENERAL_SETTINGS.maxEventAmountPersons,
    guestOption,
  );

  const handleSubmit = (values: ISettingsGuestsFormik) => {
    updateSetting({
      variables: {
        settingsData: {
          list: [
            {
              key: 'basic_event_amount_persons',
              value: String(values.baseValueGuest),
            },
            {
              key: 'min_event_amount_persons',
              value: String(values.minValueGuest),
            },
            {
              key: 'max_event_amount_persons',
              value: String(values.maxValueGuest),
            },
          ],
        },
      },
    });
  };

  return (
    <OptionsCaseLayout
      title={t('web_setting_case_label_count_guest_create_event')}
    >
      <Formik<ISettingsGuestsFormik>
        initialValues={{
          baseValueGuest: Number(baseValueGuest?.value) || '',
          minValueGuest: Number(minValueGuest?.value) || '',
          maxValueGuest: Number(maxValueGuest?.value) || '',
        }}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validationSchema={editGuestsSchema}
      >
        {({ dirty }) => {
          return (
            <Form>
              <Box sx={{ display: 'flex', gap: '16px', marginBottom: '16px' }}>
                <TextFieldFormik
                  name={'baseValueGuest'}
                  label={t('web_setting_label_basic_value')}
                  size={'large'}
                  width={'230px'}
                  type={'number'}
                />
                <TextFieldFormik
                  name={'minValueGuest'}
                  label={t('web_setting_label_min_value')}
                  size={'large'}
                  width={'230px'}
                  type={'number'}
                />
                <TextFieldFormik
                  name={'maxValueGuest'}
                  label={t('web_setting_label_max_value')}
                  size={'large'}
                  width={'230px'}
                  type={'number'}
                />
              </Box>
              <ButtonSaveChangesCSS
                size={'large'}
                type={'submit'}
                disabled={!dirty}
              >
                {t('web_action_save')}
              </ButtonSaveChangesCSS>
            </Form>
          );
        }}
      </Formik>
    </OptionsCaseLayout>
  );
};
