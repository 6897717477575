import React from 'react';

export const RestaurantIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.31079 1C4.88761 1 5.34483 1.48668 5.30885 2.06238L5.06651 5.93969C5.0306 6.51427 5.48692 7 6.06262 7C6.58966 7 7.02586 6.59021 7.05873 6.0642L7.31664 1.93762C7.34958 1.41059 7.78663 1 8.3147 1H10.0002C10.5525 1 11.0002 1.44772 11.0002 2V6C11.0002 6.55228 11.448 7 12.0002 7C12.5525 7 13.0002 6.55228 13.0002 6V2C13.0002 1.44772 13.448 1 14.0002 1H15.6858C16.2139 1 16.6509 1.41059 16.6838 1.93762L16.9418 6.0642C16.9746 6.59021 17.4108 7 17.9379 7C18.5136 7 18.9699 6.51427 18.934 5.93969L18.6916 2.06238C18.6557 1.48668 19.1129 1 19.6897 1H20.3309C21.2975 1 22.1257 1.69125 22.2986 2.64223L23.415 8.78224C23.7033 10.3677 22.5662 11.8339 21.0002 11.9869V21.5C21.0002 22.3284 20.3287 23 19.5002 23C18.6718 23 18.0002 22.3284 18.0002 21.5V12H13.0002V21C13.0002 22.1046 12.1048 23 11.0002 23H5.00024C3.89567 23 3.00024 22.1046 3.00024 21V11.9869C1.43409 11.8341 0.296809 10.3678 0.585097 8.78224L1.70146 2.64223C1.87437 1.69125 2.70263 1 3.6692 1H4.31079Z'
        fill='#B5B1AE'
      />
    </svg>
  );
};
