import React from 'react';
import { useDropzone } from 'react-dropzone';
import { DEFAULT_DROPZONE_OPTIONS } from '../../../constants/constants';
import {
  DropBoxCSS,
  DropzoneWrapperCSS,
  ImageBlockCSS,
  ImagePlaceholderCSS,
  RemoveBoxCSS,
  SubLabelCSS,
  Wrapper,
} from '../DropzoneFormik/styles';
import { IconButton, SxProps, Typography } from '@mui/material';
import { CloseDropIcon, ImagePlaceholderDropzone } from '../../Icons/index';
import { palette } from '../../../theme/foundations/index';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslatesContext } from '../../../context/TranslatesContext';
import { useDropzoneValidator } from '../../../hooks/index';

export const DropzoneRHF = ({
  subLabel,
  name,
  sx,
  mb,
  isMedium,
  isSmallLarge,
  hasError,
  onChangeCallback,
}: any) => {
  const { control, getValues, setValue } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={() => {
        const handleChange = (file: File[]) => {
          // Кастыль для Firefox для загрузки файла из FileDialog
          setTimeout(() => {
            setValue(name, file, { shouldValidate: true });
            onChangeCallback?.(file);
          }, 0);
        };
        return (
          <Dropzone
            hasError={hasError}
            value={getValues(name)}
            onChange={handleChange}
            sx={sx}
            mb={mb}
            isMedium={isMedium}
            isSmallLarge={isSmallLarge}
            subLabel={subLabel}
          />
        );
      }}
    />
  );
};

export interface IDropzoneProps {
  value: any;
  onChange: (data: any) => void;
  sx?: SxProps;
  mb?: string;
  isMedium?: boolean;
  isSmallLarge?: boolean;
  hasError?: boolean;
  subLabel?: string;
}

const Dropzone = ({
  value,
  onChange,
  sx,
  mb,
  isMedium,
  isSmallLarge,
  hasError,
  subLabel,
}: IDropzoneProps) => {
  const { t } = useTranslatesContext();
  const { nameLengthValidator } = useDropzoneValidator();

  const onDrop = (acceptedFiles: File[]) => {
    onChange(
      Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0]),
      }),
    );
  };

  const handleRemove = () => {
    onChange(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    ...DEFAULT_DROPZONE_OPTIONS,
    onDrop,
    validator: nameLengthValidator,
  });

  return (
    <Wrapper sx={sx} mb={mb}>
      <DropzoneWrapperCSS>
        <input {...getInputProps()} />
        {value ? (
          <ImageBlockCSS
            ismedium={isMedium ? 'true' : 'false'}
            issmalllarge={isSmallLarge ? 'true' : 'false'}
          >
            <img src={value?.preview || value?.url} alt='i' />
            <RemoveBoxCSS>
              <IconButton onClick={handleRemove}>
                <CloseDropIcon />
              </IconButton>
            </RemoveBoxCSS>
            {!(isMedium || isSmallLarge) && (
              <Typography variant={'sm500'} color={palette.white}>
                {t('web_dropzone_info_no_empty')}
              </Typography>
            )}
          </ImageBlockCSS>
        ) : (
          <DropBoxCSS
            {...getRootProps()}
            haserror={String(hasError)}
            ismedium={isMedium ? 'true' : 'false'}
            issmalllarge={isSmallLarge ? 'true' : 'false'}
          >
            <ImagePlaceholderCSS
              ismedium={isMedium ? 'true' : 'false'}
              issmalllarge={isSmallLarge ? 'true' : 'false'}
            >
              <ImagePlaceholderDropzone />
            </ImagePlaceholderCSS>
            {!(isMedium || isSmallLarge) && (
              <Typography variant={'sm500'}>
                {t('web_dropzone_info_empty')}
              </Typography>
            )}
          </DropBoxCSS>
        )}
      </DropzoneWrapperCSS>
      {subLabel && <SubLabelCSS>{subLabel}</SubLabelCSS>}
    </Wrapper>
  );
};
