import { REVIEW_TYPES } from '../constants/constants';

export const getReviewLabel = (reviewType: REVIEW_TYPES) => {
  switch (reviewType) {
    case REVIEW_TYPES.accurateRating:
      return 'web_review_accurate_label';
    case REVIEW_TYPES.eventMatchRating:
      return 'web_review_event_match_label';
    case REVIEW_TYPES.cuisineRating:
      return 'web_review_cuisine_label';
    case REVIEW_TYPES.serviceRating:
      return 'web_review_service_label';
    default:
      return 'web_review_service_label';
  }
};
