// import React, { useEffect } from 'react';
import {
  ContentBlockCSS,
  FlexRowPersonCSS,
  ImageBlockCSS,
  NumericBlockCSS,
  PerPortionBlockCSS,
  PriceInputWrapperCSS,
  Wrapper,
} from './syles';
// import { MOC_URL_IMG } from '../../constants/MOC';
import { Typography, TextField, InputAdornment } from '@mui/material';
import { palette } from '../../theme/foundations/index';
// import { Form, Formik } from 'formik';
import { DollarIcon } from '../Icons/index';
import { TextFieldFormik } from '../UI/TextFieldFormik/TextFieldFormik';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { Counter } from '../UI/Counter/Counter';
import { COUNTER_TYPES } from '../../constants/constants';
import { IOfferDish } from '../../pages/OrderPage/types';
import { useCallback } from 'react';

interface IOrderMenuItemProps {
  imageUrl: string;
  title: string;
  personCount: string;
  timeCount: string;
  id: number;
  volume: number;
  price: string;
  handleChangeItem?: (
    eventRating: COUNTER_TYPES,
    id: number,
    price: number,
    title: string,
    img: string,
  ) => void;
  offerDishes?: Array<IOfferDish>;
  isReadOnly?: boolean;
}

export const OrderMenuItem = ({
  imageUrl,
  title,
  personCount,
  timeCount,
  volume,
  price,
  id,
  handleChangeItem,
  offerDishes,
  isReadOnly,
}: IOrderMenuItemProps) => {
  const { t } = useTranslatesContext();

  const getCountInfo = useCallback(() => {
    return (
      offerDishes?.find((dish) => dish.id === id) || {
        count: 0,
        id,
      }
    );
  }, [id, offerDishes]);

  const countInfo = getCountInfo();

  const priceValue = (countInfo.count || 1) * Number(price);

  return (
    <Wrapper istarget={countInfo.count > 0 && !isReadOnly ? 'true' : 'false'}>
      <ImageBlockCSS>
        <img src={imageUrl} alt={'dish img'} />
      </ImageBlockCSS>
      <ContentBlockCSS>
        <Typography variant={'body2'}>{title}</Typography>
        <Typography variant={'sm350'} color={palette.primary.main}>
          {volume} {t('web_dish_volume_ml')}
        </Typography>
        <FlexRowPersonCSS>
          <Typography variant={'sm500'}>
            {t('web_restaurant_menu_persons')}: {personCount}
          </Typography>
          <Typography variant={'sm500'}>|</Typography>
          <Typography variant={'sm500'}>
            {t('web_restaurant_menu_minutes')}: {timeCount}
          </Typography>
        </FlexRowPersonCSS>
      </ContentBlockCSS>

      <NumericBlockCSS sx={{ marginLeft: 'auto' }}>
        <Counter
          price={Number(price)}
          id={id}
          value={countInfo.count}
          title={title}
          img={imageUrl}
          handleChangeRating={handleChangeItem}
        />
        <PriceInputWrapperCSS>
          <TextField
            value={Number(priceValue).toFixed(2)}
            InputProps={{
              readOnly: true,

              endAdornment: (
                <InputAdornment position='start'>
                  <DollarIcon />
                </InputAdornment>
              ),
            }}
          />
        </PriceInputWrapperCSS>

        {!!Number(countInfo.count) && (
          <PerPortionBlockCSS>
            <Typography variant={'sm350'}>
              {Number(price).toFixed(2)}$/{t('web_dish_item_portion')}
            </Typography>
          </PerPortionBlockCSS>
        )}
      </NumericBlockCSS>
    </Wrapper>
  );
};
