import { useEffect, useState } from 'react';
import {
  BackButton,
  DropzoneFormik,
  MainLayout,
  OffersDishList,
  PageTitleBlock,
  RequestHandler,
  SwitcherFormik,
  TextFieldFormik,
} from '../../components';
import { Box, Button } from '@mui/material';
import { IFormikProps } from './types';
import {
  Dish,
  Schedule_Enum,
  useCreateOfferMutation,
  useOfferQuery,
  useRestaurantsQuery,
  useUpdateOfferMutation,
  WorkDay,
} from '../../graphql/generated/graphql';
import { Form, Formik } from 'formik';
import {
  INIT_SCHEDULES_VARIOUS,
  SCHEDULE_TYPES,
  TIME_OPTIONS,
} from '../../constants/constants';

import { SelectFormik } from '../../components/UI/SelectFormik/SelectFormik';
import { TimePicker } from '../../components/UI/TimePicker/TimePicker';
import { DollarIcon } from '../../components/Icons';
import { AutocompleteFormik } from '../../components/UI/AutocompleteFormik/AutocompleteFormik';
import { useTranslatesContext } from '../../context/TranslatesContext';
import {
  ButtonGroupCSS,
  CoastWrapperCSS,
  FormWrapper,
  SchedulesElementCSS,
  SchedulesSingleWrapperCSS,
} from './styles';
import { addAdminOffersSchema } from '../../validation/schema/addAdminOffers.schema';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAccesses, useCustomRouter, useOptions } from '../../hooks';
import { useAlertContext, useAuthContext } from '../../context';
import {
  transformCreateWordDays,
  transformToFormikFormat,
} from '../../utils/transforms/transformCreateWordDays';
import { client } from '../../graphql/client';

export const AddOrUpdateAdminOffersPage = () => {
  const { goBack } = useCustomRouter();
  const { onOpenAlert } = useAlertContext();
  const { t } = useTranslatesContext();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const location = useLocation();
  const { id } = useParams();
  const { isOfferEditAccess } = useAccesses();

  //web_alert_update_offers
  const isEditAdminOffers = location.pathname.split('/').includes('edit');

  const {
    data: offerData,
    loading: offerLoading,
    error: offerError,
  } = useOfferQuery({
    variables: { offerId: Number(id) },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  const [createOffer, { loading: createLoading }] = useCreateOfferMutation({
    onCompleted: () => {
      onOpenAlert({ title: t('web_alert_create_offers') });
      navigate(-1);
    },
  });
  const [updateOffer, { loading: updateeLoading }] = useUpdateOfferMutation({
    onCompleted: () => {
      onOpenAlert({ title: t('web_alert_update_offer') });
      navigate(-1);
    },
  });

  const { kitchenOptions, eventThemeOption } = useOptions();

  const { data: restaurantsData } = useRestaurantsQuery({
    fetchPolicy: 'network-only',
    variables: {
      getRestaurantsInput: {
        brandId: user?.brand?.id,
        limit: Infinity,
        offset: 0,
      },
    },
  });

  useEffect(() => {
    client.cache.evict({ fieldName: 'restaurants' });
  }, []);

  const restaurantsOptions = !restaurantsData
    ? []
    : restaurantsData.restaurants.rows.map((restaurant) => {
        return {
          id: String(restaurant.id),
          label: restaurant.name,
        };
      });

  const scheduleVariantOptions = [
    {
      id: SCHEDULE_TYPES.SINGLE,
      label: t('web_add_res_schedule_variant_single'),
    },
    {
      id: SCHEDULE_TYPES.VARIOUS,
      label: t('web_add_res_schedule_variant_various'),
    },
  ];

  const handleSubmit = (values: IFormikProps) => {
    const kitchenIds = values.kitchen.map((kit) => Number(kit.id));
    const themeEventsIds = values.themeEvents.map((theme) => Number(theme.id));
    const restaurantsIds = values.restaurants.map((rest) => Number(rest.id));

    const formValues = {
      name: values.name,
      description: String(values.description),
      cuisinesIds: kitchenIds,
      eventThemesIds: themeEventsIds,
      restaurantsIds: restaurantsIds,
      image: typeof values.logo === 'string' ? null : values.logo,
      budgetPerPerson: Number(values.pricePerPerson),
      amountPersons: Number(values.personCount),
      scheduleType: values.scheduleVariant as Schedule_Enum,
      workDays: transformCreateWordDays(
        values.scheduleVariant as Schedule_Enum,
        values.schedules,
      ) as Array<WorkDay>,
    };

    if (!id) {
      createOffer({
        variables: {
          createOfferInput: {
            ...formValues,
          },
        },
      });
    } else {
      updateOffer({
        variables: {
          updateOfferInput: {
            ...formValues,
            id: Number(id),
          },
        },
      });
    }
  };

  const [initialValues, setInitialValues] = useState<IFormikProps>({
    name: '',
    description: '',
    kitchen: [],
    themeEvents: [],
    scheduleVariant: SCHEDULE_TYPES.SINGLE as Schedule_Enum,
    schedules: INIT_SCHEDULES_VARIOUS,
    pricePerPerson: '',
    personCount: '',
    restaurants: [],
    logo: null,
  });

  useEffect(() => {
    if (id && offerData) {
      const {
        name,
        description,
        cuisines,
        workDays,
        scheduleType,
        budgetPerPerson,
        amountPersons,
        image,
        restaurants,
        eventThemes,
      } = offerData.offer;

      queueMicrotask(() => {
        setInitialValues({
          name: name, //+
          description: description, //+
          kitchen: cuisines.map((item) => ({
            label: item.title,
            id: String(item.id),
          })), //+
          scheduleVariant: scheduleType, //+
          schedules: transformToFormikFormat(workDays), //+
          pricePerPerson: String(budgetPerPerson), //+
          personCount: String(amountPersons), //+
          restaurants:
            restaurants?.map((item) => ({
              label: item.name,
              id: String(item.id),
            })) || [], //+
          themeEvents: eventThemes.map((item) => ({
            label: item.title,
            id: String(item.id),
          })),
          logo: String(image?.url), //+
        });
      });
    }
  }, [id, offerData]);

  const dishes = offerData?.offer.offerDishes.map(
    (offerDish) => offerDish.dish,
  );
  const offerDishes = (offerData?.offer.offerDishes || []).map((offerDish) => {
    return {
      id: Number(offerDish.dishId),
      count: offerDish.quantity,
      price: offerDish.dish.price,
      title: offerDish.dish.title,
    };
  });

  const handleEditDishes = () => {
    navigate(`/offers/edit-dishes/${id}`);
  };

  return (
    <MainLayout>
      <Box mb={'15px'}>
        <BackButton />
      </Box>
      <PageTitleBlock
        title={
          !id ? t('web_add_offers_main_title') : t('web_edit_offers_main_title')
        }
      />
      <RequestHandler loading={offerLoading} error={offerError} height='50vh'>
        <Formik<IFormikProps>
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={addAdminOffersSchema}
          onSubmit={handleSubmit}
        >
          {({ values }) => {
            return (
              <Form>
                <FormWrapper>
                  <TextFieldFormik
                    name={'name'}
                    size={'large'}
                    label={t('web_add_offers_title')}
                    mt='22px'
                  />
                  <TextFieldFormik
                    name={'description'}
                    size={'large'}
                    label={t('web_add_offers_desc')}
                    multiline={true}
                    minRows={4}
                    mt='22px'
                  />
                  <AutocompleteFormik
                    name={'kitchen'}
                    options={kitchenOptions}
                    sx={{ marginTop: '24px' }}
                    label={t('web_add_offers_kitchen')}
                    placeholder={t('web_add_offers_select')}
                  />
                  <AutocompleteFormik
                    name={'themeEvents'}
                    options={eventThemeOption}
                    sx={{ marginTop: '24px' }}
                    label={t('web_add_offers_theme')}
                    placeholder={t('web_add_offers_select')}
                  />
                  <SchedulesSingleWrapperCSS>
                    <Box sx={{ width: '190px', marginRight: '16px' }}>
                      <SelectFormik
                        name={'scheduleVariant'}
                        options={scheduleVariantOptions}
                        label={t('web_add_offers_validity')}
                      />
                    </Box>
                    {values.scheduleVariant === SCHEDULE_TYPES.SINGLE ? (
                      <>
                        <TimePicker
                          options={TIME_OPTIONS}
                          name={`schedules.0`}
                        />
                      </>
                    ) : null}
                  </SchedulesSingleWrapperCSS>
                  {values.scheduleVariant === SCHEDULE_TYPES.VARIOUS ? (
                    <>
                      {values.schedules.map((schedule, index) => {
                        return (
                          <SchedulesElementCSS key={index}>
                            <SwitcherFormik
                              name={`schedules.${index}.isActive`}
                              sx={{ width: '204px' }}
                              label={t(values.schedules[index].label as string)}
                            />
                            {values.schedules[index].isActive ? (
                              <TimePicker
                                options={TIME_OPTIONS}
                                name={`schedules.${index}`}
                              />
                            ) : null}
                          </SchedulesElementCSS>
                        );
                      })}
                    </>
                  ) : null}
                  <CoastWrapperCSS>
                    <Box sx={{ width: '190px', marginRight: '16px' }}>
                      <TextFieldFormik
                        name={'pricePerPerson'}
                        size={'large'}
                        label={t('web_add_offers_price_person')}
                        type='number'
                        Icon={DollarIcon}
                      />
                    </Box>
                    <Box sx={{ width: '330px', marginRight: '20px' }}>
                      <TextFieldFormik
                        name={'personCount'}
                        size={'large'}
                        label={t('web_add_offers_people_count')}
                        type='number'
                      />
                    </Box>
                  </CoastWrapperCSS>
                  <AutocompleteFormik
                    name={'restaurants'}
                    options={restaurantsOptions}
                    sx={{ marginTop: '24px' }}
                    label={t('web_add_offers_select_restaurant')}
                    placeholder={t('web_add_offers_select')}
                  />
                  <DropzoneFormik
                    label={t('web_add_offers_photo')}
                    subLabel={t('web_add_offers_photo_sub_label')}
                    name={'logo'}
                    sx={{ marginTop: '24px', marginBottom: '25px' }}
                  />
                  {isEditAdminOffers ? (
                    <OffersDishList
                      title={t('web_offer_dishes_list_title')}
                      data={dishes as Array<Dish>}
                      offerDishes={offerDishes}
                    />
                  ) : null}

                  <ButtonGroupCSS>
                    <Button
                      type={'submit'}
                      size={'large'}
                      sx={{ width: 'fit-content' }}
                      disabled={
                        createLoading || updateeLoading || !isOfferEditAccess
                      }
                    >
                      {t(
                        isEditAdminOffers
                          ? 'web_translations_save_btn'
                          : 'web_action_add',
                      )}
                    </Button>
                    {isEditAdminOffers ? (
                      <Button
                        size={'large'}
                        sx={{ width: 'fit-content' }}
                        variant={'outlined'}
                        disabled={!isOfferEditAccess}
                        onClick={handleEditDishes}
                      >
                        {t('web_action_edit_dish')}
                      </Button>
                    ) : null}

                    <Button
                      onClick={goBack}
                      variant={'outlined'}
                      size={'large'}
                      sx={{ width: 'fit-content' }}
                    >
                      {t('web_action_cancel')}
                    </Button>
                  </ButtonGroupCSS>
                </FormWrapper>
              </Form>
            );
          }}
        </Formik>
      </RequestHandler>
    </MainLayout>
  );
};
