import { Box, BoxProps, styled } from '@mui/material';
import { palette } from '../../theme/foundations/index';
import { AliasBoolTypes } from '../../types/baseTypes';

export const Wrapper = styled(Box)(() => ({
  background: palette.white,
  padding: '16px 20px 20px 20px',
  borderRadius: '16px',
  boxShadow: '0px 2px 16px rgba(33, 52, 103, 0.1)',
  display: 'flex',
  flexDirection: 'column',
}));

interface IContentCSSProps extends BoxProps {
  cursorpointer: AliasBoolTypes;
}

export const ContentCSS = styled(Box)<IContentCSSProps>(
  ({ cursorpointer }) => ({
    cursor: cursorpointer === 'true' ? 'pointer' : '',
  }),
);

export const HeadBlockCSS = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '8px',
  gap: '5px',

  height: '36px',
}));

export const InfoBlockCSS = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  '>:first-of-type': {
    marginBottom: '4px',
  },
  '>:nth-of-type(2)': {
    marginBottom: '8px',
  },
  marginBottom: '16px',
}));

export const ImageRowCSS = styled(Box)(() => ({
  display: 'flex',
  gap: '8px',
}));

interface IButtonCroupCSSProps {
  disabledjustyfycontent: AliasBoolTypes;
}

export const ButtonCroupCSS = styled(Box)<IButtonCroupCSSProps>(
  ({ disabledjustyfycontent }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent:
      disabledjustyfycontent === 'true' ? 'unset' : 'space-between',
    gap: '8px',
    marginTop: 'auto',
  }),
);

export const RejectedLabelCSS = styled(Box)(() => ({
  display: 'flex',
  padding: '7px',
  background: palette.button.textBg,
  borderRadius: '8px',
  // maxWidth: '170px',
}));
