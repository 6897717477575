import { Box, styled } from '@mui/material';
import { palette } from '../../../theme/foundations/index';

export const Wrapper = styled(Box)(() => ({
  width: '583px',
  display: 'flex',
  flexDirection: 'column',
}));

export const DescriptionsCSS = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '3px',
}));

export const DescItemCSS = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const ReviewWrapperCSS = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '8px',
  background: palette.gray400,
  padding: '23px',
  marginTop: '38px',
}));

export const UserBlockCSS = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const UserItemCSS = styled(Box)(() => ({
  display: 'flex',
  gap: '27px',
}));

export const UserNameCSS = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
}));

export const RatingBlockCSS = styled(Box)(() => ({
  display: 'flex',
  gap: '16px',
  height: 'fit-content',
  alignItems: 'center',
}));

export const ReviewDescriptionsBlockCSS = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
}));

export const ReviewDescriptionsRowCSS = styled(Box)(() => ({
  display: 'flex',
  gap: '20px',
}));
