import React from 'react';
import { PointIcon } from '../Icons/index';
import { Rating } from '@mui/material';
import { ICustomRatingProps } from './types';
import { palette } from '../../theme/foundations/index';

export const CustomRating = ({ rating, gap = '8px' }: ICustomRatingProps) => {
  return (
    <Rating
      sx={{ gap: gap, opacity: '1 !important' }}
      name='customized-10'
      value={Math.floor(Number(rating))}
      max={4}
      disabled={true}
      icon={<PointIcon />}
      emptyIcon={<PointIcon color={palette.white400} />}
    />
  );
};
