import React from 'react';
import { SUPER_ADMIN_NAVIGATION_AUTHS } from '../../constants/constants';
import { CustomLinkMenuCSS } from './styles';
import { Badge } from '@mui/material';
import { useTranslatesContext } from '../../context/TranslatesContext';

export const SuperAdminNavigation = () => {
  const { t } = useTranslatesContext();
  return (
    <>
      {SUPER_ADMIN_NAVIGATION_AUTHS.map((authObj) => (
        <CustomLinkMenuCSS key={authObj.id} to={authObj.route}>
          {authObj.icon}
          {t(authObj.label)}
          {authObj?.badge && (
            <Badge
              color='warning'
              overlap='circular'
              badgeContent={authObj.badgeContent}
            />
          )}
        </CustomLinkMenuCSS>
      ))}
    </>
  );
};
