import React, { useCallback, useEffect, useState } from 'react';
import {
  AlertOfferToClient,
  BackButton,
  MainLayout,
  OrderMenuList,
  PageTitleBlock,
  PaginationRow,
  RequestHandler,
  SearchTextField,
  SelectCustom,
} from '../../components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, SelectChangeEvent } from '@mui/material';
import { useTranslatesContext } from '../../context/TranslatesContext';
import {
  Dish,
  Event_Offer_Status_Enum,
  useAddOrUpdateEventOfferDishesMutation,
  useBrandEventOfferQuery,
  useDishesLazyQuery,
  useRestaurantsLazyQuery,
  useUpdateEventOfferMutation,
} from '../../graphql/generated/graphql';
import { client } from '../../graphql/client';
import { useAccesses, usePagination } from '../../hooks';
import { getDishesTransformData, getQuantityDishes } from '../../utils';
import { IOfferDish } from './types';
import { COUNTER_TYPES, ROUTES } from '../../constants/constants';
import { useAlertContext, useAuthContext } from '../../context';
import dayjs from 'dayjs';

export const OrderPage = () => {
  const { t } = useTranslatesContext();
  const { onOpenAlert } = useAlertContext();
  const { isOrderConfirmAccess } = useAccesses();

  const { id: brandEventOfferId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { user } = useAuthContext(); // ! ДЛЯ ПОЛУЧЕНИЯ BrandId
  const brandId = user?.brand?.id;
  const orderNumber = location?.state?.orderNumber;

  const { data: brandEventOfferData } = useBrandEventOfferQuery({
    variables: {
      brandEventOfferId: Number(brandEventOfferId),
    },
    skip: !brandEventOfferId,
    onError: () => {
      // ! Если в brandEventOfferId данных нет
      navigate(-1);
    },
  });
  const restaurantIds =
    brandEventOfferData?.brandEventOffer.suitableRestaurantsIds;

  const [searchValue, setSearchValue] = useState('');
  const [offerDishes, setOfferDishes] = useState<Array<IOfferDish>>([]);
  const [selectRestaurant, setSelectRestaurant] = useState('');
  const [discountValue, setDiscountValue] = useState('');

  const quantityDishes = getQuantityDishes(offerDishes);

  const [
    query,
    {
      data: dishesData,
      loading: dishesLoading,
      error: dishesError,
      fetchMore,
      refetch,
    },
  ] = useDishesLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [getRestaraunts, { data: restaurantsData }] = useRestaurantsLazyQuery({
    onCompleted: (data) => {
      setSelectRestaurant(String(data.restaurants.rows[0].id));
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    client.cache.evict({ fieldName: 'restaurants' });
    if (restaurantIds) {
      getRestaraunts({
        variables: {
          getRestaurantsInput: {
            limit: Infinity,
            offset: 0,
            brandId: Number(brandId),
            ids: restaurantIds,
          },
        },
      });
    }
  }, [brandId, restaurantIds]);

  const selectOptions = restaurantsData?.restaurants.rows.map((item) => ({
    id: String(item.id),
    value: String(item.id),
    label: item.name,
  }));

  const handleChangeItem = (
    eventRating: COUNTER_TYPES,
    id: number,
    price: number,
    title: string,
    img: string,
  ) => {
    const index = offerDishes.findIndex((dish) => dish.id === id);

    if (index === -1) {
      if (eventRating === COUNTER_TYPES.PLUS) {
        setOfferDishes((prev) => [
          ...prev,
          { count: 1, id, price, title, img },
        ]);
      }
    } else {
      if (eventRating === COUNTER_TYPES.PLUS) {
        setOfferDishes((prev) => {
          const newValue = [...prev];
          newValue[index].count++;
          return newValue;
        });
      } else {
        setOfferDishes((prev) => {
          const newValue = [...prev];
          if (newValue[index].count > 1) {
            newValue[index].count--;
            return newValue;
          } else {
            newValue.splice(index, 1);
            return newValue;
          }
        });
      }
    }
  };

  const {
    paginationOptions,
    handleLoadMore: paginationHandleLoadMore,
    handleChangePage,
  } = usePagination();

  const getDishesQuery = (limit?: number, offset?: number) => {
    if (!selectRestaurant) return;
    limit = limit ?? paginationOptions.itemsCount;
    offset = offset ?? paginationOptions.offset;

    client.cache.evict({ fieldName: 'dishes' });

    query({
      variables: {
        options: {
          limit,
          offset,
          brandId: brandId,
          search: searchValue,
          restaurantsIds: [+selectRestaurant],
        },
      },
    });
  };

  const handleChangeSearchValue = (e: React.ChangeEvent<any>) => {
    setSearchValue(e.target.value);
  };

  const handleSetOfferDishes = () => {
    if (brandEventOfferData) {
      setOfferDishes(() => {
        return (
          brandEventOfferData.brandEventOffer.eventOfferDishes?.map((dish) => {
            return {
              count: dish.quantity,
              id: Number(dish.dishId),
              price: dish.dish.price,
              title: dish.dish.title,
              img: dish.dish.image.url,
            };
          }) || []
        );
      });
    }
  };

  // ! запрос при изменении селекта
  useEffect(() => {
    getDishesQuery();
  }, [selectRestaurant]);

  useEffect(() => {
    brandId && getDishesQuery();
  }, [paginationOptions.page, brandId]);

  useEffect(() => {
    brandId && getDishesQuery(paginationOptions.itemsCount, 0);
  }, [searchValue, brandId]);

  useEffect(() => {
    handleSetOfferDishes();
  }, [brandEventOfferData]);

  const handleLoadMore = () => {
    paginationHandleLoadMore(fetchMore, {
      brandId: brandId,
      search: searchValue,
      restaurantsIds: [+selectRestaurant],
    });
  };

  const data = dishesData?.dishes.rows;
  const allItemsCount = dishesData?.dishes.count || 0;

  const currentItemsCount =
    paginationOptions.itemsCount * paginationOptions.page >= allItemsCount
      ? allItemsCount
      : paginationOptions.itemsCount * paginationOptions.page;

  const pagesCount = Math.ceil(allItemsCount / paginationOptions.itemsCount);

  const formatedData = getDishesTransformData(data as Array<Dish>);

  const page =
    paginationOptions.page > pagesCount ? pagesCount : paginationOptions.page;

  const onClickPagination = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    handleChangePage(value);
  };

  const handleAddDish = () => {
    navigate(`${ROUTES.addDish}/?brandId=${brandId}`);
  };

  const getTotaOfferPrice = useCallback(() => {
    return offerDishes.reduce((acc, item) => {
      return acc + item.count * Number(item.price);
    }, 0);
  }, [offerDishes]);

  const totalOfferPrice = getTotaOfferPrice();

  const [updateEventOffer] = useUpdateEventOfferMutation({
    onCompleted: () => {
      onOpenAlert({ title: t('web_orders_brand_offer_alert_string') });
      navigate(ROUTES.orders);
    },
  });

  const [addOrUpdateEventOfferDishes] = useAddOrUpdateEventOfferDishesMutation({
    onCompleted: () => {
      updateEventOffer({
        variables: {
          updates: {
            id: Number(brandEventOfferId),
            status: Event_Offer_Status_Enum.Sended,
            comment: '',
            discount: +discountValue,
            selectedRestaurantId: +selectRestaurant,
            total: totalOfferPrice - +discountValue,
          },
        },
      });
    },
  });

  const handleSubmit = () => {
    const dataToFetch = offerDishes.map((offerDish) => ({
      dishId: offerDish.id,
      quantity: offerDish.count,
    }));
    addOrUpdateEventOfferDishes({
      variables: {
        input: {
          eventOfferId: Number(brandEventOfferId),
          offerDishes: dataToFetch,
        },
      },
    });
  };

  const handleSetSelectRestaurant = (e: SelectChangeEvent) => {
    setSelectRestaurant(e.target.value);
    handleSetOfferDishes();
  };

  const alertData = {
    title: brandEventOfferData?.brandEventOffer.event.name,
    date: dayjs(brandEventOfferData?.brandEventOffer.event.startAt).format(
      'DD-MM/YY',
    ),
    personsCount: brandEventOfferData?.brandEventOffer.event.amountPersons,
    offerInitPrice: brandEventOfferData?.brandEventOffer.event.budgetPerPerson,
  };

  return (
    <MainLayout>
      <BackButton />
      <PageTitleBlock
        title={`${t('web_order_title')} #${orderNumber}`}
        sx={{ marginTop: '10px', marginBottom: '20px' }}
      />

      <Box mb={'32px'}>
        <SearchTextField
          value={searchValue}
          onChange={handleChangeSearchValue}
        />
      </Box>
      <Box mb={'32px'}>
        <SelectCustom
          value={String(selectRestaurant)}
          onChange={handleSetSelectRestaurant}
          options={selectOptions}
        />
      </Box>
      {data && data.length === 0 && allItemsCount === 0 ? (
        <Button
          size={'large'}
          sx={{ width: 'fit-content' }}
          onClick={handleAddDish}
        >
          {t('web_restaurant_menu_add_dish_btn')}
        </Button>
      ) : (
        <RequestHandler loading={dishesLoading} error={dishesError}>
          <>
            {formatedData.map((list) => {
              return (
                <OrderMenuList
                  key={list.data[0].id}
                  label={list.label}
                  data={list.data}
                  refetch={refetch}
                  handleChangeItem={handleChangeItem}
                  offerDishes={offerDishes}
                />
              );
            })}

            {pagesCount > 1 ? (
              <PaginationRow
                from={currentItemsCount as number}
                fromText={t('web_pagination_out_of')}
                to={allItemsCount as number}
                onClickButton={handleLoadMore}
                onClickPagination={onClickPagination}
                buttonName={t('web_pagination_show_more')}
                pagesCount={pagesCount}
                page={page}
              />
            ) : null}
          </>
        </RequestHandler>
      )}

      <AlertOfferToClient
        title={alertData.title}
        subTitle={`#${alertData.date} • ${t('web_alert_person_count')}: ${
          alertData.personsCount
        }`}
        countDishes={String(quantityDishes)}
        totalOfferPrice={totalOfferPrice}
        type={'order'}
        offerInitPrice={alertData.offerInitPrice}
        offerDishes={offerDishes}
        onSubmit={handleSubmit}
        isDisabledButton={!offerDishes.length || !isOrderConfirmAccess}
        discountValue={discountValue}
        setDiscountValue={setDiscountValue}
      />
    </MainLayout>
  );
};
