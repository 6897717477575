import React from 'react';

export const AlertIcon = () => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='32' height='32' rx='16' fill='white' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4ZM14.696 11.0527C14.6375 10.2931 15.2381 9.64453 16 9.64453C16.7618 9.64453 17.3624 10.2931 17.304 11.0527L16.7603 18.1205C16.7297 18.5178 16.3984 18.8246 16 18.8246C15.6015 18.8246 15.2702 18.5178 15.2396 18.1205L14.696 11.0527ZM17.4123 20.9436C17.4123 21.7236 16.78 22.3559 16 22.3559C15.22 22.3559 14.5876 21.7236 14.5876 20.9436C14.5876 20.1636 15.22 19.5312 16 19.5312C16.78 19.5312 17.4123 20.1636 17.4123 20.9436Z'
        fill='#FF3B30'
      />
    </svg>
  );
};
