import { Box, styled } from '@mui/material';

export const Wrapper = styled(Box)({
  position: 'fixed',
  display: 'flex',
  borderRadius: '50%',
  boxShadow: '0px 0px 10px 1px',
  right: '20px',
  bottom: '20px',
  cursor: 'pointer',
  zIndex: '10',
});
