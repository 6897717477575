import React, { useCallback } from 'react';
import { Button, Checkbox } from '@mui/material';
import { DishItem } from '../DishItem/DishItem';
import {
  DishWrapperCSS,
  FormControlLabelCSS,
  HeadBlockCSS,
  Wrapper,
} from './styles';
import { IDishListProps } from './types';
import { Dish } from '../../graphql/generated/graphql';
import { useTranslatesContext } from '../../context/TranslatesContext';

export const DishList = ({
  isHasAllBtn,
  label,
  data,
  activeDishesIds,
  setActiveDishesIds,
  initData,
  refetch,
}: IDishListProps) => {
  const { t } = useTranslatesContext();

  const handleChangeItem = (id: number, isActive: boolean) => {
    setActiveDishesIds((prev) => {
      if (isActive) {
        return prev.filter((item) => item !== Number(id));
      } else return [...prev, Number(id)];
    });
  };

  const getIsActiveListValue = useCallback(() => {
    let count = 0;
    data.forEach((dish) => {
      if (activeDishesIds.includes(dish.id)) {
        count++;
      }
    });

    if (count === data.length) return true;
    return false;
  }, [activeDishesIds]);

  const handleSetAllItems = () => {
    setActiveDishesIds((prev) => {
      const allIds = (initData || []).map((item) => item.id);
      const newValue = new Set([...prev, ...allIds]);

      return Array.from(newValue);
    });
  };

  const isActiveList = getIsActiveListValue();

  const handleSetListItems = () => {
    if (!isActiveList) {
      setActiveDishesIds((prev) => {
        const allIds = (data || []).map((item) => item.id);
        const newValue = new Set([...prev, ...allIds]);

        return Array.from(newValue);
      });
    } else {
      setActiveDishesIds((prev) => {
        return prev.filter((item) => {
          if (data.map((i) => i.id).includes(item)) return false;
          return true;
        });
      });
    }
  };

  return (
    <Wrapper>
      <HeadBlockCSS>
        <FormControlLabelCSS
          control={
            <Checkbox
              checked={isActiveList}
              onChange={handleSetListItems}
              name='check'
            />
          }
          label={label}
        />
        {isHasAllBtn && (
          <Button variant={'choose'} onClick={handleSetAllItems}>
            {t('web_restaurant_menu_choose_all_btn')}
          </Button>
        )}
      </HeadBlockCSS>
      <DishWrapperCSS>
        {data.map((dish: Dish) => (
          <DishItem
            isActive={activeDishesIds.includes(dish.id)}
            handleChangeItem={handleChangeItem}
            key={dish.id}
            id={dish.id}
            imageUrl={dish.image.url}
            title={dish.title}
            personCount={String(dish.amountPersons)}
            timeCount={String(dish.cookingTime)}
            price={String(dish.price)}
            refetch={refetch}
          />
        ))}
      </DishWrapperCSS>
    </Wrapper>
  );
};
