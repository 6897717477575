import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled(IconButton)(({ theme }) => ({
  width: 30,
  height: 30,
  backgroundColor: theme.palette.button.backBtnBg,
  '&:hover': {
    backgroundColor: theme.palette.button.backBtnBg,
  },
}));
