import { Box, styled } from '@mui/material';

export const Content = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '25px',
});

export const ContentRowCSS = styled(Box)({
  display: 'flex',
  gap: '25px',
});
