import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '40px',
  gap: '10px',
});

export const HeadBlockCSS = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '10px',
  paddingLeft: '20px',
});
