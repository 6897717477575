import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Wrapper } from './styles';
import { SxProps } from '@mui/material';

interface ICircularIndeterminateProps {
  sx?: SxProps;
}

export const CircularIndeterminate = ({ sx }: ICircularIndeterminateProps) => (
  <Wrapper sx={sx}>
    <CircularProgress />
  </Wrapper>
);
