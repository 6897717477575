import { Wrapper } from './styles';

type LayoutType = {
  children: React.ReactNode;
  backgroundimage?: undefined | string;
};

export const AuthLayout = ({ children, backgroundimage }: LayoutType) => {
  return <Wrapper backgroundimage={backgroundimage}>{children}</Wrapper>;
};
