import React from 'react';

export const GlobusIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.988 0C5.364 0 0 5.376 0 12C0 18.624 5.364 24 11.988 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 11.988 0ZM20.304 7.2H16.764C16.3884 5.71375 15.8323 4.27908 15.108 2.928C17.2969 3.6816 19.1414 5.19807 20.304 7.2ZM12 2.448C12.996 3.888 13.776 5.484 14.292 7.2H9.708C10.224 5.484 11.004 3.888 12 2.448ZM2.712 14.4C2.52 13.632 2.4 12.828 2.4 12C2.4 11.172 2.52 10.368 2.712 9.6H6.768C6.672 10.392 6.6 11.184 6.6 12C6.6 12.816 6.672 13.608 6.768 14.4H2.712ZM3.696 16.8H7.236C7.62 18.3 8.172 19.74 8.892 21.072C6.70076 20.3225 4.855 18.805 3.696 16.8ZM7.236 7.2H3.696C4.855 5.19501 6.70076 3.67748 8.892 2.928C8.16771 4.27908 7.61157 5.71375 7.236 7.2ZM12 21.552C11.004 20.112 10.224 18.516 9.708 16.8H14.292C13.776 18.516 12.996 20.112 12 21.552ZM14.808 14.4H9.192C9.084 13.608 9 12.816 9 12C9 11.184 9.084 10.38 9.192 9.6H14.808C14.916 10.38 15 11.184 15 12C15 12.816 14.916 13.608 14.808 14.4ZM15.108 21.072C15.828 19.74 16.38 18.3 16.764 16.8H20.304C19.1414 18.8019 17.2969 20.3184 15.108 21.072ZM17.232 14.4C17.328 13.608 17.4 12.816 17.4 12C17.4 11.184 17.328 10.392 17.232 9.6H21.288C21.48 10.368 21.6 11.172 21.6 12C21.6 12.828 21.48 13.632 21.288 14.4H17.232Z'
        fill='#B5B1AE'
      />
    </svg>
  );
};
