import React from 'react';
import { IconProps } from '../../types/baseTypes';

export const ChevronBottom = ({ color = '#B5B1AE' }: IconProps) => {
  return (
    <svg
      width='14'
      height='8'
      viewBox='0 0 14 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.91626 5.47961L12.1453 0.288696C12.3313 0.103789 12.5829 0 12.8452 0C13.1075 0 13.3592 0.103789 13.5453 0.288696C13.6369 0.379447 13.7096 0.487411 13.7593 0.606445C13.8089 0.72548 13.8345 0.853205 13.8345 0.982178C13.8345 1.11115 13.8089 1.23888 13.7593 1.35791C13.7096 1.47694 13.6369 1.58491 13.5453 1.67566L7.61719 7.56067C7.43114 7.74558 7.17954 7.84937 6.91724 7.84937C6.65493 7.84937 6.40333 7.74558 6.21729 7.56067L0.289185 1.67566C0.197543 1.58491 0.124842 1.47694 0.0751953 1.35791C0.0255486 1.23888 0 1.11115 0 0.982178C0 0.853205 0.0255486 0.72548 0.0751953 0.606445C0.124842 0.487411 0.197543 0.379447 0.289185 0.288696C0.47523 0.103789 0.726953 0 0.989258 0C1.25156 0 1.50316 0.103789 1.68921 0.288696L6.91626 5.47961Z'
        fill={color}
      />
    </svg>
  );
};
