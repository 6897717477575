import {
  AdminOffersListsMain,
  MainLayout,
  PageTitleBlock,
  SearchTextField,
} from '../../components';
import { ChangeEvent, useState } from 'react';
import { TabsCustom } from '../../components/TabsCustom/TabsCustom';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/constants';
import { useAccesses, useDebounce } from '../../hooks';
import { useTranslatesContext } from '../../context/TranslatesContext';

export const AdminOffersPage = () => {
  const { t } = useTranslatesContext();
  const [searchText, setSearchText] = useState('');
  const [tabNum, setTabNum] = useState('1');
  const navigate = useNavigate();
  const { isOfferEditAccess } = useAccesses();

  const optionsTabs = [
    { id: '1', label: t('web_offer_tab_action_active') },
    { id: '2', label: t('web_offer_tab_action_stopped') },
    { id: '3', label: t('web_offer_tab_action_completed') },
  ];

  const handleSearchText = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleTabs = (val: string) => {
    setTabNum(val);
  };

  const handleAddOffer = () => {
    navigate(ROUTES.addAdminOffers);
  };

  const searchTextDebounce = useDebounce(searchText);

  return (
    <MainLayout>
      <PageTitleBlock
        title={t('web_offer_title_offers')}
        buttonText={t('web_offer_action_add')}
        isDisabledButton={!isOfferEditAccess}
        onClick={handleAddOffer}
      />
      <SearchTextField
        value={searchText}
        onChange={handleSearchText}
        sx={{ mb: '32px' }}
      />
      <Box mb={'30px'} display={'flex'}>
        <TabsCustom
          options={optionsTabs}
          tabNum={tabNum}
          onClick={handleTabs}
        />
      </Box>
      <AdminOffersListsMain tabNum={tabNum} searchText={searchTextDebounce} />
    </MainLayout>
  );
};
