import { Box, styled } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

export const CustomLinkMenuCSS = styled(Link)(({ to }) => {
  const location = useLocation();

  const routeToPrefix = (to as string).slice(1).split('/')[0];
  const currentPathPrefix = location.pathname.slice(1).split('/')[0];

  const match = routeToPrefix.includes(currentPathPrefix);

  return {
    display: 'flex',
    alignItems: 'center',
    height: '48px',
    padding: '0 18px',
    gap: '16px',
    borderRadius: '8px',
    textDecoration: 'none',
    color: match ? '#FD720D' : '#B5B1AE',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '20px',
    svg: {
      path: {
        fill: match && '#FD720D',
      },
    },
    background: match ? '#F5F3F1' : '',
  };
});

export const MenuCSS = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '207px',
}));
