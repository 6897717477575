import { TableCellContent } from '../TableCellContent';
import { TableListRowType } from './types';
import { TableCell, TableRow } from '@mui/material';

export const TableListRow = ({
  row,
  headCells,
  handleRow,
}: TableListRowType) => {
  return (
    <>
      <TableRow
        key={row.id}
        onClick={() => handleRow?.(row?.id)}
        sx={{ cursor: handleRow ? 'pointer' : 'auto' }}
      >
        {headCells?.map((column) => {
          const value = row[column.id];

          return (
            <TableCell key={column.id} sx={{ maxWidth: column.maxWidth }}>
              <TableCellContent id={column.id} value={value} />
            </TableCell>
          );
        })}
      </TableRow>
    </>
  );
};
