import { gql } from '@apollo/client';

export const PARSING_SCHEDULER_SETTINGS = gql`
  query parsingSchedulerSettings {
    parsingSchedulerSettings {
      id
      schedule
      lastParsedDate
    }
  }
`;
