import { useEffect } from 'react';
import {
  BackButton,
  MainLayout,
  PageTitleBlock,
  RequestHandler,
} from '../../components';
import { BrandList } from '../../components/BrandList/BrandList';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/constants';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { Brand, useBrandsLazyQuery } from '../../graphql/generated/graphql';
import { usePagination } from '../../hooks';
import { client } from '../../graphql/client';

export const BrandsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslatesContext();
  const handleAddBrands = () => {
    navigate(ROUTES.addBrands);
  };

  const [query, { data: queryData, loading, error, fetchMore }] =
    useBrandsLazyQuery({
      fetchPolicy: 'network-only',
    });

  const {
    paginationOptions,
    handleLoadMore: paginationHandleLoadMore,
    handleChangePage,
  } = usePagination();

  const handleLoadMore = () => {
    paginationHandleLoadMore(fetchMore);
  };

  const data = queryData?.brands.rows;
  const allItemsCount = queryData?.brands.count || 0;

  const currentItemsCount =
    paginationOptions.itemsCount * paginationOptions.page >= allItemsCount
      ? allItemsCount
      : paginationOptions.itemsCount * paginationOptions.page;

  const pagesCount = Math.ceil(allItemsCount / paginationOptions.itemsCount);

  const getBrandsQuery = (limit?: number, offset?: number) => {
    limit = limit ?? paginationOptions.itemsCount;
    offset = offset ?? paginationOptions.offset;

    client.cache.evict({ fieldName: 'brands' });

    query({
      variables: {
        options: {
          limit,
          offset,
        },
      },
    });
  };

  useEffect(() => {
    getBrandsQuery();
  }, [paginationOptions.page]);

  return (
    <MainLayout>
      <Box mb={'15px'}>
        <BackButton />
      </Box>
      <PageTitleBlock
        title={t('web_brands_title')}
        subTitle={''}
        buttonText={t('web_brands_add_btn')}
        onClick={handleAddBrands}
      />
      <RequestHandler loading={loading} error={error} height='50vh'>
        <BrandList
          data={data as Array<Brand> | undefined}
          handleLoadMore={handleLoadMore}
          handleChangePage={handleChangePage}
          pagesCount={pagesCount}
          page={
            paginationOptions.page > pagesCount
              ? pagesCount
              : paginationOptions.page
          }
          allItemsCount={allItemsCount}
          currentItemsCount={currentItemsCount}
          handleAddBrands={handleAddBrands}
        />
      </RequestHandler>
    </MainLayout>
  );
};
