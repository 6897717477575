import React from 'react';
import { OrdersMainWrapper } from './styles';
import { IOrdersMainProps } from './types';
import { OrderItem } from '../OrderItem/OrderItem';
import {
  Event_Offer_Status_Enum,
  EventOfferDish,
  Restaurant,
  RestaurantReview,
  useBrandEventOffersQuery,
  useRestaurantsQuery,
} from '../../graphql/generated/graphql';
import dayjs from 'dayjs';
import { RequestHandler } from '../UI/RequestHandler/RequestHandler';
import { useAuthContext } from '../../context';

import utc from 'dayjs/plugin/utc'; // Импортируем плагин для работы с UTC

dayjs.extend(utc);

export const OrdersMain = ({ tabNum }: IOrdersMainProps) => {
  const { user } = useAuthContext();

  const orderStatus = getStatus(tabNum);
  const brandId = user?.brand?.id;

  const {
    data: brandEventOffersData,
    loading: brandEventOffersLoading,
    error: brandEventOffersError,
  } = useBrandEventOffersQuery({
    variables: {
      options: {
        status: orderStatus,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: restaurantsData,
    loading: restaurantsLoading,
    error: restaurantsError,
  } = useRestaurantsQuery({
    variables: {
      getRestaurantsInput: {
        brandId: Number(brandId),
        limit: 1000,
        offset: 0,
      },
    },
    fetchPolicy: 'network-only',
    skip: !brandId,
  });

  const getDataTitle = (startAt: string, endAt: string | null | undefined) => {
    const startValue = dayjs(+startAt).format('DD MMMM, HH:mm');

    if (!endAt) return startValue;

    const endValue = dayjs(+endAt).format('DD MMMM, HH:mm');

    return `${startValue} - ${endValue}`;
  };

  const getAddress = (
    ids: Array<number>,
    data: Array<Restaurant> | undefined,
  ) => {
    if (ids.length !== 1) return null;
    const restData = data?.find((item) => {
      return item.id === ids[0];
    }) as Restaurant;

    const { name, address } = restData || {};

    return `${name} - ${address}`;
  };

  return (
    <RequestHandler
      loading={brandEventOffersLoading || restaurantsLoading}
      error={brandEventOffersError || restaurantsError}
    >
      <OrdersMainWrapper>
        {brandEventOffersData?.brandEventOffers.map((data) => {
          const date = dayjs(+data.event.startAt).format(
            `DD-MM/${data.id} HH:mm`,
          );
          const dateTitle = getDataTitle(data.event.startAt, data.event.endAt);
          const dishesImage = data.eventOfferDishes?.map((eventDish) => {
            return {
              id: eventDish.dishId,
              url: eventDish.dish.image.url,
            };
          });

          const fullAddress = getAddress(
            data.suitableRestaurantsIds,
            restaurantsData?.restaurants.rows as Array<Restaurant>,
          );

          const eventTookPlace = +data.event.startAt < Date.now();

          return (
            <OrderItem
              orderStatus={data.status}
              key={data.id}
              id={data.id}
              date={date}
              isEventTookPlace={eventTookPlace}
              priceToPeople={data.total || data.event.budgetPerPerson}
              countPeople={data.event.amountPersons}
              title={data.event.name}
              titleDate={dateTitle}
              address={fullAddress}
              dishesImages={dishesImage || []}
              comment={data.comment}
              eventOfferDishes={data.eventOfferDishes as EventOfferDish[]}
              discount={data.discount}
              review={data.restaurantReview as RestaurantReview}
              userId={data.event.user.id}
              isUpdated={!!data.event.updatedAt}
            />
          );
        })}
      </OrdersMainWrapper>
    </RequestHandler>
  );
};

const getStatus = (tabNum: string) => {
  switch (tabNum) {
    case '1':
      return [Event_Offer_Status_Enum.New];
    case '2':
      return [Event_Offer_Status_Enum.Sended];
    case '3':
      return [Event_Offer_Status_Enum.Active];
    case '4':
      return [
        Event_Offer_Status_Enum.DeclinedByUser,
        Event_Offer_Status_Enum.DeclinedByRestaurant,
      ];
    case '5':
      return [
        Event_Offer_Status_Enum.SuccessfullyClosed,
        Event_Offer_Status_Enum.UnsuccessfullyClosed,
      ];

    default:
      return [Event_Offer_Status_Enum.New];
  }
};
