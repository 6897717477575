import React, { useEffect } from 'react';
import { ContentCSS } from './styles';
import {
  Offer_Availability_Enum,
  Order_Offer_By_Enum,
  Restaurant,
  Schedule_Enum,
  Sort_Direction_Enum,
  useOffersByBrandLazyQuery,
} from '../../graphql/generated/graphql';
import { AdminOffersItem } from '../AdminOffersItem/AdminOffersItem';
import { RequestHandler } from '../UI/RequestHandler/RequestHandler';
import { EmptyList } from '../EmptyList/EmptyList';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { transformWorkTimeString } from '../../utils';
import { usePagination } from '../../hooks';
import { PaginationRow } from '../PaginationRow/PaginationRow';

interface IAdminOffersListsMainProps {
  tabNum: string;
  searchText: string;
}

export const AdminOffersListsMain = ({
  tabNum,
  searchText,
}: IAdminOffersListsMainProps) => {
  const { t } = useTranslatesContext();

  const {
    paginationOptions,
    handleLoadMore: paginationHandleLoadMore,
    handleChangePage,
  } = usePagination();

  const [getOffers, { data, loading, error, fetchMore }] =
    useOffersByBrandLazyQuery({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    });

  const getStatus = (tabNum: string) => {
    switch (tabNum) {
      case '1':
        return Offer_Availability_Enum.Active;
      case '2':
        return Offer_Availability_Enum.Stoped;
      case '3':
        return Offer_Availability_Enum.Closed;
      default:
        return Offer_Availability_Enum.Active;
    }
  };

  const getOffersQuery = (limit?: number, offset?: number) => {
    limit = limit ?? paginationOptions.itemsCount;
    offset = offset ?? paginationOptions.offset;

    getOffers({
      variables: {
        options: {
          limit,
          offset,
          search: searchText,
          status: getStatus(tabNum) as Offer_Availability_Enum,
          sort: {
            direction: Sort_Direction_Enum.Asc,
            by: Order_Offer_By_Enum.CreatedAt,
          },
        },
      },
    });
  };

  useEffect(() => {
    handleChangePage(1);
    getOffersQuery(10, 0);
  }, [searchText, tabNum]);

  useEffect(() => {
    getOffersQuery();
  }, [paginationOptions.page]);

  const handleLoadMore = () => {
    paginationHandleLoadMore(fetchMore);
  };

  const onClickPagination = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    handleChangePage(value);
  };

  const allItemsCount = data?.offersByBrand.count || 0;
  const currentItemsCount =
    paginationOptions.itemsCount * paginationOptions.page >= allItemsCount
      ? allItemsCount
      : paginationOptions.itemsCount * paginationOptions.page;
  const pagesCount = Math.ceil(allItemsCount / paginationOptions.itemsCount);
  const page =
    paginationOptions.page > pagesCount ? pagesCount : paginationOptions.page;

  return (
    <RequestHandler loading={loading} error={error} height={'50vh'}>
      {data && data?.offersByBrand.rows?.length === 0 ? (
        <EmptyList
          sx={{ marginTop: '136px' }}
          title={t('web_offers_emtpy_title')}
          img={t('web_offers_emtpy_img')}
        />
      ) : (
        <>
          <ContentCSS>
            {data?.offersByBrand.rows.map((offer) => {
              const workDayValue = `${t(
                'web_offer_schedule_from',
              )} ${transformWorkTimeString(offer.workDays[0].from)} ${t(
                'web_offer_schedule_to',
              )} ${transformWorkTimeString(offer.workDays[0].to)}`;

              const scheduleValue =
                offer.scheduleType === Schedule_Enum.Flexible
                  ? ''
                  : workDayValue;
              return (
                <AdminOffersItem
                  key={offer.id}
                  scheduleValue={scheduleValue}
                  id={offer.id}
                  name={offer.name}
                  budgetPerPerson={offer.budgetPerPerson}
                  restaurants={offer.restaurants as Restaurant[]}
                  orderStatus={getStatus(tabNum)}
                />
              );
            })}
          </ContentCSS>
          {pagesCount > 1 ? (
            <PaginationRow
              from={currentItemsCount as number}
              fromText={t('web_pagination_out_of')}
              to={allItemsCount as number}
              onClickButton={handleLoadMore}
              onClickPagination={onClickPagination}
              buttonName={t('web_pagination_show_more')}
              isHasShowMore={false}
              pagesCount={pagesCount}
              page={page}
            />
          ) : null}
        </>
      )}
    </RequestHandler>
  );
};
