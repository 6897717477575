import React from 'react';
import { Button, Typography } from '@mui/material';
import { palette } from '../../theme/foundations';
import { DishMenuAlertCSS } from './styles';
import { IAlertDishCreateMenuProps } from './types';
import { useTranslatesContext } from '../../context/TranslatesContext';

export const AlertDishCreateMenu = ({
  title,
  onClick,
  btnText,
  disabled,
}: IAlertDishCreateMenuProps) => {
  const { t } = useTranslatesContext();
  return (
    <DishMenuAlertCSS>
      <Typography variant={'body2'} color={palette.white}>
        {title}
      </Typography>
      <Button
        size={'large'}
        sx={{ width: 'fit-content' }}
        onClick={onClick}
        disabled={disabled}
      >
        {btnText || t('web_restaurant_menu_submit_btn')}
      </Button>
    </DishMenuAlertCSS>
  );
};
