import { MainLayout, PageTitleBlock } from '../../components';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { useOptions } from '../../hooks';
import {
  SettingsBudgetPerGuestForm,
  SettingsCitiesForm,
  SettingsContactsForm,
  SettingsCrateEventForm,
  SettingsDirectionKitchenForm,
  SettingsFiltersForm,
  SettingsThemeForm,
  SettingsTipsForm,
  // SettingsParsingForm,
} from '.';

export const SettingsPage = () => {
  const { t } = useTranslatesContext();

  const {
    kitchenOptions,
    eventThemeOption,
    citiesOption,
    advicesOption,
    adviceHourBefore,
    contactPhone,
    guestOption,
    filtersOption,
    budgetOption,
    // parsingOptions,
  } = useOptions();

  return (
    <MainLayout>
      <PageTitleBlock
        title={t('web_setting_addit_settings')}
        sx={{ marginBottom: '50px' }}
      />
      <SettingsContactsForm contactPhone={contactPhone} />
      <SettingsBudgetPerGuestForm budgetOption={budgetOption} />
      <SettingsCrateEventForm guestOption={guestOption} />
      <SettingsFiltersForm filtersOption={filtersOption} />
      <SettingsCitiesForm citiesOption={citiesOption} />
      {/* <SettingsParsingForm parsingOptions={parsingOptions} /> */}
      <SettingsTipsForm
        advicesOption={advicesOption}
        adviceHourBefore={adviceHourBefore}
      />
      <SettingsDirectionKitchenForm kitchenOptions={kitchenOptions} />
      <SettingsThemeForm eventThemeOption={eventThemeOption} />
    </MainLayout>
  );
};
