import React from 'react';

export const EyeOffIcon = () => {
  return (
    <svg
      width='24'
      height='23'
      viewBox='0 0 24 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.70713 0.792892L1.29292 2.20711L5.35774 6.27193C4.97908 6.58574 4.60434 6.92275 4.23373 7.28115C3.40451 8.08305 2.65433 8.93916 1.99393 9.79562C1.59543 10.3124 1.3125 10.719 1.15759 10.9612L0.812988 11.5L1.15759 12.0388C1.3125 12.281 1.59543 12.6876 1.99393 13.2044C2.65433 14.0608 3.40451 14.917 4.23373 15.7188C6.66434 18.0694 9.27247 19.5 12 19.5C13.7209 19.5 15.3943 18.9305 17.0056 17.9198L21.2929 22.2071L22.7071 20.7929L2.70713 0.792892ZM15.5447 16.4588L14.032 14.9462C13.4365 15.2981 12.7418 15.5 12 15.5C9.79088 15.5 8.00002 13.7091 8.00002 11.5C8.00002 10.7582 8.20195 10.0635 8.55384 9.46803L6.77974 7.69393C6.39235 8.00634 6.0068 8.34872 5.62406 8.71885C4.86986 9.4482 4.18241 10.2327 3.57777 11.0169C3.44718 11.1862 3.32651 11.3478 3.21619 11.5C3.32651 11.6522 3.44718 11.8138 3.57777 11.9831C4.18241 12.7673 4.86986 13.5518 5.62406 14.2811C7.71751 16.3056 9.89495 17.5 12 17.5C13.1682 17.5 14.3586 17.1322 15.5447 16.4588ZM10.0678 10.982C10.0236 11.1472 10 11.3208 10 11.5C10 12.6046 10.8955 13.5 12 13.5C12.1792 13.5 12.3528 13.4764 12.5181 13.4323L10.0678 10.982ZM20.0981 15.391L18.6838 13.9767C19.318 13.3356 19.9009 12.6593 20.4223 11.9831C20.5529 11.8138 20.6735 11.6522 20.7839 11.5C20.6735 11.3478 20.5529 11.1862 20.4223 11.0169C19.8176 10.2327 19.1302 9.4482 18.376 8.71885C16.2825 6.69437 14.1051 5.5 12 5.5C11.4777 5.5 10.951 5.57353 10.4221 5.71502L8.84017 4.13304C9.87262 3.72138 10.9271 3.5 12 3.5C14.7276 3.5 17.3357 4.93062 19.7663 7.28115C20.5955 8.08305 21.3457 8.93916 22.0061 9.79562C22.4046 10.3124 22.6875 10.719 22.8425 10.9612L23.1871 11.5L22.8425 12.0388C22.6875 12.281 22.4046 12.6876 22.0061 13.2044C21.4349 13.9451 20.7966 14.6856 20.0981 15.391Z'
        fill='#3D3835'
      />
    </svg>
  );
};
