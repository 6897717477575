import { Stack, Tooltip, Typography } from '@mui/material';
import { TableCellContentType } from '../TableListRow/types';
import { COLUMN_IDS } from '../../constants';
import { KitchenLabel } from '../../../KitchenLabel/KitchenLabel';
import { KitchenType } from '../../../../types/baseTypes';

export const TableCellContent = ({ value, id }: TableCellContentType) => {
  switch (id) {
    case COLUMN_IDS.LOCATION:
      return (
        <Tooltip title={String(value)} key={COLUMN_IDS.LOCATION}>
          <Typography
            color='brown500'
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              cursor: 'pointer',
            }}
          >
            {String(value)}
          </Typography>
        </Tooltip>
      );
    case COLUMN_IDS.KITCHENS:
      return <KitchenField value={value as Array<KitchenType>} />;
    default:
      return <Typography color='brown500'>{String(value)}</Typography>;
  }
};

const KitchenField = ({ value }: { value: Array<KitchenType> }) => {
  if (!value.length) return null;
  return (
    <Stack direction={'row'} gap={'10px'} sx={{ cursor: 'pointer' }}>
      <KitchenLabel title={value[0].name} />
      {value.length > 1 ? (
        <Tooltip
          key={COLUMN_IDS.KITCHENS}
          title={
            value.length > 1 ? (
              <Stack direction={'row'} gap={'8px'} flexWrap={'wrap'}>
                <KitchenTooltip value={value} />
                {''}
              </Stack>
            ) : (
              ''
            )
          }
        >
          <Typography variant='body1' color='#FF9500'>{`+${
            value.length - 1
          }`}</Typography>
        </Tooltip>
      ) : null}
    </Stack>
  );
};

const KitchenTooltip = ({ value }: { value: Array<KitchenType> }) => {
  return (
    <>
      {value.map((item, index) => {
        if (!index) return null;
        return <KitchenLabel title={item.name} />;
      })}
    </>
  );
};
