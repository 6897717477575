import { AliasFieldTypes } from '../../types/baseTypes';
import {
  Translate,
  TranslateImageInput,
  TranslateInput,
  TranslatesQuery,
  TranslateStringInput,
  TranslateTextInput,
} from '../../graphql/generated/graphql';

const dataFields = (
  translate: TranslateInput,
): TranslateImageInput[] | TranslateStringInput[] | TranslateTextInput[] => {
  const t = translate.strings.length
    ? 'strings'
    : translate.texts.length
    ? 'texts'
    : 'images';
  return translate[t];
};

const fieldsTypes = (object: Translate): AliasFieldTypes => {
  return object?.strings?.length
    ? 'string'
    : object?.texts?.length
    ? 'text'
    : 'image';
};

const findLocale = (localesData: any, isoLocale = 'en'): string => {
  return localesData.find((obj: any) => obj.locale === isoLocale)?.value || '';
};

export function transformTranslateInit(data?: Translate[]) {
  if (!data) return [] as TranslatesQuery[];
  return data
    .map((translateObject: Translate) => {
      const data = dataFields(translateObject).map(
        (d: Record<any, any>, i: number) => {
          return {
            id: String(i),
            value: d[fieldsTypes(translateObject)],
            locale: d.locale.code,
          };
        },
      );

      return {
        key: translateObject.key,
        fieldDataEN: findLocale(data, 'en'),
        fieldDataUA: findLocale(data, 'uk'),
        // fieldDataFR: findLocale(data, 'fr'),
        type: fieldsTypes(translateObject),
      };
    })
    .sort((a, b) => a.key.localeCompare(b.key));
}
