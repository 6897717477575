import { LabelCSS, MainCSS, Wrapper } from '../RatingFormik/styles';
import { IconButton, TextField, SxProps } from '@mui/material';
import { MinusIcon, PlusIcon } from '../../Icons/index';
import { InputWrapperCSS } from './styles';
import { COUNTER_TYPES } from '../../../constants/constants';

type Props = {
  id: number;
  label?: string;
  title: string;
  sx?: SxProps;
  value: number;
  price: number;
  img: string;
  handleChangeRating?: (
    eventRating: COUNTER_TYPES,
    id: number,
    price: number,
    title: string,
    img: string,
  ) => void;
};

export const Counter = ({
  id,
  label,
  sx,
  value,
  price,
  title,
  img,
  handleChangeRating,
}: Props) => {
  return (
    <Wrapper sx={sx}>
      {label && <LabelCSS>{label}</LabelCSS>}
      <MainCSS>
        {handleChangeRating ? (
          <IconButton
            sx={{ height: '31px' }}
            onClick={() =>
              handleChangeRating?.(
                COUNTER_TYPES.MINUS,
                id,
                Number(price),
                title,
                img,
              )
            }
          >
            <MinusIcon />
          </IconButton>
        ) : null}

        <InputWrapperCSS>
          <TextField
            value={value}
            InputProps={{
              readOnly: true,
            }}
          />
        </InputWrapperCSS>

        {handleChangeRating ? (
          <IconButton
            onClick={() => {
              handleChangeRating?.(
                COUNTER_TYPES.PLUS,
                id,
                Number(price),
                title,
                img,
              );
            }}
          >
            <PlusIcon />
          </IconButton>
        ) : null}
      </MainCSS>
    </Wrapper>
  );
};
