import React from 'react';

export const SmileIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='10' cy='10' r='10' fill='#EECA0B' />
      <circle cx='6' cy='7' r='1' fill='black' />
      <circle cx='14' cy='7' r='1' fill='black' />
      <path
        d='M6 12C6 12 7 15 10 15C13 15 14 12 14 12'
        stroke='black'
        strokeLinecap='round'
      />
    </svg>
  );
};
