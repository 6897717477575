import React from 'react';
import { Avatar } from '@mui/material';
import { ChevronBottom } from '../Icons';
import { CustomMenu } from '../UI/CustomMenu/CustomMenu';
import {
  ChevronBlockCSS,
  InfoBlockCSS,
  JobTitleCSS,
  NameCSS,
  Wrapper,
} from './styles';
import { IAvatarDropMenuProps } from './types';
import { useDropMenu } from '../../hooks';
import { useAuthContext } from '../../context';

export const AvatarDropMenu = ({
  name,
  jobAtWork,
  bottomOpen,
  avatarUrl,
}: IAvatarDropMenuProps) => {
  const { anchorEl, onOpenMenu, onCloseMenu } = useDropMenu();
  const { signOut } = useAuthContext();
  const handleLogout = () => {
    signOut();
    onCloseMenu();
  };

  return (
    <>
      <Wrapper onClick={onOpenMenu}>
        <Avatar
          alt={name}
          src={avatarUrl}
          sx={{ width: 40, height: 40, mr: '12px' }}
        />
        <InfoBlockCSS>
          <NameCSS>{name}</NameCSS>
          <JobTitleCSS>{jobAtWork}</JobTitleCSS>
        </InfoBlockCSS>
        <ChevronBlockCSS anchorel={anchorEl}>
          <ChevronBottom />
        </ChevronBlockCSS>
      </Wrapper>
      <CustomMenu
        anchorEl={anchorEl}
        handleClose={onCloseMenu}
        handleLogout={handleLogout}
        bottomOpen={bottomOpen}
      />
    </>
  );
};
