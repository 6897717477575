import React from 'react';
import { Wrapper } from './styles';
import { PointStatus } from '../../PointStatus/PointStatus';
import { Box, Divider, Typography } from '@mui/material';
import { palette } from '../../../theme/foundations/index';
import { useModalContext } from '../../../context/index';
import { useTranslatesContext } from '../../../context/TranslatesContext';

export const CompletedRejectedOrderModal = () => {
  const { infoModal } = useModalContext();
  const { t } = useTranslatesContext();
  const { numberOrder, title, reason, subReason, total } = infoModal.content;

  return (
    <Wrapper>
      <Box mb={'15px'}>
        <PointStatus
          status={'error'}
          title={`${t(
            'web_completed_rejected_modal_order_title',
          )} #${numberOrder}`}
          isLargeText
        />
      </Box>
      <Typography mb={'5px'} variant={'body3'}>
        {title}
      </Typography>
      <Typography mb={'5px'} variant={'body3'} color={palette.error.main}>
        {reason}
      </Typography>
      <Typography variant={'body5'}>
        {t('web_completed_rejected_modal_reason')}: {subReason}
      </Typography>
      <Box mt={'5px'} mb={'5px'}>
        <Divider />
      </Box>
      <Typography variant={'md750'}>
        {t('web_completed_rejected_modal_total')}: ${total}{' '}
      </Typography>
    </Wrapper>
  );
};
