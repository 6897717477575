import * as yup from 'yup';
import { MESSAGES } from '../messages';

export const addUserSchema = (offPassword?: boolean) =>
  yup.object().shape({
    name: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
    surname: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
    email: yup
      .string()
      .email(MESSAGES.INCORRECT_EMAIL)
      .required(MESSAGES.REQUIRE_MESSAGE),
    ...(offPassword && {
      password: yup
        .string()
        .min(6, MESSAGES.MIN_LENGTH('6'))
        .required(MESSAGES.REQUIRE_MESSAGE),
    }),
    position: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
    userPermission: yup
      .array()
      .min(1, MESSAGES.REQUIRE_MESSAGE)
      .required(MESSAGES.REQUIRE_MESSAGE),
    photo: yup.mixed().required(MESSAGES.REQUIRE_MESSAGE),
  });
