import * as yup from 'yup';
import { MESSAGES } from '../messages';

export const editCitiesSchema = yup.object().shape({
  cities: yup.array().of(
    yup.object().shape({
      label: yup
        .string()
        .max(255, MESSAGES.MAX_LENGTH('255'))
        .required(MESSAGES.REQUIRE_MESSAGE),
      latitude: yup.number().required(MESSAGES.REQUIRE_MESSAGE),
      longitude: yup.number().required(MESSAGES.REQUIRE_MESSAGE),
    }),
  ),
});
