import { Box, styled } from '@mui/material';
import { palette } from '../../theme/foundations/index';

export const Wrapper = styled(Box)({
  display: 'flex',
  width: '100%',
  maxWidth: '731px',
  padding: '30px',
  background: palette.white,
  boxShadow: '0px 5px 16px rgba(33, 52, 103, 0.1)',
  borderRadius: '16px',
  gap: '30px',
});

export const DropzoneBlockCSS = styled(Box)({
  display: 'flex',
  width: '100%',
  maxWidth: '180px',
  height: '233px',
});

export const MainBlockCSS = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const SwitcherBlockCSS = styled(Box)({
  display: 'flex',
  marginBottom: '20px',
});

export const DatePickerRowCSS = styled(Box)({
  display: 'flex',
  gap: '16px',
  marginBottom: '16px',
});

export const TitleBlockCSS = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  marginBottom: '20px',
});
