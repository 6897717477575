import React from 'react';
import { Box, Typography } from '@mui/material';
import { ButtonCSS, ButtonGroupCSS, TitleBlockCSS } from './styles';

export const DeleteBrandModal = () => {
  return (
    <Box>
      <TitleBlockCSS>
        <Typography variant={'md700'}>Деактивирование бренда</Typography>
        <Typography variant={'body1'}>
          Вы уверены что хотите деактивировать бренд?
        </Typography>
      </TitleBlockCSS>
      <ButtonGroupCSS>
        <ButtonCSS variant={'text'}>Отменить</ButtonCSS>
        <ButtonCSS variant={'outlined'}>Деактивировать</ButtonCSS>
      </ButtonGroupCSS>
    </Box>
  );
};
