import React from 'react';
import { Button, Typography } from '@mui/material';
import { IPageTitleBlockProps } from './types';
import {
  ButtonGroupCSS,
  ButtonSaveChangesCSS,
  TittleBlockCSS,
  Wrapper,
} from './styles';
import { TabsCustom } from '../TabsCustom/TabsCustom';
import { useTranslatesContext } from '../../context/TranslatesContext';

export const PageTitleBlock = ({
  title,
  subTitle,
  buttonText,
  buttonTextSaveChanges,
  onClick,
  enableTabs,
  onClickTabs,
  onClickSaveChanges,
  isDisabledButtonSaveChanges,
  tabNum,
  sx,
  isDisabledButton,
}: IPageTitleBlockProps) => {
  const { t } = useTranslatesContext();

  const optionsTabs = [
    { id: '1', label: t('web_brand_option_restaurants') },
    { id: '2', label: t('web_brand_option_users') },
  ];
  return (
    <Wrapper sx={sx}>
      {enableTabs && (
        <TabsCustom
          tabNum={tabNum}
          options={optionsTabs}
          onClick={onClickTabs}
        />
      )}
      <TittleBlockCSS>
        {title && <Typography variant={'big600'}>{title}</Typography>}
        {subTitle && <Typography variant={'md500'}>{subTitle}</Typography>}
      </TittleBlockCSS>
      {(buttonText || buttonTextSaveChanges) && (
        <ButtonGroupCSS>
          {buttonTextSaveChanges && (
            <ButtonSaveChangesCSS
              color={'success'}
              size={'large'}
              onClick={onClickSaveChanges}
              disabled={isDisabledButtonSaveChanges}
            >
              {buttonTextSaveChanges}
            </ButtonSaveChangesCSS>
          )}
          {buttonText && (
            <Button
              size={'large'}
              sx={{ width: 'fit-content' }}
              onClick={onClick}
              disabled={isDisabledButton}
            >
              {buttonText}
            </Button>
          )}
        </ButtonGroupCSS>
      )}
    </Wrapper>
  );
};
