import { gql } from '@apollo/client';

export const BRANDS = gql`
  query Brands($options: GetBrandsInput!) {
    brands(options: $options) {
      rows {
        id
        title
        description
        isActive
        image {
          id
          width
          height
          mime
          size
          url
        }
        restaurantsCount
      }
      count
    }
  }
`;
