import React from 'react';
import { IconProps } from '../../types/baseTypes';

export const CloseModalIcon = ({
  size = '16px',
  color = '#92929D',
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1 1.05127L8 8.4573M15 15.8633L8 8.4573M8 8.4573L15 1.05127M8 8.4573L1 15.8633'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  );
};
