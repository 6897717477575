import * as yup from 'yup';
import { MESSAGES } from '../messages';

export const editCategoriesSchema = yup.object().shape({
  categories: yup.array().of(
    yup.object().shape({
      title: yup
        .string()
        .max(255, MESSAGES.MAX_LENGTH('255'))
        .required(MESSAGES.REQUIRE_MESSAGE),
      count: yup.number(),
      isEdit: yup.boolean(),
    }),
  ),
});
