import React from 'react';
import { UserListItem } from '../UserListItem/UserListItem';
import { Wrapper } from './styles';
import { Manager } from '../../graphql/generated/graphql';
import { EmptyList } from '../EmptyList/EmptyList';
import { useTranslatesContext } from '../../context/TranslatesContext';

interface IUserListProps {
  data?: Manager[] | [];
}
export const UserList = ({ data }: IUserListProps) => {
  const { t } = useTranslatesContext();
  if (data && data.length === 0) {
    return (
      <EmptyList
        sx={{ marginTop: '136px' }}
        title={t('web_users_empty_title')}
        img={t('web_users_empty_img')}
      />
    );
  }
  return (
    <Wrapper>
      {data?.map((data) => {
        return (
          <UserListItem
            key={data.id}
            id={data.id}
            name={data.firstName || ''}
            job={data.position || ''}
            usersAllowedActions={data.roles}
            imgUrl={data.photo?.url || ''}
          />
        );
      })}
    </Wrapper>
  );
};
