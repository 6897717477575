import React from 'react';
import { Button, Typography } from '@mui/material';
import { OrderMenuItem } from '../OrderMenuItem/OrderMenuItem';
import { HeadBlockCSS, Wrapper, WrapperListCSS } from './styles';
import { IOrderMenuListProps } from './types';
import { useTranslatesContext } from '../../context/TranslatesContext';

export const OrderMenuList = ({
  label,
  data,
  handleChangeItem,
  offerDishes,
  isHasAllBtn,
  handleSetAllItems,
}: IOrderMenuListProps) => {
  const { t } = useTranslatesContext();

  return (
    <Wrapper>
      <HeadBlockCSS>
        <Typography variant={'big700'}>{label}</Typography>
        {isHasAllBtn && (
          <Button variant={'choose'} onClick={handleSetAllItems}>
            {t('web_restaurant_menu_choose_all_btn')}
          </Button>
        )}
      </HeadBlockCSS>
      <WrapperListCSS>
        {data?.map((dish) => {
          return (
            <OrderMenuItem
              key={dish.id}
              id={dish.id}
              imageUrl={dish.image.url}
              title={dish.title}
              personCount={String(dish.amountPersons)}
              timeCount={String(dish.cookingTime)}
              price={String(dish.price)}
              volume={Number(dish.volume)}
              handleChangeItem={handleChangeItem}
              offerDishes={offerDishes}
            />
          );
        })}
      </WrapperListCSS>
    </Wrapper>
  );
};
