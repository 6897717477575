import { Avatar, Box, styled } from '@mui/material';
import { palette } from '../../theme/foundations/index';

export const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  right: '33px',
  left: '250px',
  bottom: '76px',
  background: 'rgba(146, 146, 157, 0.8)',
  borderRadius: '16px',
  backdropFilter: 'blur(10px)',
  padding: '10px 10px 10px 20px',
  alignItems: 'center',
  justifyContent: 'space-between',
  zIndex: '1',
  maxWidth: '1622px',
});

export const AlertWrapper = styled(Box)({
  display: 'flex',
  width: '100%',
  gap: '12px',
  alignItems: 'center',
});

export const BottomWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  position: 'relative',
});

export const DividerCSS = styled(Box)({
  display: 'flex',
  width: '100%',
  marginTop: '10px',
  marginBottom: '12.5px',
  height: '1px',
  background: palette.gray750,
});

export const TitleBlockCSS = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '45px',
});

export const ContentCSS = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  height: '45px',
  gap: '12px',
  marginLeft: 'auto',
  marginRight: '3%',
  cursor: 'pointer',
});

export const AvatarCSS = styled(Avatar)({
  background: palette.white,
  border: '2px solid #A4A5AE !important',
  ':not(:last-of-type)': {
    marginLeft: '-15px !important',
  },
  img: {
    objectFit: 'fill',
  },
});

export const MenuItemWrapperCSS = styled(Box)({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  width: 'calc(100% + 30px)',
  padding: '30px 30px 20px 30px',
  boxShadow: '0px 5px 16px rgba(33, 52, 103, 0.1)',
  background: palette.white,
  bottom: '120px',
  left: '-20px',
  borderRadius: 8,
});

export const MenuInfoBlockCSS = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
});

export const MenuRowCSS = styled(Box)({
  display: 'flex',
});
