import React from 'react';
import { Box, Typography } from '@mui/material';
import { Wrapper } from './styles';

interface IOptionsCaseLayoutProps {
  title?: string;
  children: React.ReactNode;
  mb?: string;
}

export const OptionsCaseLayout = ({
  children,
  title,
  mb = '36px',
}: IOptionsCaseLayoutProps) => {
  return (
    <Wrapper mb={mb}>
      <Box mb={'16px'}>
        <Typography variant={'md900'}>{title}</Typography>
      </Box>
      {children}
    </Wrapper>
  );
};
