import { Box, Stack } from '@mui/material';
import { Form, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  Button,
  DropzoneFormik,
  MainLayout,
  PageTitleBlock,
  RatingFormik,
  RequestHandler,
  SwitcherFormik,
  TextFieldFormik,
} from '../../components';
import { DollarIcon } from '../../components/Icons';
import { AutocompleteFormik } from '../../components/UI/AutocompleteFormik/AutocompleteFormik';
import { BackButton } from '../../components/UI/BackButton/BackButton';
import { SelectFormik } from '../../components/UI/SelectFormik/SelectFormik';
import { TimePicker } from '../../components/UI/TimePicker/TimePicker';
import { SCHEDULE_TYPES, TIME_OPTIONS } from '../../constants/constants';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { addRestaurant } from '../../validation';
import {
  AdressWrapperCSS,
  CoastWrapperCSS,
  FormWrapper,
  SchedulesElementCSS,
  SchedulesSingleWrapperCSS,
} from './styles';
import { IFormikProps } from './types';
import { GoogleAutocompleteFormik } from '../../components/UI/GoogleAutocompleteFormik/GoogleAutocompleteFormik';
import {
  Schedule_Enum,
  useCitiesQuery,
  useCreateRestaurantMutation,
  useCuisinesQuery,
  useRestaurantQuery,
  Workday_Enum,
  WorkDay,
  useUpdateRestaurantMutation,
  UpdateWorkDayInput,
} from '../../graphql/generated/graphql';
import { useAlertContext, useAuthContext } from '../../context';
import {
  transformCreateWordDays,
  transformToFormikFormat,
} from '../../utils/transforms/transformCreateWordDays';
import { MESSAGES } from '../../validation/messages';
import { useAccesses } from '../../hooks/index';

const INIT_SCHEDULES_VARIOUS = [
  {
    from: '10:00',
    to: '18:00',
    isActive: true,
    label: 'web_add_res_schedule_day_1',
    day: Workday_Enum.Monday,
  },
  {
    from: '10:00',
    to: '18:00',
    isActive: true,
    label: 'web_add_res_schedule_day_2',
    day: Workday_Enum.Tuesday,
  },
  {
    from: '10:00',
    to: '18:00',
    isActive: true,
    label: 'web_add_res_schedule_day_3',
    day: Workday_Enum.Wednesday,
  },
  {
    from: '10:00',
    to: '18:00',
    isActive: true,
    label: 'web_add_res_schedule_day_4',
    day: Workday_Enum.Thursday,
  },
  {
    from: '10:00',
    to: '18:00',
    isActive: true,
    label: 'web_add_res_schedule_day_5',
    day: Workday_Enum.Friday,
  },
  {
    from: '10:00',
    to: '18:00',
    isActive: true,
    label: 'web_add_res_schedule_day_6',
    day: Workday_Enum.Saturday,
  },
  {
    from: '10:00',
    to: '18:00',
    isActive: true,
    label: 'web_add_res_schedule_day_7',
    day: Workday_Enum.Sunday,
  },
];

export const AddOrUpdateRestaurant = () => {
  const { id } = useParams();
  const location = useLocation();
  const { isAddRestaurantAccess } = useAccesses();
  const navigate = useNavigate();

  const { t } = useTranslatesContext();
  const { user } = useAuthContext();
  const { onOpenAlert } = useAlertContext();

  const searchParams = new URLSearchParams(location.search);
  const brandIdParams = searchParams.get('brandId');

  const [createRestaurant] = useCreateRestaurantMutation({
    onCompleted: () => {
      onOpenAlert({ title: t('web_alert_add_restaurant') });
      navigate(-1);
    },
  });
  const [updateRestaurant] = useUpdateRestaurantMutation({
    onCompleted: () => {
      onOpenAlert({ title: t('web_alert_update_restaurant') });
      navigate(-1);
    },
  });

  useEffect(() => {
    if (!isAddRestaurantAccess) {
      navigate(-1);
    }
  }, []);

  const {
    data: restaurantData,
    loading: restaurantLoading,
    error: restaurantError,
  } = useRestaurantQuery({
    variables: { id: Number(id) },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  const { data: citiesData, loading, error } = useCitiesQuery();
  const {
    data: cuisinesData,
    loading: cuisinesLoading,
    error: cuisinessError,
  } = useCuisinesQuery();

  const cityOptions = !citiesData
    ? []
    : citiesData.cities.map((item) => ({
        label: item.title,
        id: String(item.id),
      }));

  const kitchenOptions = !cuisinesData
    ? []
    : cuisinesData.cuisines.map((item) => ({
        label: item.title,
        id: String(item.id),
      }));

  const [initialValues, setInitialValues] = useState<IFormikProps>({
    name: '',
    description: '',
    city: '',
    address: '',
    scheduleVariant: SCHEDULE_TYPES.SINGLE as Schedule_Enum,
    schedules: INIT_SCHEDULES_VARIOUS,
    averageCheck: '',
    numberOfSeats: '',
    rating: '$'.repeat(3),
    kitchen: [],
    logo: null,
    mainLogo: null,
    geometry: {
      lat: 0,
      lng: 0,
    },
  });

  const brandId =
    user?.brand?.id || brandIdParams || restaurantData?.restaurant.brandId;

  const handleSubmit = (values: IFormikProps) => {
    if (!values.geometry.lat) return;

    const formValues = {
      name: values.name,
      description: values.description,
      cityId: Number(values.city),
      address: values.address,
      cuisinesIds: values.kitchen.map((i) => Number(i.id)),
      brandId: Number(brandId),
      scheduleType: values.scheduleVariant as Schedule_Enum,
      priceLevel: Number(values.rating?.length),
      averageCost: Number(values.averageCheck),
      availableSeatsNumber: Number(values.numberOfSeats),
      image: typeof values.logo === 'string' ? null : values.logo,
      secondImage: typeof values.mainLogo === 'string' ? null : values.mainLogo,
      longitude: values.geometry.lng,
      latitude: values.geometry.lat,
      workDays: transformCreateWordDays(
        values.scheduleVariant as Schedule_Enum,
        values.schedules,
      ) as Array<WorkDay>,
    };

    if (!id) {
      createRestaurant({
        variables: {
          createRestaurantInput: {
            ...formValues,
          },
        },
      });
    } else {
      updateRestaurant({
        variables: {
          updateRestaurantInput: {
            ...formValues,
            id: Number(id),
            workDays: formValues.workDays as Array<UpdateWorkDayInput>,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (id && restaurantData && citiesData && cuisinesData) {
      const {
        name,
        description,
        city,
        address,
        scheduleType,
        workDays,
        averageCost,
        availableSeatsNumber,
        priceLevel,
        cuisines,
        image,
        secondImage,
        latitude,
        longitude,
      } = restaurantData.restaurant;

      queueMicrotask(() => {
        setInitialValues({
          name: name,
          description: description || '',
          city: String(city?.id),
          address: address as string,
          scheduleVariant:
            scheduleType || (SCHEDULE_TYPES.SINGLE as Schedule_Enum),
          schedules: workDays?.length
            ? transformToFormikFormat(workDays)
            : INIT_SCHEDULES_VARIOUS,
          averageCheck: String(averageCost || ''),
          numberOfSeats: String(availableSeatsNumber || ''),
          rating: '$'.repeat(priceLevel || 3),
          kitchen: cuisines.map((item) => ({
            label: item.title,
            id: String(item.id),
          })), //+
          logo: image?.url as string,
          mainLogo: secondImage?.url,
          geometry: {
            lat: latitude,
            lng: longitude,
          },
        });
      });
    }
  }, [id, restaurantData, citiesData, cuisinesData]);

  const scheduleVariantOptions = [
    {
      id: SCHEDULE_TYPES.SINGLE,
      label: t('web_add_res_schedule_variant_single'),
    },
    {
      id: SCHEDULE_TYPES.VARIOUS,
      label: t('web_add_res_schedule_variant_various'),
    },
  ];

  return (
    <MainLayout>
      <BackButton />
      <PageTitleBlock
        title={!id ? t('web_add_res_page_title') : t('web_edit_res_page_title')}
        sx={{ marginTop: '10px' }}
      />
      <RequestHandler
        loading={loading || cuisinesLoading || restaurantLoading}
        error={error || cuisinessError || restaurantError}
        height='50vh'
      >
        <Formik<IFormikProps>
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={addRestaurant}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, dirty, submitCount }) => {
            const lat = values.geometry.lat;

            const isErrorToAddress = submitCount && dirty && !lat;

            const initRefAddressCount = useRef<number>(0);
            useEffect(() => {
              if (initRefAddressCount.current > (!id ? 0 : 1)) {
                setFieldValue(
                  'address',
                  cityOptions.find((el) => el.id === values.city)?.label,
                );
                setFieldValue('geometry', { lat: 0, lng: 0 });
              }
              initRefAddressCount.current++;
            }, [values.city]);

            return (
              <Form>
                <FormWrapper>
                  <TextFieldFormik
                    name={'name'}
                    size={'large'}
                    label={t('web_add_res_title_label')}
                    placeholder={t('web_add_res_title_placeholder')}
                    mt='22px'
                  />
                  <TextFieldFormik
                    name={'description'}
                    size={'large'}
                    label={t('web_add_res_desc_label')}
                    placeholder={t('web_add_res_desc_placeholder')}
                    multiline={true}
                    minRows={4}
                    mt='22px'
                  />
                  <AdressWrapperCSS>
                    <Box sx={{ width: '247px', marginRight: '16px' }}>
                      <SelectFormik
                        name={'city'}
                        options={cityOptions}
                        label={t('web_add_res_city_label')}
                        placeholder={t('web_add_res_city_placeholder')}
                      />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <GoogleAutocompleteFormik
                        name={'address'}
                        geometryName={'geometry'}
                        size={'large'}
                        label={t('web_add_res_address_label')}
                        placeholder={t('web_add_res_address_placeholder')}
                        minRows={4}
                        errorMessage={
                          isErrorToAddress
                            ? MESSAGES.REQUIRE_GOOGLE_AUTOCOMPLETE_MESSAGE
                            : undefined
                        }
                      />
                    </Box>
                  </AdressWrapperCSS>
                  <SchedulesSingleWrapperCSS>
                    <Box sx={{ width: '190px', marginRight: '16px' }}>
                      <SelectFormik
                        name={'scheduleVariant'}
                        options={scheduleVariantOptions}
                        label={t('web_add_res_schedule_variant_label')}
                      />
                    </Box>
                    {values.scheduleVariant === SCHEDULE_TYPES.SINGLE ? (
                      <>
                        <TimePicker
                          options={TIME_OPTIONS}
                          name={`schedules.0`}
                        />
                      </>
                    ) : null}
                  </SchedulesSingleWrapperCSS>
                  {values.scheduleVariant === SCHEDULE_TYPES.VARIOUS ? (
                    <>
                      {values.schedules.map((schedule, index) => {
                        return (
                          <SchedulesElementCSS key={index}>
                            <SwitcherFormik
                              name={`schedules.${index}.isActive`}
                              sx={{ width: '204px' }}
                              label={t(values.schedules[index].label as string)}
                            />
                            {values.schedules[index].isActive ? (
                              <TimePicker
                                options={TIME_OPTIONS}
                                name={`schedules.${index}`}
                              />
                            ) : null}
                          </SchedulesElementCSS>
                        );
                      })}
                    </>
                  ) : null}
                  <CoastWrapperCSS>
                    <Box sx={{ width: '190px', marginRight: '16px' }}>
                      <TextFieldFormik
                        name={'averageCheck'}
                        size={'large'}
                        label={t('web_add_res_average_check_label')}
                        placeholder={t('web_add_res_average_check_placeholder')}
                        type='number'
                        Icon={DollarIcon}
                      />
                    </Box>
                    <Box sx={{ width: '330px', marginRight: '20px' }}>
                      <TextFieldFormik
                        name={'numberOfSeats'}
                        size={'large'}
                        label={t('web_add_res_seats_label')}
                        placeholder={t('web_add_res_seats_placeholder')}
                        type='number'
                      />
                    </Box>
                    <RatingFormik
                      name={'rating'}
                      label={t('web_add_res_price_rating_label')}
                    />
                  </CoastWrapperCSS>

                  <AutocompleteFormik
                    name={'kitchen'}
                    options={kitchenOptions}
                    sx={{ marginTop: '24px' }}
                    label={t('web_add_res_kitchen_label')}
                    placeholder={t('web_add_res_kitchen_placeholder')}
                  />
                  <Stack gap={'50px'} direction={'row'} mt={'24px'}>
                    <DropzoneFormik
                      label={t('web_add_res_logo_label')}
                      subLabel={t('web_add_res_logo_sub_label')}
                      name={'logo'}
                    />
                    <DropzoneFormik
                      label={t('web_add_res_logo_main_photo_res')}
                      subLabel={t('web_add_res_logo_sub_label')}
                      name={'mainLogo'}
                    />
                  </Stack>
                  <Button
                    type={'submit'}
                    mt='28px'
                    width='250px'
                    height='50px'
                    disabled={!brandId}
                  >
                    {!id
                      ? t('web_add_res_submit_btn')
                      : t('web_edit_res_submit_btn')}
                  </Button>
                </FormWrapper>
              </Form>
            );
          }}
        </Formik>
      </RequestHandler>
    </MainLayout>
  );
};
