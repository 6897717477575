import { Box, styled } from '@mui/material';

export const ContentCSS = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  marginBottom: '24px',
});

export const ContentItemCSS = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '3px',
});

export const PhotoBlockCSS = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  img: {
    width: '130px',
    height: '125px',
  },
  marginBottom: '36px',
});

export const ImageWrapperCSS = styled(Box)({
  display: 'flex',
  filter: 'drop-shadow(0px 2px 16px rgba(33, 52, 103, 0.1))',
  img: {
    width: '130px',
    height: '125px',
    borderRadius: '8px',
    objectFit: 'cover',
  },
});
