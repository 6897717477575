import * as yup from 'yup';

import { MESSAGES } from '../messages';

export const registerSchema = yup.object().shape({
  name: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  email: yup
    .string()
    .email(MESSAGES.INCORRECT_EMAIL)
    .required(MESSAGES.REQUIRE_MESSAGE),
  phone: yup
    .string()
    .required(MESSAGES.REQUIRE_MESSAGE)
    .min(10, MESSAGES.MIN_LENGTH('10'))
    .matches(/^[\d ()+-]+$/, MESSAGES.INCORRECT_FORMAT),

  restaurantName: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  country: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  city: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
});
