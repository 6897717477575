import * as yup from 'yup';

import { MESSAGES } from '../messages';
import { SCHEDULE_TYPES } from '../../constants/constants';

export const addAdminOffersSchema = yup.object().shape({
  name: yup
    .string()
    .max(255, MESSAGES.MAX_LENGTH('255'))
    .required(MESSAGES.REQUIRE_MESSAGE),
  description: yup
    .string()
    .max(255, MESSAGES.MAX_LENGTH('255'))
    .required(MESSAGES.REQUIRE_MESSAGE),
  kitchen: yup
    .array()
    .min(1, MESSAGES.REQUIRE_MESSAGE)
    .required(MESSAGES.REQUIRE_MESSAGE),
  themeEvents: yup
    .array()
    .min(1, MESSAGES.REQUIRE_MESSAGE)
    .required(MESSAGES.REQUIRE_MESSAGE),
  scheduleVariant: yup
    .string()
    .oneOf([SCHEDULE_TYPES.SINGLE, SCHEDULE_TYPES.VARIOUS])
    .required(MESSAGES.REQUIRE_MESSAGE),
  schedules: yup.array().min(1, MESSAGES.REQUIRE_MESSAGE),
  pricePerPerson: yup.number().required(MESSAGES.REQUIRE_MESSAGE),
  personCount: yup
    .number()
    .max(10000, MESSAGES.MAX_NUMBER_VALUE('10000'))
    .required(MESSAGES.REQUIRE_MESSAGE),
  restaurants: yup
    .array()
    .min(1, MESSAGES.REQUIRE_MESSAGE)
    .required(MESSAGES.REQUIRE_MESSAGE),
  logo: yup.mixed().required(MESSAGES.REQUIRE_MESSAGE),
});
