import React from 'react';
import { ImageTextRightBlockCSS, ImageTextRowCSS } from './styles';
import { Box, Typography } from '@mui/material';
import { palette } from '../../../theme/foundations/index';
import { DropzoneFormik } from '../../UI/DropzoneFormik/DropzoneFormik';
import { IImageFormProps } from './types';
import { useTranslatesContext } from '../../../context/TranslatesContext';

export const ImageForm = ({ nameEnImage, labelEnText }: IImageFormProps) => {
  const { t } = useTranslatesContext();
  return (
    <>
      <ImageTextRowCSS>
        <Typography variant={'body1'}>
          {t('web_translations_modal_img_title')}
        </Typography>
        <ImageTextRightBlockCSS>
          <Box sx={{ width: '136px' }}>
            <Typography variant={'sm100'} color={palette.gray600}>
              {t('web_translations_modal_img_sub_title')}
            </Typography>
          </Box>
          <Box>
            <Typography variant={'sm500'} color={palette.dark500}>
              {t('web_translations_modal_img_notice')}
            </Typography>
          </Box>
        </ImageTextRightBlockCSS>
      </ImageTextRowCSS>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <DropzoneFormik
          name={nameEnImage}
          isMedium
          label={labelEnText}
          labelMb={'8px'}
        />
      </Box>
    </>
  );
};
