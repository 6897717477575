import { Wrapper } from './styles';
import { Box, IconButton, Typography } from '@mui/material';
import { ChevronLeftMob } from '../../components/Icons/index';
import { useNavigate } from 'react-router-dom';
import { useTranslatesContext } from '../../context/TranslatesContext';

export const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const { t } = useTranslatesContext();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Wrapper>
      <Box display={'flex'} height={'42px'}>
        <IconButton
          onClick={goBack}
          sx={{ width: '38px', marginLeft: '-17px' }}
        >
          <ChevronLeftMob />
        </IconButton>
      </Box>
      <Typography
        mb={'16px'}
        variant={'md450'}
        fontWeight={400}
        dangerouslySetInnerHTML={{
          __html: t('mobile_privacy_policy_description_index'),
        }}
      ></Typography>
    </Wrapper>
  );
};
