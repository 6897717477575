import { Typography } from '@mui/material';
import { palette } from '../../theme/foundations';

type Props = {
  text: string;
};

export const TextIcon = ({ text }: Props) => {
  return (
    <Typography variant='body1' color={palette.gray500}>
      {text}
    </Typography>
  );
};
