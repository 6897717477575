import React from 'react';
import { ImagePlaceholderDropzone } from '../Icons/index';
import { Typography } from '@mui/material';
import {
  TitleBlockCSS,
  ContentCSS,
  Wrapper,
  TextBlockCSS,
  ImageBlockCSS,
  UlCSS,
} from './styles';
import { palette } from '../../theme/foundations/index';
import { CustomActionMenu } from '../CustomActionMenu/CustomActionMenu';
import { useNavigate } from 'react-router-dom';
import {
  Manager,
  Roles,
  useRemoveManagerMutation,
  useSignInAsManagerMutation,
} from '../../graphql/generated/graphql';
import {
  useAlertContext,
  useAuthContext,
  useModalContext,
} from '../../context/index';
import { useAccesses } from '../../hooks/index';
import { LS, MODAL_NAME } from '../../constants/constants';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { getUserRoleKey } from '../../utils';

interface IUserListItemProps {
  id: string | number;
  name: string;
  job: string;
  imgUrl: string | any;
  usersAllowedActions: Roles[];
}

export const UserListItem = ({
  id,
  name,
  job,
  usersAllowedActions,
  imgUrl,
}: IUserListItemProps) => {
  const navigate = useNavigate();
  const { onOpenAlert } = useAlertContext();
  const { isManagerEditAccess, isManagerRemoveAccess } = useAccesses();
  const { user, signIn: onAuth } = useAuthContext();
  const { onOpenModal, onCloseModal } = useModalContext();
  const { isSuperAdmin } = useAccesses();
  const { t } = useTranslatesContext();

  const [removeManager] = useRemoveManagerMutation({
    update(cache, { data }) {
      onOpenAlert({ title: t('web_alert_delete_user') });

      if (data?.removeManager.id) {
        cache?.evict({
          id: `Manager:${id}`,
        });
        cache?.gc();
      }
    },
  });

  const handleEdit = () => {
    navigate(`/users/edit/${id}`);
  };

  const handleRemove = () => {
    removeManager({
      variables: {
        removeManagerId: Number(id),
      },
    });
  };

  const actionOptions = [
    {
      label: 'web_action_edit',
      action: handleEdit,
      color: '',
      disabled: !isManagerEditAccess || user?.id === id,
    },
    {
      label: 'web_action_delete',
      action: handleRemove,
      color: palette.error.main,
      disabled: !isManagerRemoveAccess || user?.id === id,
    },
  ];

  const [signInAsManager] = useSignInAsManagerMutation({
    onCompleted: (data) => {
      const isLS = !!localStorage.getItem(LS.TOKEN_BYDE_ADMIN);
      if (isLS) {
        localStorage.setItem(
          LS.TOKEN_BYDE_ADMIN,
          data.signInAsManager.accessToken,
        );
      } else {
        sessionStorage.setItem(
          LS.TOKEN_BYDE_ADMIN,
          data.signInAsManager.accessToken,
        );
      }

      onAuth(data.signInAsManager.manager as Manager);
      onCloseModal();
      onOpenAlert({
        title: `${t('web_alert_you_are_logged_in_as')} ${
          data.signInAsManager.manager.email
        }`,
      });
    },
  });

  const handleRedirectToThisUser = () => {
    signInAsManager({
      variables: {
        signInAsManagerId: Number(id),
      },
    });
  };

  const signIsAsManagerFlow = () => {
    onOpenModal(MODAL_NAME.ARE_YOU_SURE, {
      title: t('web_modal_are_you_sure_with_this_user'),
      rightBtnTitle: t('web_action_continue'),
      leftBtnTitle: t('web_action_cancel'),
      leftCB: onCloseModal,
      rightCB: handleRedirectToThisUser,
    });
  };

  return (
    <Wrapper>
      <ImageBlockCSS>
        {imgUrl ? <img src={imgUrl} alt={'i'} /> : <ImagePlaceholderDropzone />}
      </ImageBlockCSS>
      <ContentCSS>
        <TitleBlockCSS>
          <TextBlockCSS {...(isSuperAdmin && { onClick: signIsAsManagerFlow })}>
            <Typography variant={'md600'}>{name}</Typography>
            <Typography variant={'sm500'}>{job}</Typography>
          </TextBlockCSS>
          <CustomActionMenu options={actionOptions} />
        </TitleBlockCSS>
        <UlCSS>
          {usersAllowedActions?.map((userAction, index) => {
            return <li key={index}>{t(getUserRoleKey(userAction))}</li>;
          })}
        </UlCSS>
      </ContentCSS>
    </Wrapper>
  );
};
