import { MainLayout } from '../../components';
import img from '../../components/Icons/404.png';
import { Wrapper } from './styles';

export const NotFound = () => {
  return (
    <MainLayout>
      <Wrapper>
        <img src={img} alt='' width={'800px'} />
      </Wrapper>
    </MainLayout>
  );
};
