import React from 'react';
import { IconButton, SxProps, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import {
  DropBoxDropzoneOptionsCSS,
  DropzoneOptionsWrapper,
  DropzoneWrapperCSS,
  ImageBlockDropzoneOptionsCSS,
  ImagePlaceholderDropzoneOptionsCSS,
  LabelCSS,
  RemoveDropzoneOptionsCSS,
  SubLabelCSS,
} from './styles';
import { DEFAULT_DROPZONE_OPTIONS } from '../../../constants/constants';
import { CloseDropIcon, Placeholder } from '../../Icons';
import { useField, useFormikContext } from 'formik';

interface IDropzoneOptionsFormikProps {
  name: string;
  label?: string;
  subLabel?: string;
  sx?: SxProps;
  mb?: string;
  labelMb?: string;
}

export const DropzoneOptionsFormik = ({
  label,
  subLabel,
  name,
  sx,
  mb,
  labelMb = '12px',
}: IDropzoneOptionsFormikProps) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  const hasError = Boolean(meta.error && meta.touched);

  const onDrop = (acceptedFiles: File[]) => {
    setFieldValue(
      name,
      Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0]),
      }),
    );
  };

  const handleRemove = () => {
    setFieldValue(name, null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    ...DEFAULT_DROPZONE_OPTIONS,
    onDrop,
  });

  return (
    <DropzoneOptionsWrapper sx={sx} mb={mb}>
      {label && (
        <LabelCSS mb={labelMb}>
          {label}
          {hasError ? (
            <Typography ml={'auto'} variant='error'>
              {meta.error}
            </Typography>
          ) : null}
        </LabelCSS>
      )}

      <DropzoneWrapperCSS>
        <input {...getInputProps()} />
        {field.value ? (
          <ImageBlockDropzoneOptionsCSS>
            <img src={field.value.preview || field.value} alt='i' />
            <RemoveDropzoneOptionsCSS>
              <IconButton onClick={handleRemove}>
                <CloseDropIcon size={'14px'} />
              </IconButton>
            </RemoveDropzoneOptionsCSS>
          </ImageBlockDropzoneOptionsCSS>
        ) : (
          <DropBoxDropzoneOptionsCSS
            {...getRootProps()}
            haserror={String(hasError)}
          >
            <ImagePlaceholderDropzoneOptionsCSS>
              <Placeholder />
            </ImagePlaceholderDropzoneOptionsCSS>
          </DropBoxDropzoneOptionsCSS>
        )}
      </DropzoneWrapperCSS>
      {subLabel && <SubLabelCSS>{subLabel}</SubLabelCSS>}
    </DropzoneOptionsWrapper>
  );
};
