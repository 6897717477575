import * as yup from 'yup';

import { MESSAGES } from '../messages';
import { SCHEDULE_TYPES } from '../../constants/constants';

export const addRestaurant = yup.object().shape({
  name: yup
    .string()
    .max(100, MESSAGES.MAX_LENGTH('100'))
    .required(MESSAGES.REQUIRE_MESSAGE),
  description: yup.string().max(255, MESSAGES.MAX_LENGTH('255')),
  city: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  address: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  scheduleVariant: yup
    .string()
    .oneOf([SCHEDULE_TYPES.SINGLE, SCHEDULE_TYPES.VARIOUS])
    .required(MESSAGES.REQUIRE_MESSAGE),
  schedules: yup.array().of(
    yup.object().shape({
      from: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
      to: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
      isActive: yup.boolean().required(MESSAGES.REQUIRE_MESSAGE),
      label: yup.string(),
    }),
  ),
  averageCheck: yup.number().positive(MESSAGES.INVALID_VALUE),
  numberOfSeats: yup
    .number()
    .integer(MESSAGES.INVALID_VALUE)
    .positive(MESSAGES.INVALID_VALUE)
    .required(MESSAGES.REQUIRE_MESSAGE),
  rating: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  kitchen: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required(),
        label: yup.string().required(),
      }),
    )
    .min(1, MESSAGES.REQUIRE_MESSAGE)
    .required(MESSAGES.REQUIRE_MESSAGE),
  logo: yup.mixed().required(MESSAGES.REQUIRE_MESSAGE),
  mainLogo: yup.mixed().required(MESSAGES.REQUIRE_MESSAGE),
});
