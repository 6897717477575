import { Box, styled } from '@mui/material';

export const Wrapper = styled(Box)(() => ({
  width: '420px',
}));

export const ButtonGroupCSS = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  button: {
    borderRadius: '12px !important',
  },
}));
