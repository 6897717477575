import { Box, Button, Typography } from '@mui/material';
import { ButtonGroupCSS, Wrapper } from './styles';
import { Form, Formik } from 'formik';
import { TextFieldFormik } from '../../UI/TextFieldFormik/TextFieldFormik';
import { rejectedModalSchema } from '../../../validation/index';
import { IFormikProps } from './types';
import {
  Event_Offer_Status_Enum,
  useUpdateEventOfferMutation,
} from '../../../graphql/generated/graphql';
import { useAlertContext, useModalContext } from '../../../context/index';
import { BRAND_EVENT_OFFERS } from '../../../graphql/queries/brandEventOffers.gql';
import { useTranslatesContext } from '../../../context/TranslatesContext';

export const RejectedModal = () => {
  const { infoModal, onCloseModal } = useModalContext();
  const { onOpenAlert } = useAlertContext();

  const { t } = useTranslatesContext();

  const [updateEventOffer] = useUpdateEventOfferMutation({
    refetchQueries: [BRAND_EVENT_OFFERS],
    onCompleted: () => {
      onOpenAlert({ title: t('web_rejected_modal_alert_title') });
      onCloseModal();
    },
  });
  const id = infoModal.content.id;

  const handleSubmit = (values: IFormikProps) => {
    let comment = '';
    if (values.variant === 'noPlaces') {
      comment = t('web_rejected_modal_no_seats');
    } else if (values.variant === 'closed') {
      comment = t('web_rejected_modal_spec_service_off');
    } else if (values.variant === 'any') {
      comment = values.field;
    }

    updateEventOffer({
      variables: {
        updates: {
          id: id,
          status: Event_Offer_Status_Enum.DeclinedByRestaurant,
          comment: comment,
        },
      },
    });
  };

  return (
    <Wrapper>
      <Box mb={'26px'}>
        <Typography variant={'big700'}>
          {t('web_rejected_modal_order_rejection')}
        </Typography>
      </Box>
      <Formik<IFormikProps>
        initialValues={{
          variant: '',
          field: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={rejectedModalSchema}
      >
        {({ setFieldValue, values, dirty }) => {
          return (
            <Form>
              <ButtonGroupCSS>
                <Button
                  variant={values.variant === 'noPlaces' ? 'text' : 'outlined'}
                  size={'large'}
                  onClick={() => setFieldValue('variant', 'noPlaces')}
                >
                  {t('web_rejected_modal_no_seats')}
                </Button>
                <Button
                  variant={values.variant === 'closed' ? 'text' : 'outlined'}
                  size={'large'}
                  onClick={() => setFieldValue('variant', 'closed')}
                >
                  {t('web_rejected_modal_spec_service_off')}
                </Button>
                <Button
                  variant={values.variant === 'any' ? 'text' : 'outlined'}
                  size={'large'}
                  onClick={() => setFieldValue('variant', 'any')}
                >
                  {t('web_rejected_modal_another_variant')}
                </Button>
                {values.variant === 'any' ? (
                  <TextFieldFormik
                    name={'field'}
                    multiline
                    minRows={7}
                    /* eslint-disable-next-line jsx-a11y/no-autofocus */
                    autoFocus
                  />
                ) : null}
                <Button type={'submit'} disabled={!dirty}>
                  {t('web_rejected_modal_action_confirm')}
                </Button>
              </ButtonGroupCSS>
            </Form>
          );
        }}
      </Formik>
    </Wrapper>
  );
};
