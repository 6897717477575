import { Box, Button, Typography } from '@mui/material';
import { ButtonsWrapperCSS, Wrapper } from './styles';
import { Form, Formik } from 'formik';

import { useTranslatesContext } from '../../../context/TranslatesContext';
import { IEditDishCategory, IFormikProps } from './types';
import { useAlertContext, useModalContext } from '../../../context';

import { CategoriesList } from './CategoriesList';
import { editCategoriesSchema } from '../../../validation';
import {
  useBulkUpdateDishCategoryMutation,
  useDishCategoriesQuery,
} from '../../../graphql/generated/graphql';
import { DISH_CATEGORIES } from '../../../graphql/queries/dishCategories.gql';

export const CategoriesModal = () => {
  const { t } = useTranslatesContext();
  const { onCloseModal } = useModalContext();
  const { onOpenAlert } = useAlertContext();

  const { infoModal } = useModalContext();
  const brandId = infoModal?.content?.brandId;

  const { data: dishCategories } = useDishCategoriesQuery({
    fetchPolicy: 'network-only',
    variables: {
      brandId: Number(brandId),
    },
    skip: !brandId,
  });
  const [updateCategories] = useBulkUpdateDishCategoryMutation({
    onCompleted: () => {
      onOpenAlert({ title: t('web_alert_update_dish_categories') });
      onCloseModal();
    },
    refetchQueries: [DISH_CATEGORIES],
  });

  const categories = !dishCategories
    ? []
    : dishCategories.dishCategories?.map((item) => ({
        title: item.title,
        id: item.id,
        count: item.countDishes,
        isEdit: false,
      }));

  const handleSubmit = (values: IFormikProps) => {
    const deleteItems: Array<IEditDishCategory> = [];

    categories.forEach((item) => {
      if (
        !values.categories
          .map((i) => i.id)
          .filter((i) => i)
          .includes(item.id as number)
      ) {
        deleteItems.push({
          title: item.title,
          id: item.id,
          isDelete: true,
          brandId: Number(brandId),
        });
      }
    });

    const updateItems = values.categories.map((item) => {
      const idParams = item.id ? { id: item.id } : {};

      return { title: item.title, brandId: Number(brandId), ...idParams };
    });

    updateCategories({
      variables: {
        dishCategoriesData: {
          list: [...updateItems, ...deleteItems],
        },
      },
    });
  };

  const initialValues = {
    categories: categories,
  };

  return (
    <Wrapper>
      <Box>
        <Typography variant={'h2'}>
          {t('web_categories_modal_title')}
        </Typography>
      </Box>
      <Formik<any>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validationSchema={editCategoriesSchema}
      >
        {() => {
          return (
            <Form>
              <CategoriesList name={'categories'} />

              <ButtonsWrapperCSS>
                <Button
                  disabled={!brandId}
                  type={'submit'}
                  size={'large'}
                  sx={{ marginRight: '12px', width: 'fit-content' }}
                >
                  {t('web_categories_modal_submit_btn')}
                </Button>
                <Button
                  variant='outlined'
                  onClick={onCloseModal}
                  sx={{ width: 'fit-content', height: '50px' }}
                >
                  {t('web_categories_modal_close_btn')}
                </Button>
              </ButtonsWrapperCSS>
            </Form>
          );
        }}
      </Formik>
    </Wrapper>
  );
};
