import { Dish } from '../../graphql/generated/graphql';
import { IFormatedDish } from '../../types/baseTypes';

export const getDishesTransformData = (data: Array<Dish>) => {
  let indexValue = 0;
  const result: Array<IFormatedDish> = [];

  if (!data || data?.length === 0) return result;

  data.forEach((dish) => {
    // create new category array
    if (result.length === 0) {
      result.push({
        label: dish?.dishCategory?.title,
        data: [],
      });
    }

    // create new item for category array
    if (result.map((item) => item.label).includes(dish?.dishCategory?.title)) {
      result[indexValue].data.push(dish);
    } else {
      indexValue++;
      result.push({
        label: dish?.dishCategory.title,
        data: [dish],
      });
    }
  });
  return result;
};
