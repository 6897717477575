import { gql } from '@apollo/client';

export const ADVICE = gql`
  query Advices {
    advices {
      id
      text
    }
  }
`;
