import { Box, styled } from '@mui/material';

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
}));
export const ImgCSS = styled('img')(() => ({
  width: 286,
  height: 286,
  borderRadius: '50%',
}));
