import {
  ApplicationItemInfo,
  BackButton,
  KitchenLabel,
  MainLayout,
  PageTitleBlock,
  RequestHandler,
} from '../../components/index';
import {
  Autocomplete,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Content, ContentRowCSS } from './styles';
import { useTranslatesContext } from '../../context/TranslatesContext';

import { useNavigate, useParams } from 'react-router-dom';
import {
  useBrandsQuery,
  useRestaurantDetailWithoutBrandPageQuery,
  useUpdateRestaurantMutation,
} from '../../graphql/generated/graphql';

import { useState } from 'react';
import dayjs from 'dayjs';
import { useAlertContext } from '../../context';

interface BrandOption {
  id: string;
  label: string;
}

export const RestaurantWithoutBrandPage = () => {
  const { id } = useParams();
  const { t } = useTranslatesContext();
  const navigate = useNavigate();
  const { onOpenAlert } = useAlertContext();

  const [activeBrand, setActiveBrand] = useState<BrandOption | null>(null);
  const [datesParams] = useState({
    dateTo: new Date().toISOString(),
    dateFrom: dayjs().subtract(5, 'year').toISOString(),
  });

  const [updateRestaurant] = useUpdateRestaurantMutation({
    onCompleted: () => {
      onOpenAlert({ title: t('web_alert_update_restaurant') });
      navigate(-1);
    },
  });

  const {
    data: brandsData,
    loading: brandsLoading,
    error: brandsError,
  } = useBrandsQuery({
    fetchPolicy: 'network-only',
    variables: {
      options: {
        limit: 1000,
        offset: 0,
      },
    },
  });

  const {
    data: restaurantData,
    loading,
    error,
  } = useRestaurantDetailWithoutBrandPageQuery({
    variables: {
      id: Number(id),
      ...datesParams,
    },
    skip: !id,
    fetchPolicy: 'network-only',
  });

  const brands = brandsData?.brands?.rows;

  const brandsOptions =
    brands?.map((item) => ({
      label: item.title,
      id: String(item.id),
    })) || [];

  const defaultProps = {
    options: brandsOptions,
    getOptionLabel: (option: { label: string }) => option.label,
  };

  const restaurant = restaurantData?.restaurant;
  const restaurantCuisines = restaurant?.cuisines?.map((item) => {
    return {
      id: item.id,
      name: item.title,
    };
  });

  return (
    <MainLayout>
      <Box mb={'15px'}>
        <BackButton />
      </Box>
      <PageTitleBlock title={t('web_restaraunt_withount_brand_title')} />

      <RequestHandler
        loading={loading || brandsLoading}
        error={error || brandsError}
        height='50vh'
      >
        <Content>
          <ApplicationItemInfo
            title={t('web_restaraunt_withount_brand_name')}
            subTitle={restaurant?.name}
          />

          <ContentRowCSS>
            <ApplicationItemInfo
              title={t('web_restaraunt_withount_brand_adress')}
              subTitle={`${restaurant?.city?.title || ''} ${
                restaurant?.address
              }`}
            />
          </ContentRowCSS>
          <ApplicationItemInfo
            title={t('web_restaraunt_withount_brand_kitchen')}
          >
            <Stack direction={'row'} mt={'2px'} gap={'8px'} flexWrap={'wrap'}>
              {restaurantCuisines?.map((item) => {
                return <KitchenLabel title={item.name} />;
              })}
            </Stack>
          </ApplicationItemInfo>
          <ContentRowCSS>
            <ApplicationItemInfo
              title={t('web_restaraunt_withount_brand_views')}
              subTitle={String(restaurant?.viewCount)}
            />
            <ApplicationItemInfo
              title={t('web_restaraunt_withount_brand_clicks')}
              subTitle={String(restaurant?.clickCount)}
            />
            <ApplicationItemInfo
              title={t('web_restaraunt_withount_brand_requests')}
              subTitle={String(restaurant?.requestCount)}
            />
          </ContentRowCSS>

          <Box>
            <Typography variant={'sm350'}>
              {t('web_restaraunt_withount_brand_label_brand')}
            </Typography>

            <Autocomplete
              {...defaultProps}
              sx={{ width: '350px', mt: '8px' }}
              value={activeBrand}
              onChange={(
                event: React.SyntheticEvent<Element, Event>,
                newValue: BrandOption | null,
              ) => {
                setActiveBrand(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t('web_restaraunt_withount_brand_select')}
                />
              )}
            />
          </Box>
          <Button
            size={'large'}
            sx={{ width: 'fit-content' }}
            onClick={() => {
              updateRestaurant({
                variables: {
                  updateRestaurantInput: {
                    brandId: Number(activeBrand?.id),
                    id: Number(id),
                  },
                },
              });
            }}
            disabled={!activeBrand}
          >
            {t('web_restaraunt_withount_brand_submit_btn_title')}
          </Button>
        </Content>
      </RequestHandler>
    </MainLayout>
  );
};
