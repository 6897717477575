import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import {
  DropBoxCSS,
  DropzoneWrapperCSS,
  ImageBlockCSS,
  ImagePlaceholderCSS,
  LabelCSS,
  RemoveBoxCSS,
  SubLabelCSS,
  Wrapper,
} from './styles';
import { DEFAULT_DROPZONE_OPTIONS } from '../../../constants/constants';
import { CloseDropIcon, ImagePlaceholderDropzone } from '../../Icons';
import { useField, useFormikContext } from 'formik';
import { IDropzoneBlockProps } from './types';
import { palette } from '../../../theme/foundations/index';
import { useTranslatesContext } from '../../../context/TranslatesContext';
import { useDropzoneValidator } from '../../../hooks/index';

export const DropzoneFormik = ({
  label,
  subLabel,
  name,
  sx,
  mb,
  isMedium,
  labelMb = '12px',
  isSmallLarge,
  isVertical,
  isHelperText,
}: IDropzoneBlockProps) => {
  const { t } = useTranslatesContext();
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  const hasError = Boolean(meta.error && meta.touched);
  const { nameLengthValidator } = useDropzoneValidator();

  const onDrop = (acceptedFiles: File[]) => {
    setFieldValue(
      name,
      Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0]),
      }),
    );
  };

  const handleRemove = () => {
    setFieldValue(name, null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    ...DEFAULT_DROPZONE_OPTIONS,
    onDrop,
    validator: nameLengthValidator,
  });

  return (
    <Wrapper sx={sx} mb={mb}>
      {label && (
        <LabelCSS mb={labelMb}>
          {label}
          {hasError ? (
            <Typography ml={'auto'} variant='error'>
              {meta.error}
            </Typography>
          ) : null}
        </LabelCSS>
      )}

      <DropzoneWrapperCSS>
        <input {...getInputProps()} />
        {field.value ? (
          <ImageBlockCSS
            ismedium={isMedium ? 'true' : 'false'}
            issmalllarge={isSmallLarge ? 'true' : 'false'}
            isvertical={isVertical ? 'true' : 'false'}
          >
            <img src={field.value.preview || field.value} alt='i' />
            <RemoveBoxCSS>
              <IconButton onClick={handleRemove}>
                <CloseDropIcon />
              </IconButton>
            </RemoveBoxCSS>
            {!(isMedium || isSmallLarge) && (
              <Typography variant={'sm500'} color={palette.white}>
                {t('web_dropzone_info_no_empty')}
              </Typography>
            )}
          </ImageBlockCSS>
        ) : (
          <DropBoxCSS
            {...getRootProps()}
            haserror={String(hasError)}
            ismedium={isMedium ? 'true' : 'false'}
            issmalllarge={isSmallLarge ? 'true' : 'false'}
            isvertical={isVertical ? 'true' : 'false'}
          >
            <ImagePlaceholderCSS
              ismedium={isMedium ? 'true' : 'false'}
              issmalllarge={isSmallLarge ? 'true' : 'false'}
              isvertical={isVertical ? 'true' : 'false'}
            >
              <ImagePlaceholderDropzone />
            </ImagePlaceholderCSS>
            {!(isMedium || isSmallLarge) && (
              <Typography variant={'sm500'}>
                {t('web_dropzone_info_empty')}
              </Typography>
            )}
            {isHelperText && (
              <Typography
                variant={'sm50'}
                padding={'10px 20px 0px'}
                color={palette.gray550}
              >
                {t('web_dropzone_info_helper')}
              </Typography>
            )}
          </DropBoxCSS>
        )}
      </DropzoneWrapperCSS>
      {subLabel && <SubLabelCSS>{subLabel}</SubLabelCSS>}
    </Wrapper>
  );
};
