import * as yup from 'yup';
import { MESSAGES } from '../messages';

export const rejectedModalSchema = yup.object().shape({
  variant: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  field: yup.string().when('variant', {
    is: (values: string) => {
      return values === 'any';
    },
    then: yup.string().max(255, MESSAGES.MAX_LENGTH('255')),
    otherwise: yup.string().nullable(),
  }),
});
