import { styled } from '@mui/material/styles';
import { Box, FormControlLabel } from '@mui/material';
import { palette } from '../../theme/foundations/index';

export const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '40px',
});

export const HeadBlockCSS = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '10px',
  paddingLeft: '30px',
});

export const FormControlLabelCSS = styled(FormControlLabel)({
  '.MuiTypography-root': {
    color: palette.black,
    fontWeight: 500,
    fontSize: '28px',
    lineHeight: '33px',
    marginLeft: '6px',
  },
});
export const DishWrapperCSS = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
});
