import { Box, styled } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

export const DatePickerCSS = styled(DatePicker)({
  '.MuiInputBase-root': {
    paddingLeft: '14px',
    paddingRight: '14px',
  },
  '.MuiFormLabel-root': {},
});

export const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',
});
