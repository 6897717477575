import { Box, BoxProps, styled } from '@mui/material';
import { palette } from '../../theme/foundations/index';
import { AliasStatusTypes } from '../../types/baseTypes';

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  // alignItems: 'center',
  gap: '10px',
}));

interface IPointCSSProps extends BoxProps {
  status: AliasStatusTypes;
}

export const PointCSS = styled(Box)<IPointCSSProps>(({ status }) => ({
  display: 'flex',
  width: '13px',
  minWidth: '13px',
  height: '13px',
  borderRadius: '50%',
  background: status === 'success' ? palette.green400 : palette.error.main,
  marginTop: '3px',
}));
