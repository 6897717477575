import React from 'react';
import { IconButton } from '@mui/material';
import { MinusIcon, PlusIcon } from '../../Icons/index';
import { TextFieldFormik } from '../TextFieldFormik/TextFieldFormik';
import { LabelCSS, MainCSS, Wrapper } from './styles';
import { useField, useFormikContext } from 'formik';
import { IRatingFormikProps } from './types';

export const RatingFormik = ({ name, label, sx }: IRatingFormikProps) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(name);

  const handleChangeRating = (eventRating: 'minus' | 'plus') => {
    if (eventRating === 'minus') {
      if (field.value.length === 1) return;
      setFieldValue(name, field.value.slice(0, -1));
    } else {
      if (field.value.length === 5) return;
      setFieldValue(name, `${field.value}$`);
    }
  };

  return (
    <Wrapper sx={sx}>
      {label && <LabelCSS>{label}</LabelCSS>}
      <MainCSS>
        <IconButton
          sx={{ height: '31px' }}
          onClick={() => handleChangeRating('minus')}
        >
          <MinusIcon />
        </IconButton>
        <TextFieldFormik
          name={name}
          readOnly={true}
          size={'large'}
          mb={'0'}
          sx={{
            width: '100px',
            '.MuiInputBase-root': { padding: 0 },
            input: { textAlign: 'center' },
          }}
        />
        <IconButton onClick={() => handleChangeRating('plus')}>
          <PlusIcon />
        </IconButton>
      </MainCSS>
    </Wrapper>
  );
};
