import React from 'react';

export const MinusIcon = () => {
  return (
    <svg
      width='16'
      height='3'
      viewBox='0 0 16 3'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.46667 1.50008H14.5333'
        stroke='#11142D'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
