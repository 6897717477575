import { Box, styled } from '@mui/material';
import { palette } from '../../../theme/foundations/index';
import {
  IDropBoxCSSProps,
  IImageBlockCSSProps,
  IImagePlaceholderCSSProps,
} from './types';

export const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: 'fit-content',
});

export const DropzoneWrapperCSS = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  background: '#fff',
});

export const LabelCSS = styled(Box)({
  display: 'inline-flex',
  alignItems: 'center',
});

export const SubLabelCSS = styled(Box)({
  marginTop: '7px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '13px',
});

export const ImageBlockCSS = styled(Box)<IImageBlockCSSProps>(
  ({ ismedium, issmalllarge, isvertical }) => {
    const isMd = ismedium === 'true';
    const isSl = issmalllarge === 'true';
    const isVer = isvertical === 'true';
    return {
      position: 'relative',
      background: `linear-gradient(90deg, ${palette.green300} 0%, ${palette.green300} 101.81%)`,
      width: isMd ? '114px' : isSl ? '' : isVer ? '180px' : '275px',
      height: isMd ? '92px' : isSl ? '' : isVer ? '233px' : '125px',
      borderRadius: '8px',
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
      alignItems: 'center',
      justifyContent: 'center',
      border: `1px dashed ${palette.gray700}`,
      img: {
        width: isMd ? '114px' : isSl ? '164px' : '75px',
        height: isMd ? '92px' : isSl ? '128px' : '72px',
        borderRadius: '8px',
      },
    };
  },
);

// TODO !!!!!!!
export const ImagePlaceholderCSS = styled(Box)<IImagePlaceholderCSSProps>(
  ({ ismedium, issmalllarge }) => {
    const isMd = ismedium === 'true';
    const isSl = issmalllarge === 'true';
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: isMd ? '95px' : isSl ? '128px' : '75px',
      height: isSl ? '96px' : '72px',
      border: `1px solid ${palette.gray700}`,
      borderRadius: '8px',
    };
  },
);

export const RemoveBoxCSS = styled(Box)({
  position: 'absolute',
  right: '5px',
  top: '5px',
});

export const DropBoxCSS = styled(Box)<IDropBoxCSSProps>(
  ({ haserror, ismedium, issmalllarge, isvertical }) => {
    const isMedium = ismedium === 'true';
    const isLs = issmalllarge === 'true';
    const isVer = isvertical === 'true';

    return {
      width: isMedium ? '114px' : isLs ? '166px' : isVer ? '180px' : '275px',
      height: isMedium ? '92px' : isLs ? '128px' : isVer ? '233px' : '125px',
      gap: '5px',
      borderRadius: '8px',
      border:
        haserror === 'true'
          ? `1px solid ${palette.input.error}`
          : `1px dashed ${palette.gray700}`,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      cursor: 'pointer',
    };
  },
);

// ! ======================== DropzoneOptionsWrapper ========================

export const DropzoneOptionsWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: 'fit-content',
});

export const ImageBlockDropzoneOptionsCSS = styled(Box)<IImageBlockCSSProps>({
  position: 'relative',
  width: '55px',
  height: '50px',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
  alignItems: 'center',
  justifyContent: 'center',

  img: {
    width: '55px',
    height: '50px',
    borderRadius: '8px',
  },
});

export const DropBoxDropzoneOptionsCSS = styled(Box)<IDropBoxCSSProps>(
  ({ haserror }) => {
    const hasError = haserror === 'true';
    return {
      width: '55px',
      height: '50px',
      gap: '5px',
      borderRadius: '8px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      cursor: 'pointer',
      overflow: 'hidden',
      ...(hasError && { border: '1px solid red' }),
    };
  },
);

export const ImagePlaceholderDropzoneOptionsCSS = styled(
  Box,
)<IImagePlaceholderCSSProps>(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '55px',
    height: '50px',
    borderRadius: '8px',
  };
});

export const RemoveDropzoneOptionsCSS = styled(Box)({
  position: 'absolute',
  right: '12px',
  top: '12px',
});
