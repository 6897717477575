import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { useDropMenu } from '../../hooks';
import { DoteHorizontal } from '../Icons';
import { IProps } from './types';

export const CustomActionMenu = ({ options = [] }: IProps) => {
  const { anchorEl, onOpenMenu, onCloseMenu } = useDropMenu();
  const { t } = useTranslatesContext();

  return (
    <>
      <IconButton sx={{ height: '34px' }} onClick={onOpenMenu}>
        <DoteHorizontal />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={Boolean(anchorEl)}
        onClose={onCloseMenu}
        onClick={onCloseMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            minWidth: '168px',
            borderRadius: '12px 0px 12px 12px',
            overflow: 'visible',
            boxShadow: '0px 5px 16px rgba(33, 52, 103, 0.1)',
            '.MuiButtonBase-root': {
              fontSize: '12px',
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {options.map(({ label, action, color, disabled }) => {
          const colorParams = color ? { color } : {};
          return (
            <MenuItem onClick={action} key={label} disabled={disabled}>
              <Typography variant={'sm300'} {...colorParams}>
                {t(label)}
              </Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
