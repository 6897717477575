import React, { ChangeEvent } from 'react';
import {
  AlertWrapper,
  AvatarCSS,
  BottomWrapper,
  ContentCSS,
  DividerCSS,
  TitleBlockCSS,
  Wrapper,
} from './styles';
import { AvatarGroup, Button, Typography } from '@mui/material';
import { palette } from '../../theme/foundations/index';
import { AlertIcon, Triangle } from '../Icons/index';
import { MenuToAlert } from '../MenuToAlert/MenuToAlert';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { IOfferDish } from '../../pages/OrderPage/types';

interface IAlertOfferToClientProps {
  title?: string;
  subTitle?: string;
  countDishes?: string;
  coastDishes?: string;
  type?: 'order' | 'offer';
  totalOfferPrice: number;
  offerInitPrice?: number;
  offerDishes?: Array<IOfferDish>;
  onSubmit?: () => void;
  isDisabledButton?: boolean;

  discountValue?: string;
  setDiscountValue?: React.Dispatch<React.SetStateAction<string>>;
}

export const AlertOfferToClient = ({
  title,
  countDishes,
  subTitle,
  onSubmit,
  totalOfferPrice,
  offerInitPrice = 0,
  offerDishes,
  type,
  isDisabledButton,
  discountValue = '',
  setDiscountValue = () => {},
}: IAlertOfferToClientProps) => {
  const isOrderType = type === 'order';
  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false);

  const { t } = useTranslatesContext();

  const handleChangeOpenModal = () => {
    setIsOpenModal((prev) => !prev);
  };

  const handleDiscount = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const maxValue = totalOfferPrice;
    if (Number(inputValue) > maxValue || isNaN(+inputValue)) {
      setDiscountValue(discountValue);
    } else {
      setDiscountValue(inputValue);
    }
  };

  const alertTitle = offerInitPrice < totalOfferPrice - +discountValue;
  const coastDishes = `${totalOfferPrice}/${offerInitPrice}$`;

  const finalPrice = isOrderType
    ? totalOfferPrice - +discountValue
    : offerInitPrice;

  const excessAmount = Math.abs(Number(finalPrice - offerInitPrice));

  const options = offerDishes || [];
  const offerDishesIcons = (offerDishes || []).map((dish) => dish.img);

  return (
    <Wrapper>
      {alertTitle && isOrderType ? (
        <>
          <AlertWrapper>
            <AlertIcon />
            <Typography
              variant={'sm550'}
              lineHeight={'15px'}
              mr={'108px'}
              color={palette.white}
            >
              {t('web_alert_title_alert')}
            </Typography>
          </AlertWrapper>
          <DividerCSS />
        </>
      ) : null}
      <BottomWrapper>
        <TitleBlockCSS>
          <Typography variant={'sm600'} color={palette.white}>
            {subTitle}
          </Typography>
          <Typography variant={'md500'} fontWeight={500} color={palette.white}>
            {title}
          </Typography>
        </TitleBlockCSS>
        <ContentCSS onClick={handleChangeOpenModal}>
          <AvatarGroup max={3}>
            {offerDishesIcons.map((iconUrl, index) => (
              <AvatarCSS alt='avatar' src={iconUrl} key={index} />
            ))}
          </AvatarGroup>
          <Typography variant={'md500'} fontWeight={500} color={palette.white}>
            {`${t('web_alert_dish_count')}: ${countDishes}${
              isOrderType ? `, ${coastDishes}` : ''
            }`}
          </Typography>
          <Triangle />
        </ContentCSS>
        <Button
          size={'large'}
          sx={{ width: 'fit-content' }}
          onClick={onSubmit}
          disabled={isDisabledButton}
        >
          {isOrderType
            ? t('web_offer_alert_action_btn_text')
            : t('web_eit_offer_dishes_submit_btn')}
        </Button>
        {isOpenModal ? (
          <MenuToAlert
            open={isOpenModal}
            isOrderType={!!isOrderType}
            options={options}
            price={String(finalPrice)}
            excessAmount={excessAmount}
            isAlertTitle={alertTitle && isOrderType}
            coastMenu={String(totalOfferPrice)}
            amountOfTheDiscount={discountValue}
            handleDiscount={handleDiscount}
            isHasDiscountField={isOrderType}
          />
        ) : null}
      </BottomWrapper>
    </Wrapper>
  );
};
