import {
  DisappointedIcon,
  JoyIcon,
  NeutralIcon,
  SmileIcon,
} from '../components/Icons';

export const getReviewSmile = (value: number) => {
  switch (value) {
    case 1:
      return DisappointedIcon;
    case 2:
      return NeutralIcon;
    case 3:
      return SmileIcon;
    case 4:
      return JoyIcon;
    default:
      return JoyIcon;
  }
};
