import { Menu, MenuItem } from '@mui/material';
import { useTranslatesContext } from '../../../context/TranslatesContext';
import { LogoutIcon } from '../../Icons/index';
import { ICustomMenuProps } from './types';

export const CustomMenu = ({
  anchorEl,
  handleClose,
  handleLogout,
  titleLogout,
  bottomOpen,
}: ICustomMenuProps) => {
  const { t } = useTranslatesContext();
  return (
    <Menu
      sx={{ mb: '45px' }}
      id='menu-appbar'
      anchorEl={anchorEl}
      keepMounted
      {...(!bottomOpen && {
        transformOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      })}
      PaperProps={{
        sx: {
          boxShadow: '0px 5px 16px rgba(33, 52, 103, 0.1)',
          width: '168px',
          borderRadius: '12px 0px 12px 12px',
          transform: bottomOpen ? '' : 'translateY(-55px) !important',
        },
      }}
      MenuListProps={{
        sx: {
          paddingTop: '10px',
          paddingBottom: '10px',
        },
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={handleLogout} sx={{ gap: '5px' }}>
        {titleLogout || t('web_action_logout')} <LogoutIcon />
      </MenuItem>
    </Menu>
  );
};
