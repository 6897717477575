import * as yup from 'yup';
import { MESSAGES } from '../messages';

export const addTranslateSchema = yup.object().shape({
  titleField: yup
    .string()
    .min(5, MESSAGES.MIN_NUMBER_VALUE('5'))
    .required(MESSAGES.REQUIRE_MESSAGE),
  tabNum: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  enString: yup.string().when('tabNum', {
    is: (val: string) => {
      return val === '1';
    },
    then: yup.string(),
    otherwise: yup.string().nullable(),
  }),
  enImage: yup.mixed().when('tabNum', {
    is: (val: string) => {
      return val === '2';
    },
    then: yup.mixed().required(MESSAGES.REQUIRE_MESSAGE),
    otherwise: yup.mixed().nullable(),
  }),
  enTextArea: yup.string().when('tabNum', {
    is: (val: string) => {
      return val === '3';
    },
    then: yup.string(),
    otherwise: yup.string().nullable(),
  }),
});
