import * as yup from 'yup';
import { MESSAGES } from '../messages';

export const addBrandsSchema = yup.object().shape({
  title: yup
    .string()
    .max(100, MESSAGES.MAX_LENGTH('100'))
    .required(MESSAGES.REQUIRE_MESSAGE),
  description: yup
    .string()
    .max(3000, MESSAGES.MAX_LENGTH('3000'))
    .required(MESSAGES.REQUIRE_MESSAGE),
  image: yup.mixed().required(MESSAGES.REQUIRE_MESSAGE),
});
