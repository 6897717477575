import { Box, Checkbox } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { ICheckboxFormikProps } from './types';

export const CheckboxFormik = ({
  name = '',
  size = 'medium',
  mb = '0px',
  mr = '0px',
  mt = '0px',
}: ICheckboxFormikProps) => {
  const [field] = useField(name);
  const { handleChange } = useFormikContext();

  return (
    <Box mb={mb} mr={mr} mt={mt}>
      <Checkbox
        style={{ padding: '0px' }}
        name={name}
        size={size}
        checked={field.value}
        onChange={handleChange}
      />
    </Box>
  );
};
