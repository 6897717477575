import { Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import {
  AuthFormWrapper,
  AuthLayout,
  Button,
  TextFieldFormik,
} from '../../components';
import bgImage from './../../assets/images/register-bg.jpg';
import { BackButton } from '../../components/index';

import { resetPasswordSchema } from '../../validation';
import { TitleRow, Wrapper } from './styles';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { useManagerRestorePasswordMutation } from '../../graphql/generated/graphql';
import { IFormikProps } from './types';
import { useAlertContext } from '../../context/index';

export const ResetPassword = () => {
  const { t } = useTranslatesContext();
  const { onOpenAlert } = useAlertContext();
  const [managerRestorePassword] = useManagerRestorePasswordMutation({
    onCompleted: () => {
      onOpenAlert({ title: t('web_alert_reset_password_success') });
    },
    onError: () => {
      onOpenAlert({ title: t('web_alert_reset_password_error'), error: true });
    },
  });

  const handleSubmit = (values: IFormikProps) => {
    managerRestorePassword({
      variables: {
        email: values.email,
      },
    });
  };

  return (
    <AuthLayout backgroundimage={bgImage}>
      <AuthFormWrapper height={396}>
        <Wrapper>
          <TitleRow>
            <BackButton />
            <Typography variant='h2' ml={'12px'}>
              {t('web_reset_pas_title')}
            </Typography>
          </TitleRow>
          <Formik<IFormikProps>
            initialValues={{
              email: '',
            }}
            validationSchema={resetPasswordSchema}
            onSubmit={handleSubmit}
          >
            {() => {
              return (
                <Form>
                  <TextFieldFormik
                    name='email'
                    placeholder={t('web_reset_pas_email_placeholder')}
                    mt='20px'
                    label={t('web_reset_pas_email_label')}
                  />

                  <Button mt='20px' type='submit'>
                    {t('web_reset_pas_submit_btn')}
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </Wrapper>
      </AuthFormWrapper>
    </AuthLayout>
  );
};
