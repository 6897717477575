import React from 'react';

export const JoyIcon = () => {
  return (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='10.5' cy='10' r='10' fill='#EECA0B' />
      <circle cx='6.5' cy='7' r='1' fill='black' />
      <circle cx='14.5' cy='7' r='1' fill='black' />
      <path
        d='M10.5 16C14.5 16 14.5 12 14.5 12H6.5C6.5 12 6.5 16 10.5 16Z'
        stroke='black'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};
