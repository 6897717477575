import React from 'react';

export const CompleteIcon = () => {
  return (
    <svg
      width='15'
      height='12'
      viewBox='0 0 15 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1 7.36364L5.33333 11L14 1'
        stroke='#00B074'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
