import React, { useEffect, useState } from 'react';

import { PaginationRow } from '../PaginationRow/PaginationRow';
import { useTranslatesContext } from '../../context/TranslatesContext';

import {
  Restaurant,
  Sort_Direction_Enum,
  useRestaurantWithoutBrandPageLazyQuery,
} from '../../graphql/generated/graphql';
import { RequestHandler } from '../UI/RequestHandler/RequestHandler';
import { usePagination } from '../../hooks/index';
import { client } from '../../graphql/client';
import { CustomTable } from '../CustomTable/CustomTable';
import {
  COLUMN_IDS,
  TABLE_SORT_DIRECTIONS,
  TABLE_SORT_FIELDS,
} from '../CustomTable/constants';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/constants';
import { TableSortType } from '../CustomTable/types';
import { useFormikContext } from 'formik';
import dayjs from 'dayjs';
import { Typography } from '@mui/material';

export const RestaurantsWithoutBrandList = ({
  searchText,
}: {
  searchText: string;
}) => {
  const { t } = useTranslatesContext();
  const navigate = useNavigate();

  const { values } = useFormikContext<{
    period: string;
    city: number | 'all-cities';
  }>();

  const {
    paginationOptions,
    handleLoadMore: paginationHandleLoadMore,
    handleChangePage,
    handleDefaultPaginationValue,
  } = usePagination('restaurants');

  const [sortInfo, setSortInfo] = useState<TableSortType | undefined>({
    direction: TABLE_SORT_DIRECTIONS.ASC,
    field: null,
  });

  const [
    getRestaraunts,
    { data: restarauntsData, loading, error, fetchMore, networkStatus },
  ] = useRestaurantWithoutBrandPageLazyQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const handleChangeSort = (field: TABLE_SORT_FIELDS) => {
    setSortInfo((prev) => ({
      field: field,
      direction:
        prev?.field === field
          ? prev.direction === TABLE_SORT_DIRECTIONS.ASC
            ? TABLE_SORT_DIRECTIONS.DESC
            : TABLE_SORT_DIRECTIONS.ASC
          : TABLE_SORT_DIRECTIONS.ASC,
    }));
  };

  const dateParams = {
    dateTo: new Date().toISOString(),
    dateFrom:
      values.period === 'all-time'
        ? dayjs().subtract(5, 'year').toISOString()
        : values.period,
  };

  const cityParams = {
    cityId:
      values.city === 'all-cities' ? undefined : +values.city || undefined,
  };

  const sortParam = sortInfo?.field
    ? {
        sort: {
          direction:
            sortInfo.direction === TABLE_SORT_DIRECTIONS.ASC
              ? Sort_Direction_Enum.Asc
              : Sort_Direction_Enum.Desc,
          field: sortInfo?.field,
        },
      }
    : {};

  const hadnleGetRestaraunts = (limit?: number, offset?: number) => {
    limit = limit ?? paginationOptions.itemsCount;
    offset = offset ?? paginationOptions.offset;

    client.cache.evict({ fieldName: 'restaurants' });

    getRestaraunts({
      variables: {
        getRestaurantsInput: {
          limit,
          offset,
          search: searchText,
          ...cityParams,
          ...sortParam,
          brandId: null,
        },
        ...dateParams,
      },
    });
  };

  useEffect(() => {
    hadnleGetRestaraunts(10, 0);
  }, [
    searchText,
    // paginationOptions.page,
    values.period,
    searchText,
    values.city,
    // sortInfo,
  ]);

  useEffect(() => {
    if (
      restarauntsData &&
      restarauntsData?.restaurants.count ===
        restarauntsData?.restaurants.rows?.length
    ) {
      hadnleGetRestaraunts(restarauntsData?.restaurants.count, 0);
    } else {
      hadnleGetRestaraunts();
    }
  }, [sortInfo, paginationOptions.page]);

  useEffect(() => {
    handleDefaultPaginationValue();
  }, [values.period, searchText, values.city]);

  const handleLoadMore = () => {
    paginationHandleLoadMore(fetchMore, {
      search: searchText,
      ...cityParams,
      ...sortParam,
      brandId: null,
    });
  };

  const onClickPagination = (e: React.ChangeEvent<unknown>, value: number) => {
    handleChangePage(value);
  };

  //
  const handleRow = (id: number) => {
    if (!id) return;
    navigate(`${ROUTES.restaurantsWithoutBrand}/${id}`);
  };

  const TABLE_OPTIONS = [
    {
      id: COLUMN_IDS.TITLE,
      label: t('web_restaraunts_withount_table_title'),
      maxWidth: 180,
    },

    {
      id: COLUMN_IDS.LOCATION,
      label: t('web_restaraunts_withount_table_adress'),
      maxWidth: 180,
    },
    {
      id: COLUMN_IDS.KITCHENS,
      label: t('web_restaraunts_withount_table_kitchen'),
      maxWidth: 180,
    },
    {
      id: COLUMN_IDS.VIEWS,
      label: t('web_restaraunts_withount_table_views'),
      maxWidth: 180,
      handleChangeSort,
    },
    {
      id: COLUMN_IDS.CLICKS,
      label: t('web_restaraunts_withount_table_clcks'),
      maxWidth: 180,
      handleChangeSort,
    },
    {
      id: COLUMN_IDS.REQUESTS,
      label: t('web_restaraunts_withount_table_requests'),
      maxWidth: 180,
      handleChangeSort,
    },
  ];

  const createData = (restaurant: Restaurant) => {
    return {
      id: restaurant?.id || 0,
      title: restaurant?.name || '',
      location: `${restaurant?.city?.title || ''} ${restaurant?.address}`,
      kitchens: restaurant?.cuisines?.map((item) => {
        return {
          id: item.id,
          name: item.title,
        };
      }),
      viewCount: restaurant?.viewCount,
      clickCount: restaurant?.clickCount,
      requestCount: restaurant?.requestCount,
    };
  };

  const rows = restarauntsData?.restaurants?.rows?.map((restaurant) =>
    createData(restaurant as Restaurant),
  );
  //

  const allItemsCount = restarauntsData?.restaurants.count || 0;

  const pagesCount = Math.ceil(allItemsCount / paginationOptions.itemsCount);
  const currentItemsCount =
    paginationOptions.itemsCount * paginationOptions.page >= allItemsCount
      ? allItemsCount
      : paginationOptions.itemsCount * paginationOptions.page;

  const page =
    paginationOptions.page > pagesCount ? pagesCount : paginationOptions.page;

  if (rows && !rows.length)
    return <Typography>{t('web_restaraunts_withount_table_empty')}</Typography>;

  return (
    <>
      <RequestHandler
        loading={(loading && networkStatus !== 3) || networkStatus === 2}
        error={error}
        height={'50vh'}
      >
        <CustomTable
          headCells={TABLE_OPTIONS}
          rows={rows}
          sortInfo={sortInfo}
          isLoading={false}
          tableContainerSx={{ mb: '16px' }}
          handleRow={handleRow}
        />
      </RequestHandler>
      {pagesCount > 1 ? (
        <PaginationRow
          from={currentItemsCount as number}
          fromText={t('web_pagination_out_of')}
          to={allItemsCount as number}
          onClickButton={handleLoadMore}
          onClickPagination={onClickPagination}
          buttonName={t('web_pagination_show_more')}
          pagesCount={pagesCount}
          page={page}
        />
      ) : null}
    </>
  );
};
