import { Wrapper } from './styles';
import { useNavigate } from 'react-router-dom';
import { ApplicationItemInfo } from '..';
import { getIntlPhoneNumber } from '../../utils/index';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { IApplicationItemProps } from './types';

export const ApplicationItem = ({
  id,
  name,
  phoneNumber,
  title,
}: IApplicationItemProps) => {
  const navigate = useNavigate();
  const { t } = useTranslatesContext();
  const { intlPhone } = getIntlPhoneNumber(phoneNumber);

  const handleNavigateToInfo = () => {
    if (!id) return;
    navigate(`/applications/${id}`);
  };

  return (
    <Wrapper onClick={handleNavigateToInfo}>
      <ApplicationItemInfo
        mr={'56px'}
        title={t('web_app_page_label_name')}
        subTitle={name}
      />
      <ApplicationItemInfo
        mr={'118px'}
        title={t('web_app_page_label_phone')}
        subTitle={intlPhone}
      />
      <ApplicationItemInfo
        title={t('web_app_page_label_title')}
        subTitle={title}
      />
    </Wrapper>
  );
};
