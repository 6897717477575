import { Box, styled } from '@mui/material';
import { CONTENT_WIDTH } from '../../constants/constants';

export const FormWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: CONTENT_WIDTH,
});

export const ButtonBlockCSS = styled(Box)({
  display: 'flex',
  gap: '12px',
});
