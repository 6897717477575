import { Box, styled } from '@mui/material';
import { palette } from '../../../theme/foundations/index';

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  minHeight: '100vh',
}));

export const ContentCSS = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '30px 38px 40px 255px',
  position: 'relative',
  background: palette.white100,
}));
