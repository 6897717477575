import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled(Box)(() => ({
  padding: '0 20px 20px',
}));
export const TitleRow = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));
