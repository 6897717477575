type LayoutType = {
  children: React.ReactNode;
};

export const Layout = ({ children }: LayoutType) => {
  return (
    <div>
      <div>Header</div>
      {children}
      <div>footer</div>
    </div>
  );
};
