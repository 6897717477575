import React from 'react';

export const OrderIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14 2C14 3.10457 13.1046 4 12 4C10.8954 4 10 3.10457 10 2C10 0.895431 10.8954 0 12 0C13.1046 0 14 0.895431 14 2ZM12 4C17.5228 4 22 9.14873 22 15.5C22 15.6454 21.9977 16.5438 21.993 16.8867C21.9977 16.9243 22 16.9621 22 17C22 18.6569 17.5228 20 12 20C6.47715 20 2 18.6569 2 17C2 16.9621 2.00235 16.9243 2.007 16.8867C2.00235 16.5437 2 15.6454 2 15.5C2 9.14873 6.47715 4 12 4ZM23 18.5001C23 20.4331 18.0751 22.0001 12 22.0001C5.92487 22.0001 1 20.4331 1 18.5001C1 18.465 1.00162 18.4301 1.00484 18.3953C0.358617 18.8865 0 19.4293 0 20.0001C0 22.2092 5.37258 24.0001 12 24.0001C18.6274 24.0001 24 22.2092 24 20.0001C24 19.4293 23.6414 18.8865 22.9952 18.3953C22.9984 18.4301 23 18.465 23 18.5001Z'
        fill='#B5B1AE'
      />
    </svg>
  );
};
