import React from 'react';
import { MenuItem, Select, SelectChangeEvent, SxProps } from '@mui/material';

type OptionT = {
  id: string | number;
  value: string | number;
  label: string | number;
};

interface ISelectCustomProps {
  options?: OptionT[];
  value: string;
  onChange: (e: SelectChangeEvent) => void;
  fullWidth?: boolean;
  sx?: SxProps;
}

export const SelectCustom = ({
  value,
  onChange,
  options,
  fullWidth = true,
  sx,
}: ISelectCustomProps) => {
  return (
    <Select fullWidth={fullWidth} value={value} onChange={onChange} sx={sx}>
      {options?.map(({ id, value, label }) => {
        return (
          <MenuItem key={id} value={value}>
            {label}
          </MenuItem>
        );
      })}
    </Select>
  );
};
