import { Box, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  AuthFormWrapper,
  AuthLayout,
  Button,
  TextFieldFormik,
} from '../../components';
import { BackButton } from '../../components/UI/BackButton/BackButton';
import { SelectFormik } from '../../components/UI/SelectFormik/SelectFormik';
import { ROUTES } from '../../constants/constants';
import { countriesOptions } from '../../constants/countries';
import { useAlertContext } from '../../context';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { useCreateRegistrationApplicationMutation } from '../../graphql/generated/graphql';
import { registerSchema } from '../../validation';
import { Wrapper, TitleRow } from './styles';
import { IFormikProps } from './types';

export const Registration = () => {
  const navigate = useNavigate();
  const { onOpenAlert } = useAlertContext();
  const { t } = useTranslatesContext();

  const [mutate] = useCreateRegistrationApplicationMutation();

  const handleOfferNavigation = () => {
    window.open(ROUTES.registrationOffer);
  };

  const handleSubmit = (
    values: IFormikProps,
    { resetForm }: { resetForm: () => void },
  ) => {
    mutate({
      variables: { createRegistrationApplicationInput: values },
      onCompleted: () => {
        resetForm();
        onOpenAlert({ title: t('web_alert_registration_success') });
      },
      onError: (error) => {
        onOpenAlert({ title: error.message, error: true });
      },
    });
  };

  return (
    <AuthLayout backgroundimage={t('web_register_bg')}>
      <AuthFormWrapper>
        <Wrapper>
          <TitleRow>
            <BackButton />
            <Typography variant='h2' ml={'12px'}>
              {t('web_register_title')}
            </Typography>
          </TitleRow>

          <Formik<IFormikProps>
            initialValues={{
              name: '',
              email: '',
              phone: '',
              restaurantName: '',
              country: '',
              city: '',
            }}
            validationSchema={registerSchema}
            onSubmit={handleSubmit}
          >
            {() => {
              return (
                <Form>
                  <TextFieldFormik
                    name='name'
                    placeholder={t('web_register_name_placeholder')}
                    label={t('web_register_name_label')}
                    mt='20px'
                  />
                  <TextFieldFormik
                    name='email'
                    placeholder={t('web_register_email_placeholder')}
                    label={t('web_register_email_label')}
                    mt='14px'
                  />
                  <TextFieldFormik
                    name='phone'
                    placeholder={t('web_register_phone_placeholder')}
                    label={t('web_register_phone_label')}
                    mt='14px'
                  />
                  <TextFieldFormik
                    name='restaurantName'
                    placeholder={t('web_register_rest_name_placeholder')}
                    label={t('web_register_rest_name_label')}
                    mt='14px'
                  />
                  <SelectFormik
                    name={'country'}
                    options={countriesOptions}
                    placeholder={t('web_register_country_placeholder')}
                    label={t('web_register_country_label')}
                    size='small'
                    mt='14px'
                  />
                  <TextFieldFormik
                    name='city'
                    placeholder={t('web_register_city_placeholder')}
                    label={t('web_register_city_label')}
                    mt='14px'
                  />
                  <Box
                    mt='16px'
                    onClick={handleOfferNavigation}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Typography
                      variant='sm500'
                      component='p'
                      color={'primary.main'}
                    >
                      {t('web_register_offer')}
                    </Typography>
                  </Box>

                  <Button mt='16px' type='submit'>
                    {t('web_register_submit_btn')}
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </Wrapper>
      </AuthFormWrapper>
    </AuthLayout>
  );
};
