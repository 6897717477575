import React from 'react';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { Brand } from '../../graphql/generated/graphql';
import { BrandItem } from '../BrandItem/BrandItem';
import { EmptyList } from '../EmptyList/EmptyList';
import { PaginationRow } from '../PaginationRow/PaginationRow';
import { Wrapper } from './styles';

interface IBrandListProps {
  data?: Array<Brand> | undefined;
  handleLoadMore: () => void;
  handleChangePage: (page: number) => void;
  pagesCount: number;
  page: number;
  allItemsCount?: number;
  currentItemsCount?: number;
  handleAddBrands: () => void;
}

export const BrandList = ({
  data,
  handleLoadMore,
  handleChangePage,
  pagesCount,
  page,
  allItemsCount,
  currentItemsCount,
  handleAddBrands,
}: IBrandListProps) => {
  const { t } = useTranslatesContext();
  const onClickPagination = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    handleChangePage(value);
  };

  if (data && data.length === 0 && allItemsCount === 0) {
    return (
      <EmptyList
        sx={{ marginTop: '136px' }}
        title={t('web_brands_empty_title')}
        img={t('web_brands_emtpy_img')}
        button={{
          title: t('web_brands_add_btn'),
          action: handleAddBrands,
        }}
      />
    );
  }

  return (
    <>
      <Wrapper>
        {data?.map((brand: Brand) => (
          <BrandItem
            key={brand.id}
            urlImage={brand.image.url}
            nameBrand={brand.title}
            amount={Number(brand.restaurantsCount)}
            title={brand.description}
            id={brand.id}
          />
        ))}
      </Wrapper>
      {pagesCount > 1 ? (
        <PaginationRow
          from={currentItemsCount as number}
          fromText={t('web_pagination_out_of')}
          to={allItemsCount as number}
          onClickButton={handleLoadMore}
          onClickPagination={onClickPagination}
          buttonName={t('web_pagination_show_more')}
          pagesCount={pagesCount}
          page={page}
        />
      ) : null}
    </>
  );
};
