import { Box, styled } from '@mui/material';

export const ErrorIconCSS = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  background: 'red',
});
