import * as yup from 'yup';

import { MESSAGES } from '../messages';

const MAX_NUMBER = 1_000_000_000;

export const addDish = yup.object().shape({
  name: yup
    .string()
    .max(255, MESSAGES.MAX_LENGTH('255'))
    .required(MESSAGES.REQUIRE_MESSAGE),
  categories: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  logo: yup.mixed().required(MESSAGES.REQUIRE_MESSAGE),
  description: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  price: yup
    .number()
    .positive(MESSAGES.INVALID_VALUE)
    .max(MAX_NUMBER, MESSAGES.INVALID_VALUE)
    .required(MESSAGES.REQUIRE_MESSAGE),
  time: yup
    .number()
    .positive(MESSAGES.INVALID_VALUE)
    .max(MAX_NUMBER, MESSAGES.INVALID_VALUE)
    .required(MESSAGES.REQUIRE_MESSAGE),
  personsCount: yup
    .number()
    .positive(MESSAGES.INVALID_VALUE)
    .integer(MESSAGES.INVALID_VALUE)
    .max(MAX_NUMBER, MESSAGES.INVALID_VALUE)
    .required(MESSAGES.REQUIRE_MESSAGE),
  calories: yup
    .number()
    .positive(MESSAGES.INVALID_VALUE)
    .max(MAX_NUMBER, MESSAGES.INVALID_VALUE)
    .required(MESSAGES.REQUIRE_MESSAGE),
  weight: yup
    .number()
    .positive(MESSAGES.INVALID_VALUE)
    .max(MAX_NUMBER, MESSAGES.INVALID_VALUE)
    .required(MESSAGES.REQUIRE_MESSAGE),
  volume: yup
    .number()
    .positive(MESSAGES.INVALID_VALUE)
    .max(MAX_NUMBER, MESSAGES.INVALID_VALUE)
    .required(MESSAGES.REQUIRE_MESSAGE),
});
