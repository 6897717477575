import React from 'react';
import { Box, Typography } from '@mui/material';
import { ImagePlaceholderDropzone } from '../Icons/index';
import { palette } from '../../theme/foundations/index';
import {
  AmountBlockCSS,
  ContentCSS,
  ImageBlockCSS,
  LogoCSS,
  TitleBlockCSS,
  Wrapper,
} from './styles';
import { IBrandItemProps } from './types';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/constants';
import { CustomActionMenu } from '../CustomActionMenu/CustomActionMenu';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { useRemoveBrandMutation } from '../../graphql/generated/graphql';
import { useAlertContext } from '../../context';
import { BRANDS } from '../../graphql/queries/brands.gql';

export const BrandItem = ({
  urlImage,
  nameBrand,
  title,
  amount,
  id,
}: IBrandItemProps) => {
  const navigate = useNavigate();
  const { t } = useTranslatesContext();
  const { onOpenAlert } = useAlertContext();

  const [mutation] = useRemoveBrandMutation({
    update(cache, { data }) {
      onOpenAlert({ title: t('web_alert_delete_brand') });

      if (data?.removeBrand) {
        cache?.evict({
          id: `Brand:${id}`,
        });
        cache?.gc();
      }
    },
    refetchQueries: [BRANDS],
  });

  const handleGo = () => {
    navigate(`${ROUTES.brands}/${id}`);
  };
  const handleEdit = () => {
    navigate(`/brands/edit/${id}`);
  };
  const handleDeativate = () => {
    mutation({
      variables: {
        id: id,
      },
    });
  };

  const actionOptions = [
    {
      label: 'web_action_edit',
      action: handleEdit,
      color: '',
      disabled: false,
    },
    {
      label: 'web_action_deactivate',
      action: handleDeativate,
      color: palette.error.main,
      disabled: false,
    },
  ];

  return (
    <Wrapper>
      <ContentCSS onClick={handleGo}>
        <ImageBlockCSS>
          {!urlImage ? (
            <ImagePlaceholderDropzone />
          ) : (
            <LogoCSS src={urlImage} alt={'i'} />
          )}
        </ImageBlockCSS>
        <TitleBlockCSS>
          <Typography variant={'big500'}>{nameBrand}</Typography>
          <Typography variant={'sm500'}>{title}</Typography>
        </TitleBlockCSS>

        <AmountBlockCSS>
          <Typography variant={'sm400'}>
            {t('web_brands_restaraunts_count')}
          </Typography>
          <Typography
            variant={'sm400'}
            component={'span'}
            color={palette.primary.main}
          >
            {amount}
          </Typography>
        </AmountBlockCSS>
      </ContentCSS>
      <Box>
        <CustomActionMenu options={actionOptions} />
      </Box>
    </Wrapper>
  );
};
