import { gql } from '@apollo/client';

export const BRAND_EVENT_OFFERS = gql`
  query BrandEventOffers($options: GetEventOffersInput!) {
    brandEventOffers(options: $options) {
      id
      comment
      selectedRestaurantId
      status
      suitableRestaurantsIds
      event {
        name
        ratingFrom
        budgetPerPerson
        amountPersons
        eventTheme {
          id
          title
          isFavorite
        }
        repeatOnceAt
        startAt
        endAt
        user {
          id
        }
        updatedAt
      }
      eventOfferDishes {
        dish {
          image {
            url
          }
          price
          title
          id
          description
        }
        quantity
        eventOfferId
        dishId
      }
      total
      discount
      restaurantReview {
        accurateRating
        eventMatchRating
        cuisineRating
        serviceRating
        avgRating
        comment
        createdAt
        user {
          name
          photo {
            url
          }
        }
      }
    }
  }
`;
