import { Box, styled } from '@mui/material';

export const Wrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

export const LabelCSS = styled(Box)({
  marginBottom: '8px',
  display: 'inline-flex',
  alignItems: 'center',
});
