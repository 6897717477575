import * as yup from 'yup';

import { MESSAGES } from '../messages';

export const resetPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email(MESSAGES.INCORRECT_EMAIL)
    .required(MESSAGES.REQUIRE_MESSAGE),
});
