import {
  ImageBlockCSS,
  InnerTitleBlockCSS,
  PriceBlockCSS,
  TitleBlockCSS,
  TitleBlockItemCSS,
  Wrapper,
} from './styles';
import { Checkbox, Typography } from '@mui/material';
import { CustomActionMenu } from '../CustomActionMenu/CustomActionMenu';
import { palette } from '../../theme/foundations/index';
import { useNavigate } from 'react-router-dom';
import { useRemoveDishMutation } from '../../graphql/generated/graphql';
import { useAlertContext } from '../../context';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { useAccesses } from '../../hooks';

// TODO translations

interface IDishItemProps {
  imageUrl: string;
  title: string;
  personCount: string;
  timeCount: string;
  price: string;
  isActive: boolean;
  id: number;
  refetch: any;
  handleChangeItem: (id: number, isActive: boolean) => void;
}

export const DishItem = ({
  isActive,
  id,
  handleChangeItem,
  imageUrl,
  title,
  personCount,
  timeCount,
  price,
  refetch,
}: IDishItemProps) => {
  const { t } = useTranslatesContext();
  const { isDishEditAccess, isDishRemoveAccess } = useAccesses();

  const navigate = useNavigate();
  const { onOpenAlert } = useAlertContext();

  const [mutation] = useRemoveDishMutation({
    update(cache, { data }) {
      onOpenAlert({ title: t('web_alert_delete_dish') });

      if (data?.removeDish) {
        cache?.evict({
          id: `Dish:${id}`,
        });
        cache?.gc();
      }
    },
  });

  const handleChecked = () => {
    handleChangeItem(id, isActive);
  };

  const handleEdit = () => {
    navigate(`/menu/edit/${id}`);
  };

  const handleDeativate = () => {
    mutation({
      variables: {
        id: id,
      },
    });
    refetch();
  };

  const actionOptionsMOC = [
    {
      label: 'web_action_edit',
      action: handleEdit,
      color: '',
      disabled: !isDishEditAccess,
    },
    {
      label: 'web_action_deactivate',
      action: handleDeativate,
      color: palette.error.main,
      disabled: !isDishRemoveAccess,
    },
  ];

  return (
    <Wrapper target={isActive ? 'true' : 'false'}>
      <Checkbox checked={isActive} onChange={handleChecked} />
      <ImageBlockCSS>
        <img src={imageUrl} alt={'dish img'} />
      </ImageBlockCSS>
      <TitleBlockCSS>
        <TitleBlockItemCSS variant={'body2'}>{title}</TitleBlockItemCSS>
        <InnerTitleBlockCSS>
          <Typography variant={'sm500'}>
            {t('web_restaurant_menu_persons')}: {personCount}
          </Typography>
          <Typography variant={'sm500'}>|</Typography>
          <Typography variant={'sm500'}>
            {t('web_restaurant_menu_minutes')}: {timeCount}
          </Typography>
        </InnerTitleBlockCSS>
      </TitleBlockCSS>
      <PriceBlockCSS>
        <Typography variant={'md700'}>${price}</Typography>
      </PriceBlockCSS>
      <CustomActionMenu options={actionOptionsMOC} />
    </Wrapper>
  );
};
