import React from 'react';
import { palette } from './palette';

export const typography = {
  fontFamily: 'Roboto, sans-serif',
  allVariants: {
    WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
  },
  h2: {
    fontSize: '28px',
    fontWeight: 500,
    lineHeight: '32.8px',
    color: `${palette.dark500}`,
  },
  h3: {
    fontSize: '22px',
    fontWeight: 500,
    lineHeight: '22px',
    color: `${palette.black}`,
  },
  body1: {
    fontSize: '16px',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.005em',
    color: `${palette.dark500}`,
  },
  body2: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.005em',
    color: `${palette.dark500}`,
  },
  body3: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.3px',
    color: `${palette.dark500}`,
  },
  body4: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '16px',
    color: palette.brown500,
  },
  body5: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.3px',
    color: palette.black400,
  },
  error: {
    fontSize: '11px',
    fontWeight: 400,
    lineHeight: '13px',
    color: palette.input.error,
  },
  sm50: {
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '15px',
  },
  sm100: {
    fontWeight: 400,
    fontSize: '11px',
    lineHeight: '13px',
  },
  sm200: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '14px',
  },
  sm300: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    color: `${palette.dark500}`,
  },
  sm350: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    color: `${palette.brown500}`,
  },
  sm400: {
    fontWeight: 600,
    fontSize: '11px',
    lineHeight: '13px',
    letterSpacing: '-0.06px',
    color: `${palette.brown500}`,
  },
  sm500: {
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '20px',
    letterSpacing: '-0.32px',
    color: `${palette.gray600}`,
  },
  sm550: {
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '20px',
    color: palette.dark500,
  },
  sm575: {
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '20px',
    color: palette.brown500,
  },
  sm600: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: `#3D3835`,
  },
  big500: {
    fontSize: '17px',
    fontWeight: 600,
    lineHeight: '22px',
    letterSpacing: '-0.408px',
    color: palette.dark500,
  },
  big600: {
    fontSize: '31px',
    fontWeight: 500,
    lineHeight: '120%',
    letterSpacing: '0.005em',
  },
  big700: {
    fontSize: '28px',
    fontWeight: 500,
    lineHeight: '33px',
    color: palette.dark500,
  },
  big800: {
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: '33px',
    letterSpacing: '0.36px',
    color: palette.brown500,
  },
  md500: {
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  md550: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.24px',
    color: palette.brown500,
  },
  md400: {
    fontSize: '15px',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '-0.24px',
    color: palette.brown500,
  },
  md450: {
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.24px',
  },
  md600: {
    fontSize: '19px',
    fontWeight: 700,
    lineHeight: '22px',
    color: palette.dark500,
  },
  md650: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '20px',
    color: palette.brown500,
  },
  md700: {
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '24px',
    color: palette.dark500,
  },
  md750: {
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '36px',
    letterSpacing: '0.3px',
    color: palette.black400,
  },
  md800: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '-0.24px',
    color: palette.brown500,
  },
  md900: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '24px',
    color: palette.black,
  },
  md950: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0.38px',
    color: palette.brown500,
  },
};

declare module '@mui/material/styles' {
  interface TypographyVariants {
    sm500: React.CSSProperties;
    big500: React.CSSProperties;
    error: React.CSSProperties;
    sm200: React.CSSProperties;
    sm300: React.CSSProperties;
    sm400: React.CSSProperties;
    big600: React.CSSProperties;
    md500: React.CSSProperties;
    md400: React.CSSProperties;
    md600: React.CSSProperties;
    md700: React.CSSProperties;
    sm100: React.CSSProperties;
    sm600: React.CSSProperties;
    sm350: React.CSSProperties;
    big700: React.CSSProperties;
    sm550: React.CSSProperties;
    sm575: React.CSSProperties;
    md650: React.CSSProperties;
    body3: React.CSSProperties;
    md750: React.CSSProperties;
    big800: React.CSSProperties;
    md800: React.CSSProperties;
    body4: React.CSSProperties;
    md550: React.CSSProperties;
    body5: React.CSSProperties;
    md900: React.CSSProperties;
    md450: React.CSSProperties;
    sm50: React.CSSProperties;
    md950: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    sm200?: React.CSSProperties;
    sm500?: React.CSSProperties;
    big500?: React.CSSProperties;
    error?: React.CSSProperties;
    sm300?: React.CSSProperties;
    sm400?: React.CSSProperties;
    big600?: React.CSSProperties;
    md500?: React.CSSProperties;
    md400?: React.CSSProperties;
    md600?: React.CSSProperties;
    md700?: React.CSSProperties;
    sm100?: React.CSSProperties;
    sm600?: React.CSSProperties;
    sm350?: React.CSSProperties;
    big700?: React.CSSProperties;
    sm550?: React.CSSProperties;
    sm575?: React.CSSProperties;
    md650?: React.CSSProperties;
    body3?: React.CSSProperties;
    md750?: React.CSSProperties;
    big800?: React.CSSProperties;
    md800?: React.CSSProperties;
    body4?: React.CSSProperties;
    md550?: React.CSSProperties;
    body5?: React.CSSProperties;
    md900?: React.CSSProperties;
    md450?: React.CSSProperties;
    sm50?: React.CSSProperties;
    md950?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    sm200: true;
    sm500: true;
    big500: true;
    error: true;
    sm300: true;
    sm400: true;
    big600: true;
    md500: true;
    md400: true;
    md600: true;
    md700: true;
    sm100: true;
    sm600: true;
    sm350: true;
    big700: true;
    sm550: true;
    sm575: true;
    md650: true;
    body3: true;
    md750: true;
    big800: true;
    md800: true;
    body4: true;
    md550: true;
    body5: true;
    md900: true;
    md450: true;
    sm50: true;
    md950: true;
  }
}
