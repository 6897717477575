import {
  ButtonCroupCSS,
  ContentCSS,
  HeadBlockCSS,
  ImageRowCSS,
  InfoBlockCSS,
  RejectedLabelCSS,
  Wrapper,
} from './styles';
import { Button, Typography, Box } from '@mui/material';
import { RestaurantIcon } from '../Icons';
import { GroupAvatars } from '../GroupAvatars/GroupAvatars';
import { PointStatus } from '../PointStatus/PointStatus';
import { CustomActionMenu } from '../CustomActionMenu/CustomActionMenu';
import { useAlertContext, useModalContext } from '../../context';
import { MODAL_NAME } from '../../constants/constants';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { useNavigate } from 'react-router-dom';
import {
  Event_Offer_Status_Enum,
  EventOfferDish,
  RestaurantReview,
  useCreateRemindEventOfferNotificationMutation,
  useUpdateEventOfferMutation,
} from '../../graphql/generated/graphql';

import { BRAND_EVENT_OFFERS } from '../../graphql/queries/brandEventOffers.gql';
import { useAccesses } from '../../hooks';

export interface IOrderItemProps {
  orderStatus: Event_Offer_Status_Enum;
  id?: string | number;
  date?: string;
  time?: string;
  priceToPeople?: string | number;
  countPeople?: string | number;
  title?: string;
  titleDate?: string;
  address?: string | null;
  dishesImages: { id: number; url: string }[];
  statusCompleted?: 'success' | 'error';
  comment?: string | null;
  eventOfferDishes?: EventOfferDish[];
  isEventTookPlace?: boolean;
  discount?: number | null | undefined;
  review: RestaurantReview;
  userId: number;
  isUpdated: boolean;
}

export const OrderItem = ({
  id,
  orderStatus,
  date,
  time,
  priceToPeople,
  countPeople,
  title,
  titleDate,
  address,
  dishesImages,
  comment = '',
  eventOfferDishes,
  isEventTookPlace,
  discount,
  review,
  userId,
  isUpdated,
}: IOrderItemProps) => {
  const { onOpenModal } = useModalContext();
  const { t } = useTranslatesContext();
  const { isOrderConfirmAccess, isOrderRejectAccess } = useAccesses();

  const handleClickOnOrderItem = (orderStatus: Event_Offer_Status_Enum) => {
    const total = Number(priceToPeople);

    if (orderStatus === Event_Offer_Status_Enum.SuccessfullyClosed) {
      onOpenModal(MODAL_NAME.COMPLETED_SUCCESS, {
        numberOrder: date,
        title: title,
        eventOfferDishes: eventOfferDishes,
        total: total,
        discount: discount,
        review: review,
      });
    } else if (orderStatus === Event_Offer_Status_Enum.UnsuccessfullyClosed) {
      onOpenModal(MODAL_NAME.COMPLETED_REJECTED, {
        numberOrder: date,
        title: title,
        reason: t('web_completed_rejected_modal_reason_place'),
        subReason: comment,
        total: total,
      });
    }
  };
  const messageJson = JSON.stringify({
    title,
    titleDate,
    priceToPeople,
    countPeople,
  });

  return (
    <Wrapper>
      {Event_Offer_Status_Enum.New === orderStatus && isUpdated ? (
        <Box
          sx={{
            padding: '10px',
            backgroundColor: '#f1f1f1',
            borderRadius: '8px',
            width: 'fit-content',
            marginBottom: '10px',
          }}
        >
          <Typography variant='sm200'>
            {t('web_orders_edited_event')}
          </Typography>
        </Box>
      ) : null}
      <ContentCSS
        cursorpointer={
          orderStatus === Event_Offer_Status_Enum.SuccessfullyClosed ||
          orderStatus === Event_Offer_Status_Enum.UnsuccessfullyClosed
            ? 'true'
            : 'false'
        }
        onClick={() => handleClickOnOrderItem(orderStatus)}
      >
        <HeadBlockCSS>
          <Typography variant={'sm500'} whiteSpace={'nowrap'}>
            {date} {time}
          </Typography>
          <Typography
            variant={'md500'}
            whiteSpace={'nowrap'}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
          >
            ${priceToPeople}/{countPeople} {t('web_orders_item_pesons')}
          </Typography>
          {orderStatus !== 'NEW' && !!dishesImages.length && (
            <GroupAvatars imagesUrlAry={dishesImages} />
          )}
        </HeadBlockCSS>
        <InfoBlockCSS>
          <Typography variant={'body1'}>{title}</Typography>
          <Typography variant={'body2'}>{titleDate}</Typography>
          {address ? (
            <ImageRowCSS>
              <RestaurantIcon />
              <Typography
                variant={'sm550'}
                maxWidth={'287px'}
                letterSpacing={'-0.32px'}
              >
                {address}
              </Typography>
            </ImageRowCSS>
          ) : null}
        </InfoBlockCSS>
      </ContentCSS>
      <ButtonCroupCSS
        disabledjustyfycontent={
          orderStatus === Event_Offer_Status_Enum.New ? 'true' : 'false'
        }
      >
        <ItemActions
          orderStatus={orderStatus}
          id={id}
          comment={comment}
          date={date}
          eventTookPlace={isEventTookPlace}
          review={review}
          userId={userId}
          message={messageJson}
          isOrderConfirmAccess={isOrderConfirmAccess}
          isOrderRejectAccess={isOrderRejectAccess}
        />
      </ButtonCroupCSS>
    </Wrapper>
  );
};

interface IItemActionsProps {
  orderStatus: Event_Offer_Status_Enum;
  id?: string | number;
  comment: string | null;
  date?: string;
  eventTookPlace?: boolean;
  review: RestaurantReview;
  userId: number;
  message?: string;
  isOrderConfirmAccess?: boolean;
  isOrderRejectAccess?: boolean;
}

const ItemActions = ({
  orderStatus,
  id,
  comment,
  date,
  eventTookPlace,
  review,
  userId,
  message,
  isOrderConfirmAccess,
  isOrderRejectAccess,
}: IItemActionsProps) => {
  const navigate = useNavigate();
  const { onOpenModal } = useModalContext();
  const { onOpenAlert } = useAlertContext();

  const { t } = useTranslatesContext();
  const orderNumber = date;

  // const revokeOrder = () => {
  //   onOpenModal(MODAL_NAME.REJECTED, { id: id });
  // };

  const [updateEventOffer] = useUpdateEventOfferMutation({
    onCompleted: () => {
      onOpenAlert({ title: t('web_rejected_modal_alert_title_update') });
    },
    refetchQueries: [BRAND_EVENT_OFFERS],
  });

  const handleCompletedSuccessfully = () => {
    updateEventOffer({
      variables: {
        updates: {
          id: Number(id),
          status: Event_Offer_Status_Enum.SuccessfullyClosed,
          comment: '',
        },
      },
    });
  };

  const isHasReview = !!review;

  const handleRejectedOrder = () => {
    onOpenModal(MODAL_NAME.REJECTED, { id: id });
  };
  const handleNotSuccessfullyOrder = () => {
    onOpenModal(MODAL_NAME.UNSUCCESSFULLY_CLOSED, { id: id });
  };

  const actionOptions = [
    {
      label: !eventTookPlace
        ? 'web_action_withdraw_offer'
        : 'web_order_completed_not_successfully',
      action: handleNotSuccessfullyOrder,
      color: '',
      disabled: !isOrderConfirmAccess,
    },
  ];
  const handleAnswer = () => {
    navigate(`/orders/${id}`, {
      state: {
        orderNumber,
      },
    });
  };

  const [createNotification] = useCreateRemindEventOfferNotificationMutation({
    onCompleted: () => {
      onOpenAlert({ title: t('web_order_send_notification_text') });
    },
    onError: () => {
      onOpenAlert({
        title: t('web_order_send_notification_text_error'),
        error: true,
      });
    },
  });

  const handleCreateNotification = () => {
    createNotification({
      variables: {
        eventOfferId: id as number,
      },
    });
  };

  if (orderStatus === Event_Offer_Status_Enum.New) {
    return (
      <>
        <Button
          sx={{ maxWidth: 'fit-content', height: '32px' }}
          onClick={handleAnswer}
          disabled={!isOrderConfirmAccess}
        >
          {t('web_action_answer')}
        </Button>
        <Button
          variant={'visible'}
          onClick={handleRejectedOrder}
          disabled={!isOrderRejectAccess}
        >
          {t('web_action_reject')}
        </Button>
      </>
    );
  } else if (orderStatus === Event_Offer_Status_Enum.Sended) {
    return (
      <>
        <Button
          variant={'visible'}
          onClick={handleRejectedOrder}
          disabled={!isOrderRejectAccess}
        >
          {t('web_action_reject')}
        </Button>
      </>
    );
  } else if (orderStatus === Event_Offer_Status_Enum.Active) {
    return (
      <>
        {!eventTookPlace ? (
          <Button
            variant={'tab'}
            sx={{ maxWidth: 'fit-content' }}
            onClick={handleCreateNotification}
          >
            {t('web_action_reminder')}
          </Button>
        ) : (
          <Button
            sx={{ maxWidth: 'fit-content', height: '32px' }}
            onClick={handleCompletedSuccessfully}
            disabled={!isOrderConfirmAccess}
          >
            {t('web_order_completed_successfully')}
          </Button>
        )}
        <CustomActionMenu options={actionOptions} />
      </>
    );
  } else if (orderStatus === Event_Offer_Status_Enum.DeclinedByRestaurant) {
    return (
      <RejectedLabelCSS>
        <Typography variant={'sm575'} lineHeight={'15px'}>
          {`${t('web_orders_status_rejected_restaurant')}: ${comment}`}
        </Typography>
      </RejectedLabelCSS>
    );
  } else if (orderStatus === Event_Offer_Status_Enum.DeclinedByUser) {
    return (
      <RejectedLabelCSS>
        <Typography variant={'sm575'} lineHeight={'15px'}>
          {`${t('web_orders_status_rejected_client')}: ${comment}`}
        </Typography>
      </RejectedLabelCSS>
    );
  } else if (orderStatus === Event_Offer_Status_Enum.SuccessfullyClosed) {
    return (
      <PointStatus
        status={'success'}
        title={
          !isHasReview
            ? t('web_orders_status_completed_empty_grade')
            : `${t('web_orders_status_completed_with_grade')} - ${
                review.avgRating
              }`
        }
      />
    );
  } else if (orderStatus === Event_Offer_Status_Enum.UnsuccessfullyClosed) {
    return <PointStatus status={'error'} title={comment as string} />;
  } else {
    return <></>;
  }
};
