import React from 'react';
import { Box, Snackbar, Typography } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useAlertContext } from '../../../context';
import {
  CloseChipIcon,
  CloseDropIcon,
  CloseModalIcon,
  DoneIcon,
} from '../../Icons/index';
import { palette } from '../../../theme/foundations/index';
import { ErrorIconCSS } from './styles';

export const SnackbarCustom = () => {
  const { open, onCloseAlert, content } = useAlertContext();
  const { error, title, subTitle } = content || {};

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
  });

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        width: 'fit-content',
        '.MuiPaper-root': {
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          minHeight: '48px',
          padding: '12px 20px',
          borderRadius: '8px',
          '& .MuiAlert-icon': { padding: 0, margin: 0 },
          '& .MuiAlert-message': { padding: 0, margin: 0 },
          '& .MuiAlert-action': {
            display: 'none',
          },
        },
      }}
      open={open}
      autoHideDuration={5000}
      onClose={onCloseAlert}
    >
      <Alert
        onClose={onCloseAlert}
        severity={error ? 'error' : 'success'}
        icon={
          !error ? (
            <DoneIcon />
          ) : (
            <ErrorIconCSS>
              <CloseModalIcon size={'13px'} color={palette.white} />
            </ErrorIconCSS>
          )
        }
        sx={{
          width: 'fit-content',
          maxWidth: '600px',
          background: palette.white,
          boxShadow: '0px 5px 16px rgba(33, 52, 103, 0.1)',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {title && <Typography variant={'sm600'}>{title}</Typography>}
          {subTitle && <Typography variant={'md500'}>{subTitle}</Typography>}
        </Box>
      </Alert>
    </Snackbar>
  );
};
