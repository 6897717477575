import React, { useState } from 'react';
import { MainLayout, OrdersMain, PageTitleBlock } from '../../components';
import { TabsCustom } from '../../components/TabsCustom/TabsCustom';
import { Box } from '@mui/material';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { useOptionsTabsToOrdersPage } from '../../hooks';

export const OrdersPage = () => {
  const [tabNum, setTabNum] = useState('1');
  const { t } = useTranslatesContext();
  const { optionsTabs } = useOptionsTabsToOrdersPage();
  const handleTabs = (val: string) => {
    setTabNum(val);
  };

  return (
    <MainLayout>
      <PageTitleBlock title={t('web_orders_title')} />
      <Box mb={'20px'} display={'flex'}>
        <TabsCustom
          options={optionsTabs}
          tabNum={tabNum}
          onClick={handleTabs}
        />
      </Box>
      <OrdersMain tabNum={tabNum} />
    </MainLayout>
  );
};
