import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { EyeIcon, EyeOffIcon } from '../../Icons/index';
import { IGeneralTFRHFProps } from './types';

const TextFieldCustom = ({
  name,
  label,
  errorMessage,
  errors,
  minRows,
  multiline,
  readOnly,
  size,
  type,
  mb,
  sx,
  register,
  maxRows,
}: IGeneralTFRHFProps) => {
  const [viewPassword, setViewPassword] = useState(false);

  return (
    <Box mb={mb} sx={sx}>
      {label || errorMessage ? (
        <Box mb='8px' display={'flex'}>
          {label ? (
            <label htmlFor={name}>
              <Typography variant='body1'>{label}</Typography>
            </label>
          ) : null}
          {errors ? (
            <Typography ml={'auto'} variant='error'>
              {String(errorMessage)}
            </Typography>
          ) : null}
        </Box>
      ) : null}
      <TextField
        size={size}
        error={errors}
        fullWidth
        {...register}
        multiline={multiline}
        minRows={minRows}
        maxRows={maxRows}
        InputProps={{
          readOnly: readOnly,
          endAdornment: (
            <InputAdornment position='start'>
              {type === 'password' ? (
                <IconButton
                  onClick={() => {
                    setViewPassword(!viewPassword);
                  }}
                >
                  {!viewPassword ? <EyeIcon /> : <EyeOffIcon />}
                </IconButton>
              ) : null}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export const TextFieldRHF = ({ name, ...rest }: IGeneralTFRHFProps) => {
  const { formState, register } = useFormContext();
  const { errors } = formState;

  return (
    <TextFieldCustom
      register={{ ...register(name) }}
      name={name}
      errors={!!errors[name]}
      errorMessage={errors[name]?.message}
      {...rest}
    />
  );
};
