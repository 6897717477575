import { BackButton } from '../../components/UI/BackButton/BackButton';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { Content, Wrapper } from './styles';
import './styles.css';

export const RegistrationOffer = () => {
  const { t } = useTranslatesContext();
  return (
    <Wrapper>
      <Content dangerouslySetInnerHTML={{ __html: t('web_reg_offer_content') }}>
        {/* <h1>Оферта</h1>
        <h2>1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</h2>
        <p>
          Ламповый бар в самом центре города. Мы заботимся о вашей безопасности
          и комфорте и дезинфицируем все поверхности, дезинфектором на
          безаллергенной основе.
        </p>
        <h2>2. ОБЩИЕ ПОЛОЖЕНИЯ</h2>
        <p>
          2.1. Приведенная ниже информация является официальным предложением
          (офертой) любому физическому лицу (далее - Покупатель) заключить
          договор купли-продажи товаров. Указанный договор является публичным,
          т. е. согласно статье 633 Гражданского кодекса его условия одинаковы
          для всех покупателей. 2.2. Согласно статье 642 Гражданского Кодекса
          полным и безоговорочным принятием условий данного предложения
          (оферты), что подтверждает заключение Договора купли-продажи товаров
          на предложенных ниже условиях, является факт оформления и
          подтверждения заказа. 2.4. Оформлением Заказа Покупатель подтверждает
          согласования и безусловное принятие им условий настоящего предложения
          (оферты). 2.5. Заключая Договор (то есть акцептуя условия настоящего
          Предложения (Оферты) путем оформления Заказа), Покупатель подтверждает
          следующее:
        </p>
        <ul>
          <li>
            Покупатель целиком и полностью ознакомлен, и согласен с условиями
            настоящего предложения (оферты);
          </li>
          <li>
            Он дает разрешение на сбор, обработку и передачу персональных данных
            на условиях, определенных ниже в Предостережении относительно сбора,
            обработки и передачи персональных данных разрешение на обработку
            персональных данных действует в течение всего срока действия
            Договора, а также в течение неограниченного срока после окончания
            его действия. Кроме этого, заключением Договора Заказчик
            подтверждает, что он уведомлен (без дополнительного уведомления) о
            правах, установленных Законом Украины "О защите персональных
            данных", о целях сбора данных, а также о том, что его персональные
            данные передаются Продавцу с целью возможности выполнения условий
            настоящего Договора, возможности проведения взаиморасчетов, а также
            для получения счетов, актов и других документов. Заказчик также
            соглашается с тем, что Продавец имеет право предоставлять доступ и
            передавать его персональные данные третьим лицам без каких-либо
            дополнительных уведомлений Заказчика, не изменяя при этом цель
            обработки персональных данных. Объем прав Заказчика, как субъекта
            персональных данных согласно Закону Украины "О защите персональных
            данных" ему известен и понятен.
          </li>
        </ul>
        <h2>3. ЦЕНА ТОВАРА</h2>
        <p>
          3.1. Цена на каждую позицию Товара указана на сайте Интернет-магазина.
        </p>
        <p>
          3.2. Продавец имеет право в одностороннем порядке изменить цену на
          любую позицию Товара.
        </p>
        <p>
          3.3. В случае изменения цены на заказанный Товар Продавец обязуется
          проинформировать Покупателя об изменении цены Товара.
        </p>
        <p>
          3.4. Покупатель вправе подтвердить либо аннулировать Заказ на
          приобретение Товара, если цена изменена Продавцом после оформления
          Заказа.
        </p>
        <p>
          3.5. Изменение Продавцом цены на оплаченный Покупателем Товар не
          допускается.
        </p>
        <p>
          3.6. Продавец указывает стоимость доставки Товара на сайте
          Интернет-магазина либо сообщает Покупателю при оформлении заказа
          Оператором.s
        </p>
        <p>
          3.7. Обязательства Покупателя по оплате Товара считаются исполненными
          с момента поступления Продавцу денежных средств.{' '}
        </p>
        <p>
          3.8. Расчеты между Продавцом и Покупателем за Товар производятся
          способами, указанными на сайте Интернет-магазина в разделе «Доставка и
          оплата».
        </p> */}
      </Content>
    </Wrapper>
  );
};
