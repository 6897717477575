import * as yup from 'yup';
import { MESSAGES } from '../messages';

export const translationsPageSchema = yup.object().shape({
  data: yup.array().of(
    yup.object().shape({
      fieldDataEN: yup
        .mixed()
        .test('', MESSAGES.REQUIRE_MESSAGE, (val: any) => {
          if (typeof val === 'string') {
            return true;
          } else if (val === null) {
            return false;
          }
          return true;
        }),
    }),
  ),
});
