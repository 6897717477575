import { gql } from '@apollo/client';

export const CUISINES = gql`
  query cuisines {
    cuisines {
      id
      title
      image {
        url
      }
    }
  }
`;
