import React from 'react';
import { IconButton, TableCell, TableRow } from '@mui/material';
import { TextFieldRHF } from '../../UI/TextFieldRHF/TextFieldRHF';
import { CloseModalIcon } from '../../Icons/index';
import { ITableRowCustomProps } from './types';
import { DropzoneRHF } from '../..';

export const TableRowCustom = ({
  title,
  name,
  onClick,
  type,
  isError,
}: ITableRowCustomProps) => {
  const isTypeText = type === 'text';
  const handleRemove = () => {
    onClick(title);
  };
  return (
    <TableRow>
      <TableCell scope='row' size={'small'}>
        {title}
      </TableCell>
      <TableCell size={'small'} align='left'>
        {type === 'image' ? (
          <DropzoneRHF name={`${name}EN`} isSmallLarge hasError={isError} />
        ) : (
          <TextFieldRHF
            name={`${name}EN`}
            size={'small'}
            multiline={isTypeText}
            minRows={isTypeText ? 2 : 1}
            maxRows={isTypeText ? 10 : 1}
            errors={isError}
          />
        )}
      </TableCell>
      {/*<TableCell size={'small'} align='left'>*/}
      {/*  {type === 'image' ? (*/}
      {/*    <DropzoneRHF name={`${name}UA`} isSmallLarge />*/}
      {/*  ) : (*/}
      {/*    <TextFieldRHF*/}
      {/*      name={`${name}UA`}*/}
      {/*      size={'small'}*/}
      {/*      multiline={type === 'text'}*/}
      {/*      minRows={type === 'text' ? 2 : 1}*/}
      {/*    />*/}
      {/*  )}*/}
      {/*</TableCell>*/}
      {/*<TableCell size={'small'} align='left'>*/}
      {/*  {type === 'image' ? (*/}
      {/*    <DropzoneRHF name={`${name}FR`} isSmallLarge />*/}
      {/*  ) : (*/}
      {/*    <TextFieldRHF*/}
      {/*      name={`${name}FR`}*/}
      {/*      size={'small'}*/}
      {/*      multiline={type === 'text'}*/}
      {/*      minRows={type === 'text' ? 2 : 1}*/}
      {/*    />*/}
      {/*  )}*/}
      {/*</TableCell>*/}
      <TableCell size={'small'} align={'center'}>
        <IconButton onClick={handleRemove}>
          <CloseModalIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
