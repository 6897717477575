import React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';
import { ITableListHeaderProps } from './types';
import { useTranslatesContext } from '../../../context/TranslatesContext';

export const TableListHeader = ({ headCells }: ITableListHeaderProps) => {
  const { t } = useTranslatesContext();
  return (
    <TableHead>
      <TableRow>
        {headCells.map((cell, index) => {
          return (
            <TableCell
              size={'medium'}
              align={cell.align}
              key={index}
              sx={cell?.sx}
            >
              {t(cell.label)}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
