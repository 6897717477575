import { useEffect } from 'react';
import {
  ApplicationList,
  BackButton,
  MainLayout,
  PageTitleBlock,
  RequestHandler,
} from '../../components';
import { Box } from '@mui/material';
import {
  RegistrationApplication,
  useRegistrationApplicationsLazyQuery,
} from '../../graphql/generated/graphql';
import { usePagination } from '../../hooks';
import { client } from '../../graphql/client';

export const ApplicationsPage = () => {
  const [query, { data: queryData, loading, error, fetchMore }] =
    useRegistrationApplicationsLazyQuery({
      fetchPolicy: 'network-only',
    });

  const {
    paginationOptions,
    handleLoadMore: paginationHandleLoadMore,
    handleChangePage,
  } = usePagination();

  const handleLoadMore = () => {
    paginationHandleLoadMore(fetchMore);
  };

  const data = queryData?.registrationApplications.rows;
  const allItemsCount = queryData?.registrationApplications.count || 0;

  const currentItemsCount =
    paginationOptions.itemsCount * paginationOptions.page >= allItemsCount
      ? allItemsCount
      : paginationOptions.itemsCount * paginationOptions.page;

  const pagesCount = Math.ceil(allItemsCount / paginationOptions.itemsCount);

  const getApplicationsQuery = (limit?: number, offset?: number) => {
    limit = limit ?? paginationOptions.itemsCount;
    offset = offset ?? paginationOptions.offset;

    client.cache.evict({ fieldName: 'registrationApplications' });

    query({
      variables: {
        options: {
          limit,
          offset,
        },
      },
    });
  };

  useEffect(() => {
    getApplicationsQuery();
  }, [paginationOptions.page]);

  // return <MainLayout>ApplicationsPage</MainLayout>;
  return (
    <MainLayout>
      <Box mb={'15px'}>
        <BackButton />
      </Box>
      <PageTitleBlock title={'Список заявок'} />
      <RequestHandler loading={loading} error={error} height='50vh'>
        <ApplicationList
          data={data as Array<RegistrationApplication> | undefined}
          handleLoadMore={handleLoadMore}
          handleChangePage={handleChangePage}
          pagesCount={pagesCount}
          page={
            paginationOptions.page > pagesCount
              ? pagesCount
              : paginationOptions.page
          }
          allItemsCount={allItemsCount}
          currentItemsCount={currentItemsCount}
        />
      </RequestHandler>
    </MainLayout>
  );
};
