import React from 'react';
import { ButtonCSS, Wrapper } from './styles';
import { TabsCustomProps } from './types';

export const TabsCustom = ({
  options,
  onClick = () => {},
  tabNum = '1',
}: TabsCustomProps) => {
  return (
    <Wrapper>
      {options.map((option) => {
        return (
          <ButtonCSS
            key={option.id}
            variant={'tab'}
            target={tabNum === option.id ? 'true' : 'false'}
            onClick={() => onClick(option.id)}
          >
            {option.label}
          </ButtonCSS>
        );
      })}
    </Wrapper>
  );
};
