export enum TABLE_SORT_DIRECTIONS {
  ASC = 'asc',
  DESC = 'desc',
}

export enum TABLE_SORT_FIELDS {
  REQUESTS = 'requestCount',
  CLICKS = 'clickCount',
  VIEWS = 'viewCount',
}

export enum COLUMN_IDS {
  ID = 'id',
  TITLE = 'title',
  LOCATION = 'location',

  KITCHENS = 'kitchens',
  VIEWS = 'viewCount',
  CLICKS = 'clickCount',
  REQUESTS = 'requestCount',
}
