export const DisappointedIcon = () => {
  return (
    <svg
      width='21'
      height='20'
      viewBox='0 0 21 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='10.5' cy='10' r='10' fill='#EECA0B' />
      <circle cx='6.5' cy='7' r='1' fill='black' />
      <circle cx='14.5' cy='7' r='1' fill='black' />
      <path
        d='M6.5 15C6.5 15 7.5 12 10.5 12C13.5 12 14.5 15 14.5 15'
        stroke='black'
        stroke-linecap='round'
      />
    </svg>
  );
};
