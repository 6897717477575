import {
  ApplicationItemInfo,
  BackButton,
  MainLayout,
  PageTitleBlock,
  RequestHandler,
} from '../../components/index';
import { Box } from '@mui/material';
import { Content, ContentRowCSS } from './styles';
import { useTranslatesContext } from '../../context/TranslatesContext';

import { useParams } from 'react-router-dom';
import { useRegistrationApplicationQuery } from '../../graphql/generated/graphql';
import { getIntlPhoneNumber } from '../../utils/index';

export const Application = () => {
  const { id } = useParams();
  const { t } = useTranslatesContext();

  const { data, loading, error } = useRegistrationApplicationQuery({
    variables: { id: Number(id) },
    fetchPolicy: 'network-only',
  });

  const applicationInfo = data?.registrationApplication;

  const title = applicationInfo?.restaurantName;
  const name = applicationInfo?.name;
  const email = applicationInfo?.email;
  const { intlPhone } = getIntlPhoneNumber(applicationInfo?.phone);
  const country = applicationInfo?.country;
  const city = applicationInfo?.city;

  return (
    <MainLayout>
      <Box mb={'15px'}>
        <BackButton />
      </Box>
      <PageTitleBlock title={t('web_app_page_info_on_title')} />
      <RequestHandler loading={loading} error={error} height='50vh'>
        <Content>
          <ApplicationItemInfo
            title={t('web_app_page_label_title')}
            subTitle={title}
          />
          <ApplicationItemInfo
            title={t('web_app_page_label_name')}
            subTitle={name}
          />
          <ContentRowCSS>
            <ApplicationItemInfo
              title={t('web_app_page_label_email')}
              subTitle={email}
            />
            <ApplicationItemInfo
              title={t('web_app_page_label_phone')}
              subTitle={intlPhone}
            />
          </ContentRowCSS>
          <ApplicationItemInfo
            title={t('web_app_page_label_country')}
            subTitle={country}
          />
          <ApplicationItemInfo
            title={t('web_app_page_label_city')}
            subTitle={city}
          />
        </Content>
      </RequestHandler>
    </MainLayout>
  );
};
