import { gql } from '@apollo/client';

export const MANAGERS = gql`
  query Managers($filter: FindFilter) {
    managers(filter: $filter) {
      id
      email
      firstName
      lastName
      position
      photo {
        id
        width
        height
        mime
        size
        url
      }
      brand {
        id
        isActive
      }
      roles
    }
  }
`;
