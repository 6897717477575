import * as yup from 'yup';
import { MESSAGES } from '../messages';

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email(MESSAGES.INCORRECT_EMAIL)
    .required(MESSAGES.REQUIRE_MESSAGE),
  password: yup
    .string()
    .min(1, MESSAGES.MIN_NUMBER_VALUE('1'))
    .required(MESSAGES.REQUIRE_MESSAGE),
});
