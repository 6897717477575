import { useField, useFormikContext } from 'formik';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import { ChevronBottom } from '../../Icons/index';
import { palette } from '../../../theme/foundations/index';
import { ISelectFormikProps } from './types';
import { Wrapper, LabelCSS, ElementAfterOptionsWrapperCSS } from './styles';
import { useRef } from 'react';

export const SelectFormik = ({
  name,
  options,
  placeholder,
  label,
  sx,
  size = 'medium',
  mt = '0px',
  disabledIndexFrom,
  disabledIndexTo,
  mb = '0px',
  ElementAfterOptions,
}: ISelectFormikProps) => {
  const [field, meta] = useField(name);
  const { handleChange } = useFormikContext();
  const hasError = Boolean(meta.error && meta.touched);
  const ref = useRef<HTMLSelectElement | null>(null);

  const handleClose = () => {
    if (ref.current) {
      ref.current.blur();
    }
  };

  return (
    <Wrapper sx={sx} mt={mt} mb={mb}>
      {label && (
        <LabelCSS>
          {label}
          {hasError ? (
            <Typography ml={'auto'} variant='error'>
              {meta.error}
            </Typography>
          ) : null}
        </LabelCSS>
      )}
      <Select
        displayEmpty
        id={name}
        name={name}
        value={field.value}
        onChange={handleChange}
        error={hasError}
        size={size}
        ref={ref}
        MenuProps={{
          anchorOrigin: {
            vertical: 55,
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          PaperProps: {
            sx: {
              borderRadius: '9px',
              background: palette.white,
              boxShadow: '0px 2px 16px rgba(33, 52, 103, 0.1)',
              maxHeight: '300px',
              '&::-webkit-scrollbar': {
                borderRadius: '2px',
                width: '15px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#E3E0DE',
                borderRadius: '10px',
                border: '5px solid #fff',
              },
              '.MuiButtonBase-root': {
                paddingTop: '15px',
              },
            },
          },
        }}
        {...(placeholder && {
          renderValue: (value: string) => {
            if (!value)
              return (
                <Typography color={palette.input.placeholder}>
                  {placeholder}
                </Typography>
              );
            return options?.find((opt) => opt?.id === value)?.label;
          },
        })}
        IconComponent={({ ...rest }) => {
          return (
            <Box {...rest}>
              <ChevronBottom color={palette.brown500} />
            </Box>
          );
        }}
      >
        {options.map((opt, index: number) => {
          let isDisabled = false;
          if (disabledIndexTo && disabledIndexTo >= index) {
            isDisabled = true;
          }
          if (disabledIndexFrom && disabledIndexFrom <= index) {
            isDisabled = true;
          }

          return (
            <MenuItem key={opt.id} value={opt.id} disabled={isDisabled}>
              {opt.label}
            </MenuItem>
          );
        })}
        {ElementAfterOptions ? (
          <ElementAfterOptionsWrapperCSS onClick={handleClose}>
            <ElementAfterOptions />
          </ElementAfterOptionsWrapperCSS>
        ) : null}
      </Select>
    </Wrapper>
  );
};
