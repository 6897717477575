import React from 'react';
import { Typography } from '@mui/material';
import { ApplicationItemRowCSS } from './styles';

interface IApplicationItemRowProps {
  title?: string;
  subTitle?: string;
  mr?: string;
  mb?: string;
  children?: React.ReactNode;
}

export const ApplicationItemInfo = ({
  title,
  subTitle,
  mr,
  mb,
  children,
}: IApplicationItemRowProps) => {
  return (
    <ApplicationItemRowCSS mr={mr} mb={mb}>
      <Typography variant={'sm350'}>{title}</Typography>
      <Typography variant={'md400'}>{subTitle}</Typography>
      {children}
    </ApplicationItemRowCSS>
  );
};
