import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';

import { TABLE_SORT_DIRECTIONS, TABLE_SORT_FIELDS } from '../../constants';
import { TableListHeaderType } from './types';

export const TableListHeader = ({
  headCells,
  sortInfo,
}: TableListHeaderType) => {
  return (
    <TableHead>
      <TableRow>
        {headCells?.map((column) => {
          const isActiveSortColumn = column.id === sortInfo?.field;
          return (
            <TableCell
              key={column.id}
              // width={column.minWidth}
              sx={{ maxWidth: column.maxWidth }}
            >
              {column.label}

              {column?.handleChangeSort ? (
                <TableSortLabel
                  sx={{ ml: '5px' }}
                  active={isActiveSortColumn}
                  direction={
                    isActiveSortColumn
                      ? sortInfo?.direction
                      : TABLE_SORT_DIRECTIONS.ASC
                  }
                  onClick={() => {
                    column?.handleChangeSort?.(column.id as TABLE_SORT_FIELDS);
                  }}
                />
              ) : null}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
