import React from 'react';

export const EyeIcon = () => {
  return (
    <svg
      width='24'
      height='17'
      viewBox='0 0 24 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.6367 8.84937C14.6367 10.3365 13.4465 11.5241 12.0004 11.5241C10.5542 11.5241 9.36401 10.3365 9.36401 8.84937C9.36401 7.36227 10.5542 6.17468 12.0004 6.17468C13.4465 6.17468 14.6367 7.36227 14.6367 8.84937Z'
        stroke='#3D3835'
        strokeWidth='2'
      />
      <path
        d='M2 8.60144C5.18182 3.24253 13.6364 -4.25996 22 8.60144C18.8182 13.8073 10.3636 21.0954 2 8.60144Z'
        stroke='#3D3835'
        strokeWidth='2'
      />
    </svg>
  );
};
