import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { IWrapperProps } from './types';

export const Wrapper = styled(Box)<IWrapperProps>(({ width = 360, theme }) => ({
  width,
  background: theme?.palette.white,
  borderRadius: '20px',
  overflow: 'hidden',
  paddingTop: 60,
  display: 'flex',
  flexDirection: 'column',
}));
