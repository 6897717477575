import React, { useEffect, useState } from 'react';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { Wrapper } from './styles';

export const ScrollToTopButton = () => {
  const [showBtn, setShowBtn] = useState(false);
  const handleToTop = () => {
    window.scrollTo({ behavior: 'smooth', top: 0, left: 0 });
  };

  const handleScrollY = () => {
    if (window.scrollY >= 200) {
      setShowBtn(true);
    } else {
      setShowBtn(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScrollY);
    return () => window.addEventListener('scroll', handleScrollY);
  }, []);

  return (
    <>
      {showBtn ? (
        <Wrapper onClick={handleToTop}>
          <DoubleArrowIcon
            sx={{ rotate: '-90deg', width: '50px', height: '50px' }}
          />
        </Wrapper>
      ) : null}
    </>
  );
};
