import React, { useContext, useEffect, useState } from 'react';
import { LS } from '../constants/constants';
import { useErrorsStore } from '../graphql/client';
import {
  Manager,
  NewEventOffersCountSubscription,
  useManagerMeLazyQuery,
  useNewEventOffersCountSubscription,
} from '../graphql/generated/graphql';
import { RequestHandler } from '../components';
import { useApolloClient } from '@apollo/client';

type AuthContextType = {
  isAuth: boolean;
  signIn: (user: Manager | null) => void;
  signOut: () => void;
  user: Manager | null;
  subscribeData?: NewEventOffersCountSubscription;
};

export const AuthContext = React.createContext<AuthContextType>({
  isAuth: false,
  signIn: (user) => {},
  signOut: () => {},
  user: null,
});

export function useAuthContext() {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }
  return authContext;
}

type AuthContextProviderType = {
  children: React.ReactNode;
};

export function AuthContextProvider({ children }: AuthContextProviderType) {
  const [isAuth, setIsAuth] = useState(false);
  const [user, setUser] = useState<Manager | null>(null);
  const [isInit, setIsInit] = useState<boolean>(false);
  const error = useErrorsStore();
  const client = useApolloClient();

  const { data: subscribeData } = useNewEventOffersCountSubscription();

  const signIn = (user: Manager | null) => {
    setIsInit(true);
    setIsAuth(true);
    setUser(user);
    client.stop();
    client.clearStore();
  };

  const signOut = () => {
    setIsInit(true);
    setIsAuth(false);
    localStorage.removeItem(LS.TOKEN_BYDE_ADMIN);
    sessionStorage.removeItem(LS.TOKEN_BYDE_ADMIN);
    client.clearStore(); // for clearing cache in apollo
  };

  const [currentUserQuery, { loading, error: userError }] =
    useManagerMeLazyQuery({
      onCompleted: (data) => {
        if (data.managerMe) {
          signIn(data.managerMe as Manager);
        }
      },
      onError: () => {
        signOut();
      },
    });

  useEffect(() => {
    if (
      error &&
      (error?.error as any)?.extensions?.code === 'UNAUTHENTICATED'
    ) {
      signOut();
    }
  }, [error, (error?.error as any)?.extensions?.code]);

  useEffect(() => {
    const token =
      localStorage.getItem(LS.TOKEN_BYDE_ADMIN) ||
      sessionStorage.getItem(LS.TOKEN_BYDE_ADMIN);

    if (token) {
      currentUserQuery();
    } else {
      setIsInit(true);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuth: isAuth,
        user: user,
        signIn: signIn,
        signOut: signOut,
        subscribeData: subscribeData,
      }}
    >
      <RequestHandler loading={loading || !isInit} error={userError}>
        {children}
      </RequestHandler>
    </AuthContext.Provider>
  );
}
