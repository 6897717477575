import { palette } from '../../theme/foundations/index';
import { Box, Typography } from '@mui/material';
import { MenuIcon, StarIcon } from '../Icons/index';
import {
  AddressCSS,
  HeadCSS,
  InnerHeadCSS,
  PointCSS,
  RatingCSS,
  TitleBlockCSS,
  Wrapper,
} from './styles';

import { IRestaurantItemProps } from './types';
import { CustomActionMenu } from '../CustomActionMenu/CustomActionMenu';
import { useTranslatesContext } from '../../context/TranslatesContext';
import {
  useRemoveRestaurantMutation,
  useSwitchRestaurantClosedStatusMutation,
} from '../../graphql/generated/graphql';
import { useAlertContext } from '../../context/index';
import { useNavigate } from 'react-router-dom';
import { useAccesses } from '../../hooks/index';

export const RestaurantItem = ({
  id,
  title,
  close,
  ratingIn,
  ratingOut,
  address,
  description,
  temporarilyClosedText,
  brandId,
}: IRestaurantItemProps) => {
  const { t } = useTranslatesContext();
  const { onOpenAlert } = useAlertContext();
  const navigate = useNavigate();
  const { isAddRestaurantAccess, isRemoveRestaurantAccess } = useAccesses();

  const [switchRestaurantClosedStatus] =
    useSwitchRestaurantClosedStatusMutation({
      onCompleted: () => {
        onOpenAlert({ title: t('web_alert_update_restaurant') });
      },
    });

  const [removeRestaurant] = useRemoveRestaurantMutation({
    update(cache, { data }) {
      if (data?.removeRestaurant) {
        cache?.evict({
          id: `Restaurant:${id}`,
        });
        cache?.gc();
      }
    },
    onCompleted: () => {
      onOpenAlert({ title: t('web_alert_delete_restaurant') });
    },
  });

  const handleEdit = () => {
    navigate(`/restaurants/edit/${id}/?brandId=${brandId}`);
  };

  const handleGoToRestaurant = () => {
    navigate(`/restaurant/${id}`);
  };

  const handleClosed = () => {
    switchRestaurantClosedStatus({
      variables: { switchRestaurantClosedStatusId: Number(id) },
    });
  };

  const handleDeativate = () => {
    removeRestaurant({
      variables: {
        removeRestaurantId: Number(id),
      },
    });
  };

  const actionOptions = [
    {
      label: 'web_action_edit',
      action: handleEdit,
      color: '',
      disabled: !isAddRestaurantAccess,
    },
    {
      label: !close ? 'web_action_temporarily_close' : 'web_action_open',
      action: handleClosed,
      color: '',
      disabled: !isAddRestaurantAccess,
    },
    {
      label: 'web_action_deactivate',
      action: handleDeativate,
      color: palette.error.main,
      disabled: !isRemoveRestaurantAccess,
    },
  ];

  return (
    <Wrapper>
      <HeadCSS>
        <TitleBlockCSS onClick={handleGoToRestaurant}>
          <Typography variant={'md600'}>{title}</Typography>
        </TitleBlockCSS>
        {close && (
          <InnerHeadCSS>
            <PointCSS />
            <Typography
              variant={'sm200'}
              color={palette.orange500}
              whiteSpace={'nowrap'}
            >
              {temporarilyClosedText || t('web_restaraunts_temporarily_close')}
            </Typography>
          </InnerHeadCSS>
        )}

        <CustomActionMenu options={actionOptions} />
      </HeadCSS>
      {!!ratingIn && (
        <RatingCSS>
          <StarIcon />
          <Typography>
            {ratingIn}{' '}
            {`${ratingOut} ${
              ratingOut ? '(' + t('web_restaraunts_estimates') + ')' : ''
            }`}
          </Typography>
        </RatingCSS>
      )}
      <AddressCSS>
        <Box display={'flex'}>
          <MenuIcon size={'20'} />
        </Box>
        <Typography>{address}</Typography>
      </AddressCSS>
      <Box>
        <Typography variant={'sm500'} color={'#11142D'}>
          {description}
        </Typography>
      </Box>
    </Wrapper>
  );
};
