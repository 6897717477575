import { Box, styled } from '@mui/material';

export const FlexRow = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const FlexColumn = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
});
