import { Form, Formik } from 'formik';
import { Box } from '@mui/material';
import { OptionsCaseLayout, TextFieldFormik } from '../../components';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { ButtonSaveChangesCSS } from './styles';
import { ISettingsFiltersFormik, ISettingsFiltersFormProps } from './types';
import { getOption } from '../../utils';
import { editFiltersSchema } from '../../validation/schema/editFilters.schema';
import { useAlertContext } from '../../context';
import { useBulkUpdateSettingMutation } from '../../graphql/generated/graphql';
import { SETTINGS } from '../../graphql/queries/settings.gql';
import { GENERAL_SETTINGS } from '../../constants/constants';

export const SettingsFiltersForm = ({
  filtersOption,
}: ISettingsFiltersFormProps) => {
  const { t } = useTranslatesContext();
  const { onOpenAlert } = useAlertContext();

  const [updateSetting] = useBulkUpdateSettingMutation({
    onCompleted: () => onOpenAlert({ title: t('web_setting_filter_update') }),
    onError: () =>
      onOpenAlert({
        title: t('web_setting_occurred_error_update'),
        error: true,
      }),
    refetchQueries: [SETTINGS],
  });

  const countOfGuestFrom = getOption(
    GENERAL_SETTINGS.filterOffersAmountPersonsFrom,
    filtersOption,
  );
  const countOfGuestTo = getOption(
    GENERAL_SETTINGS.filterOffersAmountPersonsTo,
    filtersOption,
  );

  const handleSubmit = (values: ISettingsFiltersFormik) => {
    updateSetting({
      variables: {
        settingsData: {
          list: [
            {
              key: 'filter_offers_amount_persons_from',
              value: String(values.countOfGuestFrom),
            },
            {
              key: 'filter_offers_amount_persons_to',
              value: String(values.countOfGuestTo),
            },
          ],
        },
      },
    });
  };

  return (
    <OptionsCaseLayout title={t('web_setting_case_label_filter_search')}>
      <Formik<ISettingsFiltersFormik>
        initialValues={{
          countOfGuestFrom: Number(countOfGuestFrom?.value) || '',
          countOfGuestTo: Number(countOfGuestTo?.value) || '',
        }}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validationSchema={editFiltersSchema}
      >
        {({ dirty }) => {
          return (
            <Form>
              <Box sx={{ display: 'flex', gap: '16px', marginBottom: '16px' }}>
                <TextFieldFormik
                  name={'countOfGuestFrom'}
                  label={t('web_setting_count_person_from')}
                  size={'large'}
                  width={'230px'}
                  type={'number'}
                />
                <TextFieldFormik
                  name={'countOfGuestTo'}
                  label={t('web_setting_count_person_to')}
                  size={'large'}
                  width={'230px'}
                  type={'number'}
                />
              </Box>
              <ButtonSaveChangesCSS
                size={'large'}
                type={'submit'}
                disabled={!dirty}
              >
                {t('web_action_save')}
              </ButtonSaveChangesCSS>
            </Form>
          );
        }}
      </Formik>
    </OptionsCaseLayout>
  );
};
