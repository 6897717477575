import { Box, Button, Typography } from '@mui/material';
import { ButtonGroupCSS, Wrapper } from './styles';
import { Form, Formik } from 'formik';
import { TextFieldFormik } from '../../UI/TextFieldFormik/TextFieldFormik';
import { unsuccessfullyClosedModalSchema } from '../../../validation/index';
import { IFormikProps } from './types';
import {
  Event_Offer_Status_Enum,
  useUpdateEventOfferMutation,
} from '../../../graphql/generated/graphql';
import { useAlertContext, useModalContext } from '../../../context/index';
import { BRAND_EVENT_OFFERS } from '../../../graphql/queries/brandEventOffers.gql';
import { useTranslatesContext } from '../../../context/TranslatesContext';

export const UnsuccessfullyClosedModal = () => {
  const { infoModal, onCloseModal } = useModalContext();
  const { onOpenAlert } = useAlertContext();

  const { t } = useTranslatesContext();

  const [updateEventOffer] = useUpdateEventOfferMutation({
    refetchQueries: [BRAND_EVENT_OFFERS],
    onCompleted: () => {
      onOpenAlert({ title: t('web_completed_modal_alert_title') });
      onCloseModal();
    },
  });
  const id = infoModal.content.id;

  const handleSubmit = (values: IFormikProps) => {
    updateEventOffer({
      variables: {
        updates: {
          id: id,
          status: Event_Offer_Status_Enum.UnsuccessfullyClosed,
          comment: values.comment,
        },
      },
    });
  };

  return (
    <Wrapper>
      <Box mb={'26px'}>
        <Typography variant={'big700'}>
          {t('web_unsucces_modal_order_title')}
        </Typography>
      </Box>
      <Formik<IFormikProps>
        initialValues={{
          comment: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={unsuccessfullyClosedModalSchema}
      >
        {({ dirty }) => {
          return (
            <Form>
              <ButtonGroupCSS>
                <TextFieldFormik
                  label={t('web_web_unsucces_modal_order_comment_label')}
                  name={'comment'}
                  multiline
                  minRows={7}
                />

                <Button type={'submit'} disabled={!dirty}>
                  {t('web_unsucces_modal_action_confirm')}
                </Button>
              </ButtonGroupCSS>
            </Form>
          );
        }}
      </Formik>
    </Wrapper>
  );
};
