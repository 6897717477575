import {
  useAdvicesQuery,
  useCitiesQuery,
  useCuisinesQuery,
  useEventThemesQuery,
  useParsingSchedulerSettingsQuery,
  useSettingsQuery,
} from '../graphql/generated/graphql';
import { GENERAL_SETTINGS } from '../constants/constants';

export const useOptions = () => {
  const { data: cuisinesData } = useCuisinesQuery();
  const { data: eventThemeData } = useEventThemesQuery();
  const { data: citiesData } = useCitiesQuery();
  const { data: advicesData } = useAdvicesQuery();
  const { data: settingsData } = useSettingsQuery();
  const { data: parsingData } = useParsingSchedulerSettingsQuery();

  const kitchenOptions = !cuisinesData
    ? []
    : cuisinesData.cuisines.map((item) => {
        return {
          id: String(item.id),
          label: item.title,
          imgUrl: item?.image?.url,
        };
      });

  const eventThemeOption = !eventThemeData
    ? []
    : eventThemeData.eventThemes.map((item) => {
        return {
          id: String(item.id),
          label: item.title,
          imgUrl: item?.image?.url,
          isMain: item.isFavorite,
        };
      });

  const citiesOption = !citiesData
    ? []
    : citiesData.cities.map((item) => {
        return {
          id: String(item.id),
          label: item.title,
          latitude: Number(item.latitude),
          longitude: Number(item.longitude),
          parserLastParsedDate: item.parserLastParsedDate,
          parserCode: item.parserCode,
          parserStatus: item.parserStatus,
          countryCode: item.countryCode,
        };
      });

  const advicesOption = !advicesData
    ? []
    : advicesData.advices.map((item) => {
        return {
          id: String(item.id),
          label: item.text,
        };
      });

  const adviceHourBefore = settingsData?.settings.find(
    (item) => item.key === GENERAL_SETTINGS.hoursBeforeAdviceAppears,
  );

  const contactPhone = settingsData?.settings.find(
    (item) => item.key === GENERAL_SETTINGS.contactPhone,
  );

  const budgetOption = settingsData?.settings.filter((item) =>
    [
      GENERAL_SETTINGS.basicBudgetPerPerson as string,
      GENERAL_SETTINGS.minBudgetPerPerson as string,
      GENERAL_SETTINGS.maxBudgetPerPerson as string,
    ].includes(item.key),
  );

  const guestOption = settingsData?.settings.filter((item) =>
    [
      GENERAL_SETTINGS.basicEventAmountPersons as string,
      GENERAL_SETTINGS.minEventAmountPersons as string,
      GENERAL_SETTINGS.maxEventAmountPersons as string,
    ].includes(item.key),
  );

  const filtersOption = settingsData?.settings.filter((item) =>
    [
      GENERAL_SETTINGS.filterOffersAmountPersonsFrom as string,
      GENERAL_SETTINGS.filterOffersAmountPersonsTo as string,
    ].includes(item.key),
  );

  const parsingOptions = parsingData?.parsingSchedulerSettings?.schedule;

  return {
    kitchenOptions,
    eventThemeOption,
    citiesOption,
    advicesOption,
    adviceHourBefore,
    contactPhone,
    guestOption,
    filtersOption,
    budgetOption,
    parsingOptions,
  };
};
