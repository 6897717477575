import React from 'react';
import {
  BackButton,
  MainLayout,
  OffersDishList,
  PageTitleBlock,
  RequestHandler,
  ScrollToTopButton,
} from '../../components/index';
import { Box, Typography } from '@mui/material';
import { palette } from '../../theme/foundations/index';
import {
  ContentCSS,
  ContentItemCSS,
  ImageWrapperCSS,
  PhotoBlockCSS,
} from './styles';
import {
  Dish,
  EventTheme,
  useOfferQuery,
} from '../../graphql/generated/graphql';
import { useParams } from 'react-router-dom';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { transformWorkTimeString } from '../../utils/index';

type Validate = {
  workDay: string;
  from: string;
  to: string;
};

export const OffersSearchedNowInfoPage = () => {
  const { id: offerId } = useParams();
  const { t } = useTranslatesContext();

  const { data, loading, error } = useOfferQuery({
    variables: { offerId: Number(offerId) },
    skip: !offerId,
  });

  const cuisines = data?.offer.cuisines
    .map((cuisine) => cuisine.title)
    .join(', ');

  const validatePeriod =
    data && data.offer.scheduleType !== 'STATIC'
      ? [...data.offer.workDays]
          .filter((item) => item.isActive)
          .map((workDay) => {
            return {
              workDay: t(`web_week_day_${workDay.day}`),
              from: transformWorkTimeString(workDay.from),
              to: transformWorkTimeString(workDay.to),
            } as Validate;
          })
      : `${t('web_offer_schedule_from')} ${transformWorkTimeString(
          Number(data?.offer.workDays[0].from),
        )} ${t('web_offer_schedule_to')} ${transformWorkTimeString(
          Number(data?.offer.workDays[0].to),
        )}`;

  const dishesData = data?.offer.offerDishes.map((dishes) => {
    return dishes.dish;
  });

  const offerDishes = data?.offer.offerDishes.map((dishes) => {
    return { id: dishes.dishId, count: dishes.quantity };
  });

  const getTitlePage = (arr?: EventTheme[]) => {
    if (!arr) return '';
    return arr.map((el) => el.title).join(', ');
  };

  return (
    <MainLayout>
      <Box mb={'15px'}>
        <BackButton />
      </Box>
      <PageTitleBlock
        title={getTitlePage(data?.offer.eventThemes as EventTheme[])}
      />
      <RequestHandler loading={loading} error={error} height={'100%'}>
        <ContentCSS>
          <ContentItem
            title={t('web_offers_search_now_page_title')}
            subTitle={data?.offer.name}
          />
          <ContentItem
            title={t('web_offers_search_now_page_desc')}
            subTitle={data?.offer.description}
          />
          <ContentItem
            title={t('web_offers_search_now_page_cuisine')}
            subTitle={cuisines}
          />
          <ContentItem
            title={t('web_offers_search_now_page_validate_period')}
            subTitle={validatePeriod}
          />
          <ContentItem
            title={t('web_offers_search_now_page_price_per_person')}
            subTitle={`${data?.offer.budgetPerPerson}$`}
          />
        </ContentCSS>
        <PhotoBlockCSS>
          <Typography mb={'12px'}>
            {t('web_offers_search_now_page_photo')}
          </Typography>
          <ImageWrapperCSS>
            <img alt={'i'} src={data?.offer.image?.url} />
          </ImageWrapperCSS>
        </PhotoBlockCSS>
        <OffersDishList
          title={t('web_offers_search_now_page_dish')}
          offerDishes={offerDishes}
          data={dishesData as Dish[]}
        />
        <ScrollToTopButton />
      </RequestHandler>
    </MainLayout>
  );
};

interface IContentItemProps {
  title?: string;
  subTitle?: string | Validate[];
}

const ContentItem = ({ title, subTitle }: IContentItemProps) => {
  const { t } = useTranslatesContext();
  return (
    <ContentItemCSS>
      <Typography color={palette.gray500}>{title}</Typography>
      {Array.isArray(subTitle) ? (
        subTitle.map((item) => (
          <Typography key={item.workDay}>
            {item.workDay} {t('web_offer_schedule_from_short')} {item.from}{' '}
            {t('web_offer_schedule_to')} {item.to}
          </Typography>
        ))
      ) : (
        <Typography>{subTitle}</Typography>
      )}
    </ContentItemCSS>
  );
};
