import { Box, styled } from '@mui/material';
import { palette } from '../../theme/foundations/index';

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  left: 0,
  top: 0,
  bottom: 0,
  padding: '36px 10px 30px 10px',
  boxShadow: `0px 2px 16px ${palette.gray800}`,
  background: palette.white,
  zIndex: 1000,
}));
