import * as yup from 'yup';
import { MESSAGES } from '../messages';

export const editCuisinesSchema = yup.object().shape({
  specificCuisines: yup.array().of(
    yup.object().shape({
      label: yup
        .string()
        .max(255, MESSAGES.MAX_LENGTH('255'))
        .required(MESSAGES.REQUIRE_MESSAGE),
      imgUrl: yup.mixed().required(MESSAGES.REQUIRE_MESSAGE),
    }),
  ),
});
