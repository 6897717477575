import React from 'react';

export const LensIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_4362_4455)'>
        <path
          d='M10.2635 20.526C12.2447 20.5256 14.1762 19.9485 15.8261 18.8748C16.2496 18.5992 16.8147 18.629 17.172 18.9863L21.4788 23.293C21.8694 23.6835 22.5025 23.6834 22.893 23.293L23.2928 22.8931C23.6834 22.5026 23.6834 21.8694 23.2928 21.4789L18.9863 17.1725C18.6288 16.8152 18.5991 16.25 18.8749 15.8264C19.9492 14.1763 20.5266 12.2445 20.5271 10.263C20.5271 4.60423 15.9226 0 10.2635 0C4.60448 0 0 4.60423 0 10.263C0 15.9217 4.60448 20.526 10.2635 20.526ZM10.2635 2.56575C14.5088 2.56575 17.9612 6.01796 17.9612 10.263C17.9612 14.508 14.5088 17.9602 10.2635 17.9602C6.01828 17.9602 2.56588 14.508 2.56588 10.263C2.56588 6.01796 6.01828 2.56575 10.2635 2.56575Z'
          fill='#B5B1AE'
        />
        <path
          d='M12.0746 8.44918C12.3191 8.69434 12.5085 8.97909 12.6363 9.2884C12.8473 9.79881 13.2766 10.2632 13.8289 10.2632H14.3965C14.9481 10.2632 15.4055 9.81232 15.2991 9.27104C15.2338 8.93866 15.1354 8.6128 15.0049 8.29843C14.7464 7.67571 14.367 7.11041 13.8886 6.6352C12.185 4.93394 9.38499 4.72481 7.41269 6.00779C6.95007 6.30872 6.95315 6.95178 7.34285 7.34256L7.74281 7.74363C8.1328 8.13471 8.76734 8.11245 9.28076 7.90889C10.2121 7.53965 11.3446 7.7212 12.0746 8.44918Z'
          fill='#B5B1AE'
        />
      </g>
      <defs>
        <clipPath id='clip0_4362_4455'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
