import { RouterWrapper } from './router/Router';
import { client } from './graphql/client';
import { ApolloProvider } from '@apollo/client';
import { AlertProvider, AuthContextProvider, ModalProvider } from './context';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from './theme/theme';
import { TranslatesContextProvider } from './context/TranslatesContext';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import './App.css';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <ApolloProvider client={client}>
        <AuthContextProvider>
          <TranslatesContextProvider>
            <AlertProvider>
              <ModalProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <RouterWrapper />
                </LocalizationProvider>
              </ModalProvider>
            </AlertProvider>
          </TranslatesContextProvider>
        </AuthContextProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
