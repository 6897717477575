import React from 'react';
import { SelectFormik } from '../SelectFormik/SelectFormik';
import { ITimePickerProps } from './types';
import { Wrapper } from './styles';
import { useField } from 'formik';

export const TimePicker = ({ name, options, sx }: ITimePickerProps) => {
  const [field] = useField(name);

  const disabledIndexTo = options.findIndex(
    (option) => option.id === field.value.from,
  );
  const disabledIndexFrom = options.findIndex(
    (option) => option.id === field.value.to,
  );
  return (
    <Wrapper sx={sx}>
      <SelectFormik
        sx={{ width: '100px' }}
        name={`${name}.from`}
        options={options}
        disabledIndexFrom={disabledIndexFrom}
      />
      -
      <SelectFormik
        sx={{ width: '100px' }}
        name={`${name}.to`}
        options={options}
        disabledIndexTo={disabledIndexTo}
      />
    </Wrapper>
  );
};
