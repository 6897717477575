import { Badge } from '@mui/material';
import React from 'react';
import { ADMIN_NAVIGATION_AUTHS } from '../../constants/constants';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { CustomLinkMenuCSS } from './styles';
import { useAccesses } from '../../hooks';
import {
  Event_Offer_Status_Enum,
  useBrandEventOffersQuery,
} from '../../graphql/generated/graphql';
import { useAuthContext } from '../../context';

export const AdminNavigation = () => {
  const { t } = useTranslatesContext();
  const { isDishEditAccess } = useAccesses();
  const { subscribeData } = useAuthContext();

  const { data: brandEventOffersData } = useBrandEventOffersQuery({
    variables: {
      options: {
        status: [Event_Offer_Status_Enum.New],
      },
    },
  });

  const count = brandEventOffersData?.brandEventOffers.length;

  return (
    <>
      {ADMIN_NAVIGATION_AUTHS.map((authObj) => {
        if (authObj.label === 'web_nav_menu' && !isDishEditAccess) return null;

        return (
          <CustomLinkMenuCSS key={authObj.id} to={authObj.route}>
            {authObj.icon}
            {t(authObj.label)}
            {authObj?.badge && (
              <Badge
                color='warning'
                overlap='circular'
                badgeContent={subscribeData?.newEventOffersCount.count || count}
              />
            )}
          </CustomLinkMenuCSS>
        );
      })}
    </>
  );
};
