import {
  KeyArgsFunction,
  KeySpecifier,
} from '@apollo/client/cache/inmemory/policies';

import { BrandWithCountResponse } from '../generated/graphql';

export const paginationMergeGetRestaraunts = (
  keyArguments?: KeySpecifier | KeyArgsFunction | false,
) => {
  return {
    ['restaurants']: {
      keyArgs: keyArguments,
      merge(
        existing: BrandWithCountResponse,
        incoming: BrandWithCountResponse,
        { args }: Record<string, any>,
      ) {
        return {
          ...incoming,
          rows:
            args?.options?.offset === 0
              ? [...incoming.rows]
              : [...(existing?.rows || []), ...incoming.rows],
        };
      },
    },
  };
};
