import React from 'react';
import { Box, Button, Pagination } from '@mui/material';
import { Wrapper } from './styles';
import { palette } from '../../theme/foundations/index';
import { IPaginationRowProps } from './types';

export const PaginationRow = ({
  from,
  to,
  fromText,
  onClickButton,
  onClickPagination,
  buttonName,
  pagesCount,
  page,
  isHasShowMore = true,
}: IPaginationRowProps) => {
  return (
    <Wrapper>
      <Box sx={{ color: palette.gray600 }}>
        <span>{from}</span> {fromText} <span>{to}</span>
      </Box>
      <Box>
        {pagesCount !== page && isHasShowMore ? (
          <Button
            variant={'outlined'}
            sx={{ fontSize: '16px', background: palette.button.textBg }}
            onClick={onClickButton}
          >
            {buttonName}
          </Button>
        ) : null}
      </Box>
      <Box>
        <Pagination
          count={pagesCount}
          page={page}
          onChange={onClickPagination}
          shape='rounded'
          hideNextButton={true}
          hidePrevButton={true}
        />
      </Box>
    </Wrapper>
  );
};
