import { styled } from '@mui/material/styles';
import { Box, BoxProps, Typography } from '@mui/material';
import { palette } from '../../theme/foundations/index';
import { AliasBoolTypes } from '../../types/baseTypes';

export interface IWrapperProps extends BoxProps {
  target?: AliasBoolTypes;
}

export const Wrapper = styled(Box)<IWrapperProps>(({ target }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    boxShadow: '0px 2px 16px rgba(33, 52, 103, 0.1)',
    borderRadius: '16px',
    outline: `1px solid ${target === 'true' ? palette.primary.main : 'none'}`,
    background: palette.white,
    padding: '19px 30px 19px 19px',
  };
});

export const ImageBlockCSS = styled(Box)({
  width: '89px',
  height: '89px',
  img: {
    width: '89px',
    height: '89px',
    objectFit: 'cover',
  },
  marginLeft: '10px',
  marginRight: '20px',
});

export const TitleBlockCSS = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  width: '100%',
  marginRight: '67px',
});
export const TitleBlockItemCSS = styled(Typography)({
  overflowWrap: 'anywhere',
});

export const InnerTitleBlockCSS = styled(Box)({
  display: 'flex',
  gap: '11px',
});

export const PriceBlockCSS = styled(Box)({
  display: 'flex',
  marginRight: '41px',
});
