import React from 'react';
import { SuperAdminNavigation } from './SuperAdminNavigation';
import { AdminNavigation } from './AdminNavigation';
import { MenuCSS } from './styles';
import { useAuthContext } from '../../context';

export const NavigationSidebar = () => {
  const { user } = useAuthContext();

  const isSuperAdmin = !user?.brand;

  return (
    <MenuCSS>
      {isSuperAdmin ? <SuperAdminNavigation /> : <AdminNavigation />}
    </MenuCSS>
  );
};
