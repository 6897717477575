import { gql } from '@apollo/client';

export const TRANSLATES = gql`
  query Translates($key: String, $localeId: Int, $localeCode: String) {
    translates(key: $key, localeId: $localeId, localeCode: $localeCode) {
      key
      strings {
        locale {
          id
          title
          code
        }
        string
      }
      texts {
        text
        locale {
          code
          id
          title
        }
      }
      images {
        image {
          id
          width
          height
          mime
          size
          url
        }
        locale {
          code
          id
          title
        }
      }
    }
  }
`;
