import React from 'react';
import { Paper, Table, TableBody, TableContainer } from '@mui/material';
import { TableRowCustom } from '../TableRowCustom/TableRowCustom';
import { ITableListProps } from './types';
import { CircularIndeterminate } from '../../CircularIndeterminate/CircularIndeterminate';
import { TableListHeader } from '../TableListHeader/TableListHeader';
import { useFormContext } from 'react-hook-form';

export const TableList = ({
  options,
  data,
  onClick,
  loading,
}: ITableListProps) => {
  const {
    formState: { errors: err },
  } = useFormContext();
  return (
    <TableContainer component={Paper}>
      {loading ? (
        <CircularIndeterminate />
      ) : (
        <Table stickyHeader aria-label='simple table'>
          <TableListHeader headCells={options} />
          <TableBody>
            {data?.map((dataRow, index) => {
              const isErr = (err as any).data?.[index]?.fieldDataEN?.message;

              return (
                <TableRowCustom
                  key={index}
                  name={`data.${index}.fieldData`}
                  title={dataRow.key}
                  onClick={onClick}
                  type={dataRow.type}
                  isError={!!isErr}
                />
              );
            })}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};
