import { OptionsCaseLayout, TextFieldFormik } from '../../components';
import { FieldArray, Form, Formik } from 'formik';
import {
  ButtonSaveChangesCSS,
  FieldArrayRow,
  FieldArrayRowInner,
} from './styles';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { CloseModalIcon } from '../../components/Icons';
import { useTranslatesContext } from '../../context/TranslatesContext';
import {
  IFormikSettingsCitiesFormProps,
  ISettingsCitiesFormProps,
} from './types';
import { editCitiesSchema } from '../../validation';
import {
  useBulkUpdateCityMutation,
  useParseCityMutation,
} from '../../graphql/generated/graphql';
import { useAlertContext } from '../../context';
import { CITIES } from '../../graphql/queries/cities.gqll';
import dayjs from 'dayjs';

export const SettingsCitiesForm = ({
  citiesOption,
}: ISettingsCitiesFormProps) => {
  const { t } = useTranslatesContext();
  const { onOpenAlert } = useAlertContext();

  const [updateCity, { loading }] = useBulkUpdateCityMutation({
    onCompleted: () =>
      onOpenAlert({ title: t('web_setting_list_cities_update') }),
    onError: () =>
      onOpenAlert({
        title: t('web_setting_occurred_error_update'),
        error: true,
      }),
    refetchQueries: [CITIES],
  });

  const [parseCity, { loading: paseLoading }] = useParseCityMutation({
    onCompleted: () => onOpenAlert({ title: t('web_parsed_processing') }),
    onError: (error) =>
      onOpenAlert({
        title: error.message,
        error: true,
      }),
    refetchQueries: [CITIES],
  });

  const initialValues = {
    cities:
      citiesOption?.length < 1
        ? [{ label: '', latitude: 0, longitude: 0 }]
        : citiesOption,
  };

  const handleSubmit = (values: IFormikSettingsCitiesFormProps) => {
    const deleteItems: any = [];

    citiesOption.forEach((item) => {
      if (
        !values.cities
          .map((i) => i.id)
          .filter((i) => i)
          .includes(item.id)
      ) {
        deleteItems.push({
          title: item.label,
          id: Number(item.id),
          latitude: Number(item.latitude),
          longitude: Number(item.longitude),
          parserCode: item.parserCode,
          countryCode: item.countryCode,
          isDelete: true,
        });
      }
    });

    const updateItems = values.cities.map((item) => {
      const idParams = item.id
        ? {
            id: Number(item.id),
          }
        : {};

      return {
        title: item.label,
        latitude: Number(item.latitude),
        longitude: Number(item.longitude),
        parserCode: item.parserCode,
        countryCode: item.countryCode,
        ...idParams,
      };
    });

    updateCity({
      variables: {
        citiesData: {
          list: [...updateItems, ...deleteItems],
        },
      },
    });
  };

  return (
    <OptionsCaseLayout title={t('web_setting_case_label_city')}>
      <Box mb={'8px'} sx={{ display: 'flex', gap: '16px' }}>
        <Typography variant={'body1'} width={'200px'}>
          {t('web_setting_label_city_name')}
        </Typography>
        <Typography variant={'body1'} width={'150px'}>
          {t('web_setting_label_city_longitude')}
        </Typography>
        <Typography variant={'body1'} width={'150px'}>
          {t('web_setting_label_city_latitude')}
        </Typography>
        <Box sx={{ width: '100%', maxWidth: '100px' }} />
      </Box>
      <Formik<IFormikSettingsCitiesFormProps>
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={editCitiesSchema}
        onSubmit={handleSubmit}
      >
        {({ values, dirty }) => {
          return (
            <Form>
              <FieldArray name={'cities'}>
                {({ remove, push }) => {
                  return (
                    <FieldArrayRow>
                      {values.cities.map((city, index) => {
                        const isParseProcessing =
                          city.parserStatus === 'PROCESSING';
                        const isParseError = city.parserStatus === 'ERROR';
                        const isParseCompleted =
                          city.parserStatus === 'COMPLETED';

                        const parsedStatus = isParseCompleted
                          ? t('web_completed')
                          : isParseProcessing
                          ? t('web_processing')
                          : isParseError
                          ? t('web_error')
                          : t('web_none');
                        return (
                          <FieldArrayRowInner key={index}>
                            <TextFieldFormik
                              name={`cities.${index}.label`}
                              sx={{ minWidth: '200px' }}
                              width={'200px'}
                              size={'large'}
                            />
                            <TextFieldFormik
                              name={`cities.${index}.longitude`}
                              width={'150px'}
                              sx={{ minWidth: '150px' }}
                              size={'large'}
                              type={'number'}
                            />
                            <TextFieldFormik
                              name={`cities.${index}.latitude`}
                              width={'150px'}
                              sx={{ minWidth: '150px' }}
                              size={'large'}
                              type={'number'}
                            />
                            {city.id ? (
                              <Box
                                sx={{
                                  display: 'flex',
                                  height: '50px',
                                  alignItems: 'center',
                                }}
                              >
                                <ButtonSaveChangesCSS
                                  disabled={isParseProcessing}
                                  loading={paseLoading}
                                  onClick={() =>
                                    parseCity({
                                      variables: {
                                        cityId: Number(city?.id),
                                      },
                                    })
                                  }
                                >
                                  {t('web_parsing_button')}
                                </ButtonSaveChangesCSS>
                                <Typography ml={'16px'} width={'140px'}>
                                  {`${t('web_last_parsed_date')}: ${
                                    city?.parserLastParsedDate
                                      ? dayjs(
                                          +city?.parserLastParsedDate,
                                        ).format('DD.MM.YYYY')
                                      : t('web_no_parsed')
                                  }.`}
                                </Typography>
                                <Typography width={'140px'} ml={'16px'}>{`${t(
                                  'web_parsed_status',
                                )}: ${parsedStatus}`}</Typography>
                              </Box>
                            ) : null}

                            <IconButton
                              sx={{ mb: '6px' }}
                              size={'large'}
                              onClick={() => remove(index)}
                            >
                              <CloseModalIcon />
                            </IconButton>
                          </FieldArrayRowInner>
                        );
                      })}
                      <Stack direction={'row'} spacing={1}>
                        <Button
                          onClick={() =>
                            push({
                              label: '',
                              latitude: '',
                              longitude: '',
                            })
                          }
                          size={'large'}
                          sx={{ width: '100%', maxWidth: '160px' }}
                        >
                          {t('web_setting_action_city_add')}
                        </Button>
                        <ButtonSaveChangesCSS
                          type='submit'
                          size={'large'}
                          loading={loading}
                          disabled={!dirty}
                        >
                          {t('web_action_save')}
                        </ButtonSaveChangesCSS>
                      </Stack>
                    </FieldArrayRow>
                  );
                }}
              </FieldArray>
            </Form>
          );
        }}
      </Formik>
    </OptionsCaseLayout>
  );
};
