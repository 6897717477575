import { ImgCSS, Wrapper } from './styles';
import { IProps } from './types';
import empryListImg from './../../assets/images/empty-list.jpg';
import { Button, Typography } from '@mui/material';

export const EmptyList = ({
  img = empryListImg,
  title,
  button,
  disabled,
  sx,
}: IProps) => {
  return (
    <Wrapper sx={sx}>
      <ImgCSS src={img} alt='empty list' />
      <Typography variant='h3' mt={'20px'}>
        {title}
      </Typography>
      {button ? (
        <Button
          disabled={disabled}
          size={'large'}
          sx={{ width: 'fit-content', marginTop: '25px' }}
          onClick={button?.action}
        >
          {button?.title}
        </Button>
      ) : null}
    </Wrapper>
  );
};
