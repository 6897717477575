import { Form, Formik } from 'formik';
import { Box } from '@mui/material';
import { OptionsCaseLayout, TextFieldFormik } from '../../components';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { ButtonSaveChangesCSS } from './styles';
import {
  ISettingsBudgetFormik,
  ISettingsBudgetPerGuestFormProps,
} from './types';
import { useAlertContext } from '../../context';
import { useBulkUpdateSettingMutation } from '../../graphql/generated/graphql';
import { SETTINGS } from '../../graphql/queries/settings.gql';
import { getOption } from '../../utils';
import { editBudgetPerGuestsSchema } from '../../validation/schema/editBudgetPerGuests.schema';
import { GENERAL_SETTINGS } from '../../constants/constants';

export const SettingsBudgetPerGuestForm = ({
  budgetOption,
}: ISettingsBudgetPerGuestFormProps) => {
  const { t } = useTranslatesContext();
  const { onOpenAlert } = useAlertContext();

  const [updateSetting] = useBulkUpdateSettingMutation({
    onCompleted: () =>
      onOpenAlert({ title: t('web_setting_budget_per_guests_update') }),
    onError: () =>
      onOpenAlert({
        title: t('web_setting_occurred_error_update'),
        error: true,
      }),
    refetchQueries: [SETTINGS],
  });

  const baseValueGuest = getOption(
    GENERAL_SETTINGS.basicBudgetPerPerson,
    budgetOption,
  );
  const minValueGuest = getOption(
    GENERAL_SETTINGS.minBudgetPerPerson,
    budgetOption,
  );
  const maxValueGuest = getOption(
    GENERAL_SETTINGS.maxBudgetPerPerson,
    budgetOption,
  );

  const handleSubmit = (values: ISettingsBudgetFormik) => {
    updateSetting({
      variables: {
        settingsData: {
          list: [
            {
              key: GENERAL_SETTINGS.basicBudgetPerPerson,
              value: String(values.baseValueBudget),
            },
            {
              key: GENERAL_SETTINGS.minBudgetPerPerson,
              value: String(values.minValueBudget),
            },
            {
              key: GENERAL_SETTINGS.maxBudgetPerPerson,
              value: String(values.maxValueBudget),
            },
          ],
        },
      },
    });
  };

  return (
    <OptionsCaseLayout
      title={t('web_setting_case_label_budget_guest_create_event')}
    >
      <Formik<ISettingsBudgetFormik>
        initialValues={{
          baseValueBudget: Number(baseValueGuest?.value) || '',
          minValueBudget: Number(minValueGuest?.value) || '',
          maxValueBudget: Number(maxValueGuest?.value) || '',
        }}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validationSchema={editBudgetPerGuestsSchema}
      >
        {({ dirty }) => {
          return (
            <Form>
              <Box sx={{ display: 'flex', gap: '16px', marginBottom: '16px' }}>
                <TextFieldFormik
                  name={'baseValueBudget'}
                  label={t('web_setting_label_basic_value')}
                  size={'large'}
                  width={'230px'}
                  type={'number'}
                />
                <TextFieldFormik
                  name={'minValueBudget'}
                  label={t('web_setting_label_min_value')}
                  size={'large'}
                  width={'230px'}
                  type={'number'}
                />
                <TextFieldFormik
                  name={'maxValueBudget'}
                  label={t('web_setting_label_max_value')}
                  size={'large'}
                  width={'230px'}
                  type={'number'}
                />
              </Box>
              <ButtonSaveChangesCSS
                size={'large'}
                type={'submit'}
                disabled={!dirty}
              >
                {t('web_action_save')}
              </ButtonSaveChangesCSS>
            </Form>
          );
        }}
      </Formik>
    </OptionsCaseLayout>
  );
};
