import { Avatar, AvatarGroup } from '@mui/material';
import React from 'react';
import { IGroupAvatarsProps } from './types';

export const GroupAvatars = ({ imagesUrlAry }: IGroupAvatarsProps) => {
  return (
    <AvatarGroup max={3}>
      {imagesUrlAry.map((data) => (
        <Avatar key={data.id} alt='i' src={data.url} />
      ))}
    </AvatarGroup>
  );
};
