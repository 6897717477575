import { Box, styled } from '@mui/material';

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  background: '#FFFFFF',
  position: 'absolute',
  inset: '0 0 auto 0',
  height: '70px',
  padding: '12px 30px 12px 255px',
  justifyContent: 'space-between',
}));

export const CenterYCSS = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const ImageBlockCSS = styled(Box)(() => ({
  width: '40px',
  height: '40px',
  borderRadius: '8px',
  border: '0.5px solid rgba(61, 56, 53, 0.24)',
  marginRight: '8px',
  overflow: 'hidden',
  img: {
    width: '40px',
    height: '40px',
  },
}));
