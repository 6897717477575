import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { ContentCSS, Wrapper } from './styles';
import { Form, Formik } from 'formik';
import { TextFieldFormik } from '../../UI/TextFieldFormik/TextFieldFormik';
import { TabsCustom } from '../../TabsCustom/TabsCustom';
import { TextForm } from './TextForm';
import { ImageForm } from './ImageForm';
import { TextAreaForm } from './TextAreaForm';
import { useAddTranslateMutation } from '../../../graphql/generated/graphql';
import { TRANSLATES } from '../../../graphql/queries/translates.gql';
import { useAlertContext } from '../../../context/index';
import { addTranslateSchema } from '../../../validation/index';
import { useTranslatesContext } from '../../../context/TranslatesContext';

export const AddElementModal = () => {
  const { t } = useTranslatesContext();
  const { onOpenAlert } = useAlertContext();
  const [addTranslate] = useAddTranslateMutation();

  const handleSubmit = (values: any, { resetForm }: any) => {
    addTranslate({
      variables: {
        translateData: {
          key: values.titleField,
          strings: [
            ...(values.tabNum === '1'
              ? [{ string: values.enString, localeId: 1 }]
              : []),
          ],
          images: [
            ...(values.tabNum === '2'
              ? [{ image: values.enImage, localeId: 1 }]
              : []),
          ],
          texts: [
            ...(values.tabNum === '3'
              ? [{ text: values.enTextArea, localeId: 1 }]
              : []),
          ],
        },
      },
      onCompleted: () => {
        onOpenAlert({ title: t('web_alert_add_translate') });
        resetForm({
          values: {
            tabNum: localStorage.getItem('tabNum_addElement') || '1',
            titleField: '',
            enString: '',
            enImage: null,
            enTextArea: '',
          },
        });
      },
      onError: (error) => {
        onOpenAlert({ title: error.message, error: true });
      },
      refetchQueries: [TRANSLATES],
    });
  };

  const optionsTabs = [
    { id: '1', label: t('web_translations_modal_field_text') },
    { id: '2', label: t('web_translations_modal_field_image') },
    { id: '3', label: t('web_translations_modal_field_textarea') },
  ];

  return (
    <Wrapper>
      <Box mb={'16px'}>
        <Typography variant={'h2'}>
          {t('web_translations_modal_title')}
        </Typography>
      </Box>
      <Formik
        initialValues={{
          titleField: '',
          tabNum: '1',
          enString: '',
          enImage: null,
          enTextArea: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={addTranslateSchema}
      >
        {({ values, setFieldValue }) => {
          const handleTabsClick = (value: string) => {
            setFieldValue('tabNum', value);
            localStorage.setItem('tabNum_addElement', value);
          };
          return (
            <Form>
              <TextFieldFormik
                name={'titleField'}
                label={t('web_translations_modal_key_label')}
                mb={'20px'}
                size={'large'}
              />
              <Box display={'flex'} mb={'20px'}>
                <TabsCustom
                  options={optionsTabs}
                  onClick={handleTabsClick}
                  tabNum={values.tabNum}
                />
              </Box>
              <ContentCSS>
                {values.tabNum === '1' ? (
                  <TextForm
                    enName={'enString'}
                    enLabel={t('web_translations_modal_value_en_label')}
                  />
                ) : values.tabNum === '2' ? (
                  <ImageForm
                    nameEnImage={'enImage'}
                    labelEnText={t('web_translations_modal_value_en_label')}
                  />
                ) : (
                  <TextAreaForm
                    enName={'enTextArea'}
                    enLabel={t('web_translations_modal_value_en_label')}
                  />
                )}
              </ContentCSS>
              <Button type={'submit'} size={'large'} sx={{ marginTop: '20px' }}>
                {t('web_translations_modal_submit_btn')}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Wrapper>
  );
};
