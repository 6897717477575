import React, { useState } from 'react';
import { ModalContent } from '../components/Modal';

type Props = {
  children: React.ReactNode;
};

type InfoModalType = {
  name: string;
  content: Record<any, any>;
  open: boolean;
};

type ContextProps = {
  onOpenModal: (name: string, content?: Record<any, any>) => void;
  onCloseModal: () => void;
  infoModal: InfoModalType;
};

export const ModalContext = React.createContext<ContextProps>({
  onOpenModal: () => {},
  onCloseModal: () => {},
  infoModal: {
    name: '',
    content: {},
    open: false,
  },
});

export const useModalContext = () => {
  const modalContext = React.useContext(ModalContext);

  if (!modalContext) {
    throw new Error(
      'useModalContext must be used within a ModalContextProvider',
    );
  }
  return modalContext;
};

export const ModalProvider = ({ children }: Props) => {
  const [infoModal, setInfoModal] = useState({
    name: '',
    content: {},
    open: false,
  });

  const onOpenModal = (name: string, content: Record<any, any> = {}) => {
    setInfoModal({ name, content, open: true });
  };

  const onCloseModal = () => {
    setInfoModal({ name: '', content: {}, open: false });
  };

  const value = {
    infoModal,
    onOpenModal,
    onCloseModal,
  };

  return (
    <ModalContext.Provider value={value as any}>
      {children}
      <ModalContent />
    </ModalContext.Provider>
  );
};
