import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { FieldArray, Form, Formik } from 'formik';
import { OptionsCaseLayout, TextFieldFormik } from '../../components';
import {
  ButtonSaveChangesCSS,
  FieldArrayRow,
  FieldArrayRowInner,
} from './styles';
import { CloseModalIcon } from '../../components/Icons';
import { useTranslatesContext } from '../../context/TranslatesContext';
import {
  ISettingsTipsFormAdviceFormik,
  ISettingsTipsFormHourFormik,
  ISettingsTipsFormProps,
} from './types';
import {
  useBulkUpdateAdviceMutation,
  useBulkUpdateSettingMutation,
} from '../../graphql/generated/graphql';
import { useAlertContext } from '../../context';
import { ADVICE } from '../../graphql/queries/advices.gql';
import { editAdvicesSchema } from '../../validation';
import { SETTINGS } from '../../graphql/queries/settings.gql';
import { GENERAL_SETTINGS } from '../../constants/constants';
import { editHourAdvicesSchema } from '../../validation/schema/editHourAdvices.schema';

export const SettingsTipsForm = ({
  advicesOption,
  adviceHourBefore,
}: ISettingsTipsFormProps) => {
  const { t } = useTranslatesContext();
  const { onOpenAlert } = useAlertContext();

  const [updateAdvice] = useBulkUpdateAdviceMutation({
    onCompleted: () => onOpenAlert({ title: t('web_setting_advices_update') }),
    onError: () =>
      onOpenAlert({
        title: t('web_setting_occurred_error_update'),
        error: true,
      }),
    refetchQueries: [ADVICE],
  });

  const [updateSetting] = useBulkUpdateSettingMutation({
    onCompleted: () => onOpenAlert({ title: t('web_setting_advices_update') }),
    refetchQueries: [SETTINGS],
  });

  const handleAdvicesSubmit = (values: ISettingsTipsFormAdviceFormik) => {
    const deleteItems: any = [];

    advicesOption.forEach((item) => {
      if (
        !values.advices
          .map((i) => i.id)
          .filter((i) => i)
          .includes(item.id)
      ) {
        deleteItems.push({
          id: Number(item.id),
          text: item.label,
          isDelete: true,
        });
      }
    });

    const updateItems = values.advices.map((item) => {
      const idParams = item.id
        ? {
            id: Number(item.id),
          }
        : {};

      return {
        text: item.label,
        ...idParams,
      };
    });

    updateAdvice({
      variables: {
        advicesData: {
          list: [...updateItems, ...deleteItems],
        },
      },
    });
  };

  const handleHowHourSubmit = (values: ISettingsTipsFormHourFormik) => {
    updateSetting({
      variables: {
        settingsData: {
          list: [
            {
              key: GENERAL_SETTINGS.hoursBeforeAdviceAppears,
              value: String(values.countOfHoursBeforeTheCouncilAppears),
            },
          ],
        },
      },
    });
  };

  const initialAdvicesValues = {
    advices: advicesOption.length < 1 ? [{ label: '' }] : advicesOption,
  };

  return (
    <OptionsCaseLayout title={t('web_setting_case_label_tips')}>
      <Box mb={'16px'}>
        <Formik<ISettingsTipsFormHourFormik>
          initialValues={{
            countOfHoursBeforeTheCouncilAppears:
              Number(adviceHourBefore?.value) || '',
          }}
          onSubmit={handleHowHourSubmit}
          enableReinitialize={true}
          validationSchema={editHourAdvicesSchema}
        >
          {({ dirty }) => {
            return (
              <Form>
                <TextFieldFormik
                  name={'countOfHoursBeforeTheCouncilAppears'}
                  label={t('web_setting_label_tips_a_hour')}
                  size={'large'}
                  width={'336px'}
                  mb={'16px'}
                  type={'number'}
                />
                <ButtonSaveChangesCSS
                  type={'submit'}
                  size={'large'}
                  disabled={!dirty}
                >
                  {t('web_action_save')}
                </ButtonSaveChangesCSS>
              </Form>
            );
          }}
        </Formik>
      </Box>
      <Box mb={'8px'}>
        <Typography variant={'body1'}>{t('web_setting_label_tips')}</Typography>
      </Box>
      <Formik<ISettingsTipsFormAdviceFormik>
        initialValues={initialAdvicesValues}
        onSubmit={handleAdvicesSubmit}
        enableReinitialize={true}
        validationSchema={editAdvicesSchema}
      >
        {({ values, dirty }) => {
          return (
            <Form>
              <FieldArray name={'advices'}>
                {({ remove, push }) => {
                  return (
                    <FieldArrayRow>
                      {values.advices.map((advice, index) => {
                        return (
                          <FieldArrayRowInner key={index}>
                            <TextFieldFormik
                              name={`advices.${index}.label`}
                              fullWidth
                              width={'484px'}
                              size={'large'}
                            />
                            <IconButton
                              size={'large'}
                              onClick={() => remove(index)}
                              sx={{ marginBottom: '5px' }}
                            >
                              <CloseModalIcon />
                            </IconButton>
                          </FieldArrayRowInner>
                        );
                      })}
                      <Stack direction={'row'} spacing={1}>
                        <Button
                          onClick={() => push({ label: '' })}
                          size={'large'}
                          sx={{ width: '100%', maxWidth: '160px' }}
                        >
                          {t('web_setting_action_tips_add')}
                        </Button>
                        <ButtonSaveChangesCSS
                          type={'submit'}
                          size={'large'}
                          disabled={!dirty}
                        >
                          {t('web_action_save')}
                        </ButtonSaveChangesCSS>
                      </Stack>
                    </FieldArrayRow>
                  );
                }}
              </FieldArray>
            </Form>
          );
        }}
      </Formik>
    </OptionsCaseLayout>
  );
};
