import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CONTENT_WIDTH, MAX_WIDTH } from '../../constants/constants';

export const AdressWrapperCSS = styled(Box)(() => ({
  marginTop: 22,
  display: 'flex',
  alignItems: 'center',
}));
export const SchedulesSingleWrapperCSS = styled(Box)(() => ({
  marginTop: 22,
  display: 'flex',
  alignItems: 'flex-end',
}));
export const SchedulesElementCSS = styled(Box)(() => ({
  marginTop: 20,
  display: 'flex',
  alignItems: 'center',
  minHeight: 50,
}));
export const CoastWrapperCSS = styled(Box)(() => ({
  marginTop: 22,
  display: 'flex',
  alignItems: 'center',
}));
export const FormWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: MAX_WIDTH,
});
