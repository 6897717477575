import React from 'react';

export const Triangle = () => {
  return (
    <svg
      width='8'
      height='7'
      viewBox='0 0 8 7'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.86603 0.500001C4.48113 -0.166666 3.51887 -0.166667 3.13397 0.5L0.535898 5C0.150998 5.66667 0.632123 6.5 1.40192 6.5L6.59808 6.5C7.36788 6.5 7.849 5.66667 7.4641 5L4.86603 0.500001Z'
        fill='white'
      />
    </svg>
  );
};
