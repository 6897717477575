import {
  Box,
  BoxProps,
  Button,
  IconButton,
  Typography,
  styled,
} from '@mui/material';
import { palette } from '../../../theme/foundations';

export interface CategoryTitleWrapperProps extends BoxProps {
  iserror?: string;
}

export const Wrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '380px',
}));

export const ButtonsWrapperCSS = styled(Box)(() => ({
  display: 'flex',
  marginTop: '22px',
}));

export const CategoriesListWrapperCSS = styled(Box)(() => ({
  marginTop: '25px',
}));

export const CategoryWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  marginBottom: '5px',
}));

export const CategoryTitleWrapperCSS = styled(Box)<CategoryTitleWrapperProps>(
  ({ iserror }) => ({
    display: 'flex',
    borderRadius: '8px',
    height: '50px',

    alignItems: 'center',
    width: '350px',
    padding: '15px 14px',
    border: iserror === 'true' ? '1px solid red' : 'none',
    '& span': {
      display: 'none',
      marginLeft: 'auto',
    },
    '&:hover': {
      background: palette.button.textBg,
      '& span': {
        display: 'inline-block',
        cursor: 'pointer',
      },
    },
  }),
);

export const CategoryTitleCSS = styled(Typography)(() => ({
  maxWidth: '265px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));

export const RemoveCategoryButtonCSS = styled(IconButton)(() => ({
  height: '30px',
  width: '30px',
  position: 'absolute',
  bottom: '10px',
  right: 0,
}));

export const AddCategoryButtonCSS = styled(Button)(() => ({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '20px',
  height: '20px',
  backgroundColor: palette.white100,
  justifyContent: 'flex-start',
  padding: 0,
  paddingLeft: '12px',
  paddingRight: '12px',
  marginTop: '12px',
  color: palette.primary.main,
  background: palette.white,
  width: 'fit-content',
  '&:hover': {
    backgroundColor: palette.white100,
  },
}));
