import React from 'react';
import { Box, IconButton } from '@mui/material';
import { Wrapper, FormWrapperCSS } from './styles';

import { useModalContext } from '../../../context/index';
import { MODAL_NAME } from '../../../constants/constants';
import {
  AddElementModal,
  AreYouSureModal,
  CompletedRejectedOrderModal,
  CompletedSuccessOrderModal,
  DeleteBrandModal,
  RejectedModal,
  AddDishCategoryModal,
  UnsuccessfullyClosedModal,
} from '..';
import { CategoriesModal } from '../CategoriesModal/CategoriesModal';
import { CloseModalIcon } from '../../Icons/index';

export const ModalContent = () => {
  const { infoModal, onCloseModal } = useModalContext();
  const isVisibleCloseBtn = [infoModal.name].includes(MODAL_NAME.ARE_YOU_SURE);

  if (!infoModal.name) return null;
  return (
    <Wrapper open={infoModal.open} onClose={onCloseModal}>
      {!isVisibleCloseBtn && (
        <IconButton
          onClick={onCloseModal}
          sx={{
            position: 'absolute',
            right: '20px',
            top: '20px',
          }}
        >
          <CloseModalIcon />
        </IconButton>
      )}
      <FormWrapperCSS>
        <TypeModal />
      </FormWrapperCSS>
    </Wrapper>
  );
};

const TypeModal = () => {
  const { infoModal } = useModalContext();

  switch (infoModal.name) {
    case MODAL_NAME.DELETE_BRAND:
      return <DeleteBrandModal />;
    case MODAL_NAME.ADD_ELEMENT:
      return <AddElementModal />;
    case MODAL_NAME.ARE_YOU_SURE:
      return <AreYouSureModal />;
    case MODAL_NAME.REJECTED:
      return <RejectedModal />;
    case MODAL_NAME.UNSUCCESSFULLY_CLOSED:
      return <UnsuccessfullyClosedModal />;
    case MODAL_NAME.COMPLETED_SUCCESS:
      return <CompletedSuccessOrderModal />;
    case MODAL_NAME.COMPLETED_REJECTED:
      return <CompletedRejectedOrderModal />;
    case MODAL_NAME.ADD_CATEGORY:
      return <AddDishCategoryModal />;
    case MODAL_NAME.CATEGORIES:
      return <CategoriesModal />;
    default:
      return <Box />;
  }
};
