import { useTranslatesContext } from '../context/TranslatesContext';

export const useOptionsTabsToOrdersPage = () => {
  const { t } = useTranslatesContext();
  const optionsTabs = [
    { id: '1', label: t('web_orders_type_new') },
    { id: '2', label: t('web_orders_type_sent') },
    { id: '3', label: t('web_orders_type_in_work') },
    { id: '4', label: t('web_orders_type_rejected') },
    { id: '5', label: t('web_orders_type_completed') },
  ];
  return { optionsTabs };
};
