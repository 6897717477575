import { Box, styled } from '@mui/material';

export const Wrapper = styled(Box)(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
  };
});
export const LabelCSS = styled(Box)(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '8px',
  };
});
export const MainCSS = styled(Box)(() => {
  return {
    display: 'flex',
    alignItems: 'center',
  };
});
export const InputWrapperCSS = styled(Box)(() => {
  return {
    width: 'min-content',
    minWidth: '58px',
    marginBottom: '0px',
    marginLeft: 'auto',
    '.MuiInputBase-root': {
      padding: '0 !important',
      minHeight: '40px !important',
    },
    input: { textAlign: 'center' },
  };
});
