import React from 'react';
import { PointCSS, Wrapper } from './styles';
import { Typography } from '@mui/material';
import { IPointStatusProps } from './types';

export const PointStatus = ({
  status,
  title,
  isLargeText,
  sx,
}: IPointStatusProps) => {
  return (
    <Wrapper sx={sx}>
      <PointCSS status={status} />
      <Typography variant={!isLargeText ? 'sm575' : 'md650'}>
        {title}
      </Typography>
    </Wrapper>
  );
};
