import { Box, styled } from '@mui/material';
import { palette } from '../../theme/foundations/index';

export const Wrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '16px',
  background: palette.white,
  boxShadow: '0px 2px 16px rgba(33, 52, 103, 0.1)',
  borderRadius: '16px',
  cursor: 'pointer',
  ...theme.mixins.hoverItem,
}));
