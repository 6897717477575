import { Box, styled } from '@mui/material';
import { palette } from '../../theme/foundations/index';

export const Wrapper = styled(Box)(() => ({
  background: palette.white,
  padding: '20px 9px 17px 15px',
  borderRadius: '15px',
  boxShadow: '0px 5px 16px rgba(33, 52, 103, 0.1)',
}));

export const HeadCSS = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '22px',
  marginBottom: '16px',
}));

export const TitleBlockCSS = styled(Box)(() => ({
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const InnerHeadCSS = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  marginRight: '20px',
  marginLeft: 'auto',
}));

export const PointCSS = styled(Box)(() => ({
  width: '5px',
  height: '5px',
  borderRadius: '50%',
  background: palette.orange500,
}));

export const RatingCSS = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '14px',
  marginBottom: '14px',
}));

export const AddressCSS = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '14px',
  marginBottom: '12px',
}));
