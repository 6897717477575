import { Box, Button, Typography } from '@mui/material';
import { Wrapper } from './styles';
import { Form, Formik } from 'formik';
import { TextFieldFormik } from '../../UI/TextFieldFormik/TextFieldFormik';

import { useTranslatesContext } from '../../../context/TranslatesContext';
import { addCategorySchema } from '../../../validation/schema/addCategory.schema';
import { IFormikProps } from './types';
import { useAlertContext, useModalContext } from '../../../context';
import { useCreateDishCategoryMutation } from '../../../graphql/generated/graphql';
import { DISH_CATEGORIES } from '../../../graphql/queries/dishCategories.gql';

export const AddDishCategoryModal = () => {
  const { t } = useTranslatesContext();
  const { onCloseModal } = useModalContext();
  const { onOpenAlert } = useAlertContext();

  const { infoModal } = useModalContext();

  const [createMutation] = useCreateDishCategoryMutation({
    onCompleted: () => {
      onOpenAlert({ title: t('web_alert_add_dish_category') });
      onCloseModal();
    },
    refetchQueries: [DISH_CATEGORIES],
  });

  const brandId = infoModal?.content?.brandId;

  const handleSubmit = (values: IFormikProps) => {
    createMutation({
      variables: {
        createDishCategoryInput: {
          title: values.title,
          brandId: Number(infoModal?.content?.brandId),
        },
      },
    });
  };

  return (
    <Wrapper>
      <Box>
        <Typography variant={'h2'}>{t('web_category_modal_title')}</Typography>
      </Box>
      <Formik<IFormikProps>
        initialValues={{
          title: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={addCategorySchema}
      >
        {() => {
          return (
            <Form>
              <TextFieldFormik
                name={'title'}
                label={t('web_category_modal_title_label')}
                placeholder={t('web_category_modal_title_placeholder')}
                mt={'27px'}
                size={'large'}
              />

              <Button
                type={'submit'}
                size={'large'}
                disabled={!brandId}
                sx={{ marginTop: '22px', width: 'fit-content' }}
              >
                {t('web_category_modal_submit_btn')}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Wrapper>
  );
};
