import React from 'react';

export const StarIcon = () => {
  return (
    <svg
      width='17'
      height='16'
      viewBox='0 0 17 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.6522 6.0449C16.5427 5.70745 16.2425 5.46784 15.8892 5.4359L11.0692 4.9979L9.16916 0.538899C9.0294 0.212022 8.70816 0 8.35266 0C7.99716 0 7.67592 0.212022 7.53616 0.538899L5.63616 4.9979L0.816159 5.4359C0.461137 5.4651 0.157825 5.7034 0.0454393 6.04143C-0.0669465 6.37946 0.0332969 6.75194 0.300159 6.9879L3.94516 10.1829L2.87116 14.9139C2.79193 15.2603 2.92703 15.6205 3.21447 15.8293C3.50192 16.0381 3.88625 16.0553 4.19116 15.8729L8.34716 13.3879L12.5002 15.8729C12.8052 16.0549 13.1894 16.0376 13.4768 15.8289C13.7643 15.6202 13.8997 15.2603 13.8212 14.9139L12.7502 10.1829L16.3932 6.9879C16.6604 6.7537 16.7623 6.38274 16.6522 6.0449Z'
        fill='#B5B1AE'
      />
    </svg>
  );
};
