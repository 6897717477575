import { Avatar, Box, BoxProps, styled } from '@mui/material';
import { palette } from '../../theme/foundations/index';
import { AliasBoolTypes } from '../../types/baseTypes';

interface IWrapperProps extends BoxProps {
  istarget?: AliasBoolTypes;
}

export const Wrapper = styled(Box)<IWrapperProps>(({ theme, istarget }) => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 22px 16px 16px',
    borderRadius: '16px',
    background: palette.white,
    boxShadow: '0px 2px 16px rgba(33, 52, 103, 0.1)',
    cursor: 'pointer',
    ...(istarget === 'true' && {
      outline: `1px solid ${palette.primary.main}`,
    }),
    ...theme.mixins.hoverItem,
  };
});

export const ContentBlockCSS = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  height: '42px',
});

export const ContentBlockRowCSS = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '16px',
});

export const VerticalDividerCSS = styled(Box)({
  display: 'flex',
  height: '42px',
  width: '1px',
  background: 'rgba(61, 56, 53, 0.24)',
});

export const ImageBlockCSS = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '42px',
  width: '42px',
  border: '0.5px solid rgba(61, 56, 53, 0.24)',
  borderRadius: '8px',
  marginRight: '8px',
  overflow: 'hidden',
  img: {
    height: '42px',
    width: '42px',
  },
});

export const AvatarCSS = styled(Avatar)({
  border: '3px solid #FFF !important',
  objectFit: 'fill',
  background: palette.white,
  ':not(:last-of-type)': {
    marginLeft: '-15px !important',
  },
  img: {
    objectFit: 'fill',
  },
});

export const RatingBlockCSS = styled(Box)({
  display: 'flex',
});

export const TitleBlockCSS = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '4.5px 0px',
  minWidth: '130px',
  '> span': {
    width: '130px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

export const DescriptionBlockCSS = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  whiteSpace: 'nowrap',
  span: {
    width: '220px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export const PriceCSS = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderLeft: `0.5px solid ${palette.gray900}`,
  paddingLeft: '16px',
  minWidth: '134px',
  maxWidth: '200px',
  span: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});
