import { Form, Formik } from 'formik';
import { OptionsCaseLayout, TextFieldFormik } from '../../components';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { ButtonSaveChangesCSS } from './styles';
import { ISettingsContactFormik, ISettingsContactsFormProps } from './types';
import { useBulkUpdateSettingMutation } from '../../graphql/generated/graphql';
import { SETTINGS } from '../../graphql/queries/settings.gql';
import { useAlertContext } from '../../context';
import { editContactPhoneSchema } from '../../validation';
import { GENERAL_SETTINGS } from '../../constants/constants';

export const SettingsContactsForm = ({
  contactPhone,
}: ISettingsContactsFormProps) => {
  const { t } = useTranslatesContext();
  const { onOpenAlert } = useAlertContext();

  const [updateSetting] = useBulkUpdateSettingMutation({
    onCompleted: () =>
      onOpenAlert({ title: t('web_setting_contact_phone_update') }),
    onError: () =>
      onOpenAlert({
        title: t('web_setting_occurred_error_update'),
        error: true,
      }),
    refetchQueries: [SETTINGS],
  });

  const handleSubmit = (values: ISettingsContactFormik) => {
    updateSetting({
      variables: {
        settingsData: {
          list: [
            {
              key: GENERAL_SETTINGS.contactPhone,
              value: values.phone,
            },
          ],
        },
      },
    });
  };

  return (
    <OptionsCaseLayout title={t('web_setting_case_contacts')}>
      <Formik<ISettingsContactFormik>
        initialValues={{
          phone: contactPhone?.value || '',
        }}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validationSchema={editContactPhoneSchema}
      >
        {({ dirty }) => {
          return (
            <Form>
              <TextFieldFormik
                name={'phone'}
                label={t('web_setting_phone_contact_us')}
                size={'large'}
                width={'400px'}
                mb={'16px'}
              />
              <ButtonSaveChangesCSS
                size={'large'}
                type={'submit'}
                disabled={!dirty}
              >
                {t('web_action_save')}
              </ButtonSaveChangesCSS>
            </Form>
          );
        }}
      </Formik>
    </OptionsCaseLayout>
  );
};
