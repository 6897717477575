import { Box, styled } from '@mui/material';

export const DishMenuAlertCSS = styled(Box)({
  display: 'flex',
  position: 'fixed',
  right: '33px',
  left: '250px',
  bottom: '76px',
  height: '64px',
  background: 'rgba(146, 146, 157, 0.8)',
  borderRadius: '16px',
  backdropFilter: 'blur(10px)',
  padding: '7px 7px 7px 20px',
  alignItems: 'center',
  justifyContent: 'space-between',
  zIndex: '1',
});
