import React from 'react';
import { Box, Typography } from '@mui/material';
import { ButtonCSS, ButtonGroupCSS, TitleBlockCSS } from './styles';
import { useModalContext } from '../../../context/index';
import { useTranslatesContext } from '../../../context/TranslatesContext';

export const AreYouSureModal = () => {
  const { infoModal } = useModalContext();
  const { t } = useTranslatesContext();
  const title = t('web_are_you_sure');
  const leftBtnText = t('web_action_cancel');
  const rightBtnText = t('web_action_delete');

  return (
    <Box>
      <TitleBlockCSS>
        <Typography variant={'md700'}>
          {infoModal.content.title || title}
        </Typography>
      </TitleBlockCSS>
      <ButtonGroupCSS>
        <ButtonCSS variant={'text'} onClick={infoModal.content?.leftCB}>
          {infoModal.content?.leftBtnTitle || leftBtnText}
        </ButtonCSS>
        <ButtonCSS variant={'outlined'} onClick={infoModal.content?.rightCB}>
          {infoModal.content?.rightBtnTitle || rightBtnText}
        </ButtonCSS>
      </ButtonGroupCSS>
    </Box>
  );
};
