import React from 'react';
import {
  EmptyList,
  MainLayout,
  PageTitleBlock,
  RequestHandler,
  UserList,
} from '../../components';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/constants';
import {
  Manager,
  useManagerMeQuery,
  useManagersQuery,
} from '../../graphql/generated/graphql';
import { useAccesses } from '../../hooks/index';

export const UsersPage = () => {
  const { t } = useTranslatesContext();
  const navigate = useNavigate();
  const { isManagerEditAccess } = useAccesses();
  const { data: userData } = useManagerMeQuery(); // for get brand id

  const handleAddUser = () => {
    navigate(ROUTES.addUser);
  };
  const { data, loading } = useManagersQuery({
    variables: {
      filter: {
        brandId: userData?.managerMe.brand?.id,
      },
    },
    fetchPolicy: 'network-only',
  });

  return (
    <MainLayout>
      <PageTitleBlock
        title={t('web_users_page_title')}
        subTitle={
          !data
            ? t('web_users_page_subtitle_edit')
            : t('web_users_page_subtitle_add')
        }
        onClick={handleAddUser}
        isDisabledButton={!isManagerEditAccess}
        buttonText={data ? t('web_users_page_add_user') : ''}
      />

      <RequestHandler loading={loading}>
        {data ? (
          <UserList data={data.managers as Manager[]} />
        ) : (
          <EmptyList
            sx={{ marginTop: '136px' }}
            title={t('web_users_page_not_user')}
            img={t('web_brands_emtpy_img')}
            button={{
              title: t('web_users_page_action_add_user'),
              action: handleAddUser,
            }}
            disabled={!isManagerEditAccess}
          />
        )}
      </RequestHandler>
    </MainLayout>
  );
};
