import { Box } from '@mui/material';
import {
  Button,
  CheckboxFormik,
  DropzoneFormik,
  MainLayout,
  RatingFormik,
  SwitcherFormik,
  TextFieldFormik,
} from '../../components';
import { Form, Formik } from 'formik';
import { SelectFormik } from '../../components/UI/SelectFormik/SelectFormik';
import { AutocompleteFormik } from '../../components/UI/AutocompleteFormik/AutocompleteFormik';
import React from 'react';
import { TimePicker } from '../../components/UI/TimePicker/TimePicker';
import { useAlertContext, useModalContext } from '../../context/index';
import { MODAL_NAME } from '../../constants/constants';
import { DropzoneRHF } from '../../components/UI/DropzoneRHF/DropzoneRHF';
import { FormProvider, useForm } from 'react-hook-form';
import { TextFieldRHF } from '../../components/UI/TextFieldRHF/TextFieldRHF';
import { yupResolver } from '@hookform/resolvers/yup';
import { testDevSchema } from '../../validation/schema/testDev.schema';

export const optionSelect = [
  { id: '1', label: 'Kiev' },
  { id: '2', label: 'Vegas' },
  { id: '3', label: 'Dubai' },
];

const optionsKitchen = [
  { id: '1', label: 'Мексиканска' },
  { id: '2', label: 'Вьетнамская' },
  { id: '3', label: 'Украинская' },
  { id: '4', label: 'Европейская' },
];

const timeOption = [
  { id: '00:00', label: '00:00' },
  { id: '01:00', label: '01:00' },
  { id: '02:00', label: '02:00' },
  { id: '03:00', label: '03:00' },
  { id: '04:00', label: '04:00' },
  { id: '05:00', label: '05:00' },
  { id: '06:00', label: '06:00' },
  { id: '07:00', label: '07:00' },
  { id: '08:00', label: '08:00' },
  { id: '09:00', label: '09:00' },
  { id: '10:00', label: '10:00' },
  { id: '11:00', label: '11:00' },
  { id: '12:00', label: '12:00' },
  { id: '13:00', label: '13:00' },
  { id: '14:00', label: '14:00' },
  { id: '15:00', label: '15:00' },
  { id: '16:00', label: '16:00' },
  { id: '17:00', label: '17:00' },
  { id: '18:00', label: '18:00' },
  { id: '19:00', label: '19:00' },
  { id: '20:00', label: '20:00' },
  { id: '21:00', label: '21:00' },
  { id: '22:00', label: '22:00' },
  { id: '23:00', label: '23:00' },
];

export const DevPage = () => {
  const { onOpenModal } = useModalContext();
  const { onOpenAlert } = useAlertContext();
  const methods = useForm({
    defaultValues: {
      // photo: null,
      tf1: '',
      tf2: '',
      tf3: '',
    },
    // resolver: yupResolver(testDevSchema),
  });

  const {
    handleSubmit: handleSubmitRHF,
    formState: { errors },
    getValues,
    setValue,
  } = methods;

  console.log('errors', errors);
  const handleSubmit = (value: any) => {
    console.log(value);
  };

  const onSubmitRHF = (value: any) => {
    console.log(value);
  };

  return (
    <MainLayout>
      DevPage
      <Button onClick={() => onOpenModal(MODAL_NAME.DELETE_BRAND)}>
        open modal example
      </Button>
      <Button
        onClick={() => onOpenAlert({ title: 'test test test', error: true })}
      >
        open toast
      </Button>
      <Box>
        <Formik
          initialValues={{
            sel: '',
            kitchen: [],
            dropzoneImage: null,
            switcher: true,
            rating: '$$$$',
            schedule: {
              from: '10:00',
              to: '18:00',
            },

            checkbox: true,
          }}
          onSubmit={handleSubmit}
        >
          <Form>
            <TextFieldFormik name={'tf'} size={'large'} label={'Дороговизна'} />
            <TextFieldFormik
              name={'textarea'}
              size={'large'}
              multiline={true}
              minRows={4}
            />
            <SelectFormik
              name={'sel'}
              options={optionSelect}
              placeholder={'Выберите город'}
              label={'City'}
            />
            <SelectFormik
              name={'sel'}
              options={optionSelect}
              placeholder={'Выберите город'}
              label={'City'}
              size='small'
            />
            <SwitcherFormik
              name={'switcher'}
              sx={{ margin: '15px 0' }}
              label={'Sunday'}
            />
            <AutocompleteFormik
              name={'kitchen'}
              options={optionsKitchen}
              placeholder={'Favorites kitchen'}
              sx={{ width: '500px', marginBottom: '20px' }}
              label={'Направление кухни'}
            />
            <DropzoneFormik
              label={'Label'}
              subLabel={'До 5 Мб'}
              name={'dropzoneImage'}
              sx={{ marginBottom: '20px' }}
            />
            <RatingFormik name={'rating'} label={'Дороговизна'} />

            <TimePicker options={timeOption} name={`schedule`} />
            <CheckboxFormik name='checkbox' mr='10px' />
            <Button type={'submit'}>Default variant</Button>
            <Button variant='text'>Variant text</Button>
            <Button variant='outlined'>Variant Outlined</Button>
          </Form>
        </Formik>
        <Box
          sx={{
            width: '500px',
            border: '1px solid orange',
            margin: '40px 0',
            padding: '20px',
          }}
        >
          <FormProvider {...methods}>
            <form onSubmit={handleSubmitRHF(onSubmitRHF)}>
              <TextFieldRHF
                name={'tf1'}
                mb={'20px'}
                // errors={!!errors?.tf1}
                // errorMessage={errors?.tf1?.message}
              />
              <TextFieldRHF
                name={'tf2'}
                mb={'20px'}
                // errors={!!errors?.tf2}
                // errorMessage={errors?.tf1?.message}
              />
              <TextFieldRHF
                name={'tf3'}
                mb={'20px'}
                // errors={!!errors?.tf3}
                // errorMessage={errors?.tf3?.message}
              />
              <DropzoneRHF name={'photo'} />
              <Button type={'submit'}>on submit RHF</Button>
            </form>
          </FormProvider>
        </Box>
        <Box sx={{ maxWidth: '300px', margin: '30px 0' }}>
          <Button onClick={() => onOpenModal(MODAL_NAME.ADD_ELEMENT)}>
            AddElementModal
          </Button>
        </Box>
      </Box>
    </MainLayout>
  );
};

// {
//     "address_components": [
//         {
//             "long_name": "Амстердам",
//             "short_name": "Амстердам",
//             "types": [
//                 "locality",
//                 "political"
//             ]
//         },
//         {
//             "long_name": "Амстердам",
//             "short_name": "Амстердам",
//             "types": [
//                 "administrative_area_level_2",
//                 "political"
//             ]
//         },
//         {
//             "long_name": "Северная Голландия",
//             "short_name": "NH",
//             "types": [
//                 "administrative_area_level_1",
//                 "political"
//             ]
//         },
//         {
//             "long_name": "Нидерланды",
//             "short_name": "NL",
//             "types": [
//                 "country",
//                 "political"
//             ]
//         }
//     ],
//     "formatted_address": "Амстердам, Нидерланды",
//     "geometry": {
//         "location": {
//             "lat": 52.3675734,
//             "lng": 4.9041389
//         }
//     },
//     "place_id": "ChIJVXealLU_xkcRja_At0z9AGY",
//     "html_attributions": []
// }
