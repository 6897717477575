import { PageTitleBlock } from '../PageTitleBlock/PageTitleBlock';
import { OffersDishListCSS } from './styles';
import { Dish } from '../../graphql/generated/graphql';
import { OrderMenuItem } from '../OrderMenuItem/OrderMenuItem';
import { IOfferDish } from '../../pages/OrderPage/types';

interface IOffersDishListProps {
  title?: string;
  data: Array<Dish>;
  offerDishes?: Array<IOfferDish>;
}

export const OffersDishList = ({
  title,
  data,
  offerDishes,
}: IOffersDishListProps) => {
  if (!data?.length) return null;
  return (
    <>
      <PageTitleBlock title={title} />
      <OffersDishListCSS>
        {data?.map((dish) => {
          return (
            <OrderMenuItem
              key={dish.id}
              id={dish.id}
              imageUrl={dish.image.url}
              title={dish.title}
              personCount={String(dish.amountPersons)}
              timeCount={String(dish.cookingTime)}
              price={String(dish.price)}
              volume={Number(dish.volume)}
              offerDishes={offerDishes}
              isReadOnly={true}
            />
          );
        })}
      </OffersDishListCSS>
    </>
  );
};
