import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { FieldArray, Form, Formik } from 'formik';
import {
  ButtonSaveChangesCSS,
  FieldArrayRow,
  FieldArrayRowInner,
} from './styles';
import { DropzoneOptionsFormik } from '../../components/UI/DropzoneFormik/DropzoneOptionsFormik';
import { OptionsCaseLayout, TextFieldFormik } from '../../components';
import { CloseModalIcon } from '../../components/Icons';
import { useTranslatesContext } from '../../context/TranslatesContext';
import {
  IFormikSettingsDirectionKitchenProps,
  ISettingsDirectionKitchenProps,
} from './types';
import { useBulkUpdateCuisineMutation } from '../../graphql/generated/graphql';
import { CUISINES } from '../../graphql/queries/cuisines.gqll';
import { useAlertContext } from '../../context';
import { editCuisinesSchema } from '../../validation';

export const SettingsDirectionKitchenForm = ({
  kitchenOptions,
}: ISettingsDirectionKitchenProps) => {
  const { t } = useTranslatesContext();
  const { onOpenAlert } = useAlertContext();

  const [updateCuisine] = useBulkUpdateCuisineMutation({
    onCompleted: () =>
      onOpenAlert({ title: t('web_setting_list_cuisines_update') }),
    onError: () =>
      onOpenAlert({
        title: t('web_setting_occurred_error_update'),
        error: true,
      }),
    refetchQueries: [CUISINES],
  });

  const initialValues = {
    specificCuisines:
      kitchenOptions?.length < 1 ? [{ label: '', imgUrl: '' }] : kitchenOptions,
  };

  const handleSubmit = (values: IFormikSettingsDirectionKitchenProps) => {
    const deleteItems: any = [];

    kitchenOptions.forEach((item) => {
      if (
        !values.specificCuisines
          .map((i) => i.id)
          .filter((i) => i)
          .includes(item.id)
      ) {
        deleteItems.push({
          image: null,
          title: item.label,
          id: Number(item.id),
          isDelete: true,
        });
      }
    });

    const updateItems = values.specificCuisines.map((item) => {
      const idParams = item.id
        ? {
            id: Number(item.id),
          }
        : {};

      return {
        image: typeof item.imgUrl === 'string' ? null : item.imgUrl,
        title: item.label,
        ...idParams,
      };
    });

    updateCuisine({
      variables: {
        cuisinesData: {
          list: [...updateItems, ...deleteItems],
        },
      },
    });
  };

  return (
    <OptionsCaseLayout title={t('web_setting_case_direction_kitchen')}>
      <Box mb={'8px'} sx={{ display: 'flex' }}>
        <Typography variant={'body1'} mr={'16px'} width={'55px'}>
          {t('web_setting_label_photo')}
        </Typography>
        <Typography variant={'body1'}>
          {t('web_setting_label_kitchen_name')}
        </Typography>
      </Box>
      <Formik<IFormikSettingsDirectionKitchenProps>
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
        validationSchema={editCuisinesSchema}
      >
        {({ values, dirty }) => {
          return (
            <Form>
              <FieldArray name={'specificCuisines'}>
                {({ remove, push }) => {
                  return (
                    <FieldArrayRow>
                      {values.specificCuisines.map((cuisine, index) => {
                        return (
                          <FieldArrayRowInner key={index}>
                            <DropzoneOptionsFormik
                              name={`specificCuisines.${index}.imgUrl`}
                            />
                            <TextFieldFormik
                              width={'416px'}
                              name={`specificCuisines.${index}.label`}
                              fullWidth
                              size={'large'}
                            />
                            <IconButton
                              size={'large'}
                              sx={{
                                height: '40px',
                                marginBottom: '6px',
                              }}
                              onClick={() => remove(index)}
                            >
                              <CloseModalIcon />
                            </IconButton>
                          </FieldArrayRowInner>
                        );
                      })}
                      <Stack direction={'row'} spacing={1}>
                        <Button
                          onClick={() =>
                            push({ id: '', label: '', imgUrl: '' })
                          }
                          size={'large'}
                          sx={{ width: '100%', maxWidth: '160px' }}
                        >
                          {t('web_setting_action_kitchen_add')}
                        </Button>
                        <ButtonSaveChangesCSS
                          type={'submit'}
                          size={'large'}
                          disabled={!dirty}
                        >
                          {t('web_action_save')}
                        </ButtonSaveChangesCSS>
                      </Stack>
                    </FieldArrayRow>
                  );
                }}
              </FieldArray>
            </Form>
          );
        }}
      </Formik>
    </OptionsCaseLayout>
  );
};
