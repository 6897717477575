import { FormControlLabel, FormGroup } from '@mui/material';
import React from 'react';
import { IOSSwitch } from './styles';
import { ISwitcherCustomProps } from './types';

export const SwitcherCustom = ({
  name,
  onChange,
  value,
  label,
  sx,
  disabled,
}: ISwitcherCustomProps) => {
  return (
    <FormGroup sx={sx}>
      <FormControlLabel
        sx={{ margin: '0' }}
        control={<IOSSwitch name={name} checked={value} onChange={onChange} />}
        label={label}
        disabled={disabled}
      />
    </FormGroup>
  );
};
