import { Box, styled } from '@mui/material';
import { palette } from '../../theme/foundations/index';
import { useAccesses } from '../../hooks/index';

export const Wrapper = styled(Box)(() => {
  return {
    background: palette.white,
    display: 'flex',
    borderRadius: '16px',
    boxShadow: '0px 12px 23px rgba(62, 73, 84, 0.04)',
    padding: '16px',
  };
});

export const ImageBlockCSS = styled(Box)(() => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '89px',
    height: '89px',
    borderRadius: '8px',
    marginRight: '18px',
    background: palette.gray1000,
    img: {
      width: '89px',
      height: '89px',
      borderRadius: '8px',
    },
    svg: {
      width: '65px',
      height: '71px',
      borderRadius: '8px',
    },
  };
});
export const ContentCSS = styled(Box)(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  };
});

export const TitleBlockCSS = styled(Box)(() => {
  return {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '5px',
  };
});

export const TextBlockCSS = styled(Box)(() => {
  const { isSuperAdmin } = useAccesses();

  return {
    display: 'flex',
    flexDirection: 'column',
    cursor: isSuperAdmin ? 'pointer' : '',
  };
});
export const UlCSS = styled('ul')(() => {
  return {
    margin: 0,
    paddingLeft: '19px',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '20px',
    color: palette.dark500,
  };
});
