import { Box, styled } from '@mui/material';
import { IChevronBlockCSSProps } from './types';

export const Wrapper = styled(Box)(() => {
  return {
    display: 'flex',
    marginTop: 'auto',
    width: '168px',
    alignSelf: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  };
});

export const InfoBlockCSS = styled(Box)(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
  };
});

export const ChevronBlockCSS = styled(Box)(
  ({ anchorel }: IChevronBlockCSSProps) => {
    return {
      marginLeft: 'auto',
      marginRight: '5px',
      rotate: anchorel ? '180deg' : '0deg',
    };
  },
);

export const NameCSS = styled(Box)(({ theme }) => {
  return {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.005em',
    color: theme.palette.brown500,
  };
});

export const JobTitleCSS = styled(Box)(({ theme }) => {
  return {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.gray500,
  };
});
