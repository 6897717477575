import { AvatarGroup, Box, Checkbox, Typography } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import {
  AvatarCSS,
  ContentBlockCSS,
  ContentBlockRowCSS,
  DescriptionBlockCSS,
  ImageBlockCSS,
  PriceCSS,
  RatingBlockCSS,
  TitleBlockCSS,
  VerticalDividerCSS,
  Wrapper,
} from './styles';
import { palette } from '../../theme/foundations/index';
import { useNavigate } from 'react-router-dom';

interface IOffersItemProps {
  setTargetOfferQuantity: (action: string) => void;
  id?: string | number;
  url?: string;
  rating?: string | number;
  name?: string;
  title?: string;
  subTitle?: string;
  price?: string;
  avatarUrl?: { id: string | number; url: string }[];
  isFavorite?: boolean;
  handleChangeOffersToFavorite: (id: number, chekedValue: boolean) => void;
}

export const OffersItem = ({
  setTargetOfferQuantity,
  id,
  url,
  name,
  title,
  subTitle,
  price,
  avatarUrl,
  isFavorite,
  handleChangeOffersToFavorite,
}: IOffersItemProps) => {
  const navigate = useNavigate();
  const [check, setCheck] = useState(isFavorite);

  const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
    handleChangeOffersToFavorite(Number(id), e.target.checked);
    setCheck((prevState) => !prevState);
    setTargetOfferQuantity(e.target.checked ? 'up' : 'down');
  };

  const handleNavigate = (e: ChangeEvent<any>) => {
    if (e.target.tagName === 'INPUT') return;
    navigate(`/main-offers/${id}`);
  };

  return (
    <Wrapper istarget={check ? 'true' : 'false'} onClick={handleNavigate}>
      <ContentBlockCSS>
        <Checkbox
          sx={{ marginRight: '15px' }}
          checked={check}
          onChange={handleCheck}
        />
        <ContentBlockRowCSS>
          <RatingBlockCSS>
            <ImageBlockCSS>
              <img alt={'i'} src={url} />
            </ImageBlockCSS>
            <TitleBlockCSS>
              {/*<CustomRating rating={Number(rating)} gap={'4px'} />*/}
              <Typography variant={'md400'}>{name}</Typography>
            </TitleBlockCSS>
          </RatingBlockCSS>
          <VerticalDividerCSS />
          <DescriptionBlockCSS>
            <Typography variant={'md400'}>{title}</Typography>
            <Typography variant={'md450'} color={palette.gray500}>
              {subTitle}
            </Typography>
          </DescriptionBlockCSS>
        </ContentBlockRowCSS>
      </ContentBlockCSS>

      <Box display={'flex'}>
        <AvatarGroup max={3}>
          {avatarUrl?.map((data) => (
            <AvatarCSS key={data.id} alt={data.url} src={data.url} />
          ))}
        </AvatarGroup>
      </Box>
      <PriceCSS>
        <Typography variant={'md900'}>{price}$</Typography>
      </PriceCSS>
    </Wrapper>
  );
};
