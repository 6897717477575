import React from 'react';
import {
  ButtonCroupCSS,
  HeadRowCSS,
  ImageRowCSS,
  MainCSS,
  MainTypographyCSS,
  Wrapper,
} from './styles';
import { Button, Typography } from '@mui/material';
import { RestaurantIcon } from '../Icons/index';
import { CustomActionMenu } from '../CustomActionMenu/CustomActionMenu';
import { useTranslatesContext } from '../../context/TranslatesContext';
import { useNavigate } from 'react-router-dom';
import {
  Offer_Availability_Enum,
  Restaurant,
  useUpdateOfferMutation,
} from '../../graphql/generated/graphql';
import { useAlertContext } from '../../context';
import { OFFERS_BY_BRAND } from '../../graphql/queries/offersByBrand.gql';
import { useAccesses } from '../../hooks';

interface IAdminOffersItemProps {
  id?: string | number;
  orderStatus: Offer_Availability_Enum;
  name?: string;
  budgetPerPerson?: string | number;
  restaurants?: Restaurant[];
  scheduleValue?: string;
}

export const AdminOffersItem = ({
  id,
  name,
  orderStatus,
  budgetPerPerson,
  restaurants,
  scheduleValue,
}: IAdminOffersItemProps) => {
  const { t } = useTranslatesContext();
  const { onOpenAlert } = useAlertContext();
  const { isOfferEditAccess } = useAccesses();

  const [updateOffer] = useUpdateOfferMutation({
    onCompleted: () => {
      onOpenAlert({ title: t('web_alert_update_offer') });
    },
    refetchQueries: [OFFERS_BY_BRAND],
  });

  const handleOperatingEdit = () => {
    navigate(`/offers/edit/${id}`);
  };

  const handleChangeStatus = (status: Offer_Availability_Enum) => {
    updateOffer({
      variables: {
        updateOfferInput: {
          id: Number(id),
          status: status,
        },
      },
    });
  };

  const actionOptions = [
    {
      label: 'web_action_complete',
      action: () => handleChangeStatus(Offer_Availability_Enum.Closed),
      color: '',
      disabled: !isOfferEditAccess,
    },
  ];

  const actionStoppedOptions = [
    {
      label: 'web_action_edit',
      action: handleOperatingEdit,
      color: '',
      disabled: !isOfferEditAccess,
    },
  ];

  const navigate = useNavigate();

  const itemActions = (orderStatus: Offer_Availability_Enum) => {
    switch (orderStatus) {
      case Offer_Availability_Enum.Active:
        return (
          <>
            <Button
              sx={{ maxWidth: 'fit-content', height: '32px' }}
              onClick={handleOperatingEdit}
              disabled={!isOfferEditAccess}
            >
              {t('web_action_edit')}
            </Button>
            <Button
              disabled={!isOfferEditAccess}
              variant={'visible'}
              onClick={() => handleChangeStatus(Offer_Availability_Enum.Stoped)}
            >
              {t('web_action_stop')}
            </Button>
            <CustomActionMenu options={actionOptions} />
          </>
        );
      case Offer_Availability_Enum.Stoped:
        return (
          <>
            <Button
              disabled={!isOfferEditAccess}
              sx={{ maxWidth: 'fit-content', height: '32px' }}
              onClick={() => handleChangeStatus(Offer_Availability_Enum.Active)}
            >
              {t('web_action_run')}
            </Button>
            <Button
              disabled={!isOfferEditAccess}
              variant={'visible'}
              onClick={() => handleChangeStatus(Offer_Availability_Enum.Closed)}
            >
              {t('web_action_complete')}
            </Button>
            <CustomActionMenu options={actionStoppedOptions} />
          </>
        );
      case Offer_Availability_Enum.Closed:
        return (
          <>
            <Button
              disabled={!isOfferEditAccess}
              sx={{ maxWidth: 'fit-content', height: '32px' }}
              onClick={() => handleChangeStatus(Offer_Availability_Enum.Active)}
            >
              {t('web_action_run')}
            </Button>
          </>
        );
    }
  };

  return (
    <Wrapper>
      <HeadRowCSS>
        {scheduleValue ? (
          <Typography variant={'sm500'}>{scheduleValue}</Typography>
        ) : null}

        <Typography variant={'md500'} sx={{ marginLeft: 'auto' }}>
          ${budgetPerPerson}
        </Typography>
      </HeadRowCSS>
      <MainCSS>
        <MainTypographyCSS>{name}</MainTypographyCSS>
        <ImageRowCSS>
          <RestaurantIcon />
          <Typography
            variant={'sm550'}
            maxWidth={'287px'}
            letterSpacing={'-0.32px'}
          >
            {restaurants?.[0]?.address}
            {restaurants && restaurants.length > 1 && (
              <Typography variant={'sm550'} fontWeight={500}>
                {' '}
                {t('web_offer_item_restaurant_more_label')}{' '}
                {restaurants.length - 1}
              </Typography>
            )}
          </Typography>
        </ImageRowCSS>
      </MainCSS>
      <ButtonCroupCSS>{itemActions(orderStatus)}</ButtonCroupCSS>
    </Wrapper>
  );
};
