import { Autocomplete, IconButton, TextField, Typography } from '@mui/material';
import React from 'react';
import { useField, useFormikContext } from 'formik';
import { ChevronBottom, CloseChipIcon } from '../../Icons/index';
import { palette } from '../../../theme/foundations/index';
import { IAutocompleteFormikProps } from './types';
import { LabelCSS, Wrapper } from './styles';

export const AutocompleteFormik = ({
  name,
  options,
  placeholder,
  sx,
  label,
}: IAutocompleteFormikProps) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  const hasError = Boolean(meta.error && meta.touched);

  return (
    <Wrapper sx={{ ...sx }}>
      {label && (
        <LabelCSS>
          {label}
          {hasError ? (
            <Typography ml={'auto'} variant='error'>
              {meta.error}
            </Typography>
          ) : null}
        </LabelCSS>
      )}

      <Autocomplete
        multiple
        size={'medium'}
        value={field.value}
        onChange={(event, newValue) => {
          setFieldValue(name, [...newValue]);
        }}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        options={options}
        renderInput={(params) => (
          <TextField {...params} placeholder={placeholder} error={hasError} />
        )}
        popupIcon={<ChevronBottom color={palette.brown500} />}
        ChipProps={{
          deleteIcon: (
            <IconButton>
              <CloseChipIcon />
            </IconButton>
          ),
        }}
      />
    </Wrapper>
  );
};
