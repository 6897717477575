import { Box, Typography } from '@mui/material';
import { Props } from './types';
import { CircularIndeterminate } from '../../CircularIndeterminate/CircularIndeterminate';

export const RequestHandler = ({
  loading,
  error,
  children,
  height = '100vh',
}: Props) => {
  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: height,
          width: 'auto',
        }}
      >
        <CircularIndeterminate />
      </Box>
    );
  }

  if (error) {
    return <Typography variant='h2'>{error.message}</Typography>;
  }
  return <>{children}</>;
};
