import * as yup from 'yup';
import { MESSAGES } from '../messages';

export const editFiltersSchema = yup.object().shape({
  countOfGuestFrom: yup
    .number()
    .positive(MESSAGES.INVALID_VALUE)
    .required(MESSAGES.REQUIRE_MESSAGE),
  countOfGuestTo: yup
    .number()
    .positive(MESSAGES.INVALID_VALUE)
    .required(MESSAGES.REQUIRE_MESSAGE),
});
