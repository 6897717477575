import React from 'react';
import { Typography } from '@mui/material';
import { CenterYCSS, ImageBlockCSS, Wrapper } from './styles';
import { IHeaderForRestaurantAndUserProps } from './types';
import { BackButton } from '../UI/BackButton/BackButton';

export const HeaderForBrand = ({
  urlImage,
  brandName,
}: IHeaderForRestaurantAndUserProps) => {
  return (
    <Wrapper mb={'10px'}>
      <CenterYCSS>
        <BackButton sx={{ marginRight: '26px' }} />
        <ImageBlockCSS>
          {urlImage && <img src={urlImage} alt={''} />}
        </ImageBlockCSS>
        <Typography variant={'md400'}>{brandName}</Typography>
      </CenterYCSS>
    </Wrapper>
  );
};
